import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColorType } from 'types/colorTypes';

import { DROPDOWN_DATA } from '../constants';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  iconColor: ColorType;
};

const CounselDetailRightButton = ({ setIsOpen, iconColor }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermission } = usePermission();
  const hasCounselEdit = hasPermission(PERMISSION.counsel.edit);
  const hasCounselDelete = hasPermission(PERMISSION.counsel.delete);

  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const clickDropdown = useCallback(
    (value: string) => {
      const queryString = location.search ? `${location.search}&mode=update` : '?mode=update';

      switch (value) {
        case 'update':
          navigate(`/counsel/form${queryString}`);
          break;
        case 'remove':
          setIsOpen(true);
          break;
      }
    },
    [location.search, navigate, setIsOpen],
  );

  const dropdownContent = useMemo(
    () => (
      <ul>
        {DROPDOWN_DATA.map(({ value, label, icon }) => {
          return (
            <DropdownItem key={value}>
              <Button
                className="counsel-detail-right-button"
                size="large60"
                textColor={value === 'remove' ? 'secondary3' : 'gray2'}
                gap={32}
                rightIcon={<Icon name={icon.name} size={16} fillColor={icon.fillColor} />}
                padding={{ left: 16, right: 12 }}
                onClick={() => clickDropdown(value)}>
                {label}
              </Button>
            </DropdownItem>
          );
        })}
      </ul>
    ),
    [clickDropdown],
  );

  if (!hasCounselEdit || !hasCounselDelete) return null;

  return (
    <DropDown
      isOpen={isMoreOpen}
      content={dropdownContent}
      position="right"
      bottomSpacing={12}
      onClose={() => setIsMoreOpen(false)}>
      <IconButton onClick={() => setIsMoreOpen(!isMoreOpen)}>
        <Icon name="headerMore" color={iconColor} />
      </IconButton>
    </DropDown>
  );
};

export default CounselDetailRightButton;

const DropdownItem = styled.li`
  :not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray7};
  }

  .counsel-detail-right-button {
    min-height: 48px;
  }
`;
