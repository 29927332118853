import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostBoardNotice = () => {
  const queryClient = useQueryClient();
  const { mutationFn } = useMutationFnWithCommonParams<BoardNoticeParams>({
    method: `post`,
    url: `/api/board/notice`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['board/notice'] });
    },
  });
};

export default usePostBoardNotice;

export type BoardNoticeParams = {
  title: string;
  contents: string;
  is_top_fixed: boolean;
  target_member: 'both' | 'active' | 'expired';
  is_used_period: boolean;
  is_unlimited_period: boolean;
  popup_on: boolean;
  start_on?: string;
  end_on?: string;
  attachment_id: number[];
};
