import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import usePatchUserTicketRefund from 'hooks/service/mutations/usePatchUserTicketRefund';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_REFUND_TEXT } from 'pages/TicketDetail/constants';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TicketPaymentFormType } from 'sharedComponents/TicketForm/types';
import getPaymentFormParams from 'utils/getPaymentFormParams';

type Props = UserTicketProps & {
  onClose: () => void;
  productTypeText: string;
};

const RefundConfirmDialog = ({ userTicket, productTypeText, onClose }: Props) => {
  const { getValues } = useFormContext<TicketPaymentFormType>();
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: refundMutate } = usePatchUserTicketRefund(userTicket.id);
  const isUserTicket = userTicket.ticket.available_class_type !== 'I';

  const clickRefund = () => {
    const values = getValues();
    const paymentParams = getPaymentFormParams({ ...values });
    const params = {
      ...paymentParams,
      user_id: userTicket.user_id,
    };

    refundMutate(params, {
      onSettled: onClose,
      onSuccess: () => {
        setToast({ type: 'success', message: `${productTypeText}${TICKET_REFUND_TEXT.successMessage}` });
        navigate(-1);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      title={isUserTicket ? `${TICKET_REFUND_TEXT.dialog.title}` : undefined}
      negativeAction={{ text: TICKET_REFUND_TEXT.dialog.button.negative }}
      positiveAction={{ text: TICKET_REFUND_TEXT.dialog.button.positive, onClick: clickRefund }}>
      <DialogDescription>{TICKET_REFUND_TEXT.dialog.description[isUserTicket ? 'userTicket' : 'item']}</DialogDescription>
    </Dialog>
  );
};

export default RefundConfirmDialog;
