import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FieldType } from 'pages/MoreDetails/Staff/Form/types';
import { useState } from 'react';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import filters from 'utils/filters';
import staffUtils from 'utils/staffSchedules';

import WorkTimeAccordion from '../../WorkTime/components/WorkTimeAccordion';
import { CLOSED_DAY_TIME } from '../../WorkTime/constants';
import { EVENT_WORK_TIME_TITLE } from '../constants';
import EventRestTimeAccordion from './EventRestTimeAccordion';
import SubHeader from './SubHeader';

type Props = {
  index: number;
  /**
   * 해당 요일의 근무시간 데이터
   */
  eventWorkTimeField: ScheduleFormType;
  /**
   * 근무시간 전체 배열, 일괄 적용에 사용
   */
  filterdEventWorkTimeFields: ScheduleFormType[];
  /**
   * 해당 요일의 휴식시간 데이터
   */
  eventRestTimeFields: ScheduleFormType[];
  updateTime: (index: number, value: ScheduleFormType, type: FieldType) => void;
  removeRestTime: (index: number) => void;
  appendTime: (value: ScheduleFormType, type: FieldType) => void;
  replaceFields: (newFields: ScheduleFormType[], type: FieldType) => void;
  /**
   * 휴식시간 전체 배열, remove 및 update에 사용되는 fields index 확인용
   */
  eventRestTimeFieldsOrigin: ScheduleFormType[];
  /**
   * 같은 요일 일괄 적용 버튼 노출 유무
   * 조건: 같은 요일이 여러개면 가장빠른 날짜의 카드에만 버튼 노출
   */
  isSameDayApply: boolean;
  /**
   * true이면 근무시간이 카드 형태로 노출
   */
  shapeCard: boolean;
};

const EventWorkTimeCard = ({
  index,
  eventWorkTimeField,
  filterdEventWorkTimeFields,
  eventRestTimeFields,
  updateTime,
  removeRestTime,
  appendTime,
  replaceFields,
  eventRestTimeFieldsOrigin,
  isSameDayApply,
  shapeCard,
}: Props) => {
  const [isClosedChecked, setIsClosedChecked] = useState<boolean>(!!eventWorkTimeField?.isClosed);

  const appendRestTimeAction = (day: ScheduleFormType['day_of_week']) => {
    // 시간만으로는 dayjs 계산이 불가능해서, 오늘 날짜 기준으로 객체를 가지고와 계산 진행
    const end_time = dayjs(`${dayjs().format('YYYY-MM-DD')} ${eventWorkTimeField?.start_time}`)
      .add(30, 'minute')
      .format('HH:mm:ss');

    appendTime(
      {
        id: '',
        date: eventWorkTimeField?.date,
        type: 'eventRestTime',
        day_of_week: day,
        start_time: eventWorkTimeField?.start_time,
        end_time,
        isClosed: false,
        created_at: '',
        updated_at: '',
      },
      'rest',
    );
  };

  // 휴무일은 일괄 적용에 포함 X
  const batchApplyFields = () => {
    const newWorkTimeFields = filterdEventWorkTimeFields.map(fieldOrigin => {
      if (fieldOrigin.isClosed) return fieldOrigin;
      return {
        ...eventWorkTimeField,
        day_of_week: fieldOrigin.day_of_week,
        date: fieldOrigin.date,
      };
    });

    const newRestTimeFields = filterdEventWorkTimeFields.flatMap(fieldOrigin => {
      if (fieldOrigin.isClosed) return [];

      /** 휴식시간을 현재 선택되어있는 날짜 데이터들에 맞춰 가공 */
      const restTimeSet = eventRestTimeFields.map(restTimeField => {
        return {
          ...restTimeField,
          id: '',
          date: fieldOrigin.date,
          isClosed: fieldOrigin.isClosed,
          created_at: '',
          updated_at: '',
        };
      });
      return [...restTimeSet];
    });

    replaceFields(newWorkTimeFields, 'work');
    replaceFields(newRestTimeFields, 'rest');
  };

  return (
    <Container isClosedChecked={isClosedChecked} shapeCard={shapeCard}>
      <section className="card-header">
        <Typography size={15} weight={600} textColor="gray1">
          {filters.dateWithWeekday(eventWorkTimeField.date)}
        </Typography>

        <CheckBox
          id={`closed-checkbox-${index}`}
          label={EVENT_WORK_TIME_TITLE.closed}
          gap={8}
          checked={isClosedChecked}
          labelFontWeight={500}
          onChange={({ currentTarget: { checked: isClosed } }) => {
            const commonValue = { ...eventWorkTimeField, isClosed };
            /** 휴무일 해제 시, start, end를 기본값으로 돌리기 위한 조건 */
            const isDefaultApply =
              !isClosed &&
              eventWorkTimeField.start_time === CLOSED_DAY_TIME.start &&
              eventWorkTimeField.end_time === CLOSED_DAY_TIME.end;
            const updateValue = isDefaultApply ? { ...commonValue, start_time: '09:00:00', end_time: '18:00:00' } : commonValue;

            setIsClosedChecked(!isClosedChecked);
            updateTime(index, updateValue, 'work');
          }}
        />
      </section>

      {!isClosedChecked && (
        <>
          <section className="work-time-info">
            <SubHeader
              title={EVENT_WORK_TIME_TITLE.work}
              diffText={staffUtils.workTimeDiff(eventWorkTimeField?.start_time, eventWorkTimeField?.end_time)}
            />
            <WorkTimeAccordion field={eventWorkTimeField} index={index} updateTime={updateTime} />
          </section>

          <Divider thin />

          <section className="rest-time-info">
            <SubHeader
              title={EVENT_WORK_TIME_TITLE.rest}
              diffText={staffUtils.restTimeDiff(eventRestTimeFields, eventWorkTimeField)}
              onClick={() => {
                // 요일만 파악하면 되므로 workTime 데이터 사용
                const day = eventWorkTimeField?.day_of_week;
                appendRestTimeAction(day);
              }}
            />
            <ul className="rest-time-list">
              {eventRestTimeFields.map((eventRestTimeField, index) => {
                const isLastIndex = index === eventRestTimeFields.length - 1;
                const restTimeIndex = _.findIndex(eventRestTimeFieldsOrigin, { id: eventRestTimeField.id });

                return (
                  <li key={`${eventRestTimeField.day_of_week}-${restTimeIndex}`}>
                    {index > 0 && <Divider thin />}

                    <div className="rest-time-accordion-wrapper">
                      <EventRestTimeAccordion
                        field={eventRestTimeField}
                        index={restTimeIndex}
                        updateTime={updateTime}
                        startWorkTime={eventWorkTimeField.start_time}
                        endWorkTime={eventWorkTimeField.end_time}
                        isLastIndex={isLastIndex}
                        removeRestTime={removeRestTime}
                        appendRestTimeAction={appendRestTimeAction}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>

          {shapeCard && isSameDayApply && (
            <div className="batch-apply-button">
              <Button
                variant="outlined"
                size="small"
                widthSize={124}
                fontSize={13}
                fontWeight={600}
                textColor="gray3"
                onClick={batchApplyFields}>
                같은 요일 일괄 적용
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default EventWorkTimeCard;

const Container = styled.section<{ isClosedChecked: boolean; shapeCard: boolean }>(
  css`
    border-radius: 16px;
    background-color: ${theme.color.white};
    box-shadow: 0;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    .card-header {
      ${theme.flex('row', 'center', 'space-between')};
      padding: 0 20px;

      .checkbox > .typography {
        font-size: 1.4rem;
        color: ${theme.color.gray2} !important;
      }
    }

    .work-time-info {
      margin-top: 32px;
    }

    .rest-time-info {
      margin-top: 16px;
    }

    .work-time-info,
    .rest-time-info {
      > div {
        ${theme.flex('row', 'center', 'space-between')};

        &:first-of-type {
          margin-bottom: 8px;
        }

        .accordion {
          .header-box,
          .rest-time-info {
            width: 100%;
          }

          .rest-time-info {
            ${theme.flex('row', 'center', 'space-between')};
          }

          .header-wrapper {
            margin-bottom: 16px;
          }

          .header-wrapper.accordion__large {
            padding: 0;
            height: 22px;
          }
        }
      }

      .accordion {
        width: 100%;
      }

      .add-rest-time {
        margin-top: 16px;
      }
    }

    .rest-time-info {
      .header-wrapper {
        margin-bottom: 12px;
        padding: 0;
        height: inherit;
      }

      li:not(:first-of-type) > hr {
        margin-bottom: 12px;
      }

      li:is(:last-of-type) {
        .header-wrapper {
          margin-bottom: 0;
        }
      }
    }

    .batch-apply-button {
      ${theme.flex('', '', 'flex-end')};
      margin-top: 18px;
      padding: 0 18px;
    }
  `,

  ({ isClosedChecked }) => css`
    .card-header .checkbox > .typography {
      color: ${theme.color[isClosedChecked ? 'gray4' : 'gray2']};
    }
  `,

  ({ shapeCard }) =>
    shapeCard &&
    css`
      padding: 20px 0;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

      .card-header {
        padding: 0 20px;
      }
    `,
);
