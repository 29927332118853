import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import { CounselingDetail } from 'hooks/service/queries/useGetCounseling';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { currentCounselingAtom } from 'recoil/counseling';
import { InfoFlexRow, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';
import getEllipsisByLength from 'utils/getEllipsisByLength';
import getImageUrl from 'utils/getImageUrl';

import { CHANNEL_DATA, FUNNEL_DATA } from '../constants';
import { ConvertToLabelType } from '../type';

type Props = {
  counsel: CounselingDetail;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteCounsel: () => void;
};

const CounselDetailInfo = ({ counsel, isOpen, setIsOpen, deleteCounsel }: Props) => {
  const navigate = useNavigate();

  const setCurrentCounseling = useSetRecoilState(currentCounselingAtom);

  const convertToLabel = (array: Array<ConvertToLabelType>, value?: string | number) => {
    const result = _.find(array, { value });
    return result ? result.label : '-';
  };

  useEffect(() => {
    if (!counsel) return;
    setCurrentCounseling(counsel);
  }, [counsel, setCurrentCounseling]);

  return (
    <>
      <FieldWrapper>
        <InfoTypography size={15}>상담일시</InfoTypography>
        <InfoTypography size={15} weight={500} textColor="gray1">
          {`${filters.dateWithWeekday(counsel.created_at)} ${filters.timePeriod(counsel.start_on, counsel.end_on)}`}
        </InfoTypography>
      </FieldWrapper>
      <FieldWrapper>
        <InfoTypography size={15}>고객명</InfoTypography>
        <InfoTypography size={15} weight={500} textColor="gray1">
          {counsel.name}
        </InfoTypography>
      </FieldWrapper>
      <FieldWrapper>
        <InfoTypography size={15}>휴대폰 번호</InfoTypography>
        <InfoTypography size={15} weight={500} textColor="gray1">
          {counsel.phone ? filters.mobile(counsel.phone) : '-'}
        </InfoTypography>
      </FieldWrapper>
      <FieldWrapper>
        <InfoTypography size={15}> 회원등록 여부</InfoTypography>
        <InfoTypography size={15} weight={500} textColor="gray1">
          {counsel.user_id ? '회원' : '비회원'}
        </InfoTypography>
      </FieldWrapper>
      <FieldWrapper>
        <InfoTypography size={15}>상담유형</InfoTypography>
        <InfoTypography size={15} weight={500} textColor="gray1">
          {convertToLabel(CHANNEL_DATA, counsel.channel)}
        </InfoTypography>
      </FieldWrapper>
      <FieldWrapper>
        <InfoTypography size={15}>유입경로</InfoTypography>
        <InfoTypography size={15} weight={500} textColor="gray1">
          {convertToLabel(FUNNEL_DATA, counsel.funnel)}
        </InfoTypography>
      </FieldWrapper>

      <FieldWrapper className="staff-wrapper">
        <InfoTypography size={15}>담당스태프</InfoTypography>
        <div className="avatar-wrapper">
          <Avatar
            size={24}
            imgUrl={counsel.staff?.staff_avatar?.image ? getImageUrl(counsel?.staff?.staff_avatar.image) : undefined}
          />
          <Typography size={15} weight={500}>
            {getEllipsisByLength({ text: counsel.staff?.name || '', max: 10 })}
          </Typography>
        </div>
      </FieldWrapper>
      <ContentWrapper>
        <Typography size={15} textColor="gray2">
          상담내용
        </Typography>
        <Typography size={15} weight={500} tag="pre">
          {counsel.contents}
        </Typography>
      </ContentWrapper>

      {!counsel.user_id && (
        <ButtonWrapper>
          <Button
            className="counsel-detail-button"
            fullWidth
            variant="outlined"
            size="large56"
            textColor="gray2"
            fontSize={14}
            onClick={() => navigate('/member/form/create')}>
            회원등록
          </Button>
        </ButtonWrapper>
      )}

      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          negativeAction={{ text: '취소' }}
          positiveAction={{ text: '삭제', onClick: deleteCounsel }}>
          <DialogDescription>상담을 삭제하시겠습니까?</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default CounselDetailInfo;

const FieldWrapper = styled(InfoFlexRow)`
  height: 56px;
  padding: 0 20px;
  border-bottom: 1px solid ${theme.color.gray8};
  align-items: center;

  &.staff-wrapper {
    .typography:last-of-type {
      text-align: left;
    }

    .avatar-wrapper {
      ${theme.flex('', 'center', '', 6)};
    }
  }
`;

const ContentWrapper = styled.div`
  ${theme.flex('column', '', '', 8)}
  padding: 16px 20px;
`;

const ButtonWrapper = styled.div`
  padding: 16px 20px 32px;

  .counsel-detail-button {
    min-height: 48px;
  }
`;
