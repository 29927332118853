import styled from '@emotion/styled';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { FieldType, TimeDatasetType } from 'pages/MoreDetails/Staff/Form/types';
import { useMemo, useState } from 'react';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import filters from 'utils/filters';

import { DEFAULT_RANGE } from '../constants';

type Props = {
  field?: TimeDatasetType;
  index: number;
  updateTime: (index: number, value: ScheduleFormType, type: FieldType) => void;
  startWorkTime: DateType;
  endWorkTime: DateType;
  isLastIndex: boolean;
  removeRestTime: (index: number) => void;
  appendRestTimeAction: (day: ScheduleFormType['day_of_week']) => void;
};

const EventRestTimeAccordion = ({
  field,
  index,
  updateTime,
  startWorkTime,
  endWorkTime,
  isLastIndex,
  removeRestTime,
  appendRestTimeAction,
}: Props) => {
  const [timeRange, setTimeRange] = useState<[Date, Date]>(DEFAULT_RANGE);

  const { start_time, end_time } = field || {};

  const timeRangeDataset = useMemo(() => {
    const startHour = start_time?.slice(0, 2);
    const startMinute = start_time?.slice(3, 5);
    const endHour = end_time?.slice(0, 5);
    const endMinute = end_time?.slice(3, 5);

    return {
      start: {
        hour: parseInt(startHour || '09'),
        minute: parseInt(startMinute || '00'),
      },
      end: {
        hour: parseInt(endHour || '18'),
        minute: parseInt(endMinute || '00'),
      },
    };
  }, [start_time, end_time]);

  const timeText = useMemo(() => {
    return filters.timePeriodForOnlyTime(start_time, end_time);
  }, [start_time, end_time]);

  const startMaxTime = useMemo(() => {
    const formatDate = dayjs(dayjs().format(`YYYY-MM-DD ${endWorkTime}`)).subtract(5, 'minute');
    return dayjs(formatDate).format('HH:mm:00');
  }, [endWorkTime]);

  const endMinTime = useMemo(() => {
    const formatDate = dayjs(timeRange[0]).add(5, 'minute');
    return dayjs(formatDate).format('HH:mm:00');
  }, [timeRange]);

  return (
    <Container isLastIndex={isLastIndex}>
      <Accordion
        onChange={() => {
          const { start, end } = timeRangeDataset;
          setTimeRange([
            dayjs().set('hour', start.hour).set('minute', start.minute).toDate(),
            dayjs().set('hour', end.hour).set('minute', end.minute).toDate(),
          ]);
        }}
        header={
          <AcordionHeader>
            <Typography size={15} weight={600} textColor="gray2">
              {timeText}
            </Typography>

            <div className="icon-wrapper">
              <IconButton
                size="small"
                bgColor="gray6"
                widthSize={28}
                onClick={e => {
                  e.stopPropagation();
                  removeRestTime(index);
                }}>
                <Icon name="minusBold" size={16} fillColor={theme.color.gray1} />
              </IconButton>
              {isLastIndex && (
                <IconButton
                  size="small"
                  bgColor="gray6"
                  widthSize={28}
                  onClick={e => {
                    e.stopPropagation();
                    appendRestTimeAction(field?.day_of_week as TimeDatasetType['day_of_week']);
                  }}>
                  <Icon name="plusBold" size={16} fillColor={theme.color.gray1} />
                </IconButton>
              )}
            </div>
          </AcordionHeader>
        }
        showArrow={false}>
        <Divider thin />
        <RangeTimePicker
          stepMinute={5}
          timeRange={timeRange}
          onChange={value => {
            const start_time = dayjs(value[0]).format('HH:mm:00');
            const end_time = dayjs(value[1]).format('HH:mm:00');
            updateTime(index, { ...field, start_time, end_time } as ScheduleFormType, 'rest');
            setTimeRange(value);
          }}
          startMinTime={startWorkTime}
          startMaxTime={startMaxTime}
          endMinTime={endMinTime}
          endMaxTime={endWorkTime}
        />
        {isLastIndex && <Divider className="last-line" thin />}
      </Accordion>
    </Container>
  );
};

export default EventRestTimeAccordion;

const Container = styled.div<{ isLastIndex: boolean }>`
  hr {
    ${({ isLastIndex }) => isLastIndex && 'margin-top: 12px'};
  }

  .last-line {
    margin-top: 0;
  }
`;

const AcordionHeader = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 0 20px;
  width: 100%;

  .icon-wrapper {
    ${theme.flex('row', 'center', 'center', 8)};
  }
`;
