import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import localStorage from 'utils/localStorage';

import { SETTINGS_DELETE_ACCOUNT, SETTINGS_LOGOUT } from '../constants';
import SettingItem from './SettingItem';

const SettingBottom = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    // 튜토리얼은 앱을 설치 했을 때 최초 1회만 떠야 하므로 튜토리얼을 남기고 나머지는 삭제
    localStorage.clear();
    navigate('/login');
  };

  return (
    <>
      <SettingItem label={SETTINGS_LOGOUT} onClick={() => setIsOpen(true)} />
      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          positiveAction={{ text: '로그아웃', onClick: logout }}
          negativeAction={{ text: '취소', onClick: () => setIsOpen(false), style: 'outlined' }}>
          <Typography size={15} textColor="gray1">
            정말 로그아웃 하시겠습니까?
          </Typography>
        </Dialog>
      )}
      <SettingItem label={SETTINGS_DELETE_ACCOUNT.name} onClick={() => navigate(SETTINGS_DELETE_ACCOUNT.path)} />
    </>
  );
};

export default SettingBottom;
