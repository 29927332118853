import { queryClient } from 'api/queryClient';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

import { MORE_DETAILS_TEXT } from '../constants';
import StudioSelectButton from './StudioSelectButton';

const StudioChangeSelect = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [currentStudioId, setCurrentStudioId] = useRecoilState(studioIdAtom);
  const setCurrentStaffId = useSetRecoilState(staffIdAtom);
  const { data } = useGetStudio();

  const { control, watch } = useForm<{ studioId: string }>({
    defaultValues: { studioId: `${currentStudioId}` },
  });

  const selectedStudioId = watch('studioId');
  const selectedStudio = data.studios.find(studio => studio.id === Number(selectedStudioId));

  useEffect(() => {
    if (!selectedStudio) return;
    setCurrentStudioId(selectedStudio.id);
    setCurrentStaffId(selectedStudio.staff_id);
    if (currentStudioId !== selectedStudio.id) {
      queryClient.clear();
    }
  }, [currentStudioId, selectedStudio, setCurrentStaffId, setCurrentStudioId]);

  const { setToast } = useToast();
  const closeWithToastIfNotSelected = () => {
    if (!currentStudioId) setToast({ message: MORE_DETAILS_TEXT.notSelected.toast });
    setIsOpened(false);
  };

  return (
    <>
      <StudioSelectButton
        onClick={() => setIsOpened(true)}
        value={selectedStudio?.name}
        placeholder={MORE_DETAILS_TEXT.notSelected.select}
        isActive={isOpened}
      />
      <CheckRadioDrawer
        isOpen={isOpened}
        onClose={closeWithToastIfNotSelected}
        header={MORE_DETAILS_TEXT.notSelected.select}
        name="studioId"
        data={data.studios.map(studio => ({
          id: `${studio.id}`,
          label: studio.name,
          value: `${studio.id}`,
        }))}
        control={control}
        onClick={() => setIsOpened(false)}
      />
    </>
  );
};

export default StudioChangeSelect;
