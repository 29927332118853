import { RecoilState } from 'recoil';
import { ParamsType } from 'sharedComponents/Filters/types';

import { DEFAULT_DELETED_CLASS_FILTER_OPTIONS, DEFAULT_DELETED_CLASS_FILTER_TABS } from '../../constant';
import ScheduleCommonFilter from '../ScheduleCommonFilter';
import DeletedClassDrawerFilterOptions from './DeletedLectureDrawerFilterOptions';
import DeletedClassWholeFilterOptions from './DeletedLectureWholeFilterOptions';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  assigned?: boolean;
  isShowMoreDetailSchedule: boolean;
};

const DeletedLectureFilter = <T extends ParamsType>({ filterAtom, isShowMoreDetailSchedule }: Props<T>) => {
  return (
    <ScheduleCommonFilter
      filterAtom={filterAtom}
      filters={DEFAULT_DELETED_CLASS_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_DELETED_CLASS_FILTER_TABS}
      isShowMoreDetailSchedule={isShowMoreDetailSchedule}
      renderDrawerOptions={DeletedClassDrawerFilterOptions}
      renderWholeFilterPopupOptions={DeletedClassWholeFilterOptions}
    />
  );
};

export default DeletedLectureFilter;
