import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import DetailMain from './components/DetailMain';

const BookingDetail = () => {
  const currentLectureId = useParamsId();

  return (
    <ApiBoundary>
      <DetailMain currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingDetail;
