import styled from '@emotion/styled';
import Radio from 'components/Radio';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

const AttendanceCountFilter = ({ currentFilter, selectedOption, onClick: changeOption }: FilterProps) => {
  const previousOption = (selectedOption as string[]) ?? [];
  const [isSelected, setIsSelected] = useState(!isEmpty(previousOption));
  const { key, options, title } = currentFilter;
  const [period, setPeriod] = useState(previousOption);

  const changeAttendanceCountDate = (changedPeriod: string[]) => {
    if (!changedPeriod[0] || !changedPeriod[1]) return true;
    changeOption({ value: changedPeriod.map(date => filters.dateDash(date)), label: '' }, 'attendance_count');
    setPeriod(changedPeriod);
    return false;
  };

  const unsetAttendanceCountDate = () => {
    setIsSelected(false);
    changeOption({ value: undefined, label: '' }, 'attendance_count');
  };

  return (
    <Container>
      <FilterTitle title={title} />

      <Radio
        id={`${key}-0`}
        name={key}
        label={options[0].label}
        fontSize={14}
        checked={!isSelected}
        onChange={unsetAttendanceCountDate}
      />
      <Radio
        id={`${key}-1`}
        name={key}
        label={options[1].label}
        fontSize={14}
        checked={isSelected}
        onChange={() => setIsSelected(true)}
      />

      <div className="date-wrapper">
        <PeriodPicker
          initialDate={period}
          value={period}
          startDateDisabled={!isSelected}
          endDateDisabled={!isSelected}
          onSave={changeAttendanceCountDate}
        />
      </div>
    </Container>
  );
};

export default AttendanceCountFilter;

const Container = styled(FilterWrap)`
  label:first-of-type {
    margin-bottom: 8px;
  }

  .date-wrapper {
    margin-top: 12px;
    padding-left: 32px;
  }
`;
