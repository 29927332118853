import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';

/** 그룹 모든 수업 삭제 */
const useDeleteLectureGroupAll = (lecture: LectureDetailResponse) => {
  const { id, course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/lecture/group/all/${course_id}`,
  });

  return useMutation({
    mutationFn,
    mutationKey: ['lecture', 'delete', 'group', 'all', course_id],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', id],
      });
    },
  });
};

export default useDeleteLectureGroupAll;
