import usePostLectureGroup from 'hooks/service/mutations/usePostLectureGroup';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { formatBookingGroupParams } from 'pages/Booking/utils';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingGroupCreateFormType } from '../../types';
import CommonGroupForm from '../components/CommonGroupForm';

const GroupCreateForm = () => {
  const { handleSubmit } = useFormContext<BookingGroupCreateFormType>();

  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();
  const { mutate: createGroupLecture } = usePostLectureGroup();

  const submit = (values: BookingGroupCreateFormType) => {
    const params = formatBookingGroupParams(values);
    createGroupLecture(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.create, bottom: 76 });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(<FailAllBooking fails={error.response?.data} />);
        } else {
          errorDialog.open(error.response?.data.message);
        }
      },
    });
  };

  return (
    <CommonGroupForm pageMode="create" onSubmit={handleSubmit(submit)}>
      <BookingRangeDate isRange={true} />
      <BookingRangeTime pageMode="create" />
    </CommonGroupForm>
  );
};

export default GroupCreateForm;
