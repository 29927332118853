import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import NoAllDayDetail from './NoAllDayDetail';

/** 기타일정 종일이 아닌 상세 */
const EtcScheduleNoAllDayDetail = () => {
  const currentEtcScheduleId = useParamsId();

  return (
    <ApiBoundary>
      <NoAllDayDetail currentEtcScheduleId={currentEtcScheduleId} />
    </ApiBoundary>
  );
};

export default EtcScheduleNoAllDayDetail;
