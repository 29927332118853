import { BookingPrivateParams } from 'hooks/service/mutations/usePostBookingPrivate';

import { BookingPrivateFormType } from '../Private/types';
import formatLectureCommonParams from './formatLectureCommonParams';

const formatBookingPrivateParams = (formValues: BookingPrivateFormType): BookingPrivateParams => {
  const { userTicketIds } = formValues;

  const commonParams = formatLectureCommonParams(formValues);

  const result: BookingPrivateParams = {
    user_ticket_ids: userTicketIds,
    ...commonParams,
  };
  return result;
};

export default formatBookingPrivateParams;
