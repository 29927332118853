import styled from '@emotion/styled';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import usePatchStudioUnSync from 'hooks/service/mutations/usePatchStudioUnSync';
import { Studio } from 'hooks/service/queries/useGetStudio';
import useToast from 'hooks/useToast';
import Josa from 'josa-js';
import { CustomError } from 'types/errorTypes';

import { STUDIO_DELETE_TEXTS } from '../../pages/MoreDetails/Studio/constants';

type Props = {
  studio: Studio;
  onClose: () => void;
};

const StudioDeleteDialog = ({ studio, onClose }: Props) => {
  const { mutate: patchStudioUnSyncMutate } = usePatchStudioUnSync();
  const { setToast } = useToast();
  const params = {
    studio_id: studio.id,
  };

  const deleteStudio = () => {
    patchStudioUnSyncMutate(params, {
      onSettled: onClose,
      onSuccess: () => {
        setToast({ type: 'success', message: '시설이 삭제되었습니다.' });
      },
      onError: (error: CustomError) => {
        setToast({ message: error.response?.data.message ?? error.message });
      },
    });
  };

  const josaAfterStudioName = Josa.c(studio.name, '을/를');

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: STUDIO_DELETE_TEXTS.cancel, onClick: onClose, style: 'outlined' }}
      positiveAction={{ text: STUDIO_DELETE_TEXTS.delete, onClick: deleteStudio }}>
      <Text size={15}>
        <b>[{studio.name}]</b> {josaAfterStudioName}
        <br />
        {STUDIO_DELETE_TEXTS.deletionConfirmation}
        <br />
        {STUDIO_DELETE_TEXTS.deletionDescription}
      </Text>
    </Dialog>
  );
};

export default StudioDeleteDialog;

const Text = styled(Typography)`
  word-break: keep-all;
  > b {
    font-weight: 600;
  }
`;
