import { useIsMutating } from '@tanstack/react-query';
import Form from 'components/Form';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { TicketEditFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { SelectStaffs, ShareTicketSwitch, TicketCountForm, TicketPeriodForm } from 'sharedComponents/TicketForm';

type Props = {
  userTicket: UserTicketResponse;
  onSubmit: (values: TicketEditFormType) => void;
};

const TicketEditForm = ({ userTicket, onSubmit }: Props) => {
  const {
    is_show_cancel_count,
    ticket: { type, available_class_type },
  } = userTicket;

  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<TicketEditFormType>();
  const isMutating = useIsMutating() > 0;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} footerButton={{ text: '수정 완료', disabled: !isDirty || isMutating }}>
      <TicketPeriodForm ticketType={type} />
      <TicketCountForm ticketType={type} isShowCancelCount={!!is_show_cancel_count} />
      {available_class_type !== 'I' && <ShareTicketSwitch />}
      <SelectStaffs />
    </Form>
  );
};

export default TicketEditForm;
