import useQueryString from 'hooks/useQueryString';
import { BookingCreateStateType } from 'pages/Booking/types';
import convertBookingDateCreateFormState from 'pages/Booking/utils/convertBookingDateCreateFormState';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { BookingPrivateFormType } from '../../types';
import ConfirmBeforeBooking from '../components/ConfirmBeforeBooking';
import PrivateFormLayout from '../components/PrivateFormLayout';
import PrivateCreateForm from './PrivateCreateForm';

/** 프라이빗 수업 생성 */
const BookingPrivateCreate = () => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const { state } = useLocation() as { state: BookingCreateStateType };

  const { startDate, endDate, startTime, endTime } = convertBookingDateCreateFormState(
    state ?? {
      selectedDate: new Date(),
      from: 'floating',
    },
  );

  const methods = useForm<BookingPrivateFormType>({
    defaultValues: {
      title: '',
      startDate,
      endDate,
      startTime,
      endTime,
      lectureSize: null,
      userTicketIds: [],
      selectedStaffId: String(currentStaffId),
      roomId: null,
      repeat: null,
      repeatCustomWeekday: [],
      tempRepeatCustomWeekday: [],
      selectedUserTickets: [],
      originUserTicketIds: [],
      tempSelectedUserTickets: [],
      tempUserTicketIds: [],
    },
  });

  const { getAllSearchParams } = useQueryString();
  const { step } = getAllSearchParams();

  return (
    <PrivateFormLayout pageMode="create" {...methods}>
      {step === '2' ? <ConfirmBeforeBooking /> : <PrivateCreateForm />}
    </PrivateFormLayout>
  );
};

export default BookingPrivateCreate;
