import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { AvailableTimeType } from 'designedComponents/Pickers/NumberPicker/AvailableTimePicker';
import { PrimaryColorTypography } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { splitHourMinTypeR } from 'pages/Booking/utils/convertTimes';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import { addZero } from 'utils/getNumberWheel';

import { BookingGroupCreateFormType } from '../../../../types';
import RRType from '../RuleType/RRType';

/**
 * 그룹 수업 등록/복사/이후모든수정 폼의
 * 체크인 가능시간 설정
 */
const GroupCheckInAvailableSetting = () => {
  const { getValues, setValue } = useFormContext<BookingGroupCreateFormType>();
  const navigate = useNavigate();

  const [tempValue, setTempValue] = useState({
    startline: getValues('policy.enterStartline'),
    deadline: getValues('policy.enterDeadline'),
  });
  const { hour: startHour, min: startMin } = splitHourMinTypeR(tempValue.startline);
  const { hour: deadHour, min: deadMin } = splitHourMinTypeR(tempValue.deadline);

  const changeRR = (value: { left: AvailableTimeType; right: AvailableTimeType }) => {
    const { left, right } = value;
    const deadline = left.hour ? left.hour * 60 + left.min : left.min;
    const startline = right.hour ? right.hour * 60 + right.min : right.min;

    setTempValue({
      startline,
      deadline,
    });
  };

  const saveDate = () => {
    setValue('policy.enterStartline', tempValue.startline, { shouldDirty: true });
    setValue('policy.enterDeadline', tempValue.deadline, { shouldDirty: true });
    navigate(-1);
  };

  return (
    <>
      <AccordionWrapper>
        <RRType
          textType="checkIn"
          iconName="checkIn2"
          isOpen
          onToggle={() => null}
          startline={tempValue.startline}
          deadline={tempValue.deadline}
          accordionHeader={
            <AccordionHeader>
              <Typography size={15}>
                수업시작 <PrimaryColorTypography isOpen>{addZero(startHour)}</PrimaryColorTypography>시간
                <PrimaryColorTypography isOpen>{addZero(startMin)}</PrimaryColorTypography>분 전부터 <br />
                수업종료 <PrimaryColorTypography isOpen>{addZero(deadHour)}</PrimaryColorTypography>시간
                <PrimaryColorTypography isOpen>{addZero(deadMin)}</PrimaryColorTypography>분 후까지 <br />
                체크인 가능합니다.
              </Typography>
            </AccordionHeader>
          }
          changeDate={changeRR}
        />
      </AccordionWrapper>
      <FooterButton onClick={saveDate}>완료</FooterButton>
    </>
  );
};

export default GroupCheckInAvailableSetting;

const AccordionWrapper = styled.div`
  .accordion.opened {
    border-bottom: 1px solid ${theme.color.gray6};
  }

  .header-wrapper {
    padding: 16px 20px 17px !important;
    min-height: 100px;
    height: auto !important;
  }
`;

const AccordionHeader = styled.div`
  .primary-color-typography {
    display: inline-block;
    margin-left: 6px;
    margin-right: 2px;
  }
`;
