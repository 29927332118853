import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import IconButton from 'components/Button/IconButton';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import usePermission from 'hooks/usePermission';
import { useState } from 'react';
import { PermissionType } from 'types/permissionTypes';

import DetailDeleteMenu from './DetailDeleteMenu';
import DetailEditCancelMenu from './DetailEditCancelMenu';

type Props<T extends AxiosResponse, U extends AxiosResponse> = {
  isDeletable: boolean;
  permissionNeeded: PermissionType;
  deleteMutate: UseMutateFunction<T>;
  cancelMutate: UseMutateFunction<U>;
  editMessage: () => void;
  message: {
    count: number;
    sendOn: string;
    point?: number;
  };
  type?: 'mobile' | 'push';
};

const MessageMenuButton = <T extends AxiosResponse, U extends AxiosResponse>({
  type,
  isDeletable,
  permissionNeeded,
  deleteMutate,
  cancelMutate,
  editMessage,
  message,
}: Props<T, U>) => {
  const { hasPermission } = usePermission();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  if (!hasPermission(permissionNeeded)) return null;
  return (
    <DropDown
      position="right"
      isOpen={isDropdownOpened}
      onClose={() => setIsDropdownOpened(false)}
      content={
        isDeletable ? (
          <DetailDeleteMenu deleteMutate={deleteMutate} type={type} />
        ) : (
          <DetailEditCancelMenu message={message} editMessage={editMessage} cancelMutate={cancelMutate} />
        )
      }>
      <IconButton onClick={() => setIsDropdownOpened(!isDropdownOpened)}>
        <Icon name="headerMore" />
      </IconButton>
    </DropDown>
  );
};

export default MessageMenuButton;
