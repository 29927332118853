import CounselDrawerFilterOptions from 'pages/Member/components/CounselCustomerFilter/CounselDrawerFilterOptions';
import CounselWholeFilterOptions from 'pages/Member/components/CounselCustomerFilter/CounselWholeFilterOptions';
import { DEFAULT_COUNSEL_FILTER_OPTIONS, DEFAULT_COUNSEL_FILTER_TABS } from 'pages/Member/constants';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import Filter from 'sharedComponents/Filters';

const MessageCounselFilter = () => {
  return (
    <Filter
      filterAtom={messageTargetCounselSelectedFilter}
      filters={[
        /** 회원페이지 상담고객 필터에 회원등록 여부가 추가되어야 함 */
        DEFAULT_COUNSEL_FILTER_OPTIONS[0],
        {
          key: 'register',
          title: '회원등록 여부',
          type: 'button',
          options: [
            { value: undefined, label: '전체' },
            { value: 'registered', label: '회원' },
            { value: 'not_registered', label: '비회원' },
          ],
        },
        ...DEFAULT_COUNSEL_FILTER_OPTIONS.slice(1),
      ]}
      defaultTabLabels={{ ...DEFAULT_COUNSEL_FILTER_TABS, register: '회원등록 여부 전체' }}
      renderDrawerOptions={CounselDrawerFilterOptions}
      renderWholeFilterPopupOptions={CounselWholeFilterOptions}
    />
  );
};

export default MessageCounselFilter;
