import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import useDeleteUserTicket from 'hooks/service/mutations/useDeleteUserTicket';
import usePatchUserTicketActive from 'hooks/service/mutations/usePatchUserTicketActive';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { MAIN_DIALOG_TEXT, TICKET_INACTIVE_TEXT } from '../constants';
import { MainDialogStatus } from '../types';

type Props = {
  userTicket: UserTicketResponse;
  dialogStatus: MainDialogStatus;
  onClose: () => void;
};

const ChangeProductStatusConfirmDialog = ({ userTicket, dialogStatus, onClose }: Props) => {
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: deleteUserTicketMutate, isPending: isPendingDelete } = useDeleteUserTicket(userTicket.id);
  const { mutate: inActiveUserTicketMutate, isPending: isPendingInActive } = usePatchUserTicketActive(userTicket.id);

  const changeProductStatus = () => {
    const params = { user_id: userTicket.user_id };

    switch (dialogStatus) {
      case 'delete':
        deleteUserTicketMutate(params, {
          onSettled: onClose,
          onSuccess: () => {
            setToast({
              type: 'success',
              message: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]}이 삭제되었습니다.`,
            });
            navigate(-1);
          },
          onError: error => {
            errorDialog.open(error);
          },
        });
        break;
      case 'reuse':
        inActiveUserTicketMutate(params, {
          onSettled: onClose,
          onSuccess: () => {
            setToast({
              type: 'success',
              message: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]}이 ${TICKET_INACTIVE_TEXT.successMessage.reuse}`,
            });
          },
          onError: error => {
            errorDialog.open(error);
          },
        });
        break;
    }
  };

  const dialogText = useMemo(() => {
    switch (dialogStatus) {
      case 'delete':
        return (
          <>
            삭제된 {PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]}은 복구할 수 없으며, <br />
            결제내역도 확인할 수 없습니다.
          </>
        );
      case 'reuse':
        return;
    }
  }, [dialogStatus, userTicket.ticket.available_class_type]);

  return (
    <Dialog
      onClose={onClose}
      title={`${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]}을 ${MAIN_DIALOG_TEXT[dialogStatus].title}하시겠습니까?`}
      negativeAction={{ text: '취소' }}
      positiveAction={{
        text: MAIN_DIALOG_TEXT[dialogStatus].buttonText,
        onClick: changeProductStatus,
        disabled: isPendingDelete || isPendingInActive,
      }}>
      <DialogDescription>{dialogText}</DialogDescription>
    </Dialog>
  );
};

export default ChangeProductStatusConfirmDialog;
