import Divider from 'components/Divider';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';
import { get } from 'lodash';
import ReportMultiButtonFilter from '../ReportMultiButtonFilter';

const ReportUnpaidWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  return (
    <>
      <ButtonFilter currentFilter={filters[0]} selectedOption={get(filterChanged, filters[0].key)} onClick={changeOption} />
      <Divider />
      <ReportMultiButtonFilter
        selectedOptionKey={filters[1].key as 'paymentMethod'}
        currentAllOptionCount={filters[1].options.length - 1}
        currentFilter={filters[1]}
        selectedOption={get(filterChanged, filters[1].key)}
        onClick={changeOption}
      />
    </>
  );
};

export default ReportUnpaidWholeFilterOptions;
