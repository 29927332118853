import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { customBackHandlerAtom } from 'recoil/common';

import Drawer, { DrawerProps } from '..';
import OneLinedTitle from '../DrawerTitle/OneLinedTitle';
import CheckRadioFilterContents, { CheckRadioFilterContentsProps } from './CheckRadioFilterContents';

export type CheckRadioDrawerProps<T extends FieldValues> = Omit<DrawerProps, 'children'> & CheckRadioFilterContentsProps<T>;

const CheckRadioDrawer = <T extends FieldValues>({
  header,
  name,
  data,
  control,
  showLabelAll,
  onClick,
  ...props
}: CheckRadioDrawerProps<T>) => {
  const { isOpen, onClose } = props;

  const drawerHeader = typeof header === 'string' ? <OneLinedTitle title={header} /> : header;
  const setCustomBackHandler = useSetRecoilState(customBackHandlerAtom);

  /** 안드로이드 물리 백버튼으로 drawer 닫기 */
  useEffect(() => {
    const closeDrawerFirst = () => {
      return isOpen ? onClose : null;
    };

    setCustomBackHandler(closeDrawerFirst);
  }, [setCustomBackHandler, isOpen, onClose]);

  return (
    <Drawer header={drawerHeader} {...props}>
      <CheckRadioFilterContents
        control={control}
        name={name}
        data={data}
        showLabelAll={showLabelAll}
        onClick={onClick}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default CheckRadioDrawer;
