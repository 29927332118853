import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';
import useToast from 'hooks/useToast';
import { get } from 'lodash';
import MobilePeriodFilter from 'pages/MoreDetails/Message/Mobile/List/components/MobilePeriodFilter';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { appPushSelectedFilterAtom } from 'recoil/message';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { APP_PUSH_FILTER } from '../constants';

const AppPushFilterContents = () => {
  // api 호출에 활용되는 실제 필터 적용 값
  const [selectedFilter, setSelectedFilter] = useRecoilState(appPushSelectedFilterAtom);

  // 필터 적용 버튼 이전 UI 상태
  const [filterChanged, setFilterChanged] = useState(selectedFilter);
  const { setToast } = useToast();

  const saveChangedFilter = () => {
    setSelectedFilter({ ...filterChanged, isFilterOpened: false });
    setToast({ message: '필터가 적용되었습니다.', type: 'success' });
  };

  const closeFilterWithReset = () => {
    setSelectedFilter({ ...selectedFilter, isFilterOpened: false });
    setFilterChanged(selectedFilter);
  };

  const changeFilter = (option: FilterOptionsType, key: string) => {
    setFilterChanged(prev => ({ ...prev, [key]: option.value }));
  };

  const [isOpen, setIsOpened] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => setIsOpened(true));
    return () => {
      if (selectedFilter.isFilterOpened) {
        setSelectedFilter(prev => ({ ...prev, isFilterOpened: false }));
      }
      setIsOpened(false);
    };
  }, [selectedFilter.isFilterOpened, setSelectedFilter]);

  return (
    <Container isOpen={!!selectedFilter.isFilterOpened && isOpen}>
      <DimBackground onClick={closeFilterWithReset} />

      <FilterWrapper onClick={e => e.stopPropagation()} isOpen={!!selectedFilter.isFilterOpened && isOpen}>
        {isOpen && (
          <MobilePeriodFilter
            currentFilter={APP_PUSH_FILTER}
            selectedOption={get(filterChanged, 'period')}
            onClick={changeFilter}
            maxMonth={6}
          />
        )}
        <ButtonWrapper>
          <Button fullWidth onClick={saveChangedFilter} variant="contained" color="point" size="medium48">
            필터 적용
          </Button>
        </ButtonWrapper>
      </FilterWrapper>
    </Container>
  );
};

export default AppPushFilterContents;

const ButtonWrapper = styled.div`
  padding: 14px 20px;
  background-color: ${theme.color.gray7};
`;

const Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  ${({ isOpen }) => `
  visibility: ${isOpen ? 'visible' : 'hidden'};
    transition: ${isOpen ? 'opacity 0.3s ease-in, visibility 0s linear 0s' : 'opacity 0.2s ease-out, visibility 0s linear 0.3s'};
`}

  .period-filter {
    padding-top: 20px;
  }
`;

const DimBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: ${Z_INDEX.stickyTab - 3};
  height: 100%;
  width: 100%;
  position: absolute;
`;

const FilterWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  background-color: ${theme.color.white};
  z-index: ${Z_INDEX.stickyTab - 2};
  transition: all 0.2s ease-in-out;

  ${({ isOpen }) => `
  opacity: ${isOpen ? 1 : 0};
  transform: translateY(${isOpen ? '0' : '-100%'});
  `}
`;
