import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityPointHistory = (params: MemberPointHistoryParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MemberPointHistoryResponse, MemberPointHistoryParams>({
    url: `/api/point/history`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['member', 'point', 'history', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityPointHistory;

export type PointPaymentStatusFilterParams = 'purchase' | 'change' | 'transfer' | 'installment_payment' | 'upgrade' | 'refund';
export type PointTypeFilterParams = 'save' | 'remove' | 'destruction';

export type MemberPointHistoryParams = {
  member_id: number;
  start_date: string;
  end_date: string;
  staff_ids?: Array<number>;
  type?: Array<PointTypeFilterParams>;
  status?: Array<PointPaymentStatusFilterParams>;
};

/**
 * 티켓 결제 상태
 * purchase (발급, 구입)
 * change (변경)
 * refund (환불)
 * extension (업그레이드 - 같은 부모 수강권으로 업그레이드)
 * upgrade (업그레이드 - 다른 부모 수강권으로 업그레이드)
 * transfer (양도)
 * installment_payment (미수금)
 * full_payment (완납)
 *   - full_payment 는 DB 상에서 미수금을 완납했을 때 들어가는 값인데,
 *     프론트에서 완납과 할부를 구분 못해서 installment_payment 로 취급하고 같이 보냄
 * downgrade
 *   - 현재 없는 기능
 * pending
 *   - 값이 저장되기 전 0.1초 정도 잠깐 있는 수준의 데이터 (반환되지 않는 값이라고 보면 됨)
 */
export type PaymentStatusType =
  | 'purchase'
  | 'change'
  | 'refund'
  | 'extension'
  | 'upgrade'
  | 'transfer'
  | 'installment_payment'
  | 'full_payment'
  | 'downgrade'
  | 'pending';
export type MemberPointHistoryResponse = {
  id: number;
  studio_id: number;
  user_id: number;
  ref_type: string;
  ref_id: number;
  payment_type: string;
  point_type: 'reward' | 'deduct' | 'mix' | 'destruction' | 'batch_reward' | 'batch_deduct';
  status: PaymentStatusType;
  point_amount: number;
  reward_point: number;
  member: {
    id: number;
    name: string;
  };
  staff: {
    id: number;
    name: string;
  };
  updated_for: string;
  // 백엔드에서 포인트 날짜 필터링을 settlement_at 으로 함
  settlement_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
};
