import { ButtonProps } from 'components/Button';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingPrivateFormType } from '../../types';

type Props = Omit<ButtonProps, 'children' | 'type'>;

const SubmitButton = ({ disabled, ...props }: Props) => {
  const { control } = useFormContext<BookingPrivateFormType>();
  const title = useWatch({ control, name: 'title' });

  return (
    <FooterButton type="submit" disabled={disabled || !title} {...props}>
      예약
    </FooterButton>
  );
};

export default SubmitButton;
