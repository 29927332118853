import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { UserTicketResponse } from '../queries/useInfinityUserTicketProduct';

const usePatchUserTicketUpgrade = (userTicketId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<UserTicketUpgradeParams, UserTicketResponse>({
    method: `patch`,
    url: `/api/userTicket/upgrade/${userTicketId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePatchUserTicketUpgrade;

export type UserTicketUpgradeParams = {
  /** 업그레이드 하는 부모 수강권 아이디 */
  ticket_id: number;
  user_id: number;
  method: string;
  amount: number;
  point_amount: number;
  cash_amount: number;
  card_amount: number;
  wiretransfer_amount: number;
  unpaid_amount: number;
  reward_point: number;
  settlement_at: string;
};
