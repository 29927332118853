import { EventScheduleUpdateFormType } from 'pages/MoreDetails/Staff/Form/types';
import { useForm } from 'react-hook-form';
import getEventTimeDataset from 'utils/getEventTimeDataset';

import useGetStaffEventSchedules from './service/queries/useGetStaffEventSchedules';

type Props = {
  targetStaffId: number;
};

const useEventScheduleFormSet = ({ targetStaffId }: Props) => {
  const { data: eventSchedules } = useGetStaffEventSchedules({ targetStaffId });
  const eventTimeDataset = getEventTimeDataset(eventSchedules);

  const methods = useForm<EventScheduleUpdateFormType>({
    defaultValues: {
      schedules: eventTimeDataset,
    },
  });

  return {
    eventTimeDataset,
    methods,
  };
};

export default useEventScheduleFormSet;
