import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { Color } from 'utils/getColor';

type Props = {
  text: string;
  top?: number;
  textColor?: Color;
};

const NoContentText = ({ text, top = 120, textColor = 'gray2' }: Props) => {
  return (
    <Container top={top}>
      <Typography size={15} textColor={textColor}>
        {text}
      </Typography>
    </Container>
  );
};

export default NoContentText;

const Container = styled.section<{ top?: number }>`
  ${theme.flex()};
  margin: ${({ top }) => top}px 0;
  width: 100%;
`;
