import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Form from 'components/Form';
import SelectBoxSquare from 'components/SelectBox/SelectBoxSquare';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Typography from 'components/Typography';
import { PLACEHOLDER } from 'constants/text';
import usePostJoin from 'hooks/service/mutations/usePostJoin';
import usePostOverlapNickname from 'hooks/service/mutations/usePostOverlapNickname';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import TitleStep from 'pages/FindPassword/components/TitleStep';
import { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { registerDataAtom } from 'recoil/join';
import CompletePopupContents from 'sharedComponents/CompletePopupContents';
import { CustomError } from 'types/errorTypes';
import { birthNumber, memberName, nickNameRequired, teacherNameRequired } from 'utils/validate';
import { object } from 'yup';

import { GENDER_SELECT, SUB_TITLE } from '../constants';
import { BasicInfoFormType } from '../types';

const yupSchema = object().shape({ memberName, teacherNameRequired, nickNameRequired, birth: birthNumber });

const BasicInfoStep = () => {
  const [isOverlapPass, setIsOverlapPass] = useState(false);

  const registerData = useRecoilValue(registerDataAtom);

  const { setToast } = useToast();
  const { setPopup } = usePopup();

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
    control,
    getValues,
    setValue,
    setError,
    getFieldState,
  } = useForm<BasicInfoFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      teacherNameRequired: '',
      nickNameRequired: '',
      birth: '',
      gender: 'F',
    },
  });

  const { mutate: postOverlapNicknameMutate } = usePostOverlapNickname();

  const { mutate: postJoinMutate } = usePostJoin();

  const overlapCheckNickname = useCallback(() => {
    const params = {
      nickname: getValues('nickNameRequired'),
    };

    postOverlapNicknameMutate(params, {
      onSuccess: () => {
        setIsOverlapPass(true);
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('nickNameRequired', { message });
      },
    });
  }, [getValues, postOverlapNicknameMutate, setError]);

  const join: SubmitHandler<BasicInfoFormType> = useCallback(
    async ({ birth: birthday, gender, teacherNameRequired: name, nickNameRequired: nickname }) => {
      if (!isOverlapPass) {
        setToast({ message: '닉네임 중복 여부를 확인해 주세요.', bottom: 76 });
        return;
      }

      const basicInfo = {
        birthday,
        gender,
        name,
        nickname,
      };

      const params = {
        ...registerData,
        ...basicInfo,
      };

      postJoinMutate(params, {
        onSuccess: () => {
          setPopup(<CompletePopupContents tableData={[{ title: '아이디', content: registerData.mobile }]} />);
        },
        onError: (error: CustomError) => {
          const message = error.response?.data.message;
          setError('nickNameRequired', { message });
        },
      });
    },
    [isOverlapPass, setToast, postJoinMutate, registerData, setError, setPopup],
  );

  const subTitleOptions = {
    bottom: 40,
    title: SUB_TITLE.ENTER_BASIC_INFO,
    message: { position: 'bottom', text: '서비스 이용에 꼭 필요한 정보입니다.' },
  };

  return (
    <>
      <TitleStep currentStep={3} subTitleOptions={subTitleOptions} />
      <Form
        onSubmit={handleSubmit(join)}
        footerButton={{ text: '가입 완료', disabled: !isValid || !isDirty || !isOverlapPass || isSubmitting }}>
        <OutlinedTextFieldController
          controllerProps={{ control, name: 'teacherNameRequired' }}
          label="강사명"
          placeholder={PLACEHOLDER.STAFF}
          onClear={() => setValue('teacherNameRequired', '')}
        />

        <OutlinedTextFieldController
          controllerProps={{ control, name: 'nickNameRequired' }}
          label="닉네임"
          placeholder={PLACEHOLDER.NICKNAME}
          onKeyUp={e => !isOverlapPass && e.key === 'Enter' && overlapCheckNickname()}
          suffix={
            <Button
              color="gray"
              fontSize={13}
              fontWeight={500}
              textColor="gray2"
              size="small"
              variant="contained"
              onClick={overlapCheckNickname}
              padding={{ left: 8, right: 8 }}>
              중복확인
            </Button>
          }
          infoMessage={isOverlapPass ? '사용가능한 닉네임' : undefined}
          errorMessage={isOverlapPass ? undefined : getFieldState('nickNameRequired').error?.message}
        />

        <OutlinedTextFieldController
          controllerProps={{ control, name: 'birth' }}
          label="생년월일"
          placeholder={PLACEHOLDER.BIRTHDATE}
          onClear={() => setValue('birth', '')}
        />

        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <div>
              <Typography size={13} weight={500}>
                성별
              </Typography>
              <SelectBoxSquareWrapper gridColumnsNumber={2}>
                {GENDER_SELECT.map(item => (
                  <SelectBoxSquare
                    {...field}
                    key={item.label}
                    type="radio"
                    id={item.label}
                    label={item.label}
                    value={item.value}
                    checked={item.value === field.value}
                  />
                ))}
              </SelectBoxSquareWrapper>
            </div>
          )}
        />
      </Form>
    </>
  );
};

export default BasicInfoStep;

const SelectBoxSquareWrapper = styled.div<{ gridColumnsNumber: number }>(
  ({ gridColumnsNumber }) => css`
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(${gridColumnsNumber}, 1fr);
    margin-top: 8px;
  `,
);
