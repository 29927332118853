import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion, { AccordionProps } from 'components/Accordion';
import Icon from 'components/Icon';
import { SettingByRuleTypeProps } from 'pages/Booking/types';

type Props = AccordionProps &
  Pick<SettingByRuleTypeProps, 'iconName'> & {
    isLast?: boolean;
    noBottomBorder?: boolean;
    height?: number;
  };

const RuleTypeAccordion = ({ children, iconName, ...props }: Props) => {
  return (
    <StyledAccordion
      {...props}
      size="medium"
      showArrow={false}
      leftIcon={iconName ? <Icon name={iconName} color="gray2" /> : undefined}
      gap={12}>
      {children}
    </StyledAccordion>
  );
};

export default RuleTypeAccordion;

const StyledAccordion = styled(Accordion)<{ isLast?: boolean; noBottomBorder?: boolean; height?: number }>(
  css`
    background-color: ${theme.color.white};
    border-bottom: 1px solid ${theme.color.gray8};
    &.update-end-at .header-box {
      padding-left: 36px;
    }

    .header-wrapper {
      border-bottom: none;

      .header-box {
        align-items: flex-start;
      }
    }

    &.opened {
      border-bottom: 1px solid ${theme.color.gray6};

      .header-wrapper {
        border-bottom: 1px solid ${theme.color.gray8};
      }
    }

    .mbsc-datepicker-inline {
      border-bottom: none !important;
    }
  `,
  /** 페이지에서 제일 마지막 아코디언은 닫혔을 때 border 없고, 열리면 위 g8, 아래 g6 */
  ({ isLast }) =>
    isLast &&
    css`
      border-bottom: none;
    `,
  /** Divider 위의 아코디언은 열렸을 때 아래 border 없어야 함 */
  ({ noBottomBorder }) =>
    noBottomBorder &&
    css`
      &.opened {
        border-bottom: none;
      }
    `,
  /** 페이지에서 제일 마지막 아코디언은 닫혔을 때 border 없고, 열리면 위 g8, 아래 g6 */
  ({ height }) =>
    height &&
    css`
      .header-wrapper.accordion__medium {
        padding: 16px 20px 17px !important;
        min-height: ${height}px;
        height: auto !important;

        .header-box {
          align-items: flex-start !important;
        }
      }
    `,
);
