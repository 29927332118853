import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';

type Props = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
};

const ApplyButton = ({ text, disabled, onClick }: Props) => {
  return (
    <Container className="apply-button">
      <Button
        type="submit"
        form="check-submit"
        variant="contained"
        size="large56"
        color="point"
        fullWidth
        onClick={onClick}
        disabled={disabled}>
        {text}
      </Button>
    </Container>
  );
};

export default ApplyButton;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  padding-bottom: 10px;
  width: calc(100% - 40px);
  background-color: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};
`;
