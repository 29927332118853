import { DEFAULT_COUNSEL_FILTER_OPTIONS, DEFAULT_COUNSEL_FILTER_TABS } from 'pages/Member/constants';
import { RecoilState } from 'recoil';
import Filter from 'sharedComponents/Filters';
import { ParamsType } from 'sharedComponents/Filters/types';

import CounselDrawerFilterOptions from './CounselDrawerFilterOptions';
import CounselWholeFilterOptions from './CounselWholeFilterOptions';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
};

const CounselCustomerFilter = <T extends ParamsType>({ filterAtom }: Props<T>) => {
  return (
    <Filter
      filterAtom={filterAtom}
      filters={DEFAULT_COUNSEL_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_COUNSEL_FILTER_TABS}
      renderDrawerOptions={CounselDrawerFilterOptions}
      renderWholeFilterPopupOptions={CounselWholeFilterOptions}
    />
  );
};

export default CounselCustomerFilter;
