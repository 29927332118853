import apiAttachment from 'api/modules/attachment';
import imageCompression from 'browser-image-compression';

/**
 * 이미지 첨부파일 업로드 시 필요한 formData 로 변환 및
 * attachment api로 post 요청 후 반환값으로 attachment id 배열 만드는 utils
 */

const imageUpload = {
  async covertFormData(dataURI: string | ArrayBuffer | null, type: string) {
    if (dataURI && typeof dataURI === 'string') {
      const byteString = window.atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], {
        type: 'image/jpeg',
      });
      const file = new File([blob], 'image.jpg');

      const formData = new FormData();
      formData.append(type, file);
      return formData;
    }
  },

  async imageCompressor(fileSrc: File, type: string) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(fileSrc, options);

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);

        reader.onloadend = async () => {
          const base64data = reader.result;
          resolve(this.covertFormData(base64data, type));
        };
      });
    } catch (error) {
      return error;
    }
  },

  async getAttachmentIds(files: Array<File>, type: string) {
    if (files.length === 0) return null;

    const responses = await Promise.all(
      files.map(async file => {
        const formData = await this.imageCompressor(file, 'file');
        return apiAttachment.post(type, formData);
      }),
    );

    const ids = responses.map(res => res.data.id);
    return ids;
  },
};

export default imageUpload;
