import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import MenuNavigation from './components/MenuNavigation';
import MoreDetailSkeleton from './components/MoreDetailSkeleton';
import ProfileForStudioStaff from './components/ProfileForStudioStaff';
import StudioChangeSelect from './components/StudioChangeSelect';
import StudioUnknown from './components/StudioUnknown';

const MoreDetails = () => {
  const navigate = useNavigate();
  const headerOptions = {
    leftAlign: true,
    rightButton: (
      <IconButton onClick={() => navigate('/more-details/settings')} aria-label="setting">
        <Icon name="headerSetting" />
      </IconButton>
    ),
    noUnderline: true,
  };

  const currentStudioId = useRecoilValue(studioIdAtom);
  const isStudioSelected = !!currentStudioId;

  return (
    <MainLayout bottomNav header={headerOptions}>
      <ApiBoundary fallback={<MoreDetailSkeleton />}>
        {isStudioSelected ? (
          <>
            <StickyWrapper>
              <ProfileForStudioStaff />
              <StudioChangeSelect />
              <DividerWithMargin />
            </StickyWrapper>
            <MenuNavigation />
          </>
        ) : (
          <StudioUnknown />
        )}
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetails;

const DividerWithMargin = styled(Divider)`
  margin: 12px 0;
`;
