import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import filters from 'utils/filters';
import { hexToRgba } from 'utils/styles';

const PointListSkeleton = () => {
  const dummy = ['강사 전체', '구분 전체', '분류 전체'];
  const dummyArray = Array.from({ length: 3 }, (_, i) => i);

  return (
    <>
      <FilterTabSkeleton>
        <FilterCount>
          <Icon name="filterBold" size={16} color="white" />
          <Typography span size={13} weight={600} textColor="white">
            1
          </Typography>
        </FilterCount>
        <SelectedLi>
          <Typography span textColor="primary" size={13} weight={600}>
            {filters.dateYearMonthKor()}
          </Typography>
          <Icon name="arrowBottomBold" size={16} color="primary" />
        </SelectedLi>
        {dummy.map(text => (
          <StyledLi key={text}>
            <Typography span textColor="gray2" size={13}>
              {text}
            </Typography>
            <Icon name="arrowBottomBold" size={16} color="gray3" />
          </StyledLi>
        ))}
      </FilterTabSkeleton>
      <Divider thin thinColor="gray6" />
      <HistoryWrapper>
        {dummyArray.map((_, index) => (
          <li key={index}>
            <div className="first">
              <StyledSkeleton width={26} height={20} />
              <StyledSkeleton height={20} />
            </div>
            <div className="second">
              <StyledSkeleton width={242} height={20} />
              <StyledSkeleton width={26} height={20} />
            </div>
            <StyledSkeleton height={40} />
          </li>
        ))}
      </HistoryWrapper>
    </>
  );
};

export default PointListSkeleton;

const FilterTabSkeleton = styled.ul`
  padding: 12px 0 12px 20px;
  ${theme.flex('row', 'center', '', 4)};
  white-space: nowrap;
  overflow: auto hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FilterCount = styled.li`
  ${theme.flex('row', 'center', '', 2)};
  border-radius: 999px;
  padding: 8px 10px;
  background-color: ${theme.color.primary};
`;

const StyledLi = styled.li`
  ${theme.flex('row', 'center', '', 2)};
  padding: 8px 10px 8px 12px;
  background-color: ${theme.color.gray7};
  border-radius: 999px;
`;

const SelectedLi = styled(StyledLi)`
  background-color: ${hexToRgba(theme.color.primary, 0.12)};
`;

const HistoryWrapper = styled.ul`
  li {
    padding: 20px;
    border-bottom: 1px solid ${theme.color.gray6};

    .first {
      ${theme.flex('row', 'center', '', 8)};
      margin-bottom: 8px;
    }
    .second {
      ${theme.flex('row', 'center', 'space-between')};
      margin-bottom: 16px;
    }
  }
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 3px;
`;
