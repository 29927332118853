import Button from 'components/Button';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import NoPointDrawer from 'sharedComponents/Message/NoPointDrawer';

import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';
import { calculateRequiredPoint } from '../utils';
import MobileSendConfirmDrawer from './MobileSendConfirmDrawer';
import PointCheck from './PointCheck';

type Props = {
  onConfirm: () => void;
};
const MobileConfirmButton = ({ onConfirm }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { getValues, watch, formState } = useFormContext<MobileFormType>();

  const targetCount = watch('target') === 'member' ? watch('users').length : watch('counseling_logs').length;
  // 필수값 미입력 혹은 예약 선택 후 예약 날짜가 없으면 버튼 비활성화
  const isRequirementsFilled = watch('target') && watch('title') && watch('message') && targetCount > 0;
  const isValidReservation = !watch('isReserve') || watch('reservationDate'); // 예약 선택 후 예약 날짜가 없으면 false
  const disabled = !isRequirementsFilled || !isValidReservation || !!formState.errors.title || !!formState.errors.message;

  const mode: 'create' | 'update' = getValues('mode');

  const sendMessage = () => {
    setIsDrawerOpen(false);
    onConfirm();
  };
  return (
    <>
      <Button variant="contained" color="point" size="large56" onClick={() => setIsDrawerOpen(true)} disabled={disabled}>
        {MOBILE_FORM_TEXT[mode].confirmButton}
      </Button>
      <ApiBoundary>
        <PointCheck
          deductPoint={calculateRequiredPoint(getValues())}
          renderNoPoint={({ currentPoint, deductPoint }) => (
            <NoPointDrawer
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              currentPoint={currentPoint}
              deductPoint={deductPoint}
            />
          )}
          renderPointEnough={({ deductPoint }) => (
            <MobileSendConfirmDrawer
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              onSend={sendMessage}
              deductPoint={deductPoint}
            />
          )}
        />
      </ApiBoundary>
    </>
  );
};

export default MobileConfirmButton;
