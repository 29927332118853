import {
  ScheduleBookingParamType,
  ScheduleCheckinParamType,
  ScheduleDeletedLectureParamType,
} from 'pages/MoreDetails/Schedule/type';
import { atom } from 'recoil';
import filters from 'utils/filters';

/**
 * 더보기 > 설정에 노출할 버전정보
 */
export const currentVersionAtom = atom<string>({
  key: 'currentVersionAtom',
  default: '',
});

/**
 * 키워드 검색
 */
export const scheduleFilterKeywordAtom = atom<string>({
  key: 'scheduleFilterKeywordAtom',
  default: '',
});

/**
 * 일정관리
 */
export const bookingParamsAtom = atom<ScheduleBookingParamType>({
  key: 'bookingParamsAtom',
  default: {
    period: {
      type: 'today',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 체크인
 */
export const checkinParamsAtom = atom<ScheduleCheckinParamType>({
  key: 'checkinParamsAtom',
  default: {
    period: {
      type: 'today',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 삭제된 수업
 */
export const deletedLectureParamsAtom = atom<ScheduleDeletedLectureParamType>({
  key: 'deletedLectureParamsAtom',
  default: {
    period: {
      type: 'today',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});
