import { TabDataElementType } from 'components/Tabs';
import useQueryString from 'hooks/useQueryString';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { FallbackProps, useErrorBoundary } from 'react-error-boundary';

import { SCHEDULE_NODATA_TEXT, SCHEDULE_TABS } from '../constant';
import NoData from './NoData';
import ScheduleLayout from './ScheduleLayout';

const ScheduleErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const statusCode = error.response.status;
  const { showBoundary } = useErrorBoundary();
  const { searchKeyword } = useSearchKeywords('schedule');
  const { getSearchParams, setSearchParams } = useQueryString();

  const tab = getSearchParams('tab');
  const currentTab = SCHEDULE_TABS.find(tabData => tabData.value === tab) || SCHEDULE_TABS[0];

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value }, { replace: true });
  };

  if (statusCode !== 403) {
    showBoundary(error);
  }

  return (
    <ScheduleLayout currentTab={currentTab.value} changeTab={changeTab}>
      {currentTab.value === 'booking' && <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.booking} />}
      {currentTab.value === 'checkin' && <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.checkin} />}
      {currentTab.value === 'deletedLecture' && (
        <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.deletedLecture} />
      )}
    </ScheduleLayout>
  );
};

export default ScheduleErrorFallback;
