import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { validationIdCodeAtom } from 'recoil/validationIdCode';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  type: 'mobile-confirm' | 'password-confirm' | 'mobile-change';
};

const usePostCodeConfirm = ({ type }: Props) => {
  const validationIdCode = useRecoilValue(validationIdCodeAtom);
  const { id, code } = validationIdCode;

  const { mutationFn } = useMutationFnWithCommonParams<NoParams, Response>({
    method: 'post',
    url: `/api/auth/code-confirm/${type}/${id}/${code}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostCodeConfirm;

type NoParams = {
  studio_id: undefined;
  staff_id: undefined;
};

type Response = {
  message: string;
};
