import useGetAllMember from 'hooks/service/queries/useGetAllMember';
import usePopup from 'hooks/usePopup';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { convertAllMemberFilterToParams } from 'pages/Member/utils';
import { MessageTargetFormType, MessageType } from 'pages/MoreDetails/Message/types';
import { convertMembersToTargets } from 'pages/MoreDetails/Message/utils';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { messageTargetMemberSelectedFilter } from 'recoil/message';
import FooterButton from 'sharedComponents/FooterButton';

import { MESSAGE_TARGET_TEXT } from '../../constants';
import { convertMemberFilterToMessageFilter } from '../utils';
import ProcessingPopupContents from './ProcessingPopupContents';

type Props = {
  goToForm: (updater?: (state: MessageType) => MessageType) => void;
  messageType: 'mobile' | 'push';
};

const CompleteButton = ({ goToForm, messageType }: Props) => {
  const { control, getValues } = useFormContext<MessageTargetFormType>();
  const isAllSelected = useWatch({ control, name: 'isAllSelected' });
  const selectedFilter = useRecoilValue(messageTargetMemberSelectedFilter);
  const { searchKeyword } = useSearchKeywords('message');

  const [isAllFetchEnabled, setIsAllFetchEnabled] = useState(false);

  const { data, isFetching } = useGetAllMember(
    {
      ...convertAllMemberFilterToParams(selectedFilter),
      search: searchKeyword,
      has_mobile: messageType === 'mobile' ? 1 : undefined,
      is_app_linked: messageType === 'push' ? 1 : undefined,
    },
    isAllFetchEnabled,
  );
  const { setPopup } = usePopup();

  useEffect(() => {
    if (data && !isFetching) {
      setPopup(null);
      goToForm(
        data.length
          ? state => ({
              ...state,
              target: 'member',
              users: convertMembersToTargets(data),
              filter: convertMemberFilterToMessageFilter(selectedFilter, searchKeyword),
            })
          : undefined, // 빈 목록일 때 갱신 안함
      );
    }
  }, [data, isFetching, goToForm, setPopup, selectedFilter, searchKeyword]);

  const confirmTargets = () => {
    if (isAllSelected) {
      setPopup(<ProcessingPopupContents title="선택 회원 추가중" />);
      setIsAllFetchEnabled(true);
      return;
    }

    const selectedMember = getValues('selected');
    goToForm(
      selectedMember.length
        ? state => ({
            ...state,
            target: 'member',
            users: selectedMember,
            filter: convertMemberFilterToMessageFilter(selectedFilter, searchKeyword),
          })
        : undefined, // 빈목록일 때는 갱신 안함
    );
  };

  return <FooterButton onClick={confirmTargets}>{MESSAGE_TARGET_TEXT.confirm}</FooterButton>;
};

export default CompleteButton;
