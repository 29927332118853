import SearchBar from 'components/SearchBar';
import { useState } from 'react';

type Props = {
  initialKeyword: string;
  onClear: () => void;
  onSubmit: (keyword: string) => void;
};

const Search = ({ initialKeyword, onClear, onSubmit }: Props) => {
  const [keyword, setKeyword] = useState(initialKeyword);

  const changeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const submitKeyword = () => {
    onSubmit(keyword);
  };

  const clearSearchParam = () => {
    onClear();
    setKeyword('');
  };

  return (
    <SearchBar
      id="report-search-bar"
      placeholder={'회원명 검색'}
      onClear={clearSearchParam}
      onSubmitForm={submitKeyword}
      onChange={changeKeyword}
      value={keyword}
    />
  );
};

export default Search;
