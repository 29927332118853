import useGetMobilePoint from 'hooks/service/queries/useGetMobilePoint';
import { ReactNode } from 'react';

type Props = {
  deductPoint: number;
  renderNoPoint: (props: { currentPoint: number; deductPoint: number }) => ReactNode;
  renderPointEnough: (props: { currentPoint: number; deductPoint: number }) => ReactNode;
};

const PointCheck = ({ deductPoint, renderNoPoint, renderPointEnough }: Props) => {
  const {
    data: { point: currentPoint },
  } = useGetMobilePoint();

  if (deductPoint > currentPoint) {
    return renderNoPoint({ currentPoint, deductPoint });
  }
  return renderPointEnough({ currentPoint, deductPoint });
};

export default PointCheck;
