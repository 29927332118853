export const DEFAULT_PERMISSION_INDEX = 19;

export const PERMISSION_CHECK_LIST = {
  studio: [
    {
      id: 101310,
      label: '시설정보 설정',
      description: '상호, 주소, 연락처, 운영시간 등 시설의 정보를 추가 및 수정할 수 있습니다.',
      details: [],
    },
    {
      id: 101311,
      label: '운영정보 설정',
      description: '수업의 예약, 취소, 미리 알림을 설정할 수 있습니다.',
      details: [],
    },
    {
      id: 101312,
      label: '역할별 권한 설정',
      description: '역할별로 접근 권한을 설정할 수 있습니다.',
      details: [],
    },
    {
      id: 101317,
      label: '룸 관리 설정',
      description: '룸을 추가, 수정, 삭제하는 권한을 설정할 수 있습니다.',
      details: [],
    },
    {
      id: 101313,
      label: '스태프 조회',
      description: '스튜디오 오너, 관리자, 강사 등 스태프를 조회할 수 있습니다.',
      details: [
        { id: 101314, label: '스태프 등록' },
        { id: 101315, label: '스태프 정보 수정' },
        { id: 101316, label: '스태프 삭제' },
      ],
    },
  ],
  member: [
    {
      id: 101410,
      label: '회원 목록 조회',
      description: '전체 회원 목록을 조회할 수 있습니다.',
      details: [
        { id: 101421, label: '회원 목록 엑셀 다운로드' },
        { id: 101411, label: '회원 등록' },
        { id: 101412, label: '회원 정보 수정' },
        { id: 101413, label: '회원의 휴대폰 번호 보기' },
        { id: 101414, label: '회원 삭제' },
        { id: 101415, label: '회원에게 수강권 발급' },
        { id: 101416, label: '회원의 수강권 정보 수정' },
      ],
    },
    {
      id: 101420,
      label: '상담 고객 조회',
      description: '상담 기록, 방문 일정 등을 조회할 수 있습니다.',
      details: [
        { id: 101417, label: '상담 고객 등록' },
        { id: 101418, label: '상담 고객 수정' },
        { id: 101419, label: '상담 고객 삭제' },
      ],
    },
  ],
  ticket: [
    {
      id: 101513,
      label: '매출 열람',
      description: '매출 페이지를 열람할 수 있습니다.',
      details: [],
    },
    {
      id: 101510,
      label: '수강권 등록',
      description: '새로운 수강권을 만들 수 있습니다.',
      details: [],
    },
    {
      id: 101511,
      label: '수강권 수정수강권 정보를 수정할 수 있습니다.',
      description: '수강권 정보를 수정할 수 있습니다.',
      details: [],
    },
    {
      id: 101512,
      label: '수강권 판매 정지 및 판매 재개',
      description: '수강권 판매정지를 설정 할 수 있고 판매를 재개할 수 있습니다.',
      details: [],
    },
  ],
  schedule: [
    /** 본인의 기타 일정 */
    {
      id: 101229,
      title: '본인의 기타 일정',
      label: '본인의 기타 일정 등록',
      description: '본인의 기타 일정을 등록할 수 있습니다.',
      details: [],
    },
    {
      id: 101230,
      label: '본인의 기타 일정 수정',
      description: '본인의 기타 일정을 수정할 수 있습니다.',
      details: [],
    },
    {
      id: 101231,
      label: '본인의 기타 일정 삭제',
      description: '본인 기타 일정을 삭제할 수 있습니다. ',
      details: [],
    },
    /** 다른 스태프의 기타 일정 */
    {
      id: 101232,
      title: '다른 스태프의 기타 일정',
      label: '다른 스태프의 기타 일정 보기',
      description: '본인 외 다른 스태프의 기타 일정을 확인할 수 있습니다.',
      details: [
        { id: 101233, label: '다른 스태프의 기타 일정 등록' },
        { id: 101234, label: '다른 스태프의 기타 일정 수정' },
        { id: 101235, label: '다른 스태프의 기타 일정 삭제' },
      ],
    },
    /** 본인의 그룹 수업 */
    {
      id: 101207,
      title: '본인의 그룹 수업',
      label: '본인의 그룹 수업 등록',
      description: '본인의 그룹 수업을 등록할 수 있습니다.',
      details: [],
    },
    {
      id: 101208,
      label: '본인의 그룹 수업 수정',
      description: '본인 그룹 수업의 담당 강사, 수업 시간, 최소 수강 인원을 수정할 수 있습니다.',
      details: [],
    },
    {
      id: 101209,
      label: '본인의 그룹 수업 예약 변경',
      description: '본인 그룹 수업의 회원 예약, 출결 상태를 변경할 수 있습니다.',
      details: [],
    },
    {
      id: 101225,
      label: '본인의 그룹 수업 예약 취소',
      description: '본인 그룹 수업의 회원 예약을 취소할 수 있습니다.',
      details: [],
    },
    {
      id: 101210,
      label: '본인의 그룹 수업 삭제',
      description: '본인의 그룹 수업을 삭제할 수 있습니다.',
      details: [],
    },
    /** 본인의 프라이빗 수업 */
    {
      id: 101211,
      title: '본인의 프라이빗 수업',
      label: '본인의 프라이빗 수업 등록',
      description: '본인의 프라이빗 수업을 등록할 수 있습니다.',
      details: [],
    },
    {
      id: 101212,
      label: '본인의 프라이빗 수업 수정',
      description: '본인 프라이빗 수업의 담당 강사, 수업 시간, 최소 수강 인원을 수정할 수 있습니다.',
      details: [],
    },
    {
      id: 101213,
      label: '본인의 프라이빗 수업 예약 변경',
      description: '본인 프라이빗 수업의 회원 예약, 출결 상태를 변경할 수 있습니다.',
      details: [],
    },
    {
      id: 101226,
      label: '본인의 프라이빗 수업 예약 취소',
      description: '본인 프라이빗 수업의 회원 예약을 취소할 수 있습니다.',
      details: [],
    },
    {
      id: 101214,
      label: '본인의 프라이빗 수업 삭제',
      description: '본인의 프라이빗 수업을 삭제할 수 있습니다.',
      details: [],
    },
    /** 다른 스태프의 그룹 수업 */
    {
      id: 101216,
      title: '다른 스태프의 그룹 수업',
      label: '다른 스태프의 그룹 수업 보기',
      description: '본인 외 다른 스태프의 그룹 수업을 확인할 수 있습니다.',
      details: [
        { id: 101215, label: '다른 스태프의 그룹 수업 등록' },
        { id: 101217, label: '다른 스태프의 그룹 수업 수정' },
        { id: 101218, label: '다른 스태프의 그룹 수업 예약 변경' },
        { id: 101227, label: '다른 스태프의 그룹 수업 예약 취소' },
        { id: 101219, label: '다른 스태프의 그룹 수업 삭제' },
      ],
    },
    /** 다른 스태프의 프라이빗 수업 */
    {
      id: 101221,
      title: '다른 스태프의 프라이빗 수업',
      label: '다른 스태프의 프라이빗 수업 보기',
      description: '본인 외 다른 스태프의 프라이빗 수업을 확인할 수 있습니다.',
      details: [
        { id: 101220, label: '다른 스태프의 프라이빗 수업 등록' },
        { id: 101222, label: '다른 스태프의 프라이빗 수업 수정' },
        { id: 101223, label: '다른 스태프의 프라이빗 수업 예약 변경' },
        { id: 101228, label: '다른 스태프의 프라이빗 수업 예약 취소' },
        { id: 101224, label: '다른 스태프의 프라이빗 수업 삭제' },
      ],
    },
  ],
  notice: [
    {
      id: 101610,
      label: '공지사항 보기',
      description: '공지사항을 볼 수 있습니다.',
      details: [
        { id: 101611, label: '공지사항 등록, 수정' },
        { id: 101612, label: '공지사항 삭제' },
      ],
    },
  ],
  qna: [
    {
      id: 101620,
      label: '1:1 문의 보기',
      description: '1:1 문의를 볼 수 있습니다.',
      details: [
        { id: 101621, label: '1:1 문의 댓글 등록, 수정, 삭제' },
        { id: 101622, label: '1:1 문의 다른 스태프 댓글 삭제' },
      ],
    },
  ],
  message: [
    {
      id: 101637,
      label: '문자 메시지 보기',
      description: '문자 메시지 내역을 볼 수 있습니다.',
      details: [
        { id: 101638, label: '메시지 보내기, 수정, 취소' },
        { id: 101639, label: '메시지 삭제' },
      ],
    },
    {
      id: 101640,
      label: '앱 푸시 메시지 보기',
      description: '앱 푸시 메시지 내역을 볼 수 있습니다.',
      details: [
        { id: 101641, label: '메시지 보내기, 수정, 취소' },
        { id: 101642, label: '메시지 삭제' },
      ],
    },
  ],
};
