import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useInfinityHoliday from 'hooks/service/queries/useInfinityHoliday';

import HolidayHistoryItem from './HolidayHistoryItem';

const HolidayHistoryList = () => {
  const { data: holidays, fetchNextPage, hasNextPage } = useInfinityHoliday();

  return (
    <Container>
      <div className="holiday-list">
        {holidays.map(holiday => (
          <HolidayHistoryItem key={holiday.id} holiday={holiday} />
        ))}
      </div>

      {!hasNextPage && (
        <Button
          fullWidth
          variant="contained"
          size="medium40"
          fontSize={13}
          fontWeight={600}
          color="gray"
          rightIcon={<Icon name="arrowBottomBold" size={16} fillColor={theme.color.gray3} />}
          edge="circular"
          onClick={() => fetchNextPage()}>
          더보기
        </Button>
      )}
    </Container>
  );
};

export default HolidayHistoryList;

const Container = styled.div`
  padding-top: 16px;

  .holiday-list {
    ${theme.flex('column', '', '', 8)};
    margin-bottom: 20px;
  }

  /** 더보기 텍스트 아이콘 간격 */
  .text-button > .typography {
    margin-right: 2px;
  }
`;
