import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingCommonParams, BookingFailResponse } from './usePostBookingPrivate';

const usePostEtcSchedule = () => {
  const { mutationFn } = useMutationFnWithCommonParams<EtcScheduleParams, EtcScheduleResponse>({
    method: `post`,
    url: `/api/etcSchedule`,
  });

  return useMutation<AxiosResponse, AxiosError<Array<BookingFailResponse> | CustomError>, EtcScheduleParams | undefined, unknown>(
    {
      mutationFn,
    },
  );
};

export default usePostEtcSchedule;

export type EtcScheduleParams = Omit<BookingCommonParams, 'room_id' | 'instructor_id'> & {
  color: string | number;
  /** 반복 설정 */
  is_repeat: boolean;
  /** 종일 설정 */
  is_all_day: boolean;
  /** 중복 허용 */
  is_overlap: boolean;
  /** 나만보기 */
  is_private: boolean;
  staff_ids: Array<number>;
};

export type EtcScheduleResponse = {
  id: number;
  studio_id: number;
  title: string;
  color: string;
  is_repeat: boolean;
  is_overlap: boolean;
  is_private: boolean;
  is_all_day: boolean;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  etc_schedule_time_id: number;
};
