import { CommonPropsType, FieldType } from 'pages/MoreDetails/Staff/Form/types';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

import EventWorkTimeCardList from './components/EventWorkTimeCardList';

export type Props = CommonPropsType & {
  removeRestTime: (index: number) => void;
  updateTime: (index: number, value: ScheduleFormType, type: FieldType) => void;
};

const EventWorkTime = (props: Props) => {
  return <EventWorkTimeCardList {...props} />;
};

export default EventWorkTime;
