import usePatchLecturePrivateBulk from 'hooks/service/mutations/usePatchLecturePrivateBulk';
import { LectureCourseResponse } from 'hooks/service/queries/useGetLectureCourse';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import BookingEndDate from 'pages/Booking/components/BookingRangeDate/BookingEndDate';
import BookingStartDate from 'pages/Booking/components/BookingRangeDate/BookingStartDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { formatBookingPrivateParams } from 'pages/Booking/utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

import FailUpdateAllBooking from '../../../components/AfterRequestBooking/FailUpdateAllBooking';
import { BOOKING_PRIVATE_FORM_TEXT } from '../../constants';
import { BookingPrivateFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';
import { PrivateFormContainer } from '../Create/PrivateCreateForm';
import SubmitButton from './SubmitButton';

type Props = {
  currentLecture: LectureDetailResponse;
  course: LectureCourseResponse;
};

const PrivateUpdateAllForm = ({ currentLecture, course }: Props) => {
  const { handleSubmit } = useFormContext<BookingPrivateFormType>();

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const errorDialog = useErrorDialog();
  const { mutate: privateBulkUpdateMutate, isPending } = usePatchLecturePrivateBulk(currentLecture);

  const submit = (values: BookingPrivateFormType) => {
    const { start_date, ...rest } = formatBookingPrivateParams(values);

    const params = {
      ...rest,
      start: currentLecture.start_on,
      start_date: course.start_date,
      is_force: false,
    };

    privateBulkUpdateMutate(params, {
      onSuccess: res => {
        if (!res.data.fail.length) {
          navigate(-1);
          setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
        } else {
          setPopup(
            <FailUpdateAllBooking
              fails={res.data.fail}
              onSubmit={options => privateBulkUpdateMutate({ ...params, is_force: true }, options)}
            />,
          );
        }
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  useEffect(() => {
    if (isPending) {
      setPopup(<ProcessingPopupContents title={BOOKING_COMMON_FORM_TEXT.loadingTitle} />);
    }
  }, [isPending, setPopup]);

  return (
    <>
      <PrivateFormContainer onSubmit={handleSubmit(submit)}>
        <SelectStaff lectureStaff={currentLecture.staff} />
        <BookingStartDate />
        <BookingEndDate pageType="updateAll" />
        <BookingRangeTime pageMode="update" />
        <RepeatField />
        <Room />
        <LectureSize />
        <SelectMembersField />
        <ClassTitle description={BOOKING_PRIVATE_FORM_TEXT.title.description} />
        <SubmitButton />
      </PrivateFormContainer>
    </>
  );
};

export default PrivateUpdateAllForm;
