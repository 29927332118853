import { yupResolver } from '@hookform/resolvers/yup';
import useGetStaffWorkSchedules from 'hooks/service/queries/useGetStaffWorkSchedules';
import useParamsId from 'hooks/useParamsId';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';
import MainLayout from 'sharedComponents/MainLayout';
import getWorkTimeDataset from 'utils/getWorkTimeDataset';
import { boolean, number, object } from 'yup';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';
import { ScheduleStateType, ScheduleUpdateFormType } from '../types';
import ScheduleUpdateForm from './components/ScheduleUpdateForm';

const yupSchema = object({
  profile: object({
    private_start_interval_on_off: boolean(),
    private_start_interval_am: number(),
    private_start_interval_pm: number(),
  }),
});

const MoreDetailsStaffScheduleUpdateForm = () => {
  const { state }: ScheduleStateType = useLocation();

  const targetStaffId = useParamsId();
  const { data: workSchedules } = useGetStaffWorkSchedules({ targetStaffId });
  const workTimeDataset = useMemo(() => getWorkTimeDataset(workSchedules, 'update'), [workSchedules]);

  const methods = useForm<ScheduleUpdateFormType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      profile: state?.defaultInfo.profile,
      schedules: workTimeDataset,
    },
  });

  const { isDirty, isSubmitted } = methods.formState;

  return (
    <MainLayout header={{ title: STAFF_PROFILE_FORM_TEXT.headerTitle.schedule }}>
      {/* TODO: 로딩 처리 */}
      <ApiBoundary fallback={<div>loading..</div>}>
        <FormProvider {...methods}>
          <ScheduleUpdateForm />
        </FormProvider>
      </ApiBoundary>

      <DialogBeforeLeave isBlocked={isDirty && !isSubmitted} />
    </MainLayout>
  );
};

export default MoreDetailsStaffScheduleUpdateForm;
