import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import { ScheduleManagementBookingResponse } from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import useInfinityScheduleManagementCheckin, {
  ScheduleManagementCheckinParams,
  ScheduleManagementCheckinResponse,
} from 'hooks/service/queries/useInfinityScheduleManagementCheckin';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { checkinParamsAtom } from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import {
  calculateTotal,
  convertTostringDays,
  convertToStringStaffIds,
  filterByKeyword,
  getScheduleGroupByStartOn,
} from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const CheckinScheduleList = ({ currentTab }: Props) => {
  const scheduleCheckin = useRecoilValue(checkinParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');
  const [filteredCheckins, setFilteredCheckins] = useState<ScheduleManagementCheckinResponse[][]>([]);

  const scheduleCheckinParams: ScheduleManagementCheckinParams = {
    staff: convertToStringStaffIds(scheduleCheckin.staffs || []),
    start_date: scheduleCheckin.period?.start || filters.dateDash(),
    end_date: scheduleCheckin.period?.end || filters.dateDash(),
    week: convertTostringDays(scheduleCheckin.day || []),
    type: scheduleCheckin.type,
    paginate_type: 'detail',
    status: scheduleCheckin.status,
    start_time: scheduleCheckin.classTime?.start
      ? filters.time(scheduleCheckin.classTime?.start)
      : scheduleCheckin.classTime?.start,
    end_time: scheduleCheckin.classTime?.end ? filters.time(scheduleCheckin.classTime?.end) : scheduleCheckin.classTime?.end,
    is_range: scheduleCheckin.classTime?.type === 'timeRange' ? 1 : 0,
    order_by: scheduleCheckin.sort ?? 'asc',
  };

  const {
    data: { checkins, total },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfinityScheduleManagementCheckin(scheduleCheckinParams);

  const groupTotal = useMemo(() => calculateTotal(filteredCheckins, 'G'), [filteredCheckins]);
  const privateTotal = useMemo(() => calculateTotal(filteredCheckins, 'P'), [filteredCheckins]);

  useEffect(() => {
    // 조건에 따라 스케줄 필터링 및 그룹화
    const filtered = searchKeyword === '' ? checkins : filterByKeyword(checkins, searchKeyword);

    const groupedSchedules = getScheduleGroupByStartOn(filtered);
    setFilteredCheckins(groupedSchedules as ScheduleManagementBookingResponse[][]);
  }, [searchKeyword, checkins, currentTab]);

  if (total === 0 || filteredCheckins.length === 0)
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={groupTotal} privateTotal={privateTotal} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.checkin} />
      </>
    );

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={total} groupTotal={groupTotal} privateTotal={privateTotal} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <ContentSection>
        <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
          <ScheduleCard schedules={filteredCheckins} currentTab={currentTab} />
        </InfiniteScroll>
      </ContentSection>
    </Container>
  );
};

export default CheckinScheduleList;

const Container = styled.div`
  & > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;
