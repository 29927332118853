import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import QnaDetail from './components/QnaDetail';
import SkeletonDetail from './components/SkeletonDetail';
import { QNA_DETAIL_TEXT } from './constants';

const MoreDetailsQnaDetail = () => {
  const id = useParamsId();

  return (
    <MainLayout header={{ title: QNA_DETAIL_TEXT.title }}>
      <ApiBoundary fallback={<SkeletonDetail />}>
        <QnaDetail id={id} />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsQnaDetail;
