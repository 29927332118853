import styled from '@emotion/styled';
import Divider from 'components/Divider';
import useGetPointHistoryStaffList from 'hooks/service/queries/useGetPointHistoryStaffList';
import useInfinityPointHistory from 'hooks/service/queries/useInfinityPointHistory';
import { POINT_DEFAULT_PERIOD } from 'pages/MemberDetail/constants';
import { convertPointFilterParams } from 'pages/MemberDetail/utils/memberDetailFilters';
import { useRecoilValue } from 'recoil';
import { pointFilterPeriodAtom, pointFiltersAtom } from 'recoil/MemberDetail';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';

import PointFilter from './PointFilter';
import PointHistoryCard from './PointHistoryCard';

type Props = {
  currentMemberId: number;
};

const PointHistory = ({ currentMemberId }: Props) => {
  const selectedFilter = useRecoilValue(pointFiltersAtom);
  const { start_date, end_date, ...rest } = convertPointFilterParams(selectedFilter);

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityPointHistory({
    member_id: currentMemberId,
    start_date,
    end_date,
    ...rest,
  });

  const tempSelectedPeriod = useRecoilValue(pointFilterPeriodAtom);
  const { data: staffs } = useGetPointHistoryStaffList({
    member_id: currentMemberId,
    start_date: tempSelectedPeriod?.start_date || POINT_DEFAULT_PERIOD.start_date,
    end_date: tempSelectedPeriod?.end_date || POINT_DEFAULT_PERIOD.end_date,
  });

  return (
    <Container>
      <PointFilter currentMemberId={currentMemberId} staffs={staffs} />
      <Divider thin thinColor="gray6" />
      {!data.length ? (
        <NodataTypography>포인트 내역이 없습니다.</NodataTypography>
      ) : (
        <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {data.map(item => (
            <PointHistoryCard key={item.id} pointData={item} />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default PointHistory;

const Container = styled.div`
  padding: 0 0 40px;
`;
