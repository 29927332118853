import DateCalendarPicker from 'designedComponents/Pickers/DateCalendarPicker';
import _ from 'lodash';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

type Props = {
  isRange: boolean;
  /** 룸설정 기능 사용 시설 여부 */
  isUseRooms?: boolean;
  isInitialOpen?: boolean;
};

const BookingRangeDate = ({ isRange, isUseRooms = false, isInitialOpen }: Props) => {
  const { control, setValue } = useFormContext<BookingCommonFormType & { isChangeDate?: boolean }>();
  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });
  const [isOpen, setIsOpen] = useState(isInitialOpen);

  const changeDate = (e: { value: Array<string> | string }) => {
    if (_.isArray(e.value)) {
      const start = e.value[0] ? filters.dateDash(e.value[0]) : '';
      const end = e.value[1] ? filters.dateDash(e.value[1]) : '';
      setValue('startDate', start, { shouldDirty: true });
      setValue('endDate', end, { shouldDirty: true });
    } else {
      if (!isUseRooms) {
        setValue('isChangeDate', true, { shouldDirty: true });
        setValue('roomId', null, { shouldDirty: true });
        setValue('selectedRoom', undefined, { shouldDirty: true });
      }
      setValue('startDate', filters.dateDash(e.value), { shouldDirty: true });
      setValue('endDate', filters.dateDash(e.value), { shouldDirty: true });
    }
  };

  return (
    <FormFieldAccordion
      iconName="calendar"
      isOpen={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      header={
        isRange
          ? `${filters.dateWithWeekday(startDate)} ~ ${endDate ? filters.dateWithWeekday(endDate) : ''}`
          : filters.dateWithWeekday(endDate)
      }
      hasValue={!!startDate}>
      <DateCalendarPicker
        select={isRange ? 'range' : undefined}
        value={isRange ? [startDate, endDate] : endDate}
        onChange={changeDate}
      />
    </FormFieldAccordion>
  );
};

export default BookingRangeDate;
