import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 그룹 수업 등록 */
const BookingGroupCreate = () => {
  return (
    <ApiBoundary>
      <FormMain />
    </ApiBoundary>
  );
};

export default BookingGroupCreate;
