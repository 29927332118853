import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 기타 일정 복사 */
const EtcScheduleCopy = () => {
  return (
    <ApiBoundary>
      <FormMain />
    </ApiBoundary>
  );
};

export default EtcScheduleCopy;
