import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useInfinityMessageMobile from 'hooks/service/queries/useInfinityMessageMobile';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useRecoilValue } from 'recoil';
import { mobileSelectedFilterAtom } from 'recoil/message';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';

import { MESSAGE_IDENTIFIER } from '../../constants';
import MobileMessageListItem from './components/MobileMessageListItem';
import { MOBILE_LIST_TEXT } from './constants';
import { convertToMobileParams } from './utils';

const MoreDetailsMessageMobileList = () => {
  const selectedFilter = useRecoilValue(mobileSelectedFilterAtom);
  const { searchKeyword } = useSearchKeywords(MESSAGE_IDENTIFIER);

  const {
    data: messages,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfinityMessageMobile(convertToMobileParams(selectedFilter, searchKeyword));

  if (!messages.length && searchKeyword)
    return (
      <Container>
        <NoSearchDataTypography keyword={searchKeyword} />
      </Container>
    );
  if (!messages.length) return <StyledNodata>{MOBILE_LIST_TEXT.empty}</StyledNodata>;

  return (
    <StyledInfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage} gap={12}>
      {messages.map(message => (
        <MobileMessageListItem key={message.id} message={message} />
      ))}
    </StyledInfiniteScroll>
  );
};
export default MoreDetailsMessageMobileList;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding: 20px;
`;

const Container = styled.div`
  margin: 160px 0;
  text-align: center;
  ${theme.flex()};
`;

const StyledNodata = styled(NodataTypography)`
  margin: 160px 0;
`;
