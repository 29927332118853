import { useIsMutating } from '@tanstack/react-query';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingGroupCreateFormType } from '../../types';

type Props = {
  pageMode: AllPageModeType;
};

const SubmitButton = ({ pageMode }: Props) => {
  const {
    control,
    formState: { dirtyFields },
  } = useFormContext<BookingGroupCreateFormType>();
  const isMutating = useIsMutating() > 0;
  const title = useWatch({ control, name: 'title' });
  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <FooterButton type="submit" disabled={isMutating || !isDirty || !title}>
      {BOOKING_COMMON_FORM_TEXT.footerButton[pageMode]} 완료
    </FooterButton>
  );
};

export default SubmitButton;
