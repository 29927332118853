import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import SearchBar from 'components/SearchBar';
import Typography from 'components/Typography';
import useInfinityReportSale, { ReportSaleParams, SalesDataResponse } from 'hooks/service/queries/useInfinityReportSale';
import usePopup from 'hooks/usePopup';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { reportSaleParamAtom, reportSalePeriodAtom } from 'recoil/report/atom';
import WholeFilterPopup from 'sharedComponents/Filters/WholeFilterPopup';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { DEFAULT_REPORT_SALE_FILTER_OPTIONS } from '../../constants';
import { getSalesGroupByCreateOn } from '../../util';
import NoData from '../NoData';
import ListCard from './ListCard';
import ReportWholeFilterOptions from './ReportSaleWholeFilterOptions';

const ReportSaleList = () => {
  const { setPopup } = usePopup();
  const { searchKeyword, setSearchKeyword } = useSearchKeywords('report');
  const reportSalePeriod = useRecoilValue(reportSalePeriodAtom);
  const reportSale = useRecoilValue(reportSaleParamAtom);
  const [filteredSales, setFilteredSales] = useState<SalesDataResponse[][]>([]);
  const [keyword, setKeyword] = useState(searchKeyword);

  const salesParam: ReportSaleParams = {
    start_date: reportSalePeriod.period?.start || filters.dateDash(),
    end_date: reportSalePeriod.period?.end || filters.dateDash(),
    search: searchKeyword,
    payment_type: reportSale.paymentType,
    product_type: reportSale.productType,
    payment_method: reportSale.paymentMethod,
    paginate_type: 'detail',
  };

  const {
    data: { sales, total, totalAmount, totalCount },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfinityReportSale(salesParam);

  const clearSearchParam = () => {
    setKeyword('');
    setSearchKeyword('');
  };

  const openWholeDrawer = () => {
    setPopup(
      <WholeFilterPopup
        filterAtom={reportSaleParamAtom}
        filters={DEFAULT_REPORT_SALE_FILTER_OPTIONS}
        renderWholeFilterPopupOptions={ReportWholeFilterOptions}
      />,
    );
  };

  useEffect(() => {
    const groupedSales = getSalesGroupByCreateOn(sales);
    setFilteredSales(groupedSales);
  }, [sales]);

  return (
    <Container>
      <TotalSection>
        <div className="total-count-wrapper">
          <Typography span weight={500} textColor="gray2">
            총
          </Typography>
          <Typography span weight={600} textColor="primary">
            {totalCount ?? 0}
          </Typography>
          <Typography span weight={500} textColor="gray2">
            건
          </Typography>
        </div>
        <div className="total-amount-wrapper">
          <div>
            <Typography span size={24} weight={700} textColor="gray1">
              {`${filters.numberComma(totalAmount ?? 0)}`}
            </Typography>
            <Typography span size={21} weight={700} textColor="gray1">
              원
            </Typography>
          </div>
          <Button
            edge="circular"
            size="small"
            variant="outlined"
            padding={{ left: 12, right: 12 }}
            heightSize={28}
            fontSize={13}
            fontWeight={500}
            textColor="gray2"
            onClick={openWholeDrawer}>
            상세조회
          </Button>
        </div>
        <SearchBar
          id="report-search-bar"
          placeholder={'회원명 검색'}
          onClear={clearSearchParam}
          onSubmitForm={() => setSearchKeyword(keyword)}
          onChange={e => {
            setKeyword(e.target.value);
          }}
          value={keyword}
        />
      </TotalSection>
      {total === 0 || filteredSales.length === 0 ? (
        <NoData currentKeyword={searchKeyword} />
      ) : (
        <ListSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ListCard sales={filteredSales} />
          </InfiniteScroll>
        </ListSection>
      )}
    </Container>
  );
};

export default ReportSaleList;

const Container = styled.div``;

const TotalSection = styled.section`
  padding: 28px 20px 16px 20px;
  background-color: ${theme.color.white};

  .total-count-wrapper {
    padding-bottom: 2px;

    > span:first-of-type {
      padding-right: 4px;
    }
  }

  .total-amount-wrapper {
    ${theme.flex('', 'center', 'space-between')}

    padding-bottom: 16px;
  }
`;

const ListSection = styled.section`
  padding: 24px 20px;
  background: ${theme.color.gray6};
`;
