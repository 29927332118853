import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Drawer from 'components/Drawer';
import TimePicker from 'components/TimePicker';
import Typography from 'components/Typography';
import { useCallback, useEffect, useState } from 'react';
import { FilterOptionsType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

type Props = {
  initialTime?: (string | undefined)[];
  onSave: (option: FilterOptionsType, key: string) => void;
  startDateDisabled?: boolean;
  endDateDisabled?: boolean;
  defaultStartButtonText?: string;
  defaultEndButtonText?: string;
  prevSelectedOption?: {
    type?: string;
    start?: string;
    end?: string;
  };
};

const CheckTimePicker = ({
  defaultStartButtonText = '시작시간',
  defaultEndButtonText = '종료시간',
  initialTime,
  startDateDisabled,
  endDateDisabled,
  onSave: changeOptions,
  prevSelectedOption,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempTime, setTempTime] = useState(initialTime);
  const [activeTimePicker, setActiveTimePicker] = useState<string | null>(null);

  const startValue = !prevSelectedOption?.type ? defaultStartButtonText : filters.time(tempTime?.[0]);
  const endValue = !prevSelectedOption?.type ? defaultEndButtonText : filters.time(tempTime?.[1]);

  const changeDate = useCallback(
    (e: { value: string }) => {
      setTempTime(prevTempTime => {
        const newTempTime = prevTempTime ? [...prevTempTime] : [];
        if (activeTimePicker === 'start') {
          newTempTime[0] = e.value;
        } else if (activeTimePicker === 'end') {
          newTempTime[1] = e.value;
        }
        return newTempTime;
      });
    },
    [activeTimePicker],
  );

  const closeTimePicker = () => {
    setTempTime(initialTime || []);
    setIsOpen(false);
  };

  const saveTime = () => {
    changeOptions(
      {
        value: { type: prevSelectedOption?.type, start: tempTime?.[0], end: tempTime?.[1] },
        label: '',
      },
      'classTime',
    );
    setIsOpen(false);
  };

  useEffect(() => {
    setTempTime(initialTime);
  }, [initialTime]);

  return (
    <>
      <Container className="period-picker">
        <TimeButton
          fullWidth
          size="medium48"
          variant="outlined"
          padding={{ left: 16, right: 12 }}
          disabled={startDateDisabled}
          textColor="gray1"
          fontSize={15}
          onClick={() => {
            setIsOpen(true);
            setActiveTimePicker('start');
          }}>
          {startValue}
        </TimeButton>
        <Typography textColor="gray3" size={15} weight={500}>
          ~
        </Typography>
        <TimeButton
          fullWidth
          size="medium48"
          variant="outlined"
          padding={{ left: 16, right: 12 }}
          disabled={endDateDisabled}
          textColor={endDateDisabled ? 'gray3' : 'gray1'}
          fontSize={15}
          onClick={() => {
            setIsOpen(true);
            setActiveTimePicker('end');
          }}>
          {endValue}
        </TimeButton>
      </Container>
      <Drawer isOpen={isOpen} onClose={closeTimePicker} zIndex={6010}>
        <TimePicker value={tempTime?.[activeTimePicker === 'start' ? 0 : 1]} onChange={changeDate} stepMinute={5} />
        <BottomButtonSection>
          <ButtonGroup leftButtonWidth={115}>
            <Button
              variant="outlined"
              color="gray"
              size="large56"
              fontSize={14}
              fontWeight={500}
              onClick={() => setIsOpen(false)}>
              취소
            </Button>
            <Button variant="contained" color="point" size="large56" fontSize={14} fontWeight={500} onClick={saveTime} fullWidth>
              완료
            </Button>
          </ButtonGroup>
        </BottomButtonSection>
      </Drawer>
    </>
  );
};

export default CheckTimePicker;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between', 8)};
`;

const TimeButton = styled(Button)`
  justify-content: flex-start;

  :disabled {
    background-color: ${theme.color.gray6};
    border: 1px solid ${theme.color.gray6};

    span {
      color: ${theme.color.gray3};
    }
  }
`;

const BottomButtonSection = styled.section`
  padding: 0px 20px 32px 20px;
`;
