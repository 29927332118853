import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { AvailableTimeType } from 'designedComponents/Pickers/NumberPicker/AvailableTimePicker';
import useQueryString from 'hooks/useQueryString';
import { splitHourMinTypeR } from 'pages/Booking/utils/convertTimes';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import { InfoTypography } from 'sharedComponents/InfoTypography';

import { BookingGroupCreateFormType } from '../../../../types';
import RTypeAccordionHeader from '../RuleType/header/RTypeAccordionHeader';
import RType from '../RuleType/RType';

/**
 * 그룹 수업 등록/복사/이후모든수정 폼의
 * 당일예약변경/폐강시간/예약대기시간 설정
 */
const OthersAvailableSetting = () => {
  const { getValues, setValue } = useFormContext<BookingGroupCreateFormType>();
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();

  const [expanded, setExpanded] = useState<string | false>(getSearchParams('detail') as string);
  const [tempDailyChange, setTempDailyChange] = useState(getValues('policy.dailyBookingChangeDeadline'));
  const [tempAutoClose, setTempAutoClose] = useState(getValues('policy.autocloseDeadline'));
  const [tempWaiting, setTempWaiting] = useState(getValues('policy.bookingAutoShiftDeadline'));

  const { hour: dailyHour, min: dailyMin } = splitHourMinTypeR(tempDailyChange);
  const { hour: waitingHour, min: waitingMin } = splitHourMinTypeR(tempWaiting);
  const { hour: closeHour, min: closeMin } = splitHourMinTypeR(tempAutoClose);

  const changeAccordionOpen = (panel: string) => {
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  const changeR = (value: AvailableTimeType) => {
    const { hour, min } = value;
    const deadline = hour ? hour * 60 + min : min;

    switch (expanded) {
      case 'dailyChange':
        setTempDailyChange(deadline);
        break;
      case 'autoClose':
        setTempAutoClose(deadline);
        break;
      case 'waiting':
        setTempWaiting(deadline);
        break;
    }
  };

  const saveDate = () => {
    setValue('policy.dailyBookingChangeDeadline', tempDailyChange, { shouldDirty: true });
    setValue('policy.autocloseDeadline', tempAutoClose, { shouldDirty: true });
    setValue('policy.bookingAutoShiftDeadline', tempWaiting, { shouldDirty: true });
    navigate(-1);
  };

  return (
    <>
      <RType
        textType="dailyChange"
        iconName="changeRegular"
        isOpen={expanded === 'dailyChange'}
        onToggle={() => changeAccordionOpen('dailyChange')}
        deadline={tempDailyChange}
        changeDate={changeR}
        accordionHeader={
          <div>
            <SmallTitleTypography>당일예약 변경</SmallTitleTypography>
            <RTypeAccordionHeader isOpen={expanded === 'dailyChange'} hour={dailyHour} min={dailyMin} textType={'dailyChange'} />
            <Typography size={15}>당일 예약 변경 가능합니다.</Typography>
          </div>
        }
      />
      <Divider />
      <RType
        textType="autoClose"
        iconName="error"
        isOpen={expanded === 'autoClose'}
        onToggle={() => changeAccordionOpen('autoClose')}
        deadline={tempAutoClose}
        changeDate={changeR}
        accordionHeader={
          <div>
            <SmallTitleTypography>폐강</SmallTitleTypography>
            <Typography size={15}>최소 수강인원이 미달일 경우,</Typography>
            <RTypeAccordionHeader isOpen={expanded === 'autoClose'} hour={closeHour} min={closeMin} textType="autoClose" />
          </div>
        }
      />
      <Divider />
      <RType
        textType="waiting"
        iconName="wait"
        isOpen={expanded === 'waiting'}
        onToggle={() => changeAccordionOpen('waiting')}
        deadline={tempWaiting}
        changeDate={changeR}
        accordionHeader={
          <DailyAccordionHeader>
            <SmallTitleTypography>예약대기 자동 예약</SmallTitleTypography>
            <Typography size={15}>공석이 발생할 경우,</Typography>
            <RTypeAccordionHeader isOpen={expanded === 'waiting'} hour={waitingHour} min={waitingMin} textType={'waiting'} />
            <Typography className="waiting-description" size={13} textColor="gray2">
              예약대기가 예약으로 변경되는 시간을 설정 해주세요. <br />
              값을 변경하지 않는 경우 ‘취소 가능한 시간’ 으로 적용됩니다.
            </Typography>
          </DailyAccordionHeader>
        }
      />
      <FooterButton onClick={saveDate}>완료</FooterButton>
    </>
  );
};

export default OthersAvailableSetting;

export const SmallTitleTypography = styled(InfoTypography)`
  display: inline-block;
  margin-bottom: 2px;
`;

export const DailyAccordionHeader = styled.div`
  .waiting-description {
    margin-top: 10px;
  }
`;
