import { PERMISSION } from 'constants/permission';

import { MenusType } from './types';

export const HEADER_TEXT = {
  permissionSettings: '권한 설정',
  roleManagement: '역할 관리',
};

export const CONTENT_TEXT = {
  roleSelect: {
    title: '역할 선택',
    sub: '권한 설정할 역할을 선택하세요.',
  },
  AccessItem: {
    title: '접근항목 설정',
    sub: '* 스튜디오 오너는 모든 권한을 가집니다.',
  },
};

export const HEADER_TITLE = {
  studio: '시설 관리',
  member: '고객 관리',
  ticket: '수강권',
  schedule: '일정',
  notice: '공지사항',
  qna: '1:1 문의',
  message: '메시지',
};

const PATH = '/more-details/staff/permission-settings';
const COMMON_VALUES = {
  path: PATH,
  permissions: [PERMISSION.role.edit],
  policies: [],
};
/**
 * @description
 * permission : 해당 강사에게 권한이 있는지
 * policiesNeeded : 해당 시설 정책상 허용되어 있는지
 */
export const MENUS: MenusType = [
  { name: '시설 관리', icon: 'room', query: 'studio', ...COMMON_VALUES },
  { name: '고객 관리', icon: 'privateClass', query: 'member', ...COMMON_VALUES },
  { name: '수강권', icon: 'ticket', query: 'ticket', ...COMMON_VALUES },
  { name: '일정', icon: 'calendar', query: 'schedule', ...COMMON_VALUES },
  { name: '공지사항', icon: 'notice', query: 'notice', ...COMMON_VALUES },
  { name: '1:1 문의', icon: 'consulting', query: 'qna', ...COMMON_VALUES },
  { name: '메시지', icon: 'message', query: 'message', ...COMMON_VALUES },
];

export const SUCCESS_MESSAGE = {
  post: '역할이 추가되었습니다.',
  patch: '역할이 수정되었습니다.',
  delete: '역할이 삭제되었습니다.',
};
