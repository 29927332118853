import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';

type Props = {
  buttonList: Array<{ label: string; value: string }>;
  isOpen: boolean;
  onClose: () => void;
  onClick: (value: string) => void;
};

const MoreButtonListDrawer = ({ buttonList, isOpen, onClose, onClick }: Props) => {
  return (
    <StyledDrawer isOpen={isOpen} onClose={onClose}>
      {buttonList.map(item => (
        <Button key={item.label} fullWidth size="medium40" fontSize={17} fontWeight={400} onClick={() => onClick(item.value)}>
          {item.label}
        </Button>
      ))}
    </StyledDrawer>
  );
};

export default MoreButtonListDrawer;

const StyledDrawer = styled(Drawer)`
  ${theme.flex('column', 'center', 'flex-start', 8)};

  button {
    justify-content: flex-start;
  }
`;
