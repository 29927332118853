import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';
import { BookingFailResponse } from './usePostBookingPrivate';
import { LectureGroupParams } from './usePostLectureGroup';

const usePatchLectureGroupBulk = (lecture: LectureDetailResponse) => {
  const { course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LectureGroupBulkParams>({
    method: `patch`,
    url: `/api/lecture/group/bulk/${course_id}`,
  });

  return useMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    LectureGroupBulkParams | undefined,
    unknown
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture'],
      });
    },
  });
};

export default usePatchLectureGroupBulk;

export type LectureGroupBulkParams = LectureGroupParams & {
  start: string;
  is_force?: boolean;
};
