import { EtcScheduleUpdateBulkParams } from 'hooks/service/mutations/usePatchEtcScheduleBulk';
import { convertRepeatWeekdayNum } from 'pages/Booking/utils';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import filters from 'utils/filters';

const formatEtcParams = (formValues: EtcScheduleCreateFormType): EtcScheduleUpdateBulkParams => {
  const {
    title,
    startDate,
    startTime,
    endDate,
    endTime,
    repeat,
    repeatCustomWeekday,
    selectedStaffs,
    is_all_day,
    is_overlap,
    is_private,
    color,
  } = formValues;

  const selectedWeekday = convertRepeatWeekdayNum(repeat, repeatCustomWeekday);
  const start_time = filters.timeSecondZero(startTime);
  const end_time = filters.timeSecondZero(endTime);

  /**
   * 기타일정에서 반복설정 선택하지 않았을 때 (직접 선택했을 때만 weekday 보냄)
   * - is_repeat: false,
   * - weekday 보내지 않음
   */
  const weekday = startDate === endDate || !selectedWeekday.length ? undefined : selectedWeekday;

  return {
    title,
    start_date: startDate,
    end_date: endDate,
    start_time,
    end_time,
    is_all_day,
    is_overlap,
    is_private,
    is_repeat: !!selectedWeekday.length,
    weekday,
    staff_ids: selectedStaffs.map(({ id }) => id),
    color,
    start: filters.dateDashTimeSecondZero(startDate),
  };
};

export default formatEtcParams;
