import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useInfinityBoardNotice from 'hooks/service/queries/useInfinityBoardNotice';
import useSearchKeywords from 'hooks/useSearchKeywords';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import { NOTICE_IDENTIFIER, NOTICE_SORT_IDENTIFIER } from '../../constants';
import { NOTICE_LIST_TEXT } from '../constants';
import NoticeListItem from './NoticeListItem';

const NoticeList = () => {
  const { searchKeyword } = useSearchKeywords(NOTICE_IDENTIFIER);
  const { searchKeyword: sortKeyword } = useSearchKeywords(NOTICE_SORT_IDENTIFIER);
  const sort = sortKeyword === 'asc' ? 'asc' : 'desc';

  const {
    data: notices,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    refetch,
  } = useInfinityBoardNotice(searchKeyword, sort);

  if (notices?.length === 0)
    return (
      <Container isEmpty>
        <Typography size={15} textColor="gray2">
          {searchKeyword ? (
            <>
              <b>&ldquo;{searchKeyword}&rdquo;</b>
              {`${NOTICE_LIST_TEXT.noSearchResult}`}
            </>
          ) : (
            NOTICE_LIST_TEXT.noNotice
          )}
        </Typography>
      </Container>
    );

  return (
    <PullToRefresh onRefresh={refetch} isRefetching={isRefetching}>
      <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
        <Container>{notices?.map(notice => <NoticeListItem key={notice.id} notice={notice} />)}</Container>
      </InfiniteScroll>
    </PullToRefresh>
  );
};

export default NoticeList;

const Container = styled.section<{ isEmpty?: boolean }>`
  padding: 20px;
  ${theme.flex('column', 'center', '', 12)}
  justify-content: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
  min-height: 400px;
  b {
    font-weight: 600;
    color: ${theme.color.primary};
  }
`;
