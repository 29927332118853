import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import usePopup from 'hooks/usePopup';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import MainLayout from 'sharedComponents/MainLayout';

import BookingFailList from './BookingFailList';

type Props = {
  fails: Array<BookingFailResponse>;
};

/** 수업 생성할 때, 모든 예약에 대해 실패 시 노출할 화면 */
const FailAllBooking = ({ fails }: Props) => {
  const navigate = useNavigate();
  const { setPopup } = usePopup();

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton
            onClick={() => {
              navigate('/schedule');
              setPopup(null);
            }}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <FailBookingContainer>
        <Icon size={54} name="errorFill" color="gray4" />
        <Typography size={19} weight={700}>
          <Typography size={19} weight={700} textColor="error" span>
            {fails.length}
          </Typography>
          개의 수업과 일정이 중복되어 <br />
          예약할 수 없습니다.
        </Typography>

        <BookingFailList fails={fails} />

        <FooterButton onClick={() => setPopup(null)}>돌아가기</FooterButton>
      </FailBookingContainer>
    </MainLayout>
  );
};

export default FailAllBooking;

export const FailBookingContainer = styled.div`
  padding: 44px 32px 122px;
  text-align: center;

  h2 {
    margin-top: 12px;
  }

  ul {
    border-top: 1px solid ${theme.color.gray6};
    border-bottom: 1px solid ${theme.color.gray6};
    text-align: left;
    margin-top: 48px;

    li {
      padding: 16px 0;
    }
  }
`;
