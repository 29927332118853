import { MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';
import getImageUrl from 'utils/getImageUrl';

import Profile from './Profile';

const ProfileForStudioStaff = () => {
  const { data: staff } = useGetStaffMe();
  const avatar = staff.avatar?.image;
  const image = avatar ? getImageUrl({ path: avatar.path, name: avatar.name, extension: avatar.extension }) : undefined;
  const color = MATCH_REPRESENTATIVE_COLOR[staff.profile.representative_color] ?? 'primary';
  const role = staff.roles.length > 0 ? staff.roles[0].display_name : '';

  return (
    <Profile name={staff.name} image={image} studioIdentity={{ representativeColor: color, roleName: role }} id={staff.id} />
  );
};

export default ProfileForStudioStaff;
