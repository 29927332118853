import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useInfinityScheduleManagementDeletedLecture, {
  ScheduleManagementDeletedLectureParams,
  ScheduleManagementDeletedLectureResponse,
} from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { deletedLectureParamsAtom } from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import { calculateTotal, convertToStringStaffIds, filterByKeyword, getScheduleGroupByStartOn } from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const DeletedLectureScheduleList = ({ currentTab }: Props) => {
  const scheduleDeletedLecture = useRecoilValue(deletedLectureParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleManagementDeletedLectureResponse[][]>([]);

  const scheduleDeletedLectureParams: ScheduleManagementDeletedLectureParams = {
    staff: convertToStringStaffIds(scheduleDeletedLecture.staffs || []),
    start_date: scheduleDeletedLecture.period?.start || filters.dateDash(),
    end_date: scheduleDeletedLecture.period?.end || filters.dateDash(),
    type: scheduleDeletedLecture.type,
    paginate_type: 'detail',
    order_by: scheduleDeletedLecture.sort ?? 'asc',
  };

  const {
    data: { deletedLectures, total },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfinityScheduleManagementDeletedLecture(scheduleDeletedLectureParams);

  const groupTotal = useMemo(() => calculateTotal(filteredSchedules, 'G'), [filteredSchedules]);
  const privateTotal = useMemo(() => calculateTotal(filteredSchedules, 'P'), [filteredSchedules]);

  useEffect(() => {
    // 조건에 따라 스케줄 필터링 및 그룹화
    const filtered = searchKeyword === '' ? deletedLectures : filterByKeyword(deletedLectures, searchKeyword);

    const groupedSchedules = getScheduleGroupByStartOn(filtered);
    setFilteredSchedules(groupedSchedules as ScheduleManagementDeletedLectureResponse[][]);
  }, [searchKeyword, deletedLectures]);

  if (total === 0 || filteredSchedules.length === 0) {
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={groupTotal} privateTotal={privateTotal} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData noDataText={SCHEDULE_NODATA_TEXT.deletedLecture} />
      </>
    );
  }

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={total} groupTotal={groupTotal} privateTotal={privateTotal} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <ContentSection>
        <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
          <ScheduleCard schedules={filteredSchedules} currentTab={currentTab} />
        </InfiniteScroll>
      </ContentSection>
    </Container>
  );
};

export default DeletedLectureScheduleList;

const Container = styled.div`
  > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;
