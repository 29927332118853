import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import usePopup from 'hooks/usePopup';
import { PUSH_TEXT } from 'pages/Notification/constants';
import { PushItemType } from 'pages/Notification/types';
import MainLayout from 'sharedComponents/MainLayout';
import filters from 'utils/filters';

type Props = {
  pushItem: PushItemType;
};

const PushListItemDetail = ({ pushItem }: Props) => {
  const { setPopup } = usePopup();

  return (
    <MainLayout
      header={{
        title: PUSH_TEXT.detailTitle,
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <Container>
        <Typography size={15} weight={600}>
          {pushItem.label}
        </Typography>
        <Typography size={15} weight={600}>
          {pushItem.title}
        </Typography>
        <Typography size={15} textColor="gray3" className="date">
          {filters.dateTimeWithWeekday(pushItem.created_at)}
        </Typography>
        <Divider thin thinColor="gray6" />
        <Typography size={15} textColor="gray2" className="message">
          {pushItem.message}
        </Typography>
      </Container>
    </MainLayout>
  );
};

export default PushListItemDetail;

const Container = styled.div`
  padding: 16px 20px;
  .date {
    margin: 4px 0 16px;
  }
  .message {
    margin-top: 16px;
  }
`;
