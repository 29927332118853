import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';

/** 프라이빗 모든 수업 삭제 */
const useDeleteLecturePrivateAll = (lecture: LectureDetailResponse) => {
  const { id, course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LecturePrivateDeleteAllParams>({
    method: `delete`,
    url: `/api/lecture/private/all/${course_id}`,
  });

  return useMutation({
    mutationFn,
    mutationKey: ['lecture', 'delete', 'private', 'all', course_id],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', id],
      });
    },
  });
};

export default useDeleteLecturePrivateAll;

export type LecturePrivateDeleteAllParams = {
  course_id: number;
};
