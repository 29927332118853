import LinkButton from 'components/Button/LinkButton';
import Drawer from 'components/Drawer';
import Icon from 'components/Icon';
import { CHANNEL_DATA } from 'pages/CounselDetail/constants';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckRadioFilterContents from 'sharedComponents/CheckRadioFilterContents';

import { CounselingFormType } from '../type';

const Channel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<CounselingFormType>();
  const channel = useWatch({ control, name: 'channel' });

  const label = useMemo(() => {
    return CHANNEL_DATA.find(item => item.value === channel)?.label;
  }, [channel]);

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LinkButton
        gap={10}
        arrowBold
        arrowSize={16}
        leftIcon={<Icon name="consulting2" color="gray2" />}
        onClick={() => setIsOpen(true)}
        fontWeight={500}>
        {label}
      </LinkButton>

      <Drawer isOpen={isOpen} onClose={closeDrawer}>
        <CheckRadioFilterContents name="channel" control={control} title="상담유형" data={CHANNEL_DATA} onClick={closeDrawer} />
      </Drawer>
    </>
  );
};

export default Channel;
