import styled from '@emotion/styled';
import Divider from 'components/Divider';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import useToast from 'hooks/useToast';
import { TICKET_REFUND_TEXT } from 'pages/TicketDetail/constants';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';
import { TicketPaymentFormType } from 'sharedComponents/TicketForm/types';
import filters from 'utils/filters';

import FoldingTicketPaymentCard from '../FoldingTicketPaymentCard';
import PaymentForm from './PaymentForm';
import RefundConfirmDialog from './RefundConfirmDialog';

const TicketRefund = ({ userTicket }: UserTicketProps) => {
  const {
    payments: {
      point_amount_total,
      reward_point_total,
      card_amount_total,
      cash_amount_total,
      wiretransfer_amount_total,
      current_point,
      total_amount,
    },
  } = userTicket;
  const methods = useForm<TicketPaymentFormType>({
    defaultValues: {
      installment: 'single',
      isCheckedCard: !!card_amount_total,
      isCheckedCash: !!cash_amount_total,
      isCheckedWireTransfer: !!wiretransfer_amount_total,
      isCheckedPoint: !!point_amount_total,
      salePrice: total_amount,
      remainingPoint: current_point,

      payment: {
        amount: total_amount,
        card_amount: card_amount_total,
        cash_amount: cash_amount_total,
        wiretransfer_amount: wiretransfer_amount_total,
        point_amount: reward_point_total,
        unpaid_amount: 0,
        reward_point: point_amount_total,
        settlement_at: filters.dateDashTimeSecond(),
      },
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const { setToast } = useToast();
  const productTypeText = PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type];

  const submit = (values: TicketPaymentFormType) => {
    const { remainingPoint, payment } = values;

    /** 잔여 포인트 보다 포인트 회수가 많을 때 */
    if (Number(payment.point_amount) > Number(remainingPoint)) {
      setToast({
        type: 'error',
        message: TICKET_REFUND_TEXT.errorMessage.pointLack,
        bottom: 136,
      });
      return;
    }

    setIsOpen(true);
  };

  return (
    <MainLayout header={{ title: `${productTypeText} ${TICKET_REFUND_TEXT.title}` }}>
      <FormProvider {...methods}>
        <Container>
          <FoldingTicketPaymentCard
            isRefund
            userTicket={userTicket}
            title={`${productTypeText} ${TICKET_REFUND_TEXT.cardTitle}`}
          />
          <Divider />
          <PaymentForm onSubmit={submit} />
        </Container>

        {isOpen && (
          <RefundConfirmDialog userTicket={userTicket} productTypeText={productTypeText} onClose={() => setIsOpen(false)} />
        )}
      </FormProvider>
    </MainLayout>
  );
};

export default TicketRefund;

const Container = styled.div`
  min-height: 100%;

  .folding-ticket-payment-card {
    padding: 16px 20px 24px;
  }
`;
