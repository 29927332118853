import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityMessageMobile = (params: MessageMobileParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MessageMobileResponse, MessageMobileParams>({
    url: `/api/message/mobile`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`message/mobile`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityMessageMobile;

export type MessageMobileParams = {
  start_date: string;
  end_date: string;
  message_type?: 'SMS' | 'LMS';
  message_status?: 'request' | 'success';
  search?: string;
};

export type MessageMobileResponse = {
  id: number;
  studio_id: number;
  title: string;
  message: string;
  message_type: 'SMS' | 'LMS';
  send_person: string;
  send_count: number;
  status: 'cancel' | 'request' | 'success';
  request_count: number;
  fail_count: number;
  all_count: number;
  send_on: string;
  created_at: string;
  updated_at: string;
  canceled_at: string;
};
