import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { EtcScheduleParams, EtcScheduleResponse } from './useGetAllScheduleEtcSchedule';

type Props = {
  start_date: string;
  end_date: string;
  /**
   * 빈 배열일 경우 staff 전체 필터 적용
   */
  staff_ids: number[];
  enabled: boolean;
};

const useGetAllScheduleAllDayEtcSchedule = ({ enabled, ...params }: Props) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<EtcScheduleResponse, EtcScheduleParams>({
    url: '/api/schedule/allDayEtcSchedule',
    params: {
      ...params,
    },
  });

  return useQuery({
    queryKey: ['schedule/allDayEtcSchedule', { ...params, enabled }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllScheduleAllDayEtcSchedule;
