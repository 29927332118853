import Divider from 'components/Divider';
import Form from 'components/Form';
import usePatchPayment from 'hooks/service/mutations/usePatchPayment';
import usePostUnpaid from 'hooks/service/mutations/usePostUnpaid';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_PAYMENT_TEXT } from 'pages/TicketDetail/constants';
import { TicketPaymentUpdateFormType } from 'pages/TicketDetail/types';
import { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card, Cash, Discount, Installment, Point, SalePrice, SettlementDate, WireTransfer } from 'sharedComponents/TicketForm';
import PaymentFormTitle from 'sharedComponents/TicketForm/TicketPaymentForm/PaymentFormTitle';
import getPaymentFormParams from 'utils/getPaymentFormParams';

import PaymentForm from './PaymentForm';
import SubmitButton from './SubmitButton';

type Props = Pick<ComponentProps<typeof PaymentForm>, 'goBack'>;

const UnpaidPaymentForm = ({ goBack }: Props) => {
  const { handleSubmit, getValues } = useFormContext<TicketPaymentUpdateFormType>();

  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const { mutate: unpaidPaymentMutate } = usePostUnpaid(getValues('userTicket.id'));
  const { mutate: updatePaymentMutate } = usePatchPayment(getValues('id'));

  const saveUnpaidPayment = (values: TicketPaymentUpdateFormType) => {
    const { id, status, paymentStatus, ...payment } = values;
    const paymentParams = getPaymentFormParams({ ...payment });

    const params = {
      ...paymentParams,
      status,
    };

    if (paymentStatus === 'update') {
      updatePaymentMutate(params, {
        onSuccess: () => {
          setToast({ type: 'success', message: TICKET_PAYMENT_TEXT.saveSuccessMessage });
          goBack();
        },
        onError: error => {
          errorDialog.open(error);
        },
      });
    } else {
      unpaidPaymentMutate(params, {
        onSuccess: () => {
          setToast({ type: 'success', message: TICKET_PAYMENT_TEXT.updateSuccessMessage });
          goBack();
        },
        onError: error => {
          errorDialog.open(error);
        },
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(saveUnpaidPayment)}>
      <SalePrice />
      <Discount />
      <Divider />
      <PaymentFormTitle>결제수단</PaymentFormTitle>
      <Card />
      <Installment />
      <Cash />
      <WireTransfer />
      <Point />
      <Divider />
      <SettlementDate />
      <SubmitButton>완료</SubmitButton>
    </Form>
  );
};

export default UnpaidPaymentForm;
