import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import { PaymentRow } from './StyledComponents';

/** 적립할 포인트 입력 필드 */
const PointAccumulation = () => {
  const { control, setValue } = useFormContext<TicketPaymentFormType>();
  const rewardPoint = useWatch({ control, name: 'payment.reward_point' });

  return (
    <PaymentRow>
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'payment.reward_point' }}
        inputMode="numeric"
        placeholder="0"
        textAlign="right"
        left={{
          prefix: <Icon name="point" fillColor={theme.color.gray2} />,
          text: TICKET_PAYMENT_FORM_TEXT.point.accumulationLabel,
        }}
        suffix={
          <Typography span size={15} weight={500}>
            P
          </Typography>
        }
        value={filters.numberComma(rewardPoint || 0)}
        onChange={e => {
          setValue('payment.reward_point', filters.numberComma(e.target.value, { reverse: true }), {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
      />
    </PaymentRow>
  );
};

export default PointAccumulation;
