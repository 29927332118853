import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import getPrivateLimitValidArray from 'pages/Booking/utils/getPrivateLimitValidArray';

import ValidErrorCard from './ValidErrorCard';

type Props = {
  bookingCountValidation: BookingCountResponse;
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
};

const ValidErrorDrawer = ({ bookingCountValidation, isOpen, onClose, onClick }: Props) => {
  const historyArray = getPrivateLimitValidArray(bookingCountValidation);

  return (
    <DoubleButtonDrawer
      type={3}
      header={<TwoLinedTitle title={['예약가능 횟수 초과 일정이 있습니다.', '계속 하시겠습니까?']} />}
      isOpen={isOpen}
      onClose={onClose}
      isHeightMax
      leftButton={{
        text: '취소',
        onClick: onClose,
      }}
      rightButton={{
        text: '계속',
        onClick: onClick,
      }}>
      {/* <Container> */}
      <StyledUl>
        {historyArray.map(item => (
          <li key={`${item.name}=${item.type}`}>
            <ValidErrorCard {...item} />
          </li>
        ))}
      </StyledUl>
      {/* </Container> */}
    </DoubleButtonDrawer>
  );
};

export default ValidErrorDrawer;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 16)};
`;
