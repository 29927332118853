import styled from '@emotion/styled';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';

import { LIMIT_VALIDATION_DATA } from '../constants';

type Props = {
  bookingCountValidation: BookingCountResponse;
  onClickBooking: () => void;
  onClose: () => void;
};

const LimitValidationDialog = ({ bookingCountValidation, onClickBooking, onClose }: Props) => {
  const validationList = Object.entries(bookingCountValidation).flatMap(([key, value]) => {
    const result = [];

    if (value.daily) {
      result.push({ name: key, type: 'daily', count: value.daily_booking_limit });
    } else if (value.weekly) {
      result.push({ name: key, type: 'weekly', count: value.booking_limit_per_week });
    } else if (value.monthly) {
      result.push({ name: key, type: 'monthly', count: value.booking_limit_per_week });
    }
    return result;
  });

  return (
    <Dialog
      title="예약가능 횟수 초과"
      onClose={onClose}
      negativeAction={{ text: '취소' }}
      positiveAction={{ text: '예약', onClick: onClickBooking }}>
      <StyledDialogDescription>
        {validationList.map((item, index) => (
          <b key={`${item.type}-${index}`}>
            {item.name} ({LIMIT_VALIDATION_DATA[item.type]}: {item.count}회)
          </b>
        ))}
        계속하시겠습니까?
      </StyledDialogDescription>
    </Dialog>
  );
};

export default LimitValidationDialog;

const StyledDialogDescription = styled(DialogDescription)`
  b {
    display: block;
  }
`;
