import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityUserTicketHistory = (params: UserTicketHistoryParams & Params) => {
  const { productId, ...rest } = params;

  const { queryFn } = useInfiniteQueryFnWithCommonParams<UserTicketHistoryResponse, UserTicketHistoryParams>({
    url: `/api/userTicket/history/${params.productId}`,
    params: {
      ...rest,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityUserTicketHistory;

type Params = {
  productId: number;
};

export type UserTicketHistoryParams = {
  user_id: number;
  order_by: 'asc' | 'desc';
  start_date: string;
  end_date: string;
};
export type UserTicketHistoryResponse = {
  id: number;
  context: string | null;
  kind: string;
  new: any;
  old: any;
  staff: {
    id: number;
    name: string;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};
