import styled from '@emotion/styled';
import { color } from 'assets/styles';
import Avatar from 'components/Avatar';
import LinkButton from 'components/Button/LinkButton';
import Drawer from 'components/Drawer';
import Icon from 'components/Icon';
import useInfinityStaff from 'hooks/service/queries/useInfinityStaff';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckRadioFilterContents from 'sharedComponents/CheckRadioFilterContents';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import getColor from 'utils/getColor';
import getAvatarImageUrl from 'utils/getImageUrl';

import { CounselingFormType } from '../type';
import AvatarLabel from './AvatarLabel';
import { StaffType } from 'types/staffTypes';
import { IAvatarImage } from 'api/modules/commonType';
import Typography from 'components/Typography';

type Props = {
  staff: StaffType;
};

const Staffs = ({ staff }: Props) => {
  const {
    data: { staffs },
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfinityStaff();

  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<CounselingFormType>();
  const target_staff_id = useWatch({ control, name: 'target_staff_id' });

  const data = useMemo(() => {
    if (!staffs) return [];

    return staffs.map(({ id, avatar, name }) => {
      return {
        id: `${id}`,
        label: <AvatarLabel key={id} id={id} selectStaffId={target_staff_id} imgUrl={avatar} label={name} />,
        value: `${id}`,
      };
    });
  }, [staffs, target_staff_id]);

  const selectStaff = useMemo(() => {
    if (!staffs) return;
    return staffs.find(({ id }) => id === Number(target_staff_id));
  }, [staffs, target_staff_id]);

  const staffName = !selectStaff ? staff.name : selectStaff.name;
  const staffColor = !selectStaff ? getColor(staff.profile.representative_color) : getColor(selectStaff.color);
  const staffAvatar = !selectStaff ? (staff.avatar?.image as IAvatarImage | null) : (selectStaff.avatar as IAvatarImage | null);

  const closeDrawer = () => {
    setIsOpen(false);
  };

  if (isFetchingNextPage) <></>;

  return (
    <>
      <LinkButton
        gap={10}
        arrowBold
        arrowSize={16}
        leftIcon={<Avatar imgUrl={getAvatarImageUrl(staffAvatar)} />}
        onClick={() => setIsOpen(true)}>
        <ColorChipWithName>
          <Icon name="colorChips" fillColor={color[staffColor as keyof typeof color]} />
          <Typography size={15} weight={500} textColor="gray1">
            {staffName}
          </Typography>
        </ColorChipWithName>
      </LinkButton>

      <Drawer isOpen={isOpen} onClose={closeDrawer}>
        <InfiniteScroll isLoading={isFetchingNextPage} hasMore={hasNextPage} loadMore={fetchNextPage}>
          <CheckRadioFilterContents
            title={'담당강사 선택'}
            name="target_staff_id"
            data={data}
            control={control}
            onClick={closeDrawer}
          />
        </InfiniteScroll>
      </Drawer>
    </>
  );
};

export default Staffs;

const ColorChipWithName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
