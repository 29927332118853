import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  isPastLecture: boolean;
  onClose: () => void;
  onClickNegative: () => void;
  onClickPositive: () => void;
};

const ExceedWaitingDialog = ({ isPastLecture, onClickNegative, onClickPositive, onClose }: Props) => {
  const negativeText = isPastLecture ? '취소' : '초과예약';
  const positiveText = isPastLecture ? '초과예약' : '예약대기';
  const josa = isPastLecture ? '을' : '를';

  return (
    <Dialog
      showCloseButton={!isPastLecture}
      onClose={onClose}
      negativeAction={{ text: negativeText, onClick: onClickNegative, style: isPastLecture ? 'outlined' : 'contained' }}
      positiveAction={{ text: positiveText, onClick: onClickPositive }}>
      <DialogDescription>
        예약 정원이 마감되었습니다.
        <br />
        <b>[초과예약{`${!isPastLecture ? 'or 예약대기' : ''}`}]</b>
        {josa} 하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default ExceedWaitingDialog;
