import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

const MemberInfoSkeleton = () => {
  return (
    <>
      <Container>
        <Skeleton width={96} height={96} type="circle" />
        <StyledSkeleton width={55} height={26} type="round" />
        <StyledSkeleton width={101} height={16} type="round" />
        <StyledSkeleton width={240} height={16} type="round" />
        <StyledSkeleton height={64} type="round" />
      </Container>
      <Divider />
    </>
  );
};

export default MemberInfoSkeleton;

const Container = styled.div`
  ${theme.flex('column', 'center')};
  padding: 0 20px 24px;

  div:nth-of-type(2) {
    margin-top: 16px;
  }
  div:nth-of-type(3) {
    margin-top: 6px;
  }
  div:nth-of-type(4) {
    margin-top: 10px;
  }
  div:nth-of-type(5) {
    margin-top: 24px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 3px;
`;
