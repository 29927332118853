import errorNotConnected from 'assets/images/error_not_connected.webp';
import errorTemporaryError from 'assets/images/error_temporary_error.webp';
import errorUnauthorized from 'assets/images/error_unauthorized.webp';
import axios from 'axios';
import Typography from 'components/Typography';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import { useEffect, useMemo } from 'react';
import { type FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { drawerAtom } from 'recoil/drawer';

import { ErrorDrawerContent } from '../type';
import ErrorDrawer from './ErrorDrawer';

const ApiErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate();
  const setIsOpen = useSetRecoilState(drawerAtom);

  if (!axios.isAxiosError(error)) {
    throw error;
  }

  useEffect(() => {
    if (error) {
      setIsOpen(true);
    }
  }, [error, setIsOpen]);

  const isNetworkError = error.message === 'Network Error';
  const isUnauthorized = error.response?.status === 401;
  const isForbidden = error.response?.status === 403;

  const content: ErrorDrawerContent = useMemo(() => {
    if (isNetworkError) {
      return {
        header: <TitleWithSubText title="인터넷에 연결되지 않았습니다." subTitle="연결 확인 후 다시 시도해 주세요." />,
        image: errorNotConnected,
        buttonText: '재시도',
        buttonType: 3,
        buttonClick: resetErrorBoundary,
      };
    }

    if (isUnauthorized) {
      return {
        header: (
          <TwoLinedTitle
            title={
              <Typography size={19} weight={700}>
                인증이 만료되어
                <br />
                재로그인이 필요합니다.
              </Typography>
            }
          />
        ),
        image: errorUnauthorized,
        buttonText: '로그인',
        buttonType: 3,
        buttonClick: () => navigate('/login'),
      };
    }

    if (isForbidden) {
      return {
        header: <TitleWithSubText title="권한 없음" subTitle="페이지에 접근할 수 없습니다." />,
        image: errorTemporaryError,
        buttonText: '이전 화면으로',
        buttonType: 3,
        buttonClick: () => navigate(-1),
      };
    }

    return {
      header: <TitleWithSubText title="일시적인 오류입니다." subTitle="잠시 후 다시 시도해 주세요." />,
      image: errorTemporaryError,
      buttonText: '첫 화면으로',
      buttonText2: '이전 화면으로',
      buttonType: 4,
      buttonClick: () => navigate('/schedule'),
      buttonClick2: () => navigate(-1),
    };
  }, [isNetworkError, isUnauthorized, navigate, resetErrorBoundary]);

  return <ErrorDrawer content={content} />;
};

export default ApiErrorFallback;
