import filters from 'utils/filters';

import { FilterType, PeriodFilterType } from './types';

export const DEFAULT_PERIOD_FILTER: FilterType = {
  key: 'period',
  type: 'period',
  title: '기간선택',
  options: [
    { label: '오늘', value: undefined },
    { label: '이번주', value: 'isoWeek' },
    { label: '이번달', value: 'month' },
    { label: '직접선택', value: 'custom' },
  ],
  tabConverter: value => {
    const periodValue = value as PeriodFilterType['period'];
    if (!periodValue || !periodValue.type) return '오늘';
    if (periodValue.type === 'isoWeek') return '이번주';
    if (periodValue.type === 'month') return '이번달';
    return '직접선택';
  },
};

export const DEFAULT_PERIOD = {
  start: filters.dateDash(),
  end: filters.dateDash(),
};
