import styled from '@emotion/styled';
import useInfinityMemberCounsel from 'hooks/service/queries/useInfinityMemberCounsel';
import useSearchKeywords from 'hooks/useSearchKeywords';
import NoData from 'pages/Member/components/NoData';
import { MessageTargetFormType } from 'pages/MoreDetails/Message/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import CounselListItem from './CounselListItem';

const CounselList = () => {
  const { register, ...selectedFilter } = useRecoilValue(messageTargetCounselSelectedFilter);
  const { searchKeyword } = useSearchKeywords('message');

  const {
    data: { counsels, total },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityMemberCounsel({
    paginate_type: 'detail',
    ...selectedFilter,
    register_type: register ?? 'both',
    search: searchKeyword,
    has_mobile: 1,
  });

  const { setValue } = useFormContext<MessageTargetFormType>();
  useEffect(() => {
    setValue('total', total);
  }, [total, setValue]);

  if (!total) return <NoData currentTabLabel="등록된" currentKeyword={searchKeyword} />;
  return (
    <StyledInfiniteScroll gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      {counsels.map(counsel => (
        <CounselListItem key={counsel.id} counsel={counsel} />
      ))}
    </StyledInfiniteScroll>
  );
};

export default CounselList;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding: 20px 20px 80px 20px;
`;
