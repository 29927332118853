export const APP_PUSH_DETAIL_TEXT = {
  title: '앱 푸시 메시지 상세',
  sendOn: '발송일시',
  target: '대상회원',
  detail: '상세보기',
  count: '발송건수',

  subTitle: '발송 메시지',
  message: {
    title: '제목',
    content: '내용',
  },
};
