import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useEventScheduleFormSet from 'hooks/useEventScheduleFormSet';
import useParamsId from 'hooks/useParamsId';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import EventScheduleUpdateForm from './components/EventScheduleUpdateForm';

const MoreDetailsStaffEventScheduleUpdateForm = () => {
  const navigate = useNavigate();

  const targetStaffId = useParamsId();
  const { methods, eventTimeDataset } = useEventScheduleFormSet({ targetStaffId });

  return (
    <MainLayout
      header={{
        title: '특정일 근무시간 추가',
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <Container>
        <ApiBoundary fallback={<div>loading..</div>}>
          <FormProvider {...methods}>
            <EventScheduleUpdateForm shapeCard eventTimeDataset={eventTimeDataset} />
          </FormProvider>
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default MoreDetailsStaffEventScheduleUpdateForm;

const Container = styled.div`
  padding-bottom: 40px;
  height: 100%;

  .sub-title {
    margin: 8px 0 40px;
    padding: 0 20px;
  }
`;
