import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import useGetUserGrade from 'hooks/service/queries/useGetUserGrade';
import { MEMBER_FORM_TEXT } from 'pages/MemberForm/constants';
import { MemberFormType } from 'pages/MemberForm/types';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

const UserGradeSetting = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<MemberFormType>();
  const userGradeId = useWatch({ control, name: 'userGradeId' });
  const notSelected = MEMBER_FORM_TEXT.userGrade.notSelected.value;

  const { data: userGradeData } = useGetUserGrade();

  const data = useMemo(() => {
    const defaultItem = {
      id: notSelected,
      label: MEMBER_FORM_TEXT.userGrade.notSelected.label,
      value: notSelected,
    };
    const userGradeDataItems = userGradeData?.map(item => ({ id: `${item.id}`, label: item.name, value: `${item.id}` }));

    return [defaultItem, ...userGradeDataItems];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGradeData]);

  const label = useMemo(() => {
    if (!userGradeId || userGradeId === notSelected || !data.length) return MEMBER_FORM_TEXT.userGrade.defaultLabel;

    return data.find(item => item.value === String(userGradeId))?.label;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userGradeId]);

  return (
    <>
      <FormFieldLinkButton iconName="vip" hasValue={userGradeId !== notSelected || !userGradeId} onClick={() => setIsOpen(true)}>
        {label}
      </FormFieldLinkButton>

      <CheckRadioDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={MEMBER_FORM_TEXT.userGrade.title}
        name="userGradeId"
        control={control}
        data={data}
      />
    </>
  );
};

export default UserGradeSetting;
