import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import CheckRadioInfiniteDrawer from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioInfiniteDrawer';
import useInfinityStaff from 'hooks/service/queries/useInfinityStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import StaffLinkButton from './StaffLinkButton';

type Props = {
  /**
   * 수업 생성: 현재 로그인 한 강사
   * 수업 수정: 해당 수업의 담당 강사
   */
  currentStaff: {
    id: number;
    name: string;
    image: {
      path: string;
      name: string;
      extension: string;
    } | null;
    color: string | number;
  };
};

const Staffs = ({ currentStaff }: Props) => {
  const {
    data: { staffs },
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfinityStaff();
  const [isOpen, setIsOpen] = useState(false);

  const { control, setValue } = useFormContext<BookingCommonFormType>();
  const selectedStaffId = useWatch({ control, name: 'selectedStaffId' });

  const selectStaff = staffs.find(({ id }) => id === Number(selectedStaffId));

  const data = useMemo(() => {
    return staffs.map(({ id, avatar, name }) => {
      const staffId = String(id);
      return {
        id: staffId,
        label: (
          <AvatarLabel key={id} id={staffId} selectStaffId={String(selectedStaffId)} imgUrl={avatar} label={name} showLabelAll />
        ),
        value: staffId,
      };
    });
  }, [selectedStaffId, staffs]);

  const staffInfo = useMemo(() => {
    if (selectStaff) {
      return {
        imgUrl: selectStaff.avatar,
        name: selectStaff.name,
        color: selectStaff.color,
      };
    } else if (currentStaff) {
      return {
        imgUrl: currentStaff.image,
        name: currentStaff.name,
        color: currentStaff.color,
      };
    } else {
      return {
        imgUrl: null,
        name: BOOKING_COMMON_FORM_TEXT.staff.placeholder,
        color: '',
      };
    }
  }, [currentStaff, selectStaff]);

  useEffect(() => {
    const staff = selectStaff || {
      id: currentStaff.id,
      avatar: currentStaff.image,
      name: currentStaff.name,
      color: currentStaff.color,
    };
    setValue('selectedStaff', staff);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStaff, selectedStaffId]);

  return (
    <>
      <StaffLinkButton staffInfo={staffInfo} onClick={() => setIsOpen(true)} />

      <CheckRadioInfiniteDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={BOOKING_COMMON_FORM_TEXT.staff.drawer.title}
        isLoading={isFetchingNextPage}
        hasMore={hasNextPage}
        loadMore={fetchNextPage}
        name="selectedStaffId"
        data={data}
        control={control}
      />
    </>
  );
};

export default Staffs;
