import { useFormContext } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

const LeaveDialog = () => {
  const {
    formState: { dirtyFields, isSubmitted },
  } = useFormContext();
  const isDirty = !!Object.keys(dirtyFields).length;

  return <DialogBeforeLeave isBlocked={isDirty && !isSubmitted} />;
};

export default LeaveDialog;
