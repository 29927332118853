import { get } from 'lodash';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import AttendanceCountFilter from './AttendanceCountFilter';
import LastAttendanceDayFilter from './LastAttendanceDayFilter';
import TicketPeriodFilter from './TicketPeriodFilter';

const AllMemberDrawerFilterOptions = ({ filters, filterChanged, changeOption, currentFilterTab }: FilterOptionsProps) => {
  const currentFilter = filters.find(filter => filter.key === currentFilterTab?.value);
  if (!currentFilterTab || !currentFilter) return null;
  return (
    <>
      {currentFilter.type === 'button' && (
        <ButtonFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
      {currentFilter.key === 'ticket_remaining' && (
        <TicketPeriodFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
      {currentFilter.key === 'last_attendance_day' && (
        <LastAttendanceDayFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
      {currentFilter.key === 'attendance_count' && (
        <AttendanceCountFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
    </>
  );
};

export default AllMemberDrawerFilterOptions;
