import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const MoreInfoDrawer = ({ isOpen, onClose }: Props) => {
  return (
    <SingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="계정 통합" />}
      button={{ text: '확인', onClick: onClose }}>
      <section>
        <Typography size={15}>
          <b>하나의 계정</b>으로 보다 간편하게 스튜디오메이트를 이용할 수 있도록 로그인 정책이 개편되었습니다.
        </Typography>
        <br />
        <br />
        <Typography size={15}>
          선택한 아이디를 제외한 모든 아이디는 <b>자동 삭제</b>되며, 선택한 아이디로는 기존 서비스를 계속 이용할 수 있습니다.
        </Typography>
      </section>
    </SingleButtonDrawer>
  );
};

export default MoreInfoDrawer;
