import { ArrowFilterValuesType } from 'components/Tabs/ArrowFilterTabs';
import { FilterType } from 'sharedComponents/Filters/types';

import { ReportPointParamType } from './type';
import { getPointStatusLabel } from './util';

type SortItem = Array<{ id: string; label: string; value: 'asc' | 'desc' }>;

const REPORT_POINT_PARAM_LABEL = {
  save: {
    label: '적립',
  },
  remove: {
    label: '차감',
  },
  destruction: {
    label: '소멸',
  },
};

export const REPORT_TABS = [
  { value: 'sale', label: '수강권/상품' },
  { value: 'lecture', label: '수업' },
  { value: 'unpaid', label: '미수금' },
  { value: 'point', label: '포인트' },
];

export const REPORT_LECTURER_SORTING_FILTERS: SortItem = [
  { id: 'asc', label: '수업일시 오름차순', value: 'asc' },
  { id: 'desc', label: '수업일시 내림차순', value: 'desc' },
];

export const REPORT_UNPAID_SORTING_FILTERS: SortItem = [
  { id: 'desc', label: '결제일 최신순', value: 'desc' },
  { id: 'asc', label: '결제일 과거순', value: 'asc' },
];

export const REPORT_POINT_SORTING_FILTERS: SortItem = [
  { id: 'desc', label: '최신순', value: 'desc' },
  { id: 'asc', label: '과거순', value: 'asc' },
];

export const REPORT_PRODUCT_TYPE_LABEL = {
  G: '그룹 수강권',
  P: '프라이빗 수강권',
  S: '판매 상품',
  R: '대여 상품',
};

export const POINT_TYPE_LABEL = {
  reward: {
    label: '적립',
    color: 'skyBlue1',
  },
  batch_reward: {
    label: '적립 (일괄)',
    color: 'skyBlue1',
  },
  deduct: {
    label: '차감',
    color: 'gray3',
  },
  batch_deduct: {
    label: '차감 (일괄)',
    color: 'gray3',
  },
  destruction: {
    label: '소멸',
    color: 'gray3',
  },
  mix: {
    label: '혼합',
    color: 'gray3',
  },
};

export const REPORT_PAYMENT_LABEL = {
  new: {
    name: '신규결제',
    color: 'secondary1',
  },
  're-take': {
    name: '재결제',
    color: 'gray3',
  },
  upgrade: {
    name: '업그레이드',
    color: 'primary',
  },
  transfer: {
    name: '양도',
    color: 'blueViolet1',
  },
  refund: {
    name: '환불',
    color: 'secondary3',
  },
  experience: {
    name: '체험',
    color: 'secondary2',
  },
  unpaid: {
    name: '미수금결제',
    color: 'softYellow1',
  },
};

export const REPORT_LECTURE_STATUS_LABEL = {
  noshow: {
    name: '노쇼',
  },
  attendance: {
    name: '출석',
  },
  absence: {
    name: '결석',
  },
};

export const PERIOD_OPTIONS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      {
        value: 'day',
        label: '일간',
      },
      {
        value: 'isoWeek',
        label: '주간',
      },
      {
        value: 'month',
        label: '월간',
      },
      {
        value: 'custom',
        label: '직접선택',
      },
    ],
  },
];

export const POINT_PERIOD_OPTIONS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      {
        value: 'month',
        label: '월간',
      },
      {
        value: 'custom',
        label: '직접선택',
      },
    ],
  },
];

export const DEFAULT_REPORT_SALE_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'paymentType',
    type: 'button',
    title: '결제구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'new',
        label: '신규결제',
      },
      {
        value: 're-take',
        label: '재결제',
      },
      {
        value: 'upgrade',
        label: '업그레이드',
      },
      {
        value: 'transfer',
        label: '양도',
      },
      {
        value: 'refund',
        label: '환불',
      },
      {
        value: 'experience',
        label: '체험',
      },
      {
        value: 'unpaid',
        label: '미수금결제',
      },
    ],
  },
  {
    key: 'productType',
    type: 'button',
    title: '수강권/상품 구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
      {
        value: 'S',
        label: '판매',
      },
      {
        value: 'R',
        label: '대여',
      },
    ],
  },
  {
    key: 'paymentMethod',
    type: 'button',
    title: '결제방법',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'card',
        label: '카드',
      },
      {
        value: 'cash',
        label: '현금',
      },
      {
        value: 'wiretransfer',
        label: '계좌이체',
      },
      {
        value: 'point',
        label: '포인트',
      },
    ],
  },
  {
    key: 'ticket',
    type: 'select',
    title: '수강권',
    options: [],
  },
];

export const DEFAULT_REPORT_LECTURE_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'classType',
    type: 'button',
    title: '수업구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
    ],
  },
  {
    key: 'ticketType',
    type: 'button',
    title: '수강권 유형',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'T',
        label: '횟수제',
      },
      {
        value: 'A',
        label: '차감제',
      },
      {
        value: 'P',
        label: '기간제',
      },
    ],
  },
  {
    key: 'ticket',
    type: 'select',
    title: '수강권',
    options: [],
  },
  {
    key: 'staffs',
    type: 'select',
    title: '강사',
    options: [],
  },
];

export const DEFAULT_REPORT_UNPAID_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'productType',
    type: 'button',
    title: '수강권/상품 구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
      {
        value: 'S',
        label: '판매',
      },
      {
        value: 'R',
        label: '대여',
      },
    ],
  },
  {
    key: 'paymentMethod',
    type: 'button',
    title: '결제방법',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'card',
        label: '카드',
      },
      {
        value: 'cash',
        label: '현금',
      },
      {
        value: 'wiretransfer',
        label: '계좌이체',
      },
      {
        value: 'point',
        label: '포인트',
      },
    ],
  },
];

/** 포인트 */
export const DEFAULT_POINT_FILTER_TABS = {
  staffs: '강사 전체',
  type: '구분 전체',
  status: '분류 전체',
};

export const DEFAULT_REPORT_POINT_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'staffs',
    type: 'select',
    title: '강사',
    options: [],
    tabConverter: (value: ArrowFilterValuesType) => {
      const staffsValue = value as ReportPointParamType['staffs'];
      if (!staffsValue || staffsValue.length === 0) {
        return '강사 전체';
      }
      const staffCount = staffsValue.length;
      const staffName = staffsValue[0].name;
      const totalStaffCount = staffsValue[0].totalStaff;
      if (staffCount === 1) {
        return `${staffName}`;
      }
      if (staffCount === totalStaffCount) {
        return '강사 전체';
      }
      return `${staffName} 외 ${staffCount - 1}명`;
    },
  },
  {
    key: 'type',
    type: 'multiButtonType',
    title: '구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'save',
        label: '적립',
      },
      {
        value: 'remove',
        label: '차감',
      },
      {
        value: 'destruction',
        label: '소멸',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const typeValue = value as ReportPointParamType['type'];
      if (typeValue && typeValue.length > 0) {
        const typeFilterLabel = typeValue
          .map(type => REPORT_POINT_PARAM_LABEL[type as keyof typeof REPORT_POINT_PARAM_LABEL].label)
          .join(', ');
        return typeFilterLabel;
      }
      return '구분 전체';
    },
  },
  {
    key: 'status',
    type: 'multiButtonStatus',
    title: '분류',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'purchase',
        label: '발급',
      },
      {
        value: 'change',
        label: '변경',
      },
      {
        value: 'upgrade',
        label: '업그레이드',
      },
      {
        value: 'installment_payment',
        label: '미수금',
      },
      {
        value: 'refund',
        label: '환불',
      },
      {
        value: 'transfer',
        label: '양도',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const statusValue = value as ReportPointParamType['status'];
      if (statusValue && statusValue.length > 0) {
        const statusFilterLabel = statusValue.map(status => getPointStatusLabel(status)).join(', ');
        return statusFilterLabel;
      }
      return '분류 전체';
    },
  },
];
