import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const useDeleteUserTicket = (productId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<DeleteUserTicketParams>({
    method: 'delete',
    url: `/api/userTicket/${productId}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default useDeleteUserTicket;

export type DeleteUserTicketParams = {
  user_id: number;
};
