import useQueryString from 'hooks/useQueryString';
import { BookingAvailableFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';

import FieldButton from './FieldButton';
import { UpdateDateTypography } from './StyledComponents';

/** 예약 가능, 예약취소 가능 시간 필드 */
const BookingAvailableField = () => {
  const { getValues } = useFormContext<BookingAvailableFormType>();
  const { booking_start_at, booking_end_at, booking_cancel_start_at, booking_cancel_end_at } = getValues();

  const { getAllSearchParams, setSearchParams } = useQueryString();
  const clickField = (detail: 'booking' | 'bookingCancel') => {
    setSearchParams({
      ...getAllSearchParams(),
      type: 'bookingCancelTime',
      detail,
    });
  };

  return (
    <>
      <FieldButton
        showIcon
        label="예약 가능 시간"
        time={<UpdateDateTypography startAt={booking_start_at} endAt={booking_end_at} />}
        onClick={() => clickField('booking')}
      />
      <FieldButton
        label="예약취소 가능 시간"
        time={<UpdateDateTypography startAt={booking_cancel_start_at} endAt={booking_cancel_end_at} />}
        onClick={() => clickField('bookingCancel')}
      />
    </>
  );
};

export default BookingAvailableField;
