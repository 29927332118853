import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useParamsId from 'hooks/useParamsId';
import usePopup from 'hooks/usePopup';
import { FormProvider, useForm } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import MemoForm from 'sharedComponents/MemoForm';
import MemoList, { MemoListContainer } from 'sharedComponents/MemoForm/MemoList';
import Sorting from 'sharedComponents/MemoForm/Sorting';
import { MemoFormType, SortFormType } from 'sharedComponents/MemoForm/types';

const BookingMemo = () => {
  const currentLectureId = useParamsId();
  const { setPopup } = usePopup();

  const formValues: MemoFormType = {
    ref_type: 'lectures',
    ref_id: currentLectureId,
    memo: '',
    imgUrls: [],
    files: [],
  };

  const methods = useForm<MemoFormType & SortFormType>({
    defaultValues: {
      ...formValues,
      sort: 'desc',
    },
  });

  const clickCreateMemo = () => {
    setPopup(<MemoForm formValues={formValues} />);
  };

  return (
    <MainLayout
      header={{
        title: '메모',
        rightButton: (
          <IconButton onClick={clickCreateMemo}>
            <Icon name="headerPlus" color="gray1" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        <MemoListContainer>
          <Sorting />
          <ApiBoundary>
            <MemoList />
          </ApiBoundary>
        </MemoListContainer>
      </FormProvider>
    </MainLayout>
  );
};

export default BookingMemo;
