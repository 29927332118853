import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { counselSelectedFiltersAtom } from 'recoil/Member';

import CheckMember from './CheckMember';
import CounselList from './CounselList';
import NoData from './NoData';
import Total from './Total';

const Counsel = () => {
  const counsel = useRecoilValue(counselSelectedFiltersAtom);

  /** 상담고객 > 회원, 비회원 체크를 모두 해제 했을 경우 api 요청 보내지 않기 위한 flag */
  const isNoCounselRequest = useMemo(() => counsel.register_type === 'none', [counsel.register_type]);

  return (
    <>
      {isNoCounselRequest ? (
        <>
          <TotalWrapper>
            <Total total={0} />
            <CheckMember />
          </TotalWrapper>
          <NoData currentTabLabel={'상담고객'} />
        </>
      ) : (
        <CounselList />
      )}
    </>
  );
};

export default Counsel;

const TotalWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  margin-bottom: 16px;
`;
