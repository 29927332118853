import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { EVENT_CARD_TYPE } from 'pages/Schedule/ScheduleMain/constants';
import { StyledLectureCardType } from 'pages/Schedule/ScheduleMain/types';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarSettingsAtom } from 'recoil/calendarSettings';
import { calendarViewTypeAtom } from 'recoil/schedule';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import ScheduleContentInfo from './components/ScheduleContentInfo';

type Props = {
  lectureType: 'G' | 'P';
  startTime?: string;
  endTime?: string;
  sameTimeEvents: number;
  title: string;
  currentTraineeCount: number;
  maxTrainee: number;
  cardColor: string;
  staffName: string;
  /**
   * 예약 마감 여부
   */
  isBookingClosed: boolean;
};

const EventLectureCard = ({ ...props }: Props) => {
  const {
    lectureType,
    startTime,
    endTime,
    sameTimeEvents,
    title,
    currentTraineeCount,
    maxTrainee,
    cardColor,
    staffName,
    isBookingClosed,
  } = props;

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const { closedClassBackground } = useRecoilValue(calendarSettingsAtom);

  const isDayView = useMemo(() => calendarViewType === 'day', [calendarViewType]);
  const isWeekView = useMemo(() => calendarViewType === 'week', [calendarViewType]);

  const traineeText = useMemo(() => {
    if (isWeekView || lectureType === EVENT_CARD_TYPE.group) {
      return `${currentTraineeCount} / ${maxTrainee}`;
    }

    switch (maxTrainee) {
      case 1:
        return '개인';
      case 2:
        return '듀엣';
      case 3:
        return '트리플';
    }
  }, [lectureType, currentTraineeCount, maxTrainee, isWeekView]);

  const isShowTraineeText = useMemo(() => {
    if (calendarViewType === 'week') return sameTimeEvents === 1;
    return sameTimeEvents < 4;
  }, [calendarViewType, sameTimeEvents]);

  /** 캘린더 설정 > 마감시 배경 채우기 설정 여부 */
  const isClosedBackground = useMemo(() => {
    const isIncludes = closedClassBackground.includes(lectureType);
    if (isIncludes) return true;
    return false;
  }, [lectureType, closedClassBackground]);

  if (!startTime || !endTime) return <></>;

  const isCardBackground = isClosedBackground && (isBookingClosed || currentTraineeCount === maxTrainee);

  const currentContrastColor = getContrastTextColor(cardColor) as Color;

  return (
    <Container
      isWeekView={isWeekView}
      isPrivateCard={lectureType === EVENT_CARD_TYPE.private}
      isCardBackground={isCardBackground}
      cardColor={cardColor}
      sameTimeEvents={sameTimeEvents}>
      {isWeekView && sameTimeEvents > 2 ? (
        <></>
      ) : (
        <>
          <div className="title-wrapper">
            <Typography
              className="title"
              size={isDayView ? 14 : 12}
              weight={600}
              textColor={isCardBackground ? currentContrastColor : 'gray1'}
              ellipsis={isDayView ? 1 : 0}>
              {title}
            </Typography>

            {isShowTraineeText && (
              <Typography className="trainee-text" size={13} weight={600} textColor={currentContrastColor} lineHeight={18}>
                {traineeText}
              </Typography>
            )}
          </div>
          {isDayView && (
            <ScheduleContentInfo
              startTime={startTime}
              endTime={endTime}
              sameTimeEvents={sameTimeEvents}
              staffName={staffName}
              isCardBackground={isCardBackground}
              currentContrastColor={currentContrastColor}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default EventLectureCard;

const Container = styled.div<StyledLectureCardType>(
  /** 기본 그룹/프라이빗 수업 카드 */
  ({ cardColor }) => css`
    ${theme.flex('column', 'flex-start', 'flex-start', 2)};
    padding: 4px 8px 0;
    min-height: 28px;
    height: 100%;
    border: 1px solid ${cardColor};
    border-radius: 4px;
    background-color: ${theme.color.white};
    overflow: hidden;

    .title-wrapper {
      ${theme.flex('row', '', 'space-between')};
      width: 100%;

      .trainee-text {
        min-width: max-content;
      }
    }
  `,

  /** 주간(week) 수업 카드 */
  ({ isWeekView, sameTimeEvents }) =>
    isWeekView &&
    css`
      justify-content: flex-start;
      margin-right: 3px;
      padding: 4px ${sameTimeEvents === 2 ? 5 : 2}px;

      .title-wrapper {
        position: relative;
        ${theme.flex('column', 'flex-start', 'space-between')};
        height: 100%;

        .title {
          line-height: 12px;
        }
      }
    `,

  /** 캘린더 설정이 켜져있고, 예약 마감이거나 정원이 찼을 때 */
  ({ isCardBackground, cardColor }) =>
    isCardBackground &&
    css`
      background-color: ${cardColor};
    `,
);
