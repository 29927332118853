import Tabs, { TabDataElementType } from 'components/Tabs';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { useEffect, useRef, useState } from 'react';
import { allMemberSelectedFiltersAtom, assignedMemberSelectedFiltersAtom, counselSelectedFiltersAtom } from 'recoil/Member';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import { MAIN_TAB_DATA } from '../constants';
import AllMemberFilter from './AllMemberFilter';
import AllMemberList from './AllMemberList';
import AssignedMemberList from './AssignedMemberList';
import Counsel from './Counsel';
import CounselCustomerFilter from './CounselCustomerFilter';
import MemberFloatingButton from './MemberFloatingButton';
import { Container, ContentsContainer, ContentsWrapper, StickyWrapper } from './MemberLayout';
import MemberSkeletonFilter from './MemberSkeletonFilter';
import MemberSkeletonList from './MemberSkeletonList';
import Search from './Search';

const MemberList = () => {
  const { getSearchParams, setSearchParams } = useQueryString();
  const { hasPermission } = usePermission();

  const [isAtTop, setIsAtTop] = useState(false);
  const stickyRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (stickyRef.current) {
      const topPosition = stickyRef.current.getBoundingClientRect().top;
      setIsAtTop(topPosition <= 0);
    }
  };

  useEffect(() => {
    const scrollableContainer = document.querySelector('#scrollableTarget');

    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollableContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const tab = getSearchParams('tab');
  const canViewMembers = hasPermission(PERMISSION.member.view);
  const memberTabs = canViewMembers ? MAIN_TAB_DATA : MAIN_TAB_DATA.filter(tabData => tabData.value !== 'all');

  const currentTab = memberTabs.find(tabData => tabData.value === tab) || memberTabs[0];
  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value });
  };

  return (
    <MainLayout
      bottomNav
      scrollable
      childrenFloatingButton={
        <ApiBoundary>
          <MemberFloatingButton currentTab={currentTab.value} />
        </ApiBoundary>
      }>
      <Container>
        <Search />
        <ContentsContainer>
          <StickyWrapper ref={stickyRef} className={isAtTop ? 'sticky--at-top' : ''}>
            <Tabs data={memberTabs} value={currentTab.value} onChange={changeTab} />
            <ApiBoundary fallback={<MemberSkeletonFilter isAssigned={currentTab.value === 'assigned'} />}>
              {currentTab.value === 'all' && <AllMemberFilter filterAtom={allMemberSelectedFiltersAtom} />}
              {currentTab.value === 'assigned' && <AllMemberFilter filterAtom={assignedMemberSelectedFiltersAtom} assigned />}
            </ApiBoundary>
            {currentTab.value === 'counsel' && <CounselCustomerFilter filterAtom={counselSelectedFiltersAtom} />}
          </StickyWrapper>
          <ContentsWrapper>
            <ApiBoundary fallback={<MemberSkeletonList currentTab={currentTab.value} />}>
              {currentTab.value === 'all' && <AllMemberList />}
              {currentTab.value === 'assigned' && <AssignedMemberList />}
              {currentTab.value === 'counsel' && <Counsel />}
            </ApiBoundary>
          </ContentsWrapper>
        </ContentsContainer>
      </Container>
    </MainLayout>
  );
};

export default MemberList;
