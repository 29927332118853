import { TicketCommon, UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';

export const LOCAL_STORAGE = {
  NOTIFICATION: 'notification',
  NOTI_SCROLL_Y: 'notiScrollY',
};

export const BOOKING_TYPE = {
  BOOKED: 'booked',
  CANCEL: 'cancel',
  BOOKING_CONFIRMED: 'booking_confirmed',
  BOOKING_WAITING: 'booking_waiting',
  ABSENCE: 'absence',
  ATTENDANCE: 'attendance',
  NO_SHOW: 'noshow',
  BOOKED_AND_CONFIRM: 'booked,booking_confirmed',
} as const;

export const ACTION_BOOKING = {
  BOOKINGS: 'bookings',
  WAITINGS: 'waitings',
  BOOKING_CONFIRM: 'bookingConfirm',
  ABSENCE: 'absence',
} as const;

export const LECTURE_TYPE = {
  LECTURE_CLOSED: 'lecture_closed', // 수업종료
  BOOKING_CLOSED: 'booking_closed', // 예약마감
  TRAINEE_CLOSED: 'trainee_closed', // 인원마감
  BOOKABLE: 'bookable', // 예약가능
};

export const ERROR_CODE = {
  ALREADY_REPORTED: 208,
  MULTIPLE_CHOICES: 300,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401, // 인증
  FORBIDDEN: 403, // 사용 권한
  NOT_FOUND: 404,
  CONFLICT: 408,
  INTERNAL_SERVER: 500,
  TOO_MANY_ATTEMPTS: 429, // 과다 요청 시도
} as const;

/** 텍스트 필드 placeholder */
export const PLACEHOLDER = {
  MOBILE: '휴대폰 번호 입력',
  PASSWORD: '비밀번호 입력',
  CONFIRM_PASSWORD: '비밀번호 입력 확인',
  EMAIL: '이메일 입력',
  STAFF: '강사명 입력',
  NICKNAME: '닉네임 입력',
  SUB_DOMAIN: '서브 도메인 입력',
  BIRTHDATE: '생년월일 입력 (예: 901223)',
} as const;

/** 검색 필드 placeholder */
export const SEARCH_PLACEHOLDER = {
  STAFF_AND_MOBILE: '강사명, 휴대폰 번호 검색',
  MEMBER_AND_MOBILE: '회원명, 휴대폰 번호 검색',
} as const;

/** 목록 정렬 라벨 */
export const SORT_LABEL = {
  SORT_NAME_ASC: '이름 오름차순',
  SORT_NAME_DESC: '이름 내림차순',
  SORT_DATE_DESC: '등록일 최신순',
  SORT_DATE_ASC: '등록일 과거순',
} as const;

export const PRODUCT_TYPE_TEXT: {
  [key in UserTicketParams['product_type'] | TicketCommon['available_class_type']]: string;
} = {
  all: '수강권/상품',
  userTicket: '수강권',
  item: '상품',
  G: '수강권',
  P: '수강권',
  I: '상품',
};
