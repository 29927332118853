import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Counter from 'components/Counter';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormField from 'sharedComponents/FormField';
import FormFieldTypography from 'sharedComponents/FormField/FormFieldTypography';

import { BookingGroupCreateFormType } from '../../types';
import { TraineeCounterWrapper } from './MaxTrainee';

const MinTrainee = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();
  const maxTrainee = useWatch({ control, name: 'maxTrainee' });
  const minTrainee = useWatch({ control, name: 'minTrainee' });

  const [showButton, setShowButton] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const closeDialog = () => {
    setIsOpenDialog(false);
    setShowButton(false);
  };

  return (
    <>
      <FormField className="min-trainee" iconName="privateClass" height={95}>
        <div>
          <TraineeCounterWrapper>
            <FormFieldTypography textColor="gray1" fontWeight={400}>
              최소 수강인원
            </FormFieldTypography>
            <Controller
              name="minTrainee"
              control={control}
              render={({ field: { ref, onBlur, ...rest } }) => (
                <Counter
                  id="minTrainees"
                  max={maxTrainee}
                  onFocus={() => setShowButton(true)}
                  onBlur={() => setIsOpenDialog(true)}
                  {...rest}
                />
              )}
            />
          </TraineeCounterWrapper>
          <StyledTypography size={13} textColor="error">
            &lsquo;폐강 시간&rsquo; 이후, 최소 수강 인원이 채워지지 않으면
            <br /> 이 수업은 자동 삭제 됩니다. 0명인 경우 삭제되지 않습니다.
          </StyledTypography>
        </div>
      </FormField>

      {showButton && (
        <ButtonWrapper>
          <Button
            id="confirm-button"
            fullWidth
            size="medium48"
            variant="contained"
            color="point"
            onClick={() => setIsOpenDialog(true)}>
            확인
          </Button>
        </ButtonWrapper>
      )}

      {isOpenDialog && (
        <Dialog isError onClose={closeDialog} positiveAction={{ text: '확인', onClick: closeDialog }}>
          <DialogDescription>
            최소 수강 인원 <b>[{minTrainee}명]</b>이
            <br />
            채워지지 않으면 수업은 자동 삭제,
            <br />
            예약은 자동 취소됩니다.
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default MinTrainee;

const StyledTypography = styled(Typography)`
  margin-top: 9px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: ${Z_INDEX.footerButton + 1};
  padding: 16px 20px;
  background-color: ${theme.color.white};
`;
