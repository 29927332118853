import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { HoldingPostParams, HoldingPostResponse } from './usePostHolding';

/** 수강권 정지 수정 */
const usePatchHolding = (holdingId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<HoldingPatchParams, HoldingPostResponse>({
    method: `patch`,
    url: `/api/holding/${holdingId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePatchHolding;

export type HoldingPatchParams = Omit<HoldingPostParams, 'user_ticket_id'>;
