import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { Payment } from './useInfinityUserTicketProduct';

const useInfinityPaymentUserTicket = (params: PaymentUserTicketParams & { userTicketId: number }) => {
  const { userTicketId, user_id } = params;
  const { queryFn } = useInfiniteQueryFnWithCommonParams<Payment, PaymentUserTicketParams>({
    url: `/api/payment/${userTicketId}`,
    params: {
      user_id,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', 'payment', params.userTicketId],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityPaymentUserTicket;

export type PaymentUserTicketParams = {
  user_id: number;
};
