import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import WaitingExceedDialog from 'pages/Booking/components/WaitingExceedDialog';
import { BookingSelectMemberStateType, BookingType } from 'pages/Booking/types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';

import ExceedWaitingDialog from './ExceedWaitingDialog';
import TraineeCountInfo from './TraineeCountInfo';

type Props = {
  bookings: Array<LectureBookingListResponse>;
  bookingType: BookingType;
  currentLecture: LectureDetailResponse;
};

const TraineeCount = ({ bookings, bookingType, currentLecture }: Props) => {
  const { id, start_on, end_on, max_trainee, current_trainee_count, waiting_trainee_limit, policy } = currentLecture;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenWaitingOverDialog, setIsOpenWaitingOverDialog] = useState(false);

  const isPastLecture = dayjs(start_on).isBefore();
  const currentWaitingTraineeCount = bookings.filter(({ status }) => status === 'booking_waiting').length;
  const canBookable = current_trainee_count < max_trainee;
  const canWaiting = !policy.weekly_waiting_limit || currentWaitingTraineeCount < waiting_trainee_limit;
  const unLimitGroupWaiting = bookingType === 'group' && !waiting_trainee_limit;
  const showAddButton = bookingType === 'group' || canBookable;

  const state: BookingSelectMemberStateType = {
    startDate: filters.dateDashTimeSecond(start_on),
    endDate: filters.dateDashTimeSecond(end_on),
    maxCount: current_trainee_count ? max_trainee - current_trainee_count : max_trainee,
    maxTrainee: max_trainee,
  };

  const navigate = useNavigate();

  /** 회원 추가 버튼 클릭 */
  const clickAddMember = () => {
    // 예약 추가 가능하면 회원 추가 페이지로 이동
    if (canBookable) {
      navigate(`/booking/${bookingType}/select-member/${id}?type=booking`, { state });
    } else {
      // 예약 추가 불가하면 초과예약/대기예약 dialog 노출
      setIsOpenDialog(true);
    }
  };

  /** 예약대기 클릭 */
  const clickAddMemberWaiting = () => {
    if (canWaiting || unLimitGroupWaiting) {
      goToAddWaiting();
    } else {
      setIsOpenDialog(false);
      setIsOpenWaitingOverDialog(true);
    }
  };

  const waitingType = useMemo(() => {
    if (!policy.weekly_waiting_limit || unLimitGroupWaiting) {
      // 대기 제한 없는 시설 또는 제한없는 그룹 수업
      return 'waitingUnLimit';
    } else if (canWaiting) {
      // 대기 정원 남았을 때
      return 'waiting';
    } else {
      // 대기 정원 다 찼을 때 강제 대기 초과 예약 원하는 경우
      return 'waitingLimit';
    }
  }, [canWaiting, policy.weekly_waiting_limit, unLimitGroupWaiting]);

  /** 예약대기 페이지로 이동 */
  const goToAddWaiting = () => {
    const waitingState = {
      ...state,
      maxCount: currentWaitingTraineeCount ? waiting_trainee_limit - currentWaitingTraineeCount : waiting_trainee_limit,
    };
    navigate(`/booking/${bookingType}/select-member/${id}?type=${waitingType}`, { state: waitingState });
  };

  /** 초과예약 페이지로 이동 */
  const goToAddExceed = () => {
    navigate(`/booking/${bookingType}/select-member/${id}?type=exceed`, { state });
  };

  return (
    <>
      <Container>
        <Wrapper>
          <TraineeCountInfo
            showWaitingCount={bookingType === 'group'}
            currentBookingTraineeCount={current_trainee_count}
            currentWaitingTraineeCount={currentWaitingTraineeCount}
            maxTrainee={max_trainee}
            waitingTraineeLimit={waiting_trainee_limit}
          />
          {showAddButton && (
            <Button
              widthSize={60}
              size="small"
              variant="contained"
              color="gray"
              leftIcon={<Icon name="plusBold" size={16} color="gray3" />}
              onClick={clickAddMember}>
              추가
            </Button>
          )}
        </Wrapper>
        <Divider thin />
      </Container>

      {isOpenDialog && (
        <ExceedWaitingDialog
          isPastLecture={isPastLecture}
          onClose={() => setIsOpenDialog(false)}
          onClickNegative={isPastLecture ? () => setIsOpenDialog(false) : goToAddExceed}
          onClickPositive={isPastLecture ? goToAddExceed : clickAddMemberWaiting}
        />
      )}

      {isOpenWaitingOverDialog && (
        <WaitingExceedDialog
          waitingCountLimit={waiting_trainee_limit}
          onClose={() => setIsOpenWaitingOverDialog(false)}
          onClick={goToAddWaiting}
        />
      )}
    </>
  );
};

export default TraineeCount;

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${theme.color.white};
`;

const Wrapper = styled.div`
  padding: 17px 20px;
  ${theme.flex('', 'center', '', 8)};
  min-height: 53px;

  button {
    margin-left: auto;
  }
`;
