import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 그룹 수업 복사 */
const BookingGroupCopy = () => {
  const currentLectureId = useParamsId();

  return (
    <ApiBoundary>
      <FormMain currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingGroupCopy;
