import { has } from 'lodash';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import sessionStorage from 'utils/sessionStorage';

const useScrollRestoration = (targetElement = '#scrollableTarget') => {
  /** useNavigationType으로는 클린업 감지가 되지 않아, window.history로 구현하기 위한 용도 */
  const navigationType = useRef('POP');
  const { pathname, search } = useLocation();

  const pathnameSearch = pathname + search || '';

  /**
   * * window.history를 사용해 클린업에서 감지 가능한 navigationType 구현
   */
  useEffect(() => {
    /** popState는 자체 감지 이벤트라 리스너로 처리 */
    const handlePopState = () => (navigationType.current = 'POP');
    window.addEventListener('popstate', handlePopState);

    /**
     * pushState, replaceState는 자체적으로 발생하는 이벤트가 아니므로 오버라이드 진행
     * 기본 기능은 유지하면서 navigationType을 변경하는 로직 추가
     */
    const originPushState = window.history.pushState;
    const originReplaceState = window.history.replaceState;
    (['pushState', 'replaceState'] as const).forEach(state => {
      window.history[state] = function (...args) {
        const isPushState = state === 'pushState';
        const currentState = isPushState ? originPushState : originReplaceState;
        const currentType = isPushState ? 'PUSH' : 'REPLACE';
        currentState.apply(this, args);
        navigationType.current = currentType;
      };
    });

    return () => {
      window.removeEventListener('popstate', handlePopState);
      /** pushState, replaceState 다시 원래대로 복구 */
      window.history.pushState = originPushState;
      window.history.replaceState = originReplaceState;
    };
  }, []);

  /**
   * * 실제 페이지 진입 시점 스크롤 이벤트와 벗어날 시점 sessionStorage 저장 및 삭제
   */
  useEffect(() => {
    const target = document.querySelector(targetElement);
    if (!target) return;

    let currentScrollTop = 0;
    const scrollPositions = { ...sessionStorage.get('scrollPositions') };

    const onScroll = () => (currentScrollTop = target.scrollTop);
    target.addEventListener('scroll', onScroll);

    /** 뒤로가기 경우에만 스크롤을 이동하도록 적용  */
    if (navigationType.current === 'POP') {
      const top = scrollPositions?.[pathnameSearch] ? parseInt(scrollPositions[pathnameSearch]) : 0;
      requestAnimationFrame(() => {
        target.scrollTo({ top });
      });
    }

    /** 컴포넌트에서 벗어날 때 sessionStorage 저장 */
    return () => {
      /** POP일 때, 더이상 필요하지 않으므로 저장하지 않고 해당 프로퍼티 삭제 진행 */
      if (navigationType.current === 'POP' && has(scrollPositions, pathnameSearch)) {
        delete scrollPositions[pathnameSearch];
        sessionStorage.set('scrollPositions', scrollPositions);
      } else {
        const positions = {
          ...scrollPositions,
          [pathnameSearch]: currentScrollTop,
        };
        sessionStorage.set('scrollPositions', positions);
      }
    };
  }, [pathnameSearch, navigationType, targetElement]);

  return null;
};

export default useScrollRestoration;
