import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import useDeleteBoardNotice from 'hooks/service/mutations/useDeleteBoardNotice';
import { BoardNoticeDetailResponse } from 'hooks/service/queries/useGetBoardNoticeDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import usePermission from 'hooks/usePermission';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { NoticeFormType } from '../../Form/types';
import { NOTICE_DETAIL_TEXT } from '../constants';

type Props = {
  notice: BoardNoticeDetailResponse;
};

const BottomButtons = ({ notice }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { mutate: deleteNoticeMutate, isPending } = useDeleteBoardNotice(notice.id);

  const { hasPermission } = usePermission();
  const canUpdate = hasPermission(PERMISSION.board.notice.create);
  const canDelete = hasPermission(PERMISSION.board.notice.delete);

  const navigate = useNavigate();
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const deleteNotice = () => {
    if (isPending) return;
    deleteNoticeMutate(
      { board: notice.id },
      {
        onSuccess: () => {
          navigate('/more-details/notice', { replace: true });
          setToast({ message: NOTICE_DETAIL_TEXT.deleteCompleted, type: 'success' });
        },
        onError: error => {
          errorDialog.open(error);
        },
      },
    );
  };

  const updateNotice = () => {
    const editFormData: NoticeFormType = {
      ...notice,
      contents: notice.contents ?? '',
      period: !notice.is_unlimited_period && !notice.is_used_period ? 'unset' : notice.is_used_period ? 'limited' : 'unlimited',
      imgUrls: notice.attachments.map(file => ({ id: file.id, imgUrl: getImageUrl(file.path, '0x0') || '' })),
      files: [],
      isTargetActive: notice.target_member === 'active' || notice.target_member === 'both',
      isTargetExpired: notice.target_member === 'expired' || notice.target_member === 'both',
      start_on: notice.start_on ? filters.date(notice.start_on) : undefined,
      end_on: notice.is_used_period && notice.end_on ? filters.date(notice.end_on) : undefined,
    };

    navigate('/more-details/notice/form?type=edit', { state: editFormData });
  };

  if (!canUpdate && !canDelete) return null;
  return (
    <>
      <FloatingButtons>
        {canDelete && (
          <Button size="large56" variant="outlined" color="gray" onClick={() => setIsDialogOpen(true)} fullWidth>
            {NOTICE_DETAIL_TEXT.delete}
          </Button>
        )}
        {canUpdate && (
          <Button size="large56" variant="contained" color="point" onClick={updateNotice} fullWidth>
            {NOTICE_DETAIL_TEXT.edit}
          </Button>
        )}
      </FloatingButtons>
      {isDialogOpen && (
        <Dialog
          onClose={() => setIsDialogOpen(false)}
          negativeAction={{ text: NOTICE_DETAIL_TEXT.cancel, onClick: () => setIsDialogOpen(false), style: 'outlined' }}
          positiveAction={{ text: NOTICE_DETAIL_TEXT.delete, onClick: deleteNotice }}>
          <Typography size={15}>{NOTICE_DETAIL_TEXT.deleteConfirm}</Typography>
        </Dialog>
      )}
    </>
  );
};

export default BottomButtons;

const FloatingButtons = styled(ButtonGroup)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: ${theme.color.white};
`;
