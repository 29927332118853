import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { useFormContext, useWatch } from 'react-hook-form';

import { BookingPrivateUpdateFormType } from '../../types';

const RoomSettingDialog = () => {
  const { control, setValue } = useFormContext<BookingPrivateUpdateFormType>();
  const isChangeDate = useWatch({ control, name: 'isChangeDate' });
  const isChangeTime = useWatch({ control, name: 'isChangeTime' });
  const roomId = useWatch({ control, name: 'roomId' });
  const target = isChangeDate ? '날짜가' : isChangeTime ? '시간이' : '';

  const close = () => {
    setValue('isChangeDate', false, { shouldDirty: true });
    setValue('isChangeTime', false, { shouldDirty: true });
  };

  return (
    <>
      {roomId === null && (isChangeDate || isChangeTime) && (
        <Dialog isError onClose={close} positiveAction={{ text: '확인', onClick: close }}>
          <DialogDescription>
            {target} 변경되어 설정된 룸이
            <br />
            초기화 되었습니다.
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default RoomSettingDialog;
