import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import PeriodFilter from 'sharedComponents/Filters/PeriodFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { SCHEDULE_DEFAULT_PERIOD } from '../constant';
import { ScheduleBookingParamType } from '../type';

dayjs.extend(isoWeek);

const SchedulePeriodFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const { setToast } = useToast();
  const previousOptions = (selectedOption as ScheduleBookingParamType['period']) || SCHEDULE_DEFAULT_PERIOD;
  const [date, setDate] = useState([previousOptions.start, previousOptions.end]);

  const setFixedPeriod = (option: FilterOptionsType) => {
    const unit = option.value === 'month' || option.value === 'isoWeek' ? option.value : 'day';
    const startDate = filters.dateDash(dayjs().startOf(unit));
    const endDate = filters.dateDash(dayjs().endOf(unit));
    setDate([startDate, endDate]);
    changeOptions(
      { value: option.value ? { type: option.value as string, start: startDate, end: endDate } : undefined, label: '' },
      'period',
    );
  };

  const saveCustomPeriod = (period: string[]): boolean => {
    if (dayjs(period[0]).isBefore(dayjs(period[1]).subtract(4, 'month'))) {
      setToast({ message: '최대 4개월까지 선택 가능합니다.', bottom: 92 });
      setDate([previousOptions.start, previousOptions.end]); // 기간선택 초기화
      return true;
    }
    const dateFormatted = period.map(filters.dateDash);
    setDate(dateFormatted);
    changeOptions({ value: { type: 'custom', start: dateFormatted[0], end: dateFormatted[1] }, label: '' }, 'period');
    return false;
  };

  return (
    <PeriodFilter
      description="최대 4개월까지만 조회가능"
      currentFilter={currentFilter}
      selectedOption={selectedOption}
      onClick={setFixedPeriod}
      initialDate={date}
      onSave={saveCustomPeriod}
    />
  );
};

export default SchedulePeriodFilter;
