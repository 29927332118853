import FloatingActionButton from 'components/FloatingActionButton';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router-dom';

type Props = {
  currentTab: string;
};

const MemberFloatingButton = ({ currentTab }: Props) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();

  const permission = currentTab === 'counsel' ? PERMISSION.counsel.create : PERMISSION.member.create;
  const createFormPath = currentTab === 'counsel' ? '/counsel/form?prevUrl=member' : '/member/form/create';

  const canCreate = hasPermission(permission);
  if (!canCreate) return null;

  return <FloatingActionButton type="single" onClick={() => navigate(createFormPath)} />;
};

export default MemberFloatingButton;
