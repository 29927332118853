import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import AppPushDetail from './components/AppPushDetail';

const MoreDetailsMessageAppPushDetail = () => {
  const id = useParamsId();
  return (
    <ApiBoundary>
      <AppPushDetail id={id} />
    </ApiBoundary>
  );
};

export default MoreDetailsMessageAppPushDetail;
