import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 현재 수업에 예약 가능한 회원 수 */
const useGetBookingBookable = (enabled: boolean, lectureId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<BookingBookableResponse>({
    url: `/api/booking/bookable/${lectureId}`,
  });

  return useQuery({
    queryKey: [`booking/bookable`, lectureId],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetBookingBookable;

export type BookingBookableResponse = {
  /** 현재 수업에 예약된 인원 */
  current_trainee_count: number;
  /** 예약 가능 인원 */
  max_trainee_count: number;
  /** 현재 수업에 예약 대기된 인원 */
  current_waiting_trainee_count: number;
  /**
   * 예약 대기 가능 인원
   * 예약 대기를 무한대로 가능하면 0으로 반환됨
   */
  max_waiting_trainee_count: number;
};
