import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingFailResponse } from './usePostBookingPrivate';

const usePostBooking = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingParams>({
    method: `post`,
    url: `/api/booking`,
  });

  return useMutation<AxiosResponse, AxiosError<Array<BookingFailResponse> | CustomError>, BookingParams | undefined, unknown>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail'],
      });
    },
  });
};

export default usePostBooking;

export type BookingParams = {
  user_ticket_ids: Array<number>;
  lecture_id: number;
};
