import { PropsWithChildren } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';

const GlobalErrorBoundary = ({ children }: PropsWithChildren) => {
  const fallback = ({ error }: FallbackProps) => {
    return <div>{error.message}</div>;
  };

  // TODO: 범용 에러 페이지로 이동
  return <ErrorBoundary fallbackRender={fallback}>{children}</ErrorBoundary>;
};

export default GlobalErrorBoundary;
