import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import filters from 'utils/filters';

import DetailResultItem from './DetailResultItem';

type Props = {
  fails: Array<BookingFailResponse>;
};

const BookingFailList = ({ fails }: Props) => {
  return (
    <ul>
      {fails.map((fail, index) => {
        const { staff, start_on, end_on, title, room, code } = fail;
        const date = `${filters.dateTimeWithWeekday(start_on)} ~ ${filters.time(end_on)}`;

        return (
          <DetailResultItem
            key={`${fail.start_on}-${index}`}
            date={date}
            staffName={staff}
            title={title}
            roomName={room}
            errorCode={code}
          />
        );
      })}
    </ul>
  );
};

export default BookingFailList;
