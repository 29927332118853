import useInfinityUserTicketPrivate from 'hooks/service/queries/useInfinityUserTicketPrivate';
import UserTicketList from 'pages/Booking/SelectMember/UserTicketList';
import { BookingSelectMemberFormType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

type Props = {
  currentLectureId: number;
};

const List = ({ currentLectureId }: Props) => {
  const { control, getValues } = useFormContext<BookingSelectMemberFormType>();
  const search = useWatch({ control, name: 'search' });
  const { startDate, endDate, maxTrainee } = getValues();

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityUserTicketPrivate({
    max_trainee: maxTrainee,
    start_date: filters.dateDash(startDate),
    end_date: filters.dateDash(endDate),
    search,
    lecture_id: currentLectureId,
  });

  return <UserTicketList userTickets={data} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage} />;
};

export default List;
