import Divider from 'components/Divider';
import { TicketUpgradeFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FoldingTicketPaymentCard from '../../FoldingTicketPaymentCard';
import PaymentForm from './PaymentForm';

type Props = UserTicketProps & {
  onSubmit: (values: TicketUpgradeFormType) => void;
};

const PaymentInfo = ({ userTicket, onSubmit }: Props) => {
  const { control, setValue } = useFormContext<TicketUpgradeFormType>();
  const selectedTicket = useWatch({ control, name: 'selectedTicket' });

  /** 업그레이드 할 수강권과 현재 수강권 차액 계산 */
  const priceDiff = useMemo(() => {
    if (!selectedTicket) return 0;
    const lastPayment = userTicket.payments.last_payment;
    /** 현재 수강권이 직전에 양도 받은 수강권 */
    const isTransfer = lastPayment.status === 'transfer';
    /** 같은 수강권으로 업그레이드(연장) */
    const isExtension = userTicket.ticket_id === selectedTicket.id;

    /**
     * 양도 받은 수강권이나, 같은 수강권으로 업그레이드(연장)인 경우,
     * 이전 수강권 결제 금액과 상관 없이 새로 수강권 결제하는 개념이므로 차액 0
     */
    if (isTransfer || isExtension) {
      return 0;
    }

    /** 다른 수강권으로 업그레이드 */
    const currentTicketPrice = userTicket.payments.total_amount;
    const selectedTicketPrice = selectedTicket.original_price;

    return Math.max(selectedTicketPrice - currentTicketPrice, 0);
  }, [selectedTicket, userTicket.payments.last_payment, userTicket.payments.total_amount, userTicket.ticket_id]);

  /** 차액 및 결제 금액 세팅 */
  useEffect(() => {
    if (!selectedTicket) return;
    const upgradeAmount = priceDiff || selectedTicket.original_price;

    /** 차액 결제를 위해 결제 총액과 카드 결제금액에 차액 초기화  */
    setValue('payment.amount', upgradeAmount, { shouldDirty: true });
    setValue('payment.card_amount', upgradeAmount, { shouldDirty: true });
  }, [priceDiff, selectedTicket, setValue]);

  return (
    <>
      <FoldingTicketPaymentCard userTicket={userTicket} />
      <Divider />
      <PaymentForm priceDiff={priceDiff} upgradeSalePrice={selectedTicket?.original_price || 0} onSubmit={onSubmit} />
    </>
  );
};

export default PaymentInfo;
