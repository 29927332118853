import dayjs from 'dayjs';
import {
  ReportLectureParamType,
  ReportPeriodType,
  ReportPointParamType,
  ReportSaleParamType,
  ReportUnpaidParamType,
} from 'pages/MoreDetails/Report/type';
import { atom } from 'recoil';
import filters from 'utils/filters';

/**
 * 수강권/상품 날짜
 */

export const reportSalePeriodAtom = atom<ReportPeriodType>({
  key: 'reportSalePeriodAtom',
  default: {
    period: {
      type: 'day',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 수업 날짜
 */

export const reportLecturePeriodAtom = atom<ReportPeriodType>({
  key: 'reportLecturePeriodAtom',
  default: {
    period: {
      type: 'day',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 포인트 날짜
 */

export const reportPointPeriodAtom = atom<ReportPeriodType>({
  key: 'reportPointPeriodAtom',
  default: {
    period: {
      type: 'month',
      start: filters.dateDash(dayjs().startOf('month')),
      end: filters.dateDash(dayjs().endOf('month')),
    },
  },
});

/**
 * 수강권/상품
 */
export const reportSaleParamAtom = atom<ReportSaleParamType>({
  key: 'reportParamAtom',
  default: {},
});

/**
 * 수업
 */
export const reportLectureParamAtom = atom<ReportLectureParamType>({
  key: 'reportLectureParamAtom',
  default: {
    sort: 'asc',
  },
});

/**
 * 미수금
 */
export const reportUnpaidParamAtom = atom<ReportUnpaidParamType>({
  key: 'reportUnpaidParamAtom',
  default: {
    sort: 'desc',
  },
});

/**
 * 포인트
 */
export const reportPointParamAtom = atom<ReportPointParamType>({
  key: 'reportPointParamAtom',
  default: {
    sort: 'desc',
  },
});
