import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useInfinityReportLecture, {
  ReportLectureParams,
  ReportLectureResponse,
} from 'hooks/service/queries/useInfinityReportLecture';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { reportLectureParamAtom, reportLecturePeriodAtom } from 'recoil/report/atom';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { REPORT_LECTURER_SORTING_FILTERS } from '../../constants';
import { getSalesGroupByCreateOn } from '../../util';
import NoData from '../NoData';
import Sorting from '../Sorting';
import ReportLectureCard from './ReportLectureCard';
import ReportLectureSummary from './ReportLectureSummary';

const ReportLectureList = () => {
  const { searchKeyword } = useSearchKeywords('report');
  const reportLecturePeriod = useRecoilValue(reportLecturePeriodAtom);
  const reportLectureParam = useRecoilValue(reportLectureParamAtom);
  const [lecturesGroupByDate, setLecturesGroupByDate] = useState<ReportLectureResponse[][]>([]);
  const params: ReportLectureParams = {
    start_date: reportLecturePeriod.period?.start || filters.dateDash(),
    end_date: reportLecturePeriod.period?.end || filters.dateDash(),
    search: searchKeyword,
    class_type: reportLectureParam.classType,
    type: reportLectureParam.ticketType,
    staff_ids: reportLectureParam.staffs?.map(item => Number(item.id)),
    ticket_ids: reportLectureParam.tickets?.map(item => Number(item.id)),
    paginate_type: 'detail',
    order_by: reportLectureParam.sort,
  };

  const { data: lectures, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfinityReportLecture(params);

  useEffect(() => {
    const groupedLectures = getSalesGroupByCreateOn(lectures);
    setLecturesGroupByDate(groupedLectures);
  }, [lectures]);

  return (
    <Container>
      <ReportLectureSummary lectures={lectures} />
      <Divider thin />
      <Sorting className="right-sort" filterAtom={reportLectureParamAtom} sortOptions={REPORT_LECTURER_SORTING_FILTERS} />
      {lectures.length === 0 ? (
        <NoData currentKeyword={searchKeyword} noDataText={'매출 내역이 없습니다.'} />
      ) : (
        <ListSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ReportLectureCard lectures={lecturesGroupByDate} />
          </InfiniteScroll>
        </ListSection>
      )}
    </Container>
  );
};

export default ReportLectureList;

const Container = styled.div`
  .right-sort {
    ${theme.flex('', '', 'flex-end')}
  }

  background-color: ${theme.color.white};
`;

const ListSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};

  .date-text {
    padding-bottom: 20px;
  }
`;
