import styled from '@emotion/styled';
import useInfinityMemo from 'hooks/service/queries/useInfinityMemo';
import usePopup from 'hooks/usePopup';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import MemoCard from 'sharedComponents/MemoForm/MemoCard';
import { MemoFormType, SortFormType } from 'sharedComponents/MemoForm/types';
import NodataTypography from 'sharedComponents/NodataTypography';
import getImageUrl from 'utils/getImageUrl';

import MemoForm from '.';
import DeleteMemoDialog from './DeleteMemoDialog';

const MemoList = () => {
  const { control, setValue, getValues } = useFormContext<MemoFormType & SortFormType>();
  const sort = useWatch({ control, name: 'sort' });
  const { ref_id, ref_type } = getValues();

  const { setPopup } = usePopup();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfinityMemo({
    ref_id,
    ref_type,
    order_by: sort,
  });

  /** 메모 내역 없을 때 정렬 버튼 미노출 */
  useEffect(() => {
    setValue('showButton', !!data.length);
  }, [data.length, setValue]);

  const getUpdateFormValues = useCallback(
    (selectedMemoId: number) => {
      const selectedMemo = data.find(memo => memo.id === selectedMemoId);

      if (!selectedMemo) return;

      const { id, memo, attachments, ref_id, ref_type } = selectedMemo;
      const imgUrls = attachments.map(file => {
        return { id: file.id, imgUrl: getImageUrl(file.path) || '' };
      });

      const updateFormValues: MemoFormType = {
        ref_id,
        ref_type,
        memo_id: id,
        memo,
        attachments,
        imgUrls,
        files: [],
      };
      return updateFormValues;
    },
    [data],
  );

  const clickUpdateMemo = useCallback(
    (memoId: number) => {
      setValue('memo_id', memoId);
      const updateFormValues = getUpdateFormValues(memoId);

      if (updateFormValues) {
        setPopup(<MemoForm formValues={updateFormValues} />);
      }
    },
    [getUpdateFormValues, setPopup, setValue],
  );

  const clickDeleteMemo = useCallback(
    (memoId: number) => {
      setValue('memo_id', memoId);
      setIsOpenDeleteDialog(true);
    },
    [setValue],
  );

  return (
    <>
      {!data.length ? (
        <NodataTypography opacity={0.8}>메모가 없습니다.</NodataTypography>
      ) : (
        <InfiniteScroll gap={16} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {data.map(memo => (
            <MemoCard key={memo.id} data={memo} onUpdate={clickUpdateMemo} onRemove={clickDeleteMemo} />
          ))}
        </InfiniteScroll>
      )}

      {isOpenDeleteDialog && <DeleteMemoDialog onClose={() => setIsOpenDeleteDialog(false)} />}
    </>
  );
};

export default MemoList;

export const MemoListContainer = styled.div`
  padding: 16px 20px;

  .sort-button {
    margin: 0 0 16px auto;
  }
`;
