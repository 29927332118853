import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import usePatchBookingGroup from 'hooks/service/mutations/usePatchBookingGroup';
import usePatchBookingGroupCancelWaiting from 'hooks/service/mutations/usePatchBookingGroupCancelWaiting';
import usePatchBookingPrivate, { ChangePrivateBookingStatusParams } from 'hooks/service/mutations/usePatchBookingPrivate';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { BookingStatusType, BookingType } from 'pages/Booking/types';
import { useState } from 'react';

import CancelBookingDialog from './CancelBookingDialog';
import ChangeBookingStatusDrawer from './ChangeBookingStatusDrawer';
import MemberCard from './MemberCard';

type Props = {
  booking: LectureBookingListResponse;
  bookingType: BookingType;
  currentBookingTraineeCount: number;
  waitingOrder?: number;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
};

const BookingListItem = ({
  booking,
  bookingType,
  currentBookingTraineeCount,
  waitingOrder,
  canCancelBooking,
  canChangeBooking,
}: Props) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);
  const [currentMemberBooking, setCurrentMemberBooking] = useState<LectureBookingListResponse | null>(null);

  const { mutate: changePrivateBookingStatusMutate } = usePatchBookingPrivate(booking);
  const { mutate: changeGroupBookingStatusMutate } = usePatchBookingGroup(booking);
  const { mutate: cancelGroupWaitingMutate } = usePatchBookingGroupCancelWaiting(booking.id);
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const clickBookingStatusButton = (booking: LectureBookingListResponse) => {
    setCurrentMemberBooking(booking);
    setIsOpenDrawer(true);
  };

  const mutateCallback = (message?: string) => {
    return {
      onSettled: () => setIsOpenDrawer(false),
      onSuccess: () => {
        setToast({ type: 'success', message: message ?? '예약 상태가 변경되었습니다.' });
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    };
  };

  const changeBookingStatus = (params: ChangePrivateBookingStatusParams) => {
    if (bookingType === 'private') {
      changePrivateBookingStatusMutate(params, mutateCallback());
    } else {
      changeGroupBookingStatusMutate(params, mutateCallback());
    }
  };

  const clickDrawerItem = (value: BookingStatusType['value']) => {
    if (value === 'cancel') {
      setIsOpenDrawer(false);
      setIsOpenCancelDialog(true);
    } else if (value === 'waiting_cancel') {
      setIsOpenDrawer(false);
      cancelGroupWaitingMutate(undefined, mutateCallback('예약대기가 취소되었습니다.'));
    } else {
      changeBookingStatus({ status: value });
    }
  };

  return (
    <Container>
      <MemberCard
        booking={booking}
        waitingOrder={waitingOrder}
        canCancelBooking={canCancelBooking}
        canChangeBooking={canChangeBooking}
        onClickStatusChange={clickBookingStatusButton}
      />

      {currentMemberBooking && (
        <ChangeBookingStatusDrawer
          isOpen={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
          booking={currentMemberBooking}
          canCancelBooking={canCancelBooking}
          canChangeBooking={canChangeBooking}
          onClick={clickDrawerItem}
        />
      )}

      {isOpenCancelDialog && currentMemberBooking && (
        <CancelBookingDialog
          bookingType={bookingType}
          currentMemberBooking={currentMemberBooking}
          currentBookingTraineeCount={currentBookingTraineeCount}
          onClose={() => setIsOpenCancelDialog(false)}
        />
      )}
    </Container>
  );
};

export default BookingListItem;

const Container = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray8};
  }
`;
