import styled from '@emotion/styled';
import { MATCH_REPRESENTATIVE_COLOR, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { MemberCardProps } from 'types/memberTypes';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import MemberTicket from './components/MemberTicket';

type Props = {
  member: MemberCardProps;
  onClick: () => void;
};

const MemberCard = ({ member, onClick }: Props) => {
  const {
    avatar,
    name,
    vaccination_yn,
    mobile,
    account_type,
    attendance_count,
    profile: { registered_at, last_attendance_at },
    userGrade,
    userTickets,
  } = member;

  const nameText = name.length > 6 ? `${name.slice(0, 6)}...` : name;

  return (
    <Container onClick={onClick}>
      <div className="member-info-wrapper">
        <Avatar imgUrl={avatar ? getImageUrl(avatar.image) : undefined} size={40} edge="circular" />
        <div className="member-main-info-wrapper">
          <div className="member-flex-wrapper">
            <Typography className="name-text" span size={15} weight={700}>
              {nameText}
            </Typography>
            {vaccination_yn === 'Y' && <Icon name="vaccinateRegular" size={16} fillColor={theme.color.secondary1} />}
          </div>
          <div className="member-flex-wrapper">
            <Typography span textColor="gray2">
              {filters.mobile(mobile || '') || '-'}
            </Typography>
            {!account_type && <Icon name={'connectOff'} size={16} fillColor={theme.color.gray3} />}
          </div>
        </div>
        <div className="member-label-wrapper">
          {userGrade && (
            <Label size="small" textColor="white" color={MATCH_REPRESENTATIVE_COLOR[userGrade.representative_color]}>
              {userGrade.name}
            </Label>
          )}
          <Typography size={13} textColor="gray3">
            {filters.date(registered_at)} 등록
          </Typography>
        </div>
      </div>

      {!!userTickets.length && (
        <div className="ticket-info-wrapper">
          {userTickets.map(userTicket => (
            <MemberTicket
              key={userTicket.id}
              userTicket={userTicket}
              lastAttendanceAt={last_attendance_at}
              attendanceCount={attendance_count}
            />
          ))}
        </div>
      )}
    </Container>
  );
};

export default MemberCard;

const Container = styled.li`
  padding: 18px 20px;
  width: 100%;
  box-shadow: 1px 3px 10px rgba(145, 145, 145, 0.12);
  border-radius: 12px;
  background-color: ${theme.color.white};

  .member-info-wrapper {
    ${theme.flex('', '', '', 10)};

    .member-flex-wrapper {
      ${theme.flex('', 'center', 'initial')};
    }

    .member-label-wrapper {
      margin-left: auto;
      text-align: right;
      ${theme.flex('column', 'flex-end', 'flex-end', 4)};

      .label {
        padding: 0 3px;
      }
    }
  }
`;
