import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LecturePrivatePatchParams } from './usePatchLecturePrivate';
import { BookingFailResponse } from './usePostBookingPrivate';

/** 그룹 수업 1개 수정 */
const usePatchLectureGroup = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LectureGroupPatchParams>({
    method: `patch`,
    url: `/api/lecture/group/${lectureId}`,
  });

  return useMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    LectureGroupPatchParams | undefined,
    unknown
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default usePatchLectureGroup;

export type LectureGroupPatchParams = LecturePrivatePatchParams & {
  is_force?: boolean;
  description?: string;
  division_id?: number;
  max_trainee?: number;
  min_trainee?: number;
  waiting_trainee_limit?: number;
  is_booking_only: boolean;
  /** 예약 변경 가능 시간 */
  daily_change_booking_end_at?: string;
  /** 자동 폐강 시간 */
  autoclose_at?: string;
  /** 예약 대기 자동 예약 시간 */
  booking_auto_shift_available_minutes_from_start?: string;
};
