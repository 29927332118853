import FallbackStaffField from 'pages/Booking/components/SelectStaff/FallbackStaffField';
import { useMatch } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormField from 'sharedComponents/FormField';

import CheckPermissionSelectStaff from './CheckPermissionSelectStaff';

const SelectStaffsAndColor = () => {
  const match = useMatch('/schedule/etc/form/:mode/:id?');
  const pageMode = match?.params.mode === 'create' ? 'create' : 'update';

  return (
    <ApiBoundary
      fallback={
        <>
          <FallbackStaffField placeholder="강사선택" />
          <FormField iconName="colorChips" iconColor="gray3">
            대표 컬러
          </FormField>
        </>
      }>
      <CheckPermissionSelectStaff pageMode={pageMode} />
    </ApiBoundary>
  );
};

export default SelectStaffsAndColor;
