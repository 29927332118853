import useGetRoom from 'hooks/service/queries/useGetRoom';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import FormFieldDisabled from 'sharedComponents/FormField/FormFieldDisabled';

import RoomSetting from './RoomSetting';

/** 룸 설정 기능을 사용하는 시설인지 아닌지 판단 */
const CheckRoomUsable = () => {
  const currentStudioId = useRecoilValue(studioIdAtom);
  const {
    data: { policy },
  } = useGetStudioInfo(currentStudioId);

  const isEnabled = !!policy.is_use_rooms;
  const { data } = useGetRoom({ isEnabled });

  if (!isEnabled || !data) {
    return <FormFieldDisabled iconName="room">룸 설정 안함</FormFieldDisabled>;
  }
  return <RoomSetting roomData={data} isUseRooms={isEnabled} />;
};

export default CheckRoomUsable;
