import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import DateCalendarPicker from 'components/DateCalendarPicker';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

type Props = {
  pageType: 'update' | 'updateAll';
};

const BookingEndDate = ({ pageType }: Props) => {
  const { getValues, setValue, control } = useFormContext<BookingCommonFormType>();
  const [isOpen, setIsOpen] = useState(false);
  const endDate = useWatch({ control, name: 'endDate' });

  const changeEndDate = (e: { value: Date }) => {
    setValue('endDate', filters.dateDash(e.value), { shouldDirty: true });
  };

  return (
    <StyledAccordion
      leftIcon={<Icon name={pageType === 'update' ? 'calendar' : 'dateEnd'} color="gray2" />}
      showArrow={false}
      gap={10}
      isOpen={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      header={
        <Typography span size={15} weight={500} textColor={isOpen ? 'primary' : 'gray1'}>
          {filters.dateWithWeekday(endDate)}
        </Typography>
      }>
      <DateCalendarPicker
        select={undefined}
        min={getValues('startDate')}
        defaultValue={getValues('endDate')}
        onChange={changeEndDate}
        value={endDate}
      />
    </StyledAccordion>
  );
};

export default BookingEndDate;

const StyledAccordion = styled(Accordion)<{ isOpen: boolean }>`
  border-bottom: 1px solid;
  border-bottom-color: ${({ isOpen }) => (isOpen ? theme.color.gray6 : theme.color.gray8)};

  .date-range-picker {
    padding: 24px 0;
    border-top: 1px solid ${theme.color.gray8};
  }
`;
