import 'swiper/css';

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { useNavigate } from 'react-router-dom';
import UserTicketCard from 'sharedComponents/UserTicketCard';
import { UserTicketCardType } from 'sharedComponents/UserTicketCard/types';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = CurrentMemberIdProps & {
  canCreateUserTicket: boolean;
  currentUserTickets: UserTicketCardType[];
  productType: UserTicketParams['product_type'];
};

const TicketSwiper = ({ canCreateUserTicket, currentMemberId, productType, currentUserTickets }: Props) => {
  const navigate = useNavigate();

  const goToDetail = (ticket: UserTicketCardType) => {
    navigate(`/product/detail/${ticket.id}?memberId=${currentMemberId}`);
  };

  const goToTicketForm = () => {
    navigate(`/product/form?memberId=${currentMemberId}&productType=${productType}&step=1`);
  };

  return (
    <Container>
      <Swiper initialSlide={canCreateUserTicket ? 1 : 0} slidesPerView={'auto'} centeredSlides={true} modules={[EffectCoverflow]}>
        {canCreateUserTicket && (
          <SwiperSlide>
            <TicketDefault className="user-ticket-card" onClick={goToTicketForm}>
              <div className="icon-wrapper">
                <Icon name="plusBold" color="gray5" />
              </div>
              <Typography span size={13} weight={600} textColor="gray3">
                {PRODUCT_TYPE_TEXT[productType]} 추가
              </Typography>
            </TicketDefault>
          </SwiperSlide>
        )}
        {currentUserTickets.map(ticket => (
          <SwiperSlide key={ticket.id} onClick={() => goToDetail(ticket)}>
            <UserTicketCard ticket={ticket} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default TicketSwiper;

const Container = styled.div`
  margin: 24px 0 12px;

  .swiper-wrapper {
    align-items: center;
    padding-bottom: 20px;

    .swiper-slide {
      width: auto;

      .user-ticket-card {
        transform: scale(0.9);
        transition: transform 0.3s linear;
      }

      &.swiper-slide-active {
        .user-ticket-card {
          transform: scale(1);
        }
      }
    }
  }
`;

const TicketDefault = styled.div`
  width: 311px;
  height: 188px;
  border-radius: 12px;
  background-color: ${theme.color.gray5};
  ${theme.flex('column', 'center', 'center', 8)};

  .icon-wrapper {
    ${theme.flex()};
    width: 32px;
    height: 32px;
    background-color: ${theme.color.gray3};
    border-radius: 50%;
  }
`;
