import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { BottomWrapper } from 'components/Form';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { AppLinkMessageFormType } from 'pages/MemberDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

type Props = {
  onOpen: () => void;
};

const SubmitButton = ({ onOpen }: Props) => {
  const { control } = useFormContext<AppLinkMessageFormType>();
  const isReserve = useWatch({ control, name: 'isReserve' });
  const reservationDate = useWatch({ control, name: 'reservationDate' });

  return (
    <Container isReserve={isReserve}>
      {isReserve && (
        <div className="reservation-wrapper">
          <Label
            size="regular"
            textColor="secondary1"
            color="secondary1"
            opacity={0.12}
            minWidth={44}
            fontSize={13}
            borderRadius={4}>
            예약
          </Label>
          {reservationDate && (
            <Typography size={15} weight={600}>
              {filters.dateTimeWithWeekday(reservationDate)}
            </Typography>
          )}
        </div>
      )}
      <Button
        color="point"
        fullWidth
        size="large56"
        variant="contained"
        disabled={isReserve && !reservationDate}
        onClick={onOpen}>
        {isReserve ? '보내기 예약' : '보내기'}
      </Button>
    </Container>
  );
};

export default SubmitButton;

const Container = styled(BottomWrapper)<{ isReserve: boolean }>(
  css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 50;
    background-color: ${theme.color.white};

    .reservation-wrapper {
      ${theme.flex('row', 'center', '', 12)};
      margin-bottom: 16px;
    }
  `,
  ({ isReserve }) =>
    isReserve &&
    css`
      box-shadow: 0px -2px 3px 0px rgba(145, 145, 145, 0.12);
    `,
);
