import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { HoldingPostParams } from './usePostHolding';

/** 수강권 정지 취소 */
const useDeleteHolding = (holdingId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<HoldingDeleteParams>({
    method: `delete`,
    url: `/api/holding/${holdingId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default useDeleteHolding;

export type HoldingDeleteParams = Pick<HoldingPostParams, 'user_ticket_id'>;
