import Divider from 'components/Divider';
import Tabs, { TabDataElementType } from 'components/Tabs';
import TabPanel from 'components/Tabs/TabPanel';
import useGetStaff from 'hooks/service/queries/useGetStaff';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { targetStaffAtom } from 'recoil/staff';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { TAB_DATA } from '../constants';
import StaffProfileCard from './ProfileCard/StaffProfileCard';
import SkeletonTimeSchedule from './SkeletonTimeSchedule';
import AssignedMembers from './TabContent/AssignedMember';
import BasicInfo from './TabContent/BasicInfo';
import TimeSchedule from './TabContent/TimeSchedule';

const StaffDetailLayout = () => {
  const setStaffName = useSetRecoilState(targetStaffAtom);

  const { getSearchParams, setSearchParams } = useQueryString();
  const targetStaffId = useParamsId();
  const { data: staff } = useGetStaff({ targetStaffId });

  const tabValue = getSearchParams('tab') as string;
  const currentTab = TAB_DATA.find(tabData => tabData.value === tabValue) || TAB_DATA[0];

  useEffect(() => {
    if (staff) {
      setStaffName({ id: staff.id, name: staff.name });
    }
  }, [staff, setStaffName]);

  const privateStartInterval = useMemo(() => {
    return {
      isEnabled: !!staff.profile?.private_start_interval_on_off,
      am: staff.profile?.private_start_interval_am,
      pm: staff.profile?.private_start_interval_pm,
    };
  }, [staff.profile]);

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value }, { replace: true });
  };

  return (
    <>
      <StaffProfileCard staff={staff} />
      <Divider />
      <Tabs data={TAB_DATA} value={currentTab.value} onChange={changeTab} />
      <TabPanel>
        {currentTab.value === 'basicInfo' && (
          <BasicInfo selfIntroduction={staff.profile.self_introduction} careers={staff.careers} />
        )}
        {currentTab.value === 'schedule' && (
          <ApiBoundary fallback={<SkeletonTimeSchedule />}>
            <TimeSchedule privateStartInterval={privateStartInterval} />
          </ApiBoundary>
        )}
        {currentTab.value === 'AssignedMembers' && <AssignedMembers />}
      </TabPanel>
    </>
  );
};

export default StaffDetailLayout;
