import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 프라이빗 수업 1개 수정 */
const usePatchLecturePrivate = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LecturePrivatePatchParams, LecturePrivatePatchResponse>({
    method: `patch`,
    url: `/api/lecture/private/${lectureId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default usePatchLecturePrivate;

export type LecturePrivatePatchParams = {
  title: string;
  instructor_id: number;
  start_on: string;
  end_on: string;
  room_id?: number;
  /** 예약 가능 시작/종료 시간 */
  booking_start_at?: string;
  booking_end_at?: string;
  /** 예약 취소 가능 시작/종료 시간 */
  booking_cancel_start_at?: string;
  booking_cancel_end_at?: string;
  /** 체크인 가능 시작/종료 시간 */
  enter_start_at?: string;
  enter_end_at?: string;
};

export type LecturePrivatePatchResponse = {
  id: number;
};
