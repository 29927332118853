import FloatingActionButton from 'components/FloatingActionButton';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router-dom';

const CreateButton = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();

  if (hasPermission(PERMISSION.board.notice.create)) {
    return <FloatingActionButton type="single" onClick={() => navigate('form')} bottom={24} />;
  }
  return null;
};

export default CreateButton;
