import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 예약 문자 취소 */
const usePostMessageMobileCancel = (id: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `post`,
    url: `/api/message/mobile/${id}`,
  });

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['message/mobile', id] });
    },
  });
};

export default usePostMessageMobileCancel;
