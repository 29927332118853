import styled from '@emotion/styled';
import defaultProfile from 'assets/images/default_profile.webp';
import { useEffect, useMemo, useState } from 'react';

type AvatarSize = 96 | 80 | 32 | 24 | 16 | number;

type Props = {
  /** 이미지의 주소 값 */
  imgUrl?: string;
  /** Avatar 크기 (기본값: 24) */
  size?: AvatarSize;
  /** 외곽 형태 (기본값: circular) */
  edge?: 'square' | 'circular';
};

type AvatarStyle = {
  /** Avatar 크기 (기본값: 24) */
  size?: AvatarSize;
  /** 외곽 형태 (기본값: circular) */
  edge?: 'square' | 'circular';
  /** Avatar 패딩 */
  paddingSize?: number;
};

const useLoaded = ({ imgUrl }: Props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!imgUrl) return undefined;

    setLoaded(true);

    const image = new Image();

    image.onerror = () => {
      setLoaded(false);
    };
    image.src = imgUrl;
  }, [imgUrl]);

  return loaded;
};

const Avatar = ({ imgUrl, size = 24, edge = 'circular' }: Props) => {
  const loaded = useLoaded({ imgUrl });
  const isImageLoadSuccess = imgUrl && loaded;
  const imgSrc = isImageLoadSuccess ? imgUrl : defaultProfile;

  const paddingSize = useMemo(() => {
    switch (size) {
      case 24:
        return 1;
      case 16:
        return 0.67;
      default:
        return 0;
    }
  }, [size]);

  return (
    <Container className="avatar" size={size} paddingSize={paddingSize}>
      <AvatarImg edge={edge} src={imgSrc} alt="avatar image" />
    </Container>
  );
};

const Container = styled.div<AvatarStyle>`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  padding: ${props => props.paddingSize + 'px'};
`;

const AvatarImg = styled.img<AvatarStyle>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ edge }) => (edge === 'circular' ? '50%' : '16px')};
`;

export default Avatar;
