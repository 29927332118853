import FloatingActionButton, { ActionsType } from 'components/FloatingActionButton';
import Tabs from 'components/Tabs';
import useQueryString from 'hooks/useQueryString';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import { MESSAGE_IDENTIFIER, MESSAGE_LIST_TEXT, MESSAGE_TABS } from './constants';
import MoreDetailsMessageMobileList from './Mobile/List';
import MobileFilter from './Mobile/List/components/MobileFilter';
import MobileSkeletonList from './Mobile/List/components/MobileSkeletonList';
import MoreDetailsMessagePushList from './Push/List';
import AppPushFilterButton from './Push/List/components/AppPushFilterButton';
import AppPushFilterContents from './Push/List/components/AppPushFilterContents';
import AppPushSkeletonList from './Push/List/components/AppPushSkeletonList';

const MoreDetailsMessageList = () => {
  const { getSearchParams } = useQueryString();

  const tab = getSearchParams('tab');
  const currentTab = MESSAGE_TABS.find(tabData => tabData.value === tab) || MESSAGE_TABS[0];

  const navigate = useNavigate();
  const actions: Array<ActionsType> = [
    { label: '앱푸시 보내기', icon: 'alert', onClick: () => navigate(`push/form`) },
    { label: '문자 보내기', icon: 'message', onClick: () => navigate(`mobile/form`) },
  ];

  return (
    <MainLayout
      header={{
        title: MESSAGE_LIST_TEXT.title,
        bgColor: 'white',
        rightButton: <HeaderSearchButton identifier={MESSAGE_IDENTIFIER} />,
        noUnderline: true,
      }}
      backgroundColor="gray6"
      childrenFloatingButton={<FloatingActionButton actions={actions} bottom={24} />}>
      <StickyWrapper>
        <Tabs data={MESSAGE_TABS} value={currentTab.value} onChange={tab => navigate(`?tab=${tab.value}`, { replace: true })} />
      </StickyWrapper>
      {currentTab.value === 'mobile' ? <MobileFilter /> : <AppPushFilterButton />}
      {currentTab.value === 'mobile' ? (
        <ApiBoundary fallback={<MobileSkeletonList />}>
          <MoreDetailsMessageMobileList />
        </ApiBoundary>
      ) : (
        <ApiBoundary fallback={<AppPushSkeletonList />}>
          <AppPushFilterContents />
          <MoreDetailsMessagePushList />
        </ApiBoundary>
      )}
    </MainLayout>
  );
};

export default MoreDetailsMessageList;
