import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Drawer from 'designedComponents/Drawers';
import CheckRadioFilterContents from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioFilterContents';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CONTENT_TEXT } from '../constants';
import ContentTitle from './ContentTitle';

type Props = {
  roles: Array<{
    id: string;
    label: string;
    value: string;
  }>;
};

const RoleSelect = ({ roles }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { control, setValue, watch } = useFormContext();

  const currentRole = roles.filter(role => role.id === watch('role'))[0].label;

  return (
    <Container>
      <ContentTitle title={CONTENT_TEXT.roleSelect.title} sub={CONTENT_TEXT.roleSelect.sub} showRoleButton />
      <Button
        className="role-drawer-button"
        variant="outlined"
        size="medium48"
        fontSize={15}
        borderRadius={10}
        textColor="gray1"
        rightIcon={<Icon name="arrowBottom" fillColor={theme.color.gray2} />}
        padding={{ left: 16, right: 12 }}
        fullWidth
        onClick={() => setIsDrawerOpen(true)}>
        {currentRole}
      </Button>

      <Drawer header={<OneLinedTitle title="역할 선택" />} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <CheckRadioFilterContents
          name="role"
          data={roles}
          control={control}
          onClick={({ target: { value } }) => {
            setValue('role', value);
          }}
        />
      </Drawer>
    </Container>
  );
};

export default RoleSelect;

const Container = styled.section`
  padding: 8px 20px 32px;

  .role-drawer-button {
    ${theme.flex('row', 'center', 'space-between')};
  }
`;
