import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isEqual, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { selectedFiltersAtom } from 'recoil/schedule';
import { SelectedFiltersAtomType } from 'recoil/schedule/types';
import MainLayout from 'sharedComponents/MainLayout';

import FilterStaffsDrawer from '../drawer/FilterStaffsDrawer';
import ApplyButton from './components/ApplyButton';
import FilterSchedules from './components/FilterScheduleItems';
import FilterTitle from './components/FilterTitle';
import { FILTER_POPUP_HEADER, TOAST_MESSAGE } from './constants';

type Props = {
  allStaffs: StaffResponse[];
  isStaffViewPermission: boolean;
  isLoading: boolean;
};

const FilterSettings = ({ allStaffs, isStaffViewPermission, isLoading }: Props) => {
  const staffId = useRecoilValue(staffIdAtom);

  const [selectedFilters, setSelectedFilters] = useRecoilState(selectedFiltersAtom);

  /** 일정(schedules) 필터 임시값 */
  const [tempSchedules, setTempSchedules] = useState<SelectedFiltersAtomType['schedules']>(selectedFilters.schedules);
  /** 강사(staffs) 필터 임시값(UI 구조가 이중 레이어라서 임시값 2개 필요) */
  const [tempApplyStaffs, setTempApplyStaffs] = useState<SelectedFiltersAtomType['staffs']>(selectedFilters.staffs);
  const [tempCheckedStaffs, setTempCheckedStaffs] = useState<SelectedFiltersAtomType['staffs']>(selectedFilters.staffs);
  const [isStaffsDrawerOpen, setIsStaffsDrawerOpen] = useState(false);

  /** staffs 필터 비동기 초기값 세팅 */
  const initialStaffIds = useMemo(() => {
    if (!isStaffViewPermission) return [staffId];
    return ['all' as const];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /** 비동기 초기값 세팅을 위한 이펙트 */
  useEffect(() => {
    if (selectedFilters.staffs.includes('all') && !isLoading) {
      setTempApplyStaffs(initialStaffIds);
      setTempCheckedStaffs(initialStaffIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { setPopup } = usePopup();
  const { setToast } = useToast();

  const closePopup = () => setPopup(null);

  const applySelectedFilters = () => {
    if (!isStaffViewPermission) {
      setToast({ type: 'error', message: TOAST_MESSAGE.notPermission, bottom: 92 });
      return;
    }

    setSelectedFilters({ schedules: tempSchedules, staffs: tempApplyStaffs });
    setToast({ type: 'success', message: TOAST_MESSAGE.filterSuccess, bottom: 76 });
    closePopup();
  };

  const resetFilters = () => {
    setTempSchedules(['all']);
    setTempApplyStaffs(['all']);
    setTempCheckedStaffs(['all']);
  };

  const isDirty = useMemo(() => {
    // 여기에서 sortBy는 단순히 배열 인덱스를 같게 맞추기 위함으로, 특정 조건이 따로 있진 않음
    const isSchedulesDirty = !isEqual(sortBy(selectedFilters.schedules), sortBy(tempSchedules));
    const isStaffsDirty = !isEqual(sortBy(initialStaffIds), sortBy(tempApplyStaffs));
    return isSchedulesDirty || isStaffsDirty;
  }, [selectedFilters, tempSchedules, tempApplyStaffs, initialStaffIds]);

  return (
    <MainLayout
      header={{
        title: FILTER_POPUP_HEADER.title,
        leftButton: (
          <IconButton onClick={closePopup}>
            <Icon name="headerClose" size={24} />
          </IconButton>
        ),
        rightButton: (
          <Button fontSize={15} fontWeight={600} textColor="gray3" onClick={resetFilters}>
            초기화
          </Button>
        ),
      }}>
      <Container>
        <section className="filter-section schedule">
          <FilterTitle title={FILTER_POPUP_HEADER.subTitle.schedule} />
          <FilterSchedules tempSchedules={tempSchedules} setTempSchedules={setTempSchedules} />
        </section>

        <Divider />

        <section className="filter-section">
          <FilterTitle title={FILTER_POPUP_HEADER.subTitle.staff} />

          <FilterStaffsDrawer
            isOpen={isStaffsDrawerOpen}
            staffs={allStaffs}
            tempApplyStaffs={tempApplyStaffs}
            tempCheckedStaffs={tempCheckedStaffs}
            setIsOpen={setIsStaffsDrawerOpen}
            setTempApplyStaffs={setTempApplyStaffs}
            setTempCheckedStaffs={setTempCheckedStaffs}
          />
        </section>

        <ApplyButton text="필터 적용" onClick={applySelectedFilters} disabled={!tempApplyStaffs.length || !isDirty} />
      </Container>
    </MainLayout>
  );
};

export default FilterSettings;

const Container = styled.div`
  ${theme.flex('column', 'flex-start', 'center', 24)};
  margin-top: 16px;

  hr {
    width: 100%;
  }

  .filter-section {
    padding: 0 20px;
    width: 100%;

    &.schedule {
      .input-select-base-input-wrapper {
        display: none;
      }
    }

    .select-drawer {
      width: 100%;
    }
  }

  .apply-button {
    padding: 0 20px 10px;
    width: 100%;
  }
`;
