import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { MessagePushResponse } from 'hooks/service/queries/useInfinityMessagePush';
import MessageLabel from 'pages/MoreDetails/Message/components/MessageLabel';
import { Link } from 'react-router-dom';
import filters from 'utils/filters';

import { MESSAGE_PUSH_STATUS_ADAPTER } from '../constants';

type Props = {
  push: MessagePushResponse;
};

const AppPushListItem = ({ push }: Props) => {
  return (
    <Container to={`push/${push.id}`}>
      <Typography span size={15} weight={600}>
        {filters.dateTimeWithWeekday(push.send_on)}
      </Typography>
      <Typography span size={15} weight={500} className="count" textColor="gray2">
        {filters.numberComma(push.target_count)}건
      </Typography>
      <Typography ellipsis={1} textColor="gray2" className="title">
        {push.title}
      </Typography>
      <StyledLabel status={MESSAGE_PUSH_STATUS_ADAPTER[push.status]} width={54} />
    </Container>
  );
};

export default AppPushListItem;

const Container = styled(Link)`
  padding: 18px 20px;
  background-color: ${theme.color.white};
  border-radius: 12px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  position: relative;

  .count {
    position: relative;
    padding-left: 12px;
    :before {
      content: '';
      position: absolute;
      width: 1px;
      height: 10px;
      background-color: ${theme.color.gray4};
      top: 50%;
      margin-top: -5px;
      left: 6px;
    }
  }
  .title {
    margin-top: 6px;
  }
`;

const StyledLabel = styled(MessageLabel)`
  position: absolute;
  top: 18px;
  right: 20px;
`;
