import styled from '@emotion/styled';
import React from 'react';

const TermsOfServiceDetail = () => {
  return (
    <Container id="paper">
      <h1 className="title">스튜디오메이트 이용약관</h1>

      <section className="chapter">
        <h2 className="chapter_title">제1장 총칙</h2>
        <h4 className="chapter_sub_title">제1조 (목적)</h4>
        <p>
          본 약관은 (주)스튜디오메이트(이하 “회사“)가 운영하는 스튜디오메이트 서비스(이하 “서비스“) 이용과 관련하여 회사와
          이용자의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.
        </p>
        <p>
          <em>※ 「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 본 약관을 준용합니다.」</em>
        </p>

        <h4 className="chapter_sub_title">제2조 (용어의 정의)</h4>
        <p>본 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.</p>
        <ol>
          <li>
            “서비스”라 함은 구현되는 단말기(PC, TV, 휴대폰 등의 각종 유무선 장치를 포함)와 상관없이 “회사”가 “서비스 회원”,
            “시설회원” 에게 제공하는 제반 서비스 모두를 의미합니다.
          </li>

          <li>
            “서비스회원”이라 함은 회사의 약관 제5조에 정해진 가입 절차에 따라 가입하여 정상적으로 “서비스”를 이용할 수 있는 권한을
            부여 받은 일반인을 말합니다.
          </li>

          <li>
            “시설회원”이라 함은 회사의 약관 제5조에 정해진 가입 절차에 따라 가입하여 정상적으로 “서비스”를 이용할 수 있는 권한을
            부여 받은 시설을 말합니다.
          </li>

          <li>
            “강사 회원”이라 함은 “회사”의 서비스를 이용하는 “시설회원”에서 일을 하고 있는 강사, 코치 등을 말하며 “서비스회원”은
            “강사회원”을 포함합니다.
          </li>

          <li>
            “아이디(ID)”라 함은 “서비스” 이용을 위하여 “시설회원”이 정하고 “회사”가 승인하는 문자와 숫자의 조합을 의미합니다.
          </li>

          <li>“비밀번호”라 함은 “시설회원”이 자신이 정한 문자 또는 숫자(특수문자 포함)의 조합을 의미합니다.</li>

          <li>
            “수강권”이란 “시설회원”이 “서비스회원”에게 “서비스”를 정상적으로 이용하기 위해 구매/사용이 가능하도록 제공하는 모든
            수업 이용권을 의미합니다.
          </li>

          <li>”서비스 이용권”이라 함은 “회사”의 서비스를 이용하기 위하여 “시설회원”이 구매하는 모든 이용권을 의미합니다.</li>

          <li>
            스튜디오메이트 서비스 페이지 (이하 “서비스 페이지”이라 함)라 함은 “시설회원”이 온라인을 통해 “서비스”를 이용할 수 있는
            회사의 인터넷 사이트를 말합니다.
          </li>

          <li>
            스튜디오메이트 메인 홈페이지(이하 “홈페이지”)라 함은 “시설회원” 및 “서비스회원”이 이용할 수 있는 “회사”의 일반적인
            정보를 제공해주는 인터넷 사이트를 말합니다.
          </li>

          <li>
            스튜디오메이트 모바일 APP(이하 “모바일 APP”)이라 함은 “서비스회원” 및 “시설회원”이 이용할 수 있는 “회사”가 제공하는
            모바일 어플리케이션입니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제3조 (약관 등의 명시와 설명 및 개정)</h4>
        <ol>
          <li>
            “회사”는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
            전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수
            있도록 “서비스 페이지”의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수
            있도록 할 수 있습니다.
          </li>

          <li>
            본 약관은 “서비스”를 이용하고자 하는 모든 “시설회원”에 대하여 그 효력이 발생합니다. 다만, 회사가 필요하다고 인정하는
            경우에는 회원가입 시에 입력한 전자우편 주소를 통한 이메일 발송 등의 방법으로 알림으로써 효력을 발생하게 할 수도
            있습니다.
          </li>

          <li>
            “회사”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」,
            「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
            「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
          </li>

          <li>
            “회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 초기화면에 그 적용일자 7일 이전부터
            적용일자 전일까지 아래 규정된 방법 중 1가지 이상의 방법으로 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는
            경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “회사“는 개정 전 내용과 개정 후 내용을 명확하게
            비교하여 이용자가 알기 쉽도록 표시합니다.
            <ul>
              <li>이메일 통보</li>
              <li>“홈페이지”(studiomate.kr)내 고지</li>
              <li>“모바일 APP” PUSH</li>
              <li>기타 “시설회원” 가입시 “시설회원”이 제공한 연락처 정보 등을 이용한 전화 안내 등의 방법</li>
            </ul>
          </li>

          <li>
            “회사”가 영업양도 등의 방법으로 본 약관에 따른 계약관계를 이전하고자 하는 경우, 그 사실을 “시설회원”에게 개별적으로
            통지하고 이에 대하여 통지를 받은 날로부터 30일간 이의를 제기하지 않고 계속해서 “서비스”를 이용하는 경우에는 이에
            대하여 동의한 것으로 봅니다.
          </li>

          <li>
            본 약관의 개정과 관련하여 이의가 있는 “시설회원”은 회원탈퇴를 할 수 있으며 개정된 약관의 효력발생일까지 탈퇴하지
            않거나 별도로 당사에 이의를 제기하지 않는 경우 변경된 약관에 동의한 것으로 봅니다.
          </li>

          <li>
            본 약관에 동의하는 것은 정기적으로 “홈페이지” 혹은 “모바일 APP”을 방문하여 약관의 변경사항을 확인하는 것에 동의함을
            의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 “시설회원”의 피해는 “회사”에서 책임지지 않습니다.
          </li>

          <li>
            본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제
            등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계 법령 또는 상관례에 따릅니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제4조 (서비스의 중단)</h4>

        <ol>
          <li>
            “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 “서비스”의 제공을
            일시적으로 중단할 수 있습니다.
          </li>
          <li>
            “회사”는 제1항의 사유로 “서비스”의 제공이 일시적으로 중단됨으로 인하여 발생한 “시설회원”이 입은 손해에 대하여 “회사”의
            고의 또는 중과실이 인정되는 경우에는 배상합니다.
          </li>
          <li>
            사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 “서비스”를 제공할 수 없게 되는 경우에는 “회사”는 제7조에 정한
            방법으로 “시설회원”에게 통지하고 당초 “회사”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “회사”가 보상기준
            등을 고지하지 아니한 경우에는 “시설회원”의 마일리지 또는 적립금 등을 “회사”에서 통용되는 통화가치에 상응하는 현물 또는
            현금으로 “시설회원”에게 지급합니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h2 className="chapter_title">제2장 회원가입과 서비스 신청</h2>

        <h4 className="chapter_sub_title">제5조 (회원가입)</h4>
        <ol>
          <li>
            “서비스회원” 은 “모바일 APP”을 통해 가입 양식에 따라 회원정보를 기입한 후 본 약관과
            ‘개인정보처리방침’(개인정보수집제공 및 활용 동의 등)에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
          </li>

          <li>
            “시설회원”의 사업체에서 근무하고 있는 직원 또는 강사들은 “회사“의 “서비스”를 이용하기 위하여 회원가입을 신청할 시
            “서비스회원”의 지위를 취득할 수 있습니다.
          </li>

          <li>
            고객으로부터 “서비스회원”가입 신청이 있는 경우 “회사”는 자체 기준에 따른 심사를 거친 후 자격을 부여할 수 있으며 자격이
            부여된 고객은 당사로부터 가입완료 공지를 받은 시점부터 “서비스회원”으로서 지위를 취득하고 “서비스”를 이용할 수
            있습니다.
          </li>

          <li>“시설회원” 은 스튜디오메이트 “홈페이지”, 모바일 채널 등을 통해 가입 양식에 따라 회원가입을 신청합니다.</li>

          <li>
            고객으로부터 “시설회원” 가입 신청이 있는 경우 당사는 자체 기준에 따른 심사를 거친 후 자격을 부여할 수 있으며 자격이
            부여된 고객은 당사로부터 가입완료 공지를 받은 시점부터 “시설회원”으로서 지위를 취득하고 서비스를 구매/이용할 수
            있습니다.
          </li>

          <li>
            “서비스회원” 및 “시설회원”은 “회사”로부터 부여된 자격을 타인에게 양도하거나 대여 또는 담보 등 어떠한 목적으로도
            이용하게 할 수 없습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제6조 (시설회원 서비스 신청)</h4>

        <p>
          “시설회원”은 아래와 같은 유사한 방법에 의하여 “서비스”를 신청하며, “회사”는 다음의 각 내용을 알기 쉽게 제공하여야
          합니다.
        </p>

        <ol>
          <li>재화나 서비스 검색 및 선택</li>
          <li>신청자의 성명, 주소, 전화번호, 전자우편주소(또는 휴대전화) 등의 입력</li>
          <li>약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인</li>
          <li>본 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)</li>
          <li>재화나 서비스 구매 및 이에 관한 확인 또는 “회사”의 확인에 대한 동의</li>
          <li>결제방법의 선택</li>
        </ol>

        <h4 className="chapter_sub_title">제7조 (회원에 대한 통지)</h4>

        <ol>
          <li>
            “회사”가 “시설회원”에 대한 통지를 하는 경우, “시설회원”이 “회사”와 미리 약정하여 지정한 전자우편 주소로 할 수
            있습니다.
          </li>

          <li>
            “회사”는 불특정다수 “시설회원”에 대한 통지의 경우 1주일 이상 스튜디오메이트 홈페이지 내 게시함으로써 개별 통지에
            갈음할 수 있습니다. 다만, 개별 “시설회원”과의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제8조 (회원의 구분 및 권한)</h4>

        <ol>
          <li>
            “서비스회원”은 “회사”가 제공하는 “서비스”를 이용하기 위하여 자발적으로 모바일 APP을 통하여 가입한 사람을 말합니다.
          </li>
          <li>
            “시설회원”은 “회사”가 제공하는 “서비스”를 이용하기 위하여 자발적으로 웹페이지 및 모바일 APP등을 통하여 가입한 시설을
            말합니다.
          </li>
          <li>
            “서비스회원” 과 “시설회원”은 구분되며 “서비스회원”과 관련된 모든 정보의 관리 및 운영과 관련된 권한은 “회사”에게 있으며
            “시설회원”에게는 아무런 권한이 없습니다.
          </li>
          <li>“시설회원”과 관련된 모든 정보의 관리 및 운영과 관련된 권한은 “회사”에게 있습니다.</li>
        </ol>

        <h4 className="chapter_sub_title">제9조 (회원 탈퇴 및 자격 상실 등)</h4>

        <ol>
          <li>
            “시설회원”은 “홈페이지” 및 모바일 채널 등을 통해 직접 접수할 경우 회원탈퇴를 신청할 수 있습니다. 다만 온라인 접수가
            어려울 경우에 한하여 서면, 이메일, 전화, 기타 당사가 정하는 방법으로 회원탈퇴를 요청할 수 있습니다.
          </li>
          <li>
            다음 각 호의 사유에 해당하는 경우, “회사”는 “시설회원” 자격을 제한 및 정지시킬 수 있습니다.
            <ul>
              <li>가입 신청 시에 허위 내용을 등록하거나 타인의 명의를 도용한 경우</li>
              <li>부정구매, 부정 사용 등 “서비스”를 부정한 방법 또는 목적으로 “서비스”를 이용한 경우</li>
              <li>
                서비스회원”이 “시설회원”에게 구입한 “수강권” 등의 대금, 기타 이용에 관련하여 “시설회원”이 부담해야 하는 채무를
                기일에 지급, 이행하지 않는 경우
              </li>
              <li>다른 사람의 “서비스” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
              <li>
                “회사“와 유사 서비스를 운영하거나 운영 예정인 회사 또는 시설 등이 ‘회사’가 제공하는 ‘서비스’를 이용하는 경우
              </li>
              <li>기타 본 약관이 규정하는 “시설회원”의 의무를 위반하는 경우</li>
              <li>“서비스”를 이용하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
            </ul>
          </li>

          <li>
            “회사”가 “시설회원” 자격을 제한 또는 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지
            아니하는 경우 “회사”는 시설회원” 자격을 상실시킬 수 있습니다.
          </li>
          <li>
            “회사”가 “시설회원” 자격을 상실시키는 경우에는 등록을 말소합니다. 이 경우 “시설회원”에게 이를 통지하고, 등록 말소 전에
            최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제10조 (서비스 이용 및 관리)</h4>

        <ol>
          <li>“서비스회원“과 “시설회원”간에 성립된 거래에 관련해서 “회사“는 책임을 지지 않습니다.</li>
          <li>
            발급된 아이디 및 비밀번호는 “시설회원” 스스로의 책임하에 관리하여야 하며 “시설회원”의 고의 또는 과실로 분실, 도난
            되거나 비밀번호가 유출되는 등의 사고가 발생할 경우, “시설회원”은 즉시 그 사실을 “회사”에 통지하여야 합니다.
          </li>
          <li>
            “회사”는 “시설회원”으로부터 본 조 제2항에 따른 통지를 받고 “시설회원”이 사용 중지를 요청할 경우 필요한 제반 조치를
            취합니다. 단, “회사”는 “시설회원”이 본 조 제2항에 따른 통지 시점 이전에 발생한 사고로서 해당 사고가 “시설회원”의 고의
            또는 과실 등 귀책사유에 의한 경우에는 이에 대해서는 책임 지지 않습니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h2 className="chapter_title">제3장 서비스 이용권</h2>

        <h4 className="chapter_sub_title">제11조 (서비스이용권 생성)</h4>
        <ol>
          <li>
            “서비스이용권”은 “시설회원”이 “회사”에서 판매되는 상품이나 서비스를 구매한 것으로 “회사”에서 제공하는 “서비스”와
            관련하여 “시설회원”이 이용하는 서비스를 말합니다.
          </li>
          <li>
            “회사”는 상품을 구입하거나 “서비스”를 이용하고 그에 따른 대금을 결제한 “시설회원”에게 “회사”가 고지한 대로 이용권을
            산정, 부여합니다.
          </li>
          <li>
            “시설회원”은 “서비스이용권” 신청 전 반드시 모든 정보를 정확히 기재하여야 하며 정확한 정보가 아니거나 타인의 정보를
            도용하여 허위 기재할 경우 법적 보호를 받을 수 없으며 이에 따른 민, 형사 기타 모든 법적 책임은 신청한 “시설회원”에게
            있습니다.
          </li>
          <li>“서비스이용권”은 “시설회원”이 보유한 이용권의 만료기간 내에서 이용가능함을 원칙으로 합니다.</li>
          <li>“서비스이용권” 판매 및 요금 기준은 “회사”의 내부 방침에 따라 임의로 변경될 수 있습니다.</li>
        </ol>

        <h4 className="chapter_sub_title">제12조 (서비스이용권 결제, 취소 및 환불)</h4>
        <ol>
          <li>
            “서비스”에 오류가 있을 경우 “시설회원”은 오류발생 시점부터 7일 이내에 “회사”에 정정신청을 하여야 하며, “회사”는
            “시설회원”의 정정 신청일로부터 최대 6개월 이내에 정정절차를 마쳐야 합니다. 이 때 “시설회원”은 “서비스”의 오류를 증명할
            수 있는 객관적인 자료를 제시하여야 합니다.
          </li>
          <li>
            “서비스” 기간이 만료되면 “시설회원”은 “서비스” 접속 및 이용이 불가합니다. “회사“는 연 결제를 이용 중인 “시설회원”의
            서비스페이지에 만료일 최소 7일 전에 서비스 종료일을 게시합니다. 또한 “시설회원”은 만료기간 전에 “서비스”의
            설정화면에서 만료기간 확인이 가능합니다.
          </li>
          <li>
            “시설회원”이 “서비스” 이용을 중지하고자 환불하는 경우, 이용 시작 전과 이용 시작 후에 따라 다르며 해당 내용은 아래와
            같습니다.
          </li>
        </ol>

        <p>
          <em>
            ※ 이용 시작 전, 시설이 납부완료한 이용료에 대한 환불을 요청하는 경우, 한국 소비자원의 소비자 분쟁해결 기준 및
            공정거래위원회의 표준약관에 의거하여 아래 규정에 따라 진행됩니다.
          </em>
        </p>

        <table>
          <tbody>
            <tr>
              <th>구분</th>
              <th>내용</th>
            </tr>
            <tr>
              <td>이용시작 전</td>
              <td>
                <ul>
                  <li>결제일로부터 7일 이내 결제 취소 요청 시 전액 환불</li>
                  <li>
                    결제일로부터 7일 경과된 경우에는 결제액에서 위약금
                    <br />
                    (결제상품의 10%) 공제 후 환불
                  </li>
                  <li>이용기간이 만료된 상품의 경우 환불 불가</li>
                  <li>할인특가상품의 경우 할인가격이 아닌 정상가격을 기준으로 공제한 후 환불</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>이용시작 후</td>
              <td>
                <ul>
                  <li>“시설”은 유료 서비스 공급일 이후 7일 이내에 결제 취소(청약 철회)를 요청할 수 있습니다.</li>
                  <li>
                    정기결제 “시설”이 유료 서비스 공급일 이후 청약 철회가 가능한 기간(유료 서비스 공급일로부터 7일 이내)을
                    경과하여 청약 철회를 신청하면 환불은 불가능 합니다.
                  </li>
                  <li>
                    정기결제 “시설”이 이미 결제한 서비스 이용 계약을 해지했을 때 남은 정기 결제 계약 기간동안 유료서비스를 이용할
                    수 있습니다.[김경준3]
                  </li>
                  <li>정기결제 “시설”이 현재 상품을 이용하고 다음 달부터 해지하는 경우 다음 달부터 정기 결제가 취소됩니다.</li>
                  <li>
                    <div>
                      일시불 결제 “시설”이 유료 서비스 공급일 이후 청약 철회가 가능한 기간(유료 서비스 공급일로부터 7일 이내)을
                      경과하여 결제 취소시
                    </div>
                    <ul>
                      <li>
                        위약금(결제 상품의 10%)과 상품 결제에 대한 할인분을 적용하지 않고 월 정기결제 기준의 가격으로 월할 및 일할
                        계산하여 정산 후 환불합니다.
                      </li>
                      <li>
                        연결제 상품의 경우 위약금(결제 상품가격의 10%)과 연 결제에 대한 할인분을 적용하지 않고 월 정기결제 기준의
                        가격으로 월할 및 일할 계산하여 정산 후 환불.
                      </li>
                    </ul>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <em> ※ 결제수단 별 상세 환불 절차는 아래 규정에 따라 진행됩니다.</em>
        </p>

        <table>
          <tbody>
            <tr>
              <td>자동이체, 무통장 입금</td>
              <td>환불 규정에 따라 공제 후 계좌 환불</td>
            </tr>
            <tr>
              <td>카드결제</td>
              <td>
                환불 규정에 따라 공제 후 결제 부분 취소. 영업일 기준으로 3~7일 소요(정확한 일정은 해당 카드사에 문의하여 확인하실
                수 있습니다.)
              </td>
            </tr>
          </tbody>
        </table>

        <h4 className="chapter_sub_title">제13조 (시설이용권 변경, 정지 및 해지)</h4>

        <ol>
          <li>“서비스이용권” 상품 변경은 “서비스페이지“에서 본인이 직접 변경 할 수 있습니다.</li>
          <li>
            정기결제 “시설회원”은 결제수단에 따라 아래와 같이 변경할 수 있습니다.
            <ul>
              <li>
                신용카드 정기결제
                <ul>
                  <li>변경된 상품은 결제청구 이후 이용시작일부터 적용됩니다.</li>
                  <li>
                    정기 결제일 5일 전까지 신청해야 다음 이용권부터 변경된 상품이 적용되며 그 후에 신청할 경우, 그 다음 이용권부터
                    변경된 상품이 적용됩니다.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>

        <h4 className="chapter_sub_title">제14조 (회원탈퇴 및 잔여 기간의 처리)</h4>

        <ol>
          <li>
            본 약관 제9조 1항에 정해진 방법으로 탈퇴를 하고자 하는 “시설회원”이 잔여 기간을 사용하고자 할 경우 회원탈퇴 요청일
            전까지 구매한 “서비스이용권”을 본 약관이 정하는 바에 따라 사용해야 합니다.
          </li>
          <li>탈퇴 전까지 사용하지 않은 “서비스이용권”은 자동으로 소멸됩니다.</li>
        </ol>
      </section>

      <section className="chapter">
        <h2 className="chapter_title">제4장 포인트의 충전, 환불, 소멸 등</h2>

        <h4 className="chapter_sub_title">제15조 (포인트 충전)</h4>

        <ol>
          <li>
            “포인트”는 신용카드와 체크카드(이하 “카드결제금액”이라고 합니다)을 통해 충전이 가능합니다. “포인트”와 “카드결제금액”의
            교환 비율은 일대일(1:1)입니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제16조 (포인트 환불)</h4>

        <ol>
          <li>
            “회사“는 “회원“이 서비스 이용 중단 등을 이유로 “포인트“의 환불을 요청하는 경우 남은 “잔여 포인트”에 대해 “회원“이
            충전한 수단에 따라 카드결제 취소 또는 현금으로 해당 금액을 돌려드립니다. 단, 무상으로 지급한 “포인트”가 있을 경우,
            “잔여 포인트”에서 이를 차감하고 환불합니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제17조 (포인트 소멸)</h4>

        <ol>
          <li>
            “포인트“는 마지막으로 충전 또는 이용된 날로부터 5년이 경과하도록 다시 충전 또는 이용되지 않을 경우 상법 상의
            상사소멸시효에 의해 소멸될 수 있습니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h2 className="chapter_title">제5장 개인정보보호</h2>

        <h4 className="chapter_sub_title">제18조 (개인정보보호)</h4>
        <ol>
          <li>
            “회사”는 “시설회원”의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. 다음 아래
            사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다. 뿐만 아니라 사전에 회원으로부터 동의를 얻어 다양한 서비스
            이용시 발생하는 정보를 수시로 수집 저장 할 수 있으며 이를 분석하여 “시설회원”에게 보다 나은 서비스를 제공하고 고객
            지향적인 마케팅 활동을 하기 위해 사용합니다. 개인정보수집 및 활용에 관한 자세한 사항은 당사의 “홈페이지”에 게재되어
            있는 ‘개인정보처리방침’에 따릅니다.
          </li>

          <div>
            <ol>
              <li>이름</li>
              <li>연락처</li>
              <li>주소</li>
              <li>이메일주소</li>
              <li>성별</li>
              <li>생년월일</li>
              <li>결제정보</li>
            </ol>
          </div>

          <li>
            “회사”는 개인정보를 수집, 이용하고자 하는 경우 “시설회원”에게 그 목적을 고지하고 그로부터 별도 양식에 따른
            ‘개인정보처리방침(개인정보수집 및 활용 동의서)’를 받고 있으며 “시설회원”은 언제든지 이를 철회할 수 있습니다.
          </li>
          <li>
            “시설회원”은 회원가입시 기재한 개인정보의 내용에 변경사항이 발생한 경우 즉시 변경사항을 정정하여 기재하여야 하고
            변경의 지체로 인한 “시설회원”의 손해에 대해 “회사”는 책임을 지지 않습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제19조 (개인정보 이용과 제공)</h4>

        <p>
          “회사”는 수집된 개인정보를 ‘개인정보처리방침’에 기재된 목적 범위 내에서만 활용하며 수집한 개인정보를 당해 “시설회원”의
          동의없이 ‘개인정보처리방침’에 기재된 목적 범위를 넘어선 용도로 이용하거나 기재된 자 이외의 제3자에게 제공할 수 없습니다.
          다만, 다음의 경우에는 예외로 합니다.
        </p>
        <ol>
          <li>배송업무상 배송업체에게 배송에 필요한 최소한의 “시설회원”의 정보 (성명, 주소, 전화번호)를 알려주는 경우</li>
          <li>
            통계작성 연구 또는 마케팅 활동 등을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공되어 제공하는 경우
          </li>
          <li>재화 등의 거래에 따른 대금정산을 위하여 필요한 경우</li>
          <li>법률의 규정에 의하여 필요한 불가피한 사유가 있는 경우</li>
          <li>
            “회사” 또는 제휴사와 관련하여 합병, 인수, 포괄적 영업양도 등이 있는 경우 합병 후 당사, 인수당사 및 영업양수인에 대하여
            제공하는 경우
          </li>
        </ol>

        <h4 className="chapter_sub_title">제20조 (개인정보 보유 및 이용기간)</h4>

        <ol>
          <li>
            “회사”는 수집된 “시설회원”의 개인정보에 대해 수집 목적 또는 제공받은 목적이 달성되면 지체없이 파기함을 원칙으로
            합니다. 다만, 다음 각 호의 경우 일정기간 동안 예외적으로 수집한 회원정보의 전부 또는 일부를 보관할 수 있습니다.
            <ul>
              <li>고객요구사항 처리의 목적: 수집한 회원정보를 회원탈퇴 후 30일간 보유</li>
              <li>
                기타 “회사” 및 제휴사가 필요에 의해 별도로 동의를 득한 경우: 별도 동의를 받은 범위(회원정보 및 보유기간)내에서
                보유
              </li>
            </ul>
          </li>

          <li>
            위 1항에도 불구하고 상법 및 ‘전자상거래 등에서 소비자보호에 관한 법률’등 관련 법령의 규정에 의하여 다음과 같이
            일정기간 보유해야 할 필요가 있을 경우에는 관련 법령이 정한 기간 또는 다음 각 호의 기간 동안 회원정보를 보유할 수
            있습니다.
            <ul>
              <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
              <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
              <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
            </ul>
          </li>
        </ol>

        <h4 className="chapter_sub_title">제21조 (개인정보 보호를 위한 회원의 권리)</h4>

        <ol>
          <li>
            “시설회원”은 회원탈퇴를 통해 본 약관과 관련한 개인정보의 제공 및 활용과 관련한 동의를 철회할 수 있습니다. 그 경우에도
            회사는 제20조에 한하여 회원정보를 보유할 수 있습니다.
          </li>
          <li>
            “시설회원”은 “회사”가 고지한 개인정보보호책임자에게 본인의 개인정보에 대한 열람을 요구할 수 있으며 자신의 개인정보에
            오류가 있는 경우 서비스 홈페이지 혹은 어플리케이션을 통해 직접 처리하거나 개인정보보호책임자에게 정정을 요구할 수
            있습니다.
            <ul>
              <li>개인정보 열람 요구</li>
              <li>오류 등이 있을 경우 정정 요구</li>
              <li>개인정보 삭제 요구</li>
            </ul>
          </li>
          <li>
            “회사”는 “시설회원”으로부터 본 조 제1항의 규정에 의한 동의철회 및 본 조 제2항의 규정에 의한 열람 및 정정요구를 받은
            경우에는 지체없이 필요한 조치를 취하도록 합니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제22조 (개인정보처리의 위탁)</h4>

        <p>
          “회사”는 수집된 개인정보의 처리 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를
          업체에 위탁할 수 있으며 위탁업체의 세부내용은 당사나 제휴사의 서비스 홈페이지의 ‘개인정보처리방침’을 통해 확인할 수
          있습니다.
        </p>

        <h4 className="chapter_sub_title">제23조 (개인정보처리)</h4>
        <ol>
          <li>
            “회사”는 수집된 “시설회원”의 개인정보 처리를 위해 개인정보 보호책임자와 개인정보 보호 담당자를 별도로 지정해 관리하며
            개인정보 보호책임자와 개인정보 보호담당자의 성명과 연락처는 “회사”의 서비스 홈페이지 혹은 어플리케이션 내
            ‘개인정보처리방침’을 통해 확인할 수 있습니다.
          </li>
          <li>
            “회사”는 “시설회원”이 안심하고 “서비스”를 이용할 수 있도록 “시설회원”의 개인정보보호를 위한 보안시스템을 갖추도록
            노력합니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h2 className="chapter_title">제6장 스튜디오메이트 서비스</h2>

        <h4 className="chapter_sub_title">제24조 (홈페이지 개요)</h4>
        <ol>
          <li>
            회사는 “시설회원”에게 보다 나은 스튜디오메이트 서비스를 제공하기 위하여 스튜디오메이트 “서비스” 관련 전용 “홈페이지”
            www.studiomate.kr을 운영합니다.
          </li>
          <li>홈페이지 서비스를 이용하고자 하는 “시설회원”은 당사가 정한 별도의 가입 절차에 따라 가입 후 이용이 가능합니다.</li>
        </ol>

        <h4 className="chapter_sub_title">제25조 (홈페이지 서비스 및 이용)</h4>

        <ol>
          <li>회사는 “홈페이지”를 통해 다음과 같은 업무를 수행합니다.</li>
          <li>
            <ol>
              <li>“시설이용권” 구매 및 사용 신청과 서비스 정보 제공</li>
              <li>기타 회사가 정하는 업무</li>
            </ol>
          </li>
          <li>본 약관 제5조 4항에 따라 회사는 “시설회원”이 가입한 이후부터 스튜디오메이트 서비스 페이지를 제공합니다.</li>
        </ol>

        <h4 className="chapter_sub_title">제26조 (서비스 페이지의 중단)</h4>

        <ol>
          <li>
            회사는 다음 각 호에 해당하는 경우 “서비스 페이지” 제공을 전부 또는 일부를 제한하거나 중지할 수 있습니다.
            <ul>
              <li>컴퓨터 등 정보통신설비의 점검, 보수, 교체 및 고장, 통신의 두절 등의 부득이한 사유가 발생한 경우</li>
              <li>전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우</li>
              <li>정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
              <li>“서비스페이지” 제공업자와의 계약 종료 등과 같은 “회사”의 제반 사정으로 서비스를 유지할 수 없는 경우</li>
              <li>기타 국가 비상사태나 천재지변 등 불가항력적 사유가 있는 경우</li>
            </ul>
          </li>

          <li>
            “회사”는 본 조 제1항의 규정에 의하여 “서비스” 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 이메일 또는
            “홈페이지”에 게시하는 방법으로 “시설회원”에게 서비스 중단 사실을 알려드립니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제27조 (저작권의 귀속 및 이용제한)</h4>
        <ol>
          <li>“회사“가 작성한 저작물에 대한 저작권 기타 지적재산권은 ”회사“에 귀속합니다.</li>
          <li>
            “시설회원”은 스튜디오메이트 서비스를 이용함으로써 얻은 정보를 “회사”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송
            기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
          </li>
          <li>
            “시설회원”이 서비스 내에 게시한 게시물(텍스트, 이미지, 동영상, 링크 등의 기타정보)에 대한 권리와 책임은 해당 게시물을
            등록한 “시설회원”에게 있습니다 . 따라서 “시설회원”의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사 기타 법적
            책임은 전적으로 해당”시설회원”이 부담하여야 합니다.
          </li>
          <li>
            “회사”는 “시설회원”이 작성한 콘텐츠에 대해서 감시 및 관리할 수 없으며 이에 대해서 책임지지 않습니다. “회사”는
            “시설회원”이 등록하는 게시물의 신뢰성과 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지 않습니다.
          </li>
          <li>
            “회사”는 게시된 내용을 게시물의 동일성을 해하지 않는 범위 내에서 편집, 이동시킬 수 있는 권리를 보유하며 다음의 경우
            사전 통지 없이 삭제할 수 있습니다.
            <ul>
              <li>본 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</li>
              <li>다른 회원 또는 제 3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</li>
              <li>“회사”에서 규정한 게시기간이나 용량을 초과한 경우</li>
              <li>공공질서 및 미풍양속에 위반되거나 범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
              <li>
                ‘회사’ 및 ‘회사’의 ‘서비스’에 악의를 가지고 있거나 “회사“와 동일, 유사한 사업을 수행하거나 수행 예정으로 이를 위해
                게시물을 게시, 이용한 경우
              </li>
              <li>제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
              <li>기타 관계 법령에 위배되는 경우</li>
            </ul>
          </li>

          <li>
            “시설회원”이 “서비스” 내에 게시하는 게시물은 검색결과 내지 서비스 운영 및 홍보 등을 위해서 매체, 홈페이지, 기타의
            방법에서 사용될 수 있습니다. 이때 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.
          </li>
          <li>
            “시설회원”이 회원탈퇴를 한 경우에는 본인이 기록한 저작물 일체가 삭제되지 않은 채 보존되며 “회사” 내부 규정에 맞게
            재구성하여 사용될 수 있습니다. 탈퇴 후에 저작물의 노출을 원하지 않는다면 탈퇴 전에 컨텐츠를 삭제하여야 합니다.
          </li>
          <li>
            “시설회원”의 게시물 또는 저작물이 “회사” 또는 제3자의 저작권 등 지적재산권을 침해함으로써 발생하는 민, 형사 기타
            법적책임은 전적으로 “시설회원”이 부담해야 합니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제28조 (“회사”의 의무)</h4>
        <ol>
          <li>
            “회사”는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 본 약관이 정하는 바에 따라 지속적이고,
            안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
          </li>
          <li>“회사”는 “시설회원”의 개인정보 수집 및 이용에 관련하여 ‘개인정보처리방침’을 준수합니다.</li>
          <li>
            “회사”는 “시설회원”으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거쳐
            처리하여야 합니다. 처리 시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다.
          </li>
          <li>
            “회사”가 제공하는 “서비스 페이지”로 인하여 “시설회원”에게 손해가 발생한 경우 “회사”의 고의나 과실에 기해 발생한 경우에
            한하여 “회사”에서 책임을 부담하며 그 책임의 범위는 예측이 가능한 통상손해에 한합니다.
          </li>
          <li>
            “회사”는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영 유지와 관련 있는
            법규를 준수합니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제29조 (“시설회원”의 의무)</h4>

        <ol>
          <li>
            “시설회원”은 “회사”에서 제공하는 “서비스’를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를
            하여서는 안됩니다.
            <ul>
              <li>
                가입신청 또는 정보 변경을 목적으로 “회사”에 개인정보 등록시 실명이 아닌 정보 또는 다른 사람의 정보를 사용하거나
                허위 사실을 기재하는 행위
              </li>

              <li>
                타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 다른 “회원”의 계정 및 “비밀번호”를 도용, 부정하게
                사용하거나, 타인의 신용카드, 전화번호 등을 무단으로 수집, 사용하는 행위
              </li>

              <li>“회사”에 게시된 정보의 무단 변경, 삭제 등 훼손 행위</li>

              <li>
                “회사”의 “서비스” 및 제공된 애플리케이션을 이용하여 얻은 정보를 “회사”의 사전 승낙없이 복제, 유통, 조장하거나
                상업적으로 이용하는 행위 또는 알려지거나 알려지지 않은 버그를 악용하여 “서비스”를 이용하는 행위
              </li>

              <li>
                “회사” 또는 제3자의 지적재산권, 초상권 등 기타 권리를 침해하거나 “회사”의 “서비스”를 통하지 않고 다른 “회원”의
                개인정보를 수집, 저장, 유포, 게시하는 행위
              </li>

              <li>“회사” 및 제3자의 명예를 훼손하거나 업무를 방해하는 행위 또는 회사 및 제3자에게 손해를 가하는 행위</li>

              <li>
                제3자를 기망하여 이득을 취하거나 “회사”가 제공하는 “서비스”를 불건전하게 이용하여 제3자에게 피해를 주는 행위
              </li>

              <li>
                음란, 저속한 정보를 교류, 게재하거나 그에 관해 연결(링크)하거나 사회통념상 타인에게 불쾌감을 줄 수 있는 내용을
                담은 광고 및 홍보물을 게재하는 행위
              </li>

              <li>재물을 걸고 도박하는 등 사해행위를 유도하거나 참여하는 행위</li>

              <li>
                수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글, 화상 또는 영상을 게재하거나 상대방에게 전송, 도달,
                유포하는 행위
              </li>

              <li>
                관련 법령에 의하여 전송 또는 게시가 금지되는 정보 또는 컴퓨터 소프트웨어, 하드웨어, 전기통신장비의 정상적인 가동을
                방해, 파괴할 목적으로 고안된 소프트웨어 바이러스 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를
                전송, 게시, 유포, 사용하는 행위
              </li>

              <li>
                “회사”로부터 특별한 권리를 부여받지 않고 애플리케이션을 변경하거나 애플리케이션에 다른 프로그램을 추가 또는
                삽입하거나 서버를 해킹, 역설계, 소스코드나 애플리케이션 데이터의 유출 및 변경, 별도의 서버를 구축하거나 웹사이트의
                일부분을 임의로 변경 또는 도용하여 “회사”를 사칭하는 행위
              </li>

              <li>
                “회사”의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여 명함 등 정보를 등록하거나 글을 게시하거나
                메일을 발송하는 행위
              </li>

              <li>“회사”의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 “서비스”를 이용하는 행위</li>

              <li>기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에 위배되는 행위</li>
            </ul>
          </li>

          <li>
            “시설회원”은 “회사” 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을 확인하고 이를 준수할 의무가 있으며 기타
            “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다.
          </li>

          <li>“시설회원”의 계정에 관한 관리 책임은 “시설회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</li>

          <li>
            “회사”는 본조 제1항, 제2항 및 제1항의 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영 정책에서 정할 수
            있으며, 회원은 이를 준수할 의무가 있습니다.
            <ul>
              <li>“시설 회원”의 “ID”, “비밀번호”의 정함에 대한 제한</li>

              <li>게시판 이용 등에 대한 제한</li>

              <li>
                기타 “시설 회원”의 “서비스” 이용에 대한 본질적인 권리를 침해하지 않는 범위 내에서 “회사”가 운영상 필요하다고
                인정되는 사항
              </li>
            </ul>
          </li>
        </ol>

        <h4 className="chapter_sub_title">제30조 (정보의 제공 및 광고의 게재)</h4>

        <ol>
          <li>
            “회사”는 “시설회원”이 “서비스” 이용 중 필요하다고 인정되는 거래 관련 정보, 회원 문의 등에 대한 회신 등의 정보를
            공지사항, 전자우편, SMS 등의 방법으로 회원에게 제공할 수 있습니다.
          </li>
          <li>
            “회사”는 관계 법령에 따라 적법한 절차를 통해 “시설회원”에게 광고를 제공할 수 있고, “회사“는 “서비스 페이지” 화면 및
            “모바일 APP” 화면에 광고를 게재할 수 있습니다.
          </li>
          <li>광고 활동으로 발생하는 수익은 모두 “회사”의 수익이며 별도로 “시설회원”에게 공유하지 않습니다.</li>
          <li>
            “회사” 및 “시설회원”은 “서비스회원”의 동의를 얻어 영리목적의 광고성 이메일 및 SMS, DM 등을 발송하거나 별도의 안내 권유
            전화를 할 수 있습니다. “서비스회원”이 명백히 수신거부의사를 표시할 경우 “회사” 및 “시설회원”은 이를 즉시 중지하여야
            합니다.
          </li>
          <li>
            “시설회원”이 “서비스”상에 게재되어 있는 광고를 이용하거나 “서비스”를 통한 광고주의 판촉 활동에 따른 상품을 이용할
            경우, 이는 전적으로 “시설회원” 과 광고주 간의 법률 관계이므로 그로 인해 발생한 분쟁 등의 문제는 “시설회원”과 광고주가
            직접 해결하여야 하며, 이와 관련하여 “회사”는 어떠한 책임도 지지 않습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제31조 (마케팅 및 상품 판매 활동)</h4>

        <ol>
          <li>
            “회사”는 “시설회원”에게 상품 판매 활동을 할 수 있으며 관련 정보를 공지사항, 배너(서비스 페이지, 모바일 APP 등), SMS,
            전자우편 등의 방법으로 “시설회원”에게 제공할 수 있습니다.
          </li>
          <li>
            “회사”는 “시설회원”에게 별도의 사전동의 없이 상품판매 활동을 할 수 있으며 판매활동의 결과 및 과정은 공유하지 않습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제32조 (결제 서비스)</h4>

        <ol>
          <li>
            “회사”는 “시설회원”과 “서비스회원” 사이에 이루어지는 거래의 편리성을 증진시키는 도구로서 온라인(모바일 포함) 결제
            서비스를 제공할 수 있습니다.
          </li>
          <li>
            “시설회원”이 이용하는 결제 서비스의 결제 후 보완 서비스(CS)는 “시설회원”의 책임으로 진행됩니다. 따라서 “서비스회원”과
            “시설회원”간에 성립된 거래의 진실성이나 서비스의 완전 제공 등의 책임은 거래 당사자들인 “서비스회원”과 “시설회원”
            스스로가 부담하여야 하며, “회사”는 책임지지 않습니다.
          </li>
          <li>
            제 28조에 따라 “서비스”를 통한 광고주의 판촉 활동에 따른 상품을 결제하는 경우, 이는 전적으로 “시설회원”과 광고주 간의
            법률관계 이므로 그로 인해 발생한 분쟁 등의 문제는 “시설회원”과 광고주가 직접 해결하여야 하며 이와 관련하여 “회사”는
            어떠한 책임도 지지 않습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제33조 (연결 “웹사이트“와 피연결 “웹사이트“간의 관계)</h4>

        <ol>
          <li>
            상위 “연결“과 하위 피연결 ”웹사이트”가 하이퍼링크 (예: 문자, 그림 및 동영상 등이 포함됨)방식으로 연결된 경우, 전자를
            연결 “웹사이트”라 하고 후자를 피연결 “웹사이트“라고 합니다.
          </li>
          <li>
            연결 “웹사이트“는 피연결 ”웹사이트“가 독자적으로 제공하는 재화 등에 의하여 “서비스회원“과 “시설회원“이 행하는 거래에
            대해서는 책임 지지 않습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제34조 (서비스 이용책임)</h4>

        <p>
          “시설회원”은 “회사”와 적법한 권한이 있는 자로부터 구체적으로 명시된 사전 서면 승인을 받은 경우를 제외하고는 “서비스”를
          이용하여 상품, 서비스를 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌이 광고, 음란사이트 등을 통한 상업행위나 상용
          S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 제재 또는 처벌 등 법적
          조치 등에 관해서는 ”회사”가 책임을 지지 않습니다.
        </p>

        <h4 className="chapter_sub_title">제35조 (서비스 이용 관련 분쟁 해결)</h4>

        <ol>
          <li>
            “회사”는 “시설회원”이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 별도의 고객센터를
            설치/운영 합니다.
          </li>
          <li>
            “회사”는 “시설회원”으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거쳐
            처리합니다. 단, 처리 시 일정기간이 소요될 경우 회원에게 그 사유와 처리 일정을 즉시 통보합니다.
          </li>
          <li>
            “회사”와 “시설회원” 간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제 15조에 의하여 설치된
            전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제36조 (본 약관에서 정하지 않은 사항)</h4>

        <p>본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다.</p>

        <h4 className="chapter_sub_title">제37조 (면책조항)</h4>
        <ol>
          <li>
            “시설회원”과 “회사” 양 당사자는 자연재해, 전쟁 또는 테러, 폭동, 노사문제, 정부 조치, 인터넷 장애 및 서비스 거부(DoS)
            공격 등과 같은 당사자의 통제력을 벗어난 조건으로 인해 발생한 약관 불이행에 대해서는 책임을 지지 않습니다.
          </li>
          <li>“회사”는 “시설회원”의 귀책사유로 인한 “서비스” 이용 장애에 대하여 책임을 지지 않습니다.</li>
          <li>
            “회사”는 “시설회원”이 “서비스”를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여
            책임을 지지 않습니다.
          </li>
          <li>
            “회사”는 “시설회원”이 “서비스”에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.
          </li>
          <li>
            “회사”의 귀책사유로 “시설회원“에게 별도 손해가 발생한 경우에도 “회사”는 “시설회원”이 “회사”의 책임을 묻는 사건이
            발생하기 전 12개월 동안 “시설회원”이 지불한 금액보다 많은 금액을 배상하지 않습니다.
          </li>
          <li>
            “회사”의 귀책사유로 “시설회원”에게 별도 손해가 발생한 경우에도 “회사”는 “시설회원”이 “회사”의 책임을 묻는 사건이
            발생하기 전 12개월 동안 “시설회원”이 지불한 금액보다 많은 금액을 배상하지 않습니다.
          </li>
        </ol>

        <h4 className="chapter_sub_title">제38조 (재판권 및 준거법)</h4>

        <ol>
          <li>본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국 관련법령 및 상관례에 따릅니다.</li>
          <li>
            본 약관에 의한 서비스 이용과 관련한 제반 분쟁 및 소송은 “시설회원”의 주소를 관할하는 법원에 의하고, 주소가 없는
            경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
            거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
          </li>
        </ol>
      </section>

      <section className="footer">
        <p>본 약관은 2022년 12월 1일부터 시행합니다.</p>
      </section>
    </Container>
  );
};

export default React.memo(TermsOfServiceDetail);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px auto 0;
  padding-bottom: 120px;

  /** 리스트 스타일 */
  ol {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 20px;

    li {
      list-style: decimal;
    }
  }

  ul {
    padding-left: 14px;

    li {
      list-style-type: lower-alpha;
    }
  }

  /** 참고 문구 */
  p > em {
    font-style: italic;
  }

  /** 테이블 */
  td,
  th {
    padding: 4px 10px;
    border: 1px solid #000;
    font-size: 14px;
    line-height: 22px;
  }

  /** 메인 타이틀 */
  .title {
    font-size: 16px;
    font-weight: 700;
  }

  /** 각 장간 거리 */
  .chapter {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  /** 각 장 타이틀 */
  .chapter_title {
    font-size: 18px;
    font-weight: 400;
  }

  /** 각 조 타이틀 */
  .chapter_sub_title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
  }

  /** 본문 */
  p,
  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`;
