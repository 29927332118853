import BookingAvailableSetting from 'pages/Booking/components/AvailableTime/BookingAvailableSetting';
import CheckInAvailableSetting from 'pages/Booking/components/AvailableTime/CheckInAvailableSetting';
import Repeat from 'pages/Booking/components/Repeat';
import { AllPageModeType } from 'pages/Booking/types';

import GroupBookingAvailableSetting from './Operation/Create/GroupBookingAvailableSetting';
import GroupCheckInAvailableSetting from './Operation/Create/GroupCheckInAvailableSetting';
import OthersAvailableSetting from './Operation/Create/OthersAvailableSetting';
import UpdateOthersSetting from './Operation/UpdateSingle/UpdateOthersSetting';

type Props = {
  type: string | string[];
  pageMode: AllPageModeType;
};

/** 그룹 수업 폼 > 타입별 설정 페이지 */
const FormDetailSetting = ({ type, pageMode }: Props) => {
  const isUpdateSingle = pageMode === 'update'; // 1개 수정

  switch (type) {
    case 'repeat':
      return <Repeat pageMode={pageMode === 'updateAll' ? 'update' : 'create'} />;
    case 'bookingCancelTime':
      if (isUpdateSingle) {
        return <BookingAvailableSetting />;
      } else {
        return <GroupBookingAvailableSetting />;
      }
    case 'checkInTime':
      if (isUpdateSingle) {
        return <CheckInAvailableSetting />;
      } else {
        return <GroupCheckInAvailableSetting />;
      }
    case 'otherDetailTime':
      if (isUpdateSingle) {
        return <UpdateOthersSetting />;
      } else {
        return <OthersAvailableSetting />;
      }
  }
};

export default FormDetailSetting;
