import { Navigate } from 'react-router-dom';
import localStorage from 'utils/localStorage';

export default function PrivateRoute({ children }: { children: React.ReactNode }) {
  const accessToken = localStorage.get('access_token');
  const onboardingShow = localStorage.get('onboardingShow');
  // 온보딩이 완료되지 않은 경우 온보딩 페이지로 이동
  if (!onboardingShow) {
    return <Navigate to="/onboarding" replace />;
  }

  // 토큰이 있는 경우 접근 허용
  if (accessToken) {
    return <>{children}</>;
  }

  // 토큰이 없는 경우 로그인 페이지로 이동
  return <Navigate to="/intro" replace />;
}
