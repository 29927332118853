import dayjs from 'dayjs';
import useToast from 'hooks/useToast';
import { get } from 'lodash';
import { useState } from 'react';
import PeriodFilter from 'sharedComponents/Filters/PeriodFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { MOBILE_DEFAULT_PERIOD, MOBILE_LIST_TEXT } from '../constants';
import { MobileSelectedFilterType } from '../type';
type Props = FilterProps & {
  maxMonth?: number;
};

const MobilePeriodFilter = ({ currentFilter, selectedOption, onClick: changeOptions, maxMonth = 4 }: Props) => {
  const previousOptions = (selectedOption as MobileSelectedFilterType['period']) || MOBILE_DEFAULT_PERIOD;
  const [date, setDate] = useState([previousOptions.start, previousOptions.end]);

  const setFixedPeriod = (option: FilterOptionsType) => {
    const unit = option.value === 'day' || option.value === 'isoWeek' ? option.value : 'month';
    const startDate = filters.dateDash(dayjs().startOf(unit));
    const endDate = filters.dateDash(dayjs().endOf(unit));
    setDate([startDate, endDate]);
    changeOptions(
      { value: option.value ? { type: option.value as string, start: startDate, end: endDate } : undefined, label: '' },
      'period',
    );
  };

  const { setToast } = useToast();

  const saveCustomPeriod = (period: string[]) => {
    if (dayjs(period[0]).isBefore(dayjs(period[1]).subtract(maxMonth, 'month'))) {
      setToast({ message: MOBILE_LIST_TEXT.overPeriod(maxMonth), bottom: 92 });
      setDate([previousOptions.start, previousOptions.end]); // 기간선택 초기화
      return true;
    }
    const dateFormatted = period.map(filters.dateDash);
    setDate(dateFormatted);
    changeOptions({ value: { type: 'custom', start: dateFormatted[0], end: dateFormatted[1] }, label: '' }, 'period');
    return false;
  };

  return (
    <PeriodFilter
      description={MOBILE_LIST_TEXT.periodDescription(maxMonth)}
      currentFilter={currentFilter}
      selectedOption={get(selectedOption, 'type')}
      onClick={setFixedPeriod}
      onSave={saveCustomPeriod}
      initialDate={date}
    />
  );
};

export default MobilePeriodFilter;
