import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Icon from 'components/Icon';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MESSAGE_DETAIL_TEXT } from '../constants';

type Props<T extends AxiosResponse> = {
  deleteMutate: UseMutateFunction<T>;
  type?: 'mobile' | 'push';
};

/**
 * 메세지 상세 우측 상단 더보기 메뉴
 * 예약취소 / 이미 발송된 메세지 삭제
 */
const DetailDeleteMenu = <T extends AxiosResponse>({ deleteMutate, type }: Props<T>) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const deleteMobileMessage = () => {
    deleteMutate(undefined, {
      onSuccess: () => {
        setToast({ type: 'success', message: MESSAGE_DETAIL_TEXT.delete.success });
        navigate(`/more-details/message${type ? `?tab=${type}` : ''}`, { replace: true });
      },
      onError: error => {
        errorDialog.open(error);
      },
      onSettled: () => {
        setIsDialogOpened(false);
      },
    });
  };

  return (
    <>
      <Button
        size="medium48"
        widthSize={128}
        fontSize={15}
        fontWeight={500}
        padding={{ left: 16, right: 12 }}
        gap={32}
        textColor="secondary3"
        rightIcon={<Icon name="delete" size={16} color="secondary3" />}
        onClick={() => setIsDialogOpened(true)}>
        {MESSAGE_DETAIL_TEXT.delete.button}
      </Button>
      {isDialogOpened && (
        <Dialog
          onClose={() => setIsDialogOpened(false)}
          negativeAction={{ text: MESSAGE_DETAIL_TEXT.delete.cancel, onClick: () => setIsDialogOpened(false), style: 'outlined' }}
          positiveAction={{ text: MESSAGE_DETAIL_TEXT.delete.ok, onClick: deleteMobileMessage }}
          title={MESSAGE_DETAIL_TEXT.delete.dialogTitle}>
          <DialogDescription>{MESSAGE_DETAIL_TEXT.delete.dialogDescription}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default DetailDeleteMenu;
