import DayTimePicker, { DayTimePickerProps } from 'designedComponents/Pickers/DayTimePicker';
import RuleTypeAccordion from 'pages/Booking/components/AvailableTime/RuleType/RuleTypeAccordion';
import { RuleTypeContainer } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { GroupRuleTypeCommonProps } from 'pages/Booking/Group/types';
import { numToTimeColon } from 'pages/Booking/utils/convertTimes';

import ATypeAccordionHeader from './header/ATypeAccordionHeader';

type Props = GroupRuleTypeCommonProps & {
  endDays: number;
  endTime: number;
  changeDate: DayTimePickerProps['onChange'];
};

/** 수업일 기준 - 마감시간  */
const AType = ({ iconName, isOpen, onToggle, textType, endDays, endTime, changeDate }: Props) => {
  return (
    <RuleTypeContainer className={`rule-type A ${textType}`}>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={isOpen}
        isLast={textType === 'cancel'}
        noBottomBorder={textType === 'booking'}
        onChange={() => onToggle(!isOpen)}
        header={<ATypeAccordionHeader isOpen={isOpen} textType={textType} endDays={endDays} endTime={endTime} />}>
        <DayTimePicker endValues={{ day: endDays, time: numToTimeColon(endTime) }} onChange={changeDate} />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default AType;
