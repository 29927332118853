import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { PERMISSION } from 'constants/permission';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { MemberFormType } from 'pages/MemberForm/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { CardWrapper } from 'sharedComponents/InfoTypography';
import TicketCard from 'sharedComponents/TicketCard';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';

import { MemberFormTicketWrapper } from '../Tickets/TicketsList';

const UserTickets = () => {
  const { control, setValue } = useFormContext<MemberFormType>();
  const userTickets = useWatch({ control: control, name: 'userTickets' });

  const { hasPermission } = usePermission();
  const canUpdateUserTicket = hasPermission(PERMISSION.ticket.edit);

  const { getAllSearchParams, setSearchParams } = useQueryString();

  const clickUpdate = (userTicket: UserTicketResponse) => {
    const { id, ticket } = userTicket;
    const productType = ticket.available_class_type === 'I' ? 'item' : 'userTicket';

    const selectedUpdatedTicket = {
      ...userTicket,
      userTicketId: id,
    };

    setValue('userTickets', userTickets);
    setValue('selectedUpdatedTicket', selectedUpdatedTicket);

    setSearchParams({
      ...getAllSearchParams(),
      type: 'product',
      productMode: 'update',
      userTicketId: `${id}`,
      productType,
      step: '2',
    });
  };

  if (!userTickets.length) {
    return <></>;
  }

  return (
    <Container className="user-ticket-list" canUpdateUserTicket={canUpdateUserTicket}>
      {userTickets.map(userTicket => {
        return (
          <MemberFormTicketWrapper className="user-ticket-card" key={userTicket.id}>
            <TicketCard ticket={formatSmallUserTicketCard(userTicket)} />
            {canUpdateUserTicket && (
              <ButtonGroup gap={4}>
                <Button
                  padding={{ left: 8, right: 8 }}
                  widthSize={64}
                  size="small"
                  variant="outlined"
                  textColor="gray2"
                  onClick={() => clickUpdate(userTicket)}>
                  수정
                </Button>
              </ButtonGroup>
            )}
          </MemberFormTicketWrapper>
        );
      })}
    </Container>
  );
};

export default UserTickets;

const Container = styled(CardWrapper)<{ canUpdateUserTicket: boolean }>`
  padding: 8px 20px 20px;

  ${({ canUpdateUserTicket }) =>
    !canUpdateUserTicket &&
    css`
      .user-ticket-card {
        padding: 4px 20px;
      }
    `}
`;
