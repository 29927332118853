import { useIsMutating } from '@tanstack/react-query';
import usePostUserTicketTransfer from 'hooks/service/mutations/usePostUserTicketTransfer';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_TRANSFER_TEXT } from 'pages/TicketDetail/constants';
import { TicketTransferFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import getPaymentFormParams from 'utils/getPaymentFormParams';

type Props = UserTicketProps & {
  productTypeText: string;
};

const SubmitButton = ({ userTicket, productTypeText }: Props) => {
  const { handleSubmit, control } = useFormContext<TicketTransferFormType>();
  const selectedMember = useWatch({ control, name: 'selectedMember' });

  const isMutating = useIsMutating() > 0;
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const navigate = useNavigate();
  const { mutate: transferMutate } = usePostUserTicketTransfer(userTicket.id);

  const submit = async (values: TicketTransferFormType) => {
    const { selectedMember, installment, payment, count, period } = values;
    if (!selectedMember) return;

    const transfer_amount = payment.amount;
    const before_user_ticket_amount = userTicket.payments.total_amount;

    const { amount, ...paymentParams } = getPaymentFormParams({ payment, installment });

    const params = {
      ...count,
      ...period,
      ...paymentParams,
      user_id: userTicket.user_id,
      transferor_id: selectedMember.id,
      transfer_amount,
      before_user_ticket_amount,
    };

    transferMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: `${productTypeText}${TICKET_TRANSFER_TEXT.successMessage}` });
        navigate(`/member/detail/${selectedMember.id}`);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <FooterButton onClick={handleSubmit(submit)} disabled={isMutating || !selectedMember}>
      수강권 양도
    </FooterButton>
  );
};

export default SubmitButton;
