import styled from '@emotion/styled';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { BookingCommonFormType, PageModeType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

type Props = {
  pageMode: PageModeType;
  /** 룸설정 기능 사용 시설 여부 */
  isUseRooms?: boolean;
};

const BookingRangeTime = ({ pageMode, isUseRooms }: Props) => {
  const { control, setValue } = useFormContext<BookingCommonFormType & { isChangeTime?: boolean }>();
  const startTime = useWatch({ control, name: 'startTime' });
  const endTime = useWatch({ control, name: 'endTime' });

  const changeTime = (value: [Date, Date]) => {
    if (pageMode === 'update' && !!isUseRooms) {
      setValue('isChangeTime', true, { shouldDirty: true });
      setValue('roomId', null, { shouldDirty: true });
      setValue('selectedRoom', undefined, { shouldDirty: true });
    }

    setValue('startTime', value[0], { shouldDirty: true });
    setValue('endTime', value[1], { shouldDirty: true });

    /** 시작시간이 종료시간 보다 늦거나 시작시간과 종료시간이 같은 경우 */
    if (dayjs(value[0]).isAfter(value[1]) || dayjs(value[0]).isSame(value[1])) {
      const add30Min = dayjs(value[0]).add(30, 'minute').toDate();
      setValue('endTime', add30Min, { shouldDirty: true });
    }
  };

  return (
    <StyledAccordion iconName="time" header={filters.timePeriod(startTime, endTime)} hasValue={!!startTime}>
      <RangeTimePicker showAddTimeButton stepMinute={5} timeRange={[startTime, endTime]} onChange={changeTime} />
    </StyledAccordion>
  );
};

const StyledAccordion = styled(FormFieldAccordion)`
  .range-time-picker {
    padding-bottom: 24px;
  }
`;

export default BookingRangeTime;
