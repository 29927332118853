import dayjs from 'dayjs';
import { PointPeriodParamsType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { pointFilterPeriodAtom } from 'recoil/MemberDetail';
import { FilterProps } from 'sharedComponents/Filters/types';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

type Props = Pick<FilterProps, 'onClick'> & {
  previousOptions: PointPeriodParamsType;
};

const PeriodSelectCustom = ({ previousOptions, onClick: changeOptions }: Props) => {
  const [date] = useState([previousOptions.start_date, previousOptions.end_date]);
  /** 선택한 기간에 따라 강사 목록 호출용도 */
  const setTempPeriod = useSetRecoilState(pointFilterPeriodAtom);

  const save = (period: string[]) => {
    const value = {
      type: 'custom',
      start_date: filters.dateDash(period[0]),
      end_date: filters.dateDash(period[1]),
    };
    setTempPeriod(value);
    changeOptions({ value, label: '' }, 'period');

    // 필터 내에서 날짜 변경하면 기존 선택한 강사 리셋
    changeOptions({ value: undefined, label: '' }, 'selectedStaffs');
    return false;
  };

  return <PeriodPicker initialDate={date} max={dayjs().toString()} onSave={save} />;
};

export default PeriodSelectCustom;
