import useInfinityUserTicketLecture from 'hooks/service/queries/useInfinityUserTicketLecture';
import UserTicketList from 'pages/Booking/SelectMember/UserTicketList';
import { BookingSelectMemberFormType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  currentLectureId: number;
};

const List = ({ currentLectureId }: Props) => {
  const { control, getValues } = useFormContext<BookingSelectMemberFormType>();
  const search = useWatch({ control, name: 'search' });
  const { startDate, endDate } = getValues();

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityUserTicketLecture({
    lectureId: currentLectureId,
    start_date: startDate,
    end_date: endDate,
    search,
  });

  return <UserTicketList userTickets={data} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage} />;
};

export default List;
