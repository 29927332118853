import { useRecoilState } from 'recoil';
import { toastAtom } from 'recoil/toast';

const useToast = () => {
  const [toast, setToast] = useRecoilState(toastAtom);

  return { toast, setToast };
};

export default useToast;
