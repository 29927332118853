import { useSuspenseQuery } from '@tanstack/react-query';
import { MemberGradeType } from 'pages/Member/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 시설에 설정된 회원 등급 목록 조회 */
const useGetMemberGrade = (enabled = true) => {
  const { queryFn } = useQueryFnWithCommonParams<MemberGradeResponse>({
    url: `/api/member/grade`,
  });

  return useSuspenseQuery({
    queryKey: ['member-grade', enabled], // 회원 목록 변동에 영향을 받지 않아도 됨
    queryFn: () => (enabled ? queryFn() : null),
    select: data => data?.data,
  });
};

export default useGetMemberGrade;

type MemberGradeResponse = Array<MemberGradeType>;
