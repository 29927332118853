import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Radio from 'components/Radio';
import { useState } from 'react';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';

import { ScheduleCheckinParamType } from '../../type';
import { changeNearestFiveMinutes } from '../../util';
import CheckTimePicker from './CheckinTimePicker';

const CheckinTimeFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const prevSelectedOption = selectedOption as ScheduleCheckinParamType['classTime'];
  const [searchTime] = useState(
    prevSelectedOption
      ? [prevSelectedOption?.start, prevSelectedOption?.end]
      : [changeNearestFiveMinutes(), changeNearestFiveMinutes()],
  );

  const changeTimeOption = (value: string) => {
    const targetValue = prevSelectedOption?.type === value ? 'lectureTime' : value;
    changeOptions(
      {
        value: targetValue ? { type: targetValue, start: searchTime[0], end: searchTime[1] } : undefined,
        label: targetValue || '',
      },
      'classTime',
    );
  };

  return (
    <Container>
      <FilterTitle title={currentFilter.title} />
      <RadioSection>
        <Radio
          id="all"
          name="time"
          value={undefined}
          checked={!prevSelectedOption}
          label="전체"
          onChange={e => changeTimeOption(e.target.value)}
        />
        <Radio
          id="lectureTime"
          name="time"
          value="lectureTime"
          checked={prevSelectedOption?.type === 'lectureTime' || prevSelectedOption?.type === 'timeRange'}
          label="시간설정"
          onChange={e => changeTimeOption(e.target.value)}
        />
      </RadioSection>
      <TimePickerSection>
        <CheckTimePicker
          initialTime={searchTime}
          prevSelectedOption={prevSelectedOption}
          startDateDisabled={!prevSelectedOption}
          endDateDisabled={!prevSelectedOption}
          onSave={changeOptions}
        />
      </TimePickerSection>
      <RangeSearchSection>
        <CheckBox
          id="timeRange"
          variant="simple"
          label="범위로 검색"
          labelFontsize={15}
          value="timeRange"
          gap={2}
          checked={prevSelectedOption?.type === 'timeRange'}
          onChange={e => changeTimeOption(e.target.value)}
        />
      </RangeSearchSection>
    </Container>
  );
};

export default CheckinTimeFilter;

const Container = styled(FilterWrap)`
  .filter-option-button-wrapper {
    flex-wrap: wrap;
    margin-top: 12px;
    background-color: ${theme.color.white};
  }
`;

const RadioSection = styled.section`
  & :not(:last-child) {
    padding-bottom: 12px;
  }
`;

const TimePickerSection = styled.section`
  padding: 15px 0px 12px;
`;

const RangeSearchSection = styled.section``;
