import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 프라이빗 수업 1개 삭제 */
const useDeleteLecturePrivate = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/lecture/private/${lectureId}`,
  });

  return useMutation({
    mutationFn,
    mutationKey: ['lecture', 'delete', 'private', lectureId],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default useDeleteLecturePrivate;
