import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useInfinityPayment from 'hooks/service/queries/useInfinityPayment';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { ComponentProps } from 'react';
import { useRecoilValue } from 'recoil';
import { paymentSelectedFilterAtom } from 'recoil/MemberDetail';
import { DEFAULT_PERIOD } from 'sharedComponents/Filters/constants';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import getPaymentDetail from 'utils/getPaymentDetail';

import PaymentDetailCard from './PaymentDetailCard';
import PaymentSummary from './PaymentSummary';

const PaymentList = ({ currentMemberId }: CurrentMemberIdProps) => {
  const selectedFilter = useRecoilValue(paymentSelectedFilterAtom);
  const period = selectedFilter?.period ?? DEFAULT_PERIOD;

  const {
    data: { data, total_credit, unpaid_credit },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityPayment({
    user_id: currentMemberId,
    start_date: period.start,
    end_date: period.end,
  });

  return (
    <>
      <PaymentSummary paymentSummary={{ totalAmount: total_credit, unpaidAmount: unpaid_credit }} />

      <Container>
        {!data.length ? (
          <NodataTypography>결제내역이 없습니다.</NodataTypography>
        ) : (
          <InfiniteContainer>
            <InfiniteScroll showMoreButton hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage} gap={12}>
              {data.map(payment => {
                const { settlement_at, user_ticket } = payment;

                const { data: paymentDetail } = getPaymentDetail(payment);
                paymentDetail.unshift({
                  label: '수강권명',
                  value: user_ticket.ticket.title,
                });

                const data: ComponentProps<typeof PaymentDetailCard>['data'] = {
                  settlementAt: settlement_at,
                  detail: paymentDetail,
                };
                return <PaymentDetailCard key={payment.id} data={data} />;
              })}
            </InfiniteScroll>
          </InfiniteContainer>
        )}
      </Container>
    </>
  );
};

export default PaymentList;

const Container = styled.div`
  background-color: ${theme.color.gray6};
  flex: 1;
`;

const InfiniteContainer = styled.div`
  padding: 20px;

  .more-button {
    margin-top: 12px;
  }
`;
