import styled from '@emotion/styled';
import { MutateOptions, useIsMutating } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import BookingFailList from 'pages/Booking/components/AfterRequestBooking/BookingFailList';
import { FailBookingContainer } from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { useNavigate } from 'react-router-dom';
import LeftSmallButtonGroup from 'sharedComponents/LeftSmallButtonGroup';
import MainLayout from 'sharedComponents/MainLayout';

type Props<T, E = unknown, D = unknown> = {
  fails: Array<BookingFailResponse>;
  onSubmit: (options: MutateOptions<AxiosResponse<T>, AxiosError<E> | Error, D>) => void;
};

/** 그룹/프라이빗 모든 수업 수정 요청 후 1개라도 실패 에러 있으면 노출 */
const FailUpdateAllBooking = <T, E, D>({ onSubmit, fails }: Props<T, E, D>) => {
  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const errorDialog = useErrorDialog();
  const isPending = useIsMutating() > 0;

  const closePopup = () => {
    setPopup(null);
  };

  const submit = () => {
    onSubmit({
      onSuccess: () => {
        navigate(-1);
        closePopup();
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={closePopup}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <Container>
        <Icon size={54} name="errorFill" color="gray4" />
        <Typography size={19} weight={700}>
          <Typography size={19} weight={700} textColor="error" span>
            {fails.length}
          </Typography>
          개의 수업과 일정이 중복됩니다. <br />
          계속 하시겠습니까?
        </Typography>

        <BookingFailList fails={fails} />

        <StyledButtonGroup
          leftButton={{
            text: '돌아가기',
            disabled: isPending,
            onClick: closePopup,
          }}
          rightButton={{
            text: '계속',
            disabled: isPending,
            onClick: submit,
          }}
        />
      </Container>
    </MainLayout>
  );
};

export default FailUpdateAllBooking;

const Container = styled(FailBookingContainer)`
  height: 100%;
`;

const StyledButtonGroup = styled(LeftSmallButtonGroup)`
  position: fixed;
  bottom: 0;
  left: 0;
`;
