import useGetEtcScheduleEtcScheduleTime from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const NoAllDayDetail = ({ currentEtcScheduleId }: Props) => {
  const { data } = useGetEtcScheduleEtcScheduleTime(currentEtcScheduleId);
  const { canUpdateEtcSchedule, canDeleteEtcSchedule } = useCheckPermissionEtcDetail(data.staffs);

  return (
    <DetailMain
      currentEtcScheduleId={currentEtcScheduleId}
      data={data}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default NoAllDayDetail;
