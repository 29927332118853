import { MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useCheckPermissionBookingDetail from 'hooks/useCheckPermissionBookingDetail';
import { DropdownType } from 'pages/Booking/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import BookingDetailDropDown from './BookingDetailDropDown';
import BookingOpenCloseDialog from './BookingOpenCloseDialog';
import Bookings from './Bookings';
import ColoredDetailInfo from './ColoredDetailInfo';
import LectureDelete from './LectureDelete';
import LectureUpdateListDrawer from './LectureUpdateListDrawer';

type Props = {
  currentLectureId: number;
};

const DetailMain = ({ currentLectureId }: Props) => {
  const { data, isError } = useGetLectureDetail(currentLectureId);
  const bookingType = data.type === 'G' ? 'group' : 'private';
  const { canUpdateLecture, canDeleteLecture, canCancelBooking, canChangeBooking } = useCheckPermissionBookingDetail(
    data.staff.id,
    bookingType,
  );

  const navigate = useNavigate();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState<'open' | 'close' | null>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState<'update' | 'delete' | null>(null);

  const bgColor = MATCH_REPRESENTATIVE_COLOR[data.staff.representative_color];
  const contrastColor = getContrastTextColor(bgColor) as Color;
  const isPastLecture = dayjs(data.start_on).isBefore();

  const clickDropDownItem = (value: DropdownType['value']) => {
    switch (value) {
      case 'open':
        setOpenCloseDialog('open');
        break;
      case 'close':
        setOpenCloseDialog('close');
        break;
      case 'update':
        if (isPastLecture || data.booking_closed_at) {
          navigate(`/booking/${bookingType}/form/update/${currentLectureId}`);
        } else {
          setIsOpenDrawer('update');
        }
        break;
      case 'copy':
        navigate(`/booking/${bookingType}/form/copy/${currentLectureId}`);
        break;
      case 'delete':
        setIsOpenDrawer('delete');
        break;
    }
    setIsOpenDropDown(false);
  };

  /**
   * 수업 수정 후 그 사이 삭제됐거나 하는 등
   * 수업 상세 데이터 불러오는 중 에러 반환할 때
   */
  useEffect(() => {
    if (!isError) return;
    navigate(-1);
  }, [isError, navigate]);

  return (
    <MainLayout
      header={{
        title: data.title,
        titleScrollThreshold: 40,
        noUnderline: true,
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" color={contrastColor} />
          </IconButton>
        ),
        rightButton:
          !canUpdateLecture && !canDeleteLecture ? undefined : (
            <BookingDetailDropDown
              contrastColor={contrastColor}
              bookingClosedAt={data.booking_closed_at}
              isOpen={isOpenDropDown}
              onToggle={setIsOpenDropDown}
              onClick={clickDropDownItem}
              canUpdateLecture={canUpdateLecture}
              canDeleteLecture={canDeleteLecture}
            />
          ),
        bgColor,
      }}>
      <ColoredDetailInfo currentLecture={data} bgColor={bgColor} contrastColor={contrastColor} />

      <ApiBoundary>
        <Bookings
          currentLecture={data}
          bookingType={bookingType}
          canCancelBooking={canCancelBooking}
          canChangeBooking={canChangeBooking}
        />
      </ApiBoundary>

      <LectureUpdateListDrawer
        currentLectureId={currentLectureId}
        bookingType={bookingType}
        isOpen={isOpenDrawer === 'update'}
        onClose={() => setIsOpenDrawer(null)}
      />

      <LectureDelete
        currentLecture={data}
        bookingType={bookingType}
        isPastLecture={isPastLecture}
        isOpen={isOpenDrawer === 'delete'}
        onClose={() => setIsOpenDrawer(null)}
      />

      {!!openCloseDialog && (
        <BookingOpenCloseDialog
          currentLectureId={currentLectureId}
          openCloseDialog={openCloseDialog}
          onClose={() => setOpenCloseDialog(null)}
        />
      )}
    </MainLayout>
  );
};

export default DetailMain;
