import BookingAvailableField from 'pages/Booking/components/AvailableTime/BookingAvailableField';
import CheckInAvailableField from 'pages/Booking/components/AvailableTime/CheckInAvailableField';
import { UpdateDateTypography } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BookingGroupUpdateFormType } from 'pages/Booking/Group/types';
import { useFormContext } from 'react-hook-form';

import OthersAvailableField from '../OthersAvailableField';

const UpdateOperationAccordionContents = () => {
  const { getValues } = useFormContext<BookingGroupUpdateFormType>();
  const { enter_start_at, daily_change_booking_end_at, booking_auto_shift_available_minutes_from_start } = getValues();

  return (
    <>
      <BookingAvailableField />
      <OthersAvailableField
        dailyChangeTime={<UpdateDateTypography endAt={daily_change_booking_end_at} />}
        autoWaitingTime={<UpdateDateTypography endAt={booking_auto_shift_available_minutes_from_start} />}
      />
      {!!enter_start_at && <CheckInAvailableField />}
    </>
  );
};

export default UpdateOperationAccordionContents;
