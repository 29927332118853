import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose: () => void;
};

/** 프라이빗 폼 > 회원 추가 LeaveDialog */
const SelectMemberLeaveDialog = ({ onClose }: Props) => {
  const { resetField, setValue, watch } = useFormContext<BookingPrivateFormType>();
  const { tempUserTicketIds, tempSelectedUserTickets } = watch();

  const navigate = useNavigate();

  const clickLeave = () => {
    navigate(-1);
    onClose();
    if (!tempUserTicketIds.length) {
      resetField('userTicketIds');
      resetField('selectedUserTickets');
    } else {
      setValue('userTicketIds', [...tempUserTicketIds]);
      setValue('selectedUserTickets', [...tempSelectedUserTickets]);
    }
  };

  return (
    <Dialog onClose={onClose} positiveAction={{ text: '나가기', onClick: clickLeave }} negativeAction={{ text: '취소' }}>
      <DialogDescription>
        변경사항이 저장되지 않았습니다. <br />
        페이지를 나가시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default SelectMemberLeaveDialog;
