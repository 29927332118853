import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import TimePicker from 'components/TimePicker';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';

import NumberPicker from '.';

export type AvailableTimeType = { hour: number; min: number };
export type AvailableTimePickerProps = {
  /** deadline 값 */
  deadline: AvailableTimeType;
  onChangeDeadline: (value: AvailableTimeType) => void;
  /** startline 값 */
  startline?: AvailableTimeType;
  onChangeStartline?: (value: AvailableTimeType) => void;
  /** startline 최대값 */
  startHourMax?: number;
  /** deadline 최대값 */
  deadHourMax?: number;
};

const AvailableTimePicker = ({
  startHourMax,
  deadHourMax,
  deadline,
  startline,
  onChangeDeadline,
  onChangeStartline,
}: AvailableTimePickerProps) => {
  const endMax = useMemo(() => {
    if (!startline) return;
    if (deadHourMax) return deadHourMax;
    if (startline.hour === 0) return 0;
    if (startline.min === 0) return startline.hour - 1;
    return startline.hour;
  }, [deadHourMax, startline]);

  useEffect(() => {
    if (!startline || !onChangeStartline || startHourMax || deadHourMax) return;

    if (startline.hour < deadline.hour) {
      onChangeStartline({
        hour: deadline.hour + 12,
        min: 0,
      });
    }

    if (startline.hour === deadline.hour && startline.min < deadline.min) {
      onChangeDeadline({
        ...deadline,
        min: startline.min,
      });
    }
  }, [deadHourMax, deadline, onChangeDeadline, onChangeStartline, startHourMax, startline]);

  return (
    <Container className="available-time-picker" hasStartLine={!!startline}>
      {startline && onChangeStartline && (
        <div className="time-wrapper startline">
          <NumberPicker
            hasZero
            min={0}
            max={startHourMax ?? 99}
            defaultSelection={startline.hour}
            onChange={e =>
              onChangeStartline({
                hour: e.value,
                min: startHourMax === e.value ? 0 : startline.min,
              })
            }
          />

          <TimePicker
            timeFormat="mm"
            value={dayjs().minute(startline.min).toDate()}
            onChange={e =>
              onChangeStartline({
                ...startline,
                min: startHourMax === startline.hour ? 0 : dayjs(e.value).minute(),
              })
            }
          />
        </div>
      )}
      <div className="time-wrapper deadline">
        <NumberPicker
          hasZero
          min={0}
          max={endMax ?? 99}
          defaultSelection={deadline.hour}
          onChange={e =>
            onChangeDeadline({
              hour: e.value,
              min: deadHourMax === e.value ? 0 : deadline.min,
            })
          }
        />
        <TimePicker
          timeFormat="mm"
          value={dayjs().minute(deadline.min).toDate()}
          onChange={e =>
            onChangeDeadline({
              ...deadline,
              min: deadHourMax === deadline.hour ? 0 : dayjs(e.value).minute(),
            })
          }
        />
      </div>
    </Container>
  );
};

export default AvailableTimePicker;

const Container = styled(CommonWheelStyles)<{ hasStartLine: boolean }>(
  css`
    background-color: ${theme.color.white};

    /** 공통 스타일 */
    .time-wrapper {
      ${theme.flex()};
    }

    .number-picker,
    .time-picker {
      flex: 1;

      /** selected bar */
      .mbsc-scroller-wheel-line {
        margin: 0;
        border-radius: 0;
      }
    }

    .number-picker {
      flex: 1;

      /** 왼쪽(시작 값) NumberPicker */
      .mbsc-scroller-wheel-item {
        margin-left: auto;
        width: 32px;
        margin-right: 16px;
      }

      .mbsc-scroller-wheel-line {
        margin-left: 8px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    /** 오른쪽(종료 값) TimePicker */
    .time-picker {
      .mbsc-scroller-wheel-group {
        justify-content: flex-start;
      }

      .mbsc-datetime-minute-wheel {
        flex: 1;
      }

      .mbsc-scroller-wheel-item {
        width: 32px;
        margin-left: 16px;
      }

      .mbsc-scroller-wheel-line {
        margin-right: 8px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        /** 시간과 분 사이 콜론(:) */
        &::before {
          left: 1px;
        }
      }
    }
  `,

  /** 시작 선택 값 있을 때 */
  ({ hasStartLine }) =>
    hasStartLine &&
    css`
      ${theme.flex()};

      .time-wrapper {
        flex: 1;

        /** 가운데 세로 구분선 */
        &.deadline {
          border-left: 1px solid ${theme.color.gray8};
        }
      }
    `,
);
