import FloatingActionButton, { ActionsType } from 'components/FloatingActionButton';
import { PERMISSION } from 'constants/permission';
import dayjs from 'dayjs';
import usePermission from 'hooks/usePermission';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom, currentDateAtom, currentPeriodAtom } from 'recoil/schedule';

const ScheduleFloatingButton = () => {
  const currentDate = useRecoilValue(currentDateAtom);
  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const currentPeriod = useRecoilValue(currentPeriodAtom);

  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const canCounselCreate = hasPermission(PERMISSION.counsel.create);

  const selectedDate = useMemo(() => {
    switch (calendarViewType) {
      default:
      case 'day':
      case 'month':
        return currentDate;
      case 'week':
        return dayjs(currentPeriod.startDate).add(1, 'days').toDate();
    }
  }, [calendarViewType, currentDate, currentPeriod.startDate]);

  const goLink = (link: string) => {
    navigate(link, { state: { from: 'floating', selectedDate } });
  };

  const buttonActions: ActionsType[] = canCounselCreate
    ? [
        {
          label: '기타 일정',
          icon: 'calendar',
          onClick: () => goLink('/schedule/etc/form/create'),
        },
        {
          label: '상담',
          icon: 'consulting',
          onClick: () => goLink('/counsel/form'),
        },
        {
          label: '그룹 수업',
          icon: 'groupClass',
          onClick: () => goLink('/booking/group/form/create'),
        },
        {
          label: '프라이빗 수업',
          icon: 'privateClass',
          onClick: () => goLink('/booking/private/form/create'),
        },
      ]
    : [
        {
          label: '기타 일정',
          icon: 'calendar',
          onClick: () => goLink('/schedule/etc/form/create'),
        },
        {
          label: '그룹 수업',
          icon: 'groupClass',
          onClick: () => goLink('/booking/group/form/create'),
        },
        {
          label: '프라이빗 수업',
          icon: 'privateClass',
          onClick: () => goLink('/booking/private/form/create'),
        },
      ];

  return <FloatingActionButton actions={buttonActions} />;
};

export default ScheduleFloatingButton;
