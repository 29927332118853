import styled from '@emotion/styled';
import { ReactComponent as CenterNone } from 'assets/images/center_none.svg';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { Studio } from 'hooks/service/queries/useGetStudio';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

type Props = {
  studios: Studio[];
};

const StudioSelectionRequired = ({ studios }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [currentStudioId, setCurrentStudioId] = useRecoilState(studioIdAtom);
  const setCurrentStaffId = useSetRecoilState(staffIdAtom);

  const { control, watch } = useForm<{ studioId: string }>({
    defaultValues: { studioId: `${currentStudioId}` },
  });

  const selectedStudioId = watch('studioId');
  const selectedStudio = studios.find(studio => studio.id === Number(selectedStudioId));

  useEffect(() => {
    if (!selectedStudio) return;
    setCurrentStudioId(selectedStudio.id);
    setCurrentStaffId(selectedStudio.staff_id);
  }, [selectedStudio, setCurrentStaffId, setCurrentStudioId]);

  return (
    <Container>
      <CenterNone />
      <Typography size={19} weight={700} className="title">
        시설을 선택해 주세요.
      </Typography>
      <Typography size={15} textColor="gray2" className="description">
        앱 사용을 위해 시설을 선택해 주세요.
      </Typography>
      <Button
        padding={{ left: 40, right: 40 }}
        variant="outlined"
        textColor="gray2"
        size="medium48"
        onClick={() => setIsDrawerOpen(true)}>
        시설 선택하기
      </Button>
      <CheckRadioDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        header="시설선택"
        name="studioId"
        data={studios.map(studio => ({
          id: `${studio.id}`,
          label: studio.name,
          value: `${studio.id}`,
        }))}
        control={control}
      />
    </Container>
  );
};

export default StudioSelectionRequired;

const Container = styled.section`
  height: 100%;
  ${theme.flex('column', 'center', 'center')}
  .title {
    margin: 24px 0 14px;
  }
  .description {
    margin-bottom: 32px;
  }
`;
