import Icon from 'components/Icon';

import NotificationIcon from './components/NotificationIcon';
import { BottomNavsType } from './types';

export const bottomNavs: BottomNavsType[] = [
  {
    title: '일정',
    route: '/schedule',
    isSelected: ['/schedule'],
    iconName: 'scheduleFill',
    IconComponent: Icon,
  },
  {
    title: '회원',
    route: '/member',
    isSelected: ['/member'],
    iconName: 'memberFill',
    IconComponent: Icon,
  },
  {
    title: '알림',
    route: '/notification',
    isSelected: ['/notification'],
    iconName: 'alertFill',
    IconComponent: NotificationIcon, // api 호출을 포함한 커스텀 아이콘으로 대체
  },
  {
    title: '라운지',
    route: '/lounge',
    isSelected: ['/lounge'],
    iconName: 'loungeFill',
    IconComponent: Icon,
  },
  {
    title: '더보기',
    route: '/more-details',
    isSelected: ['/more-details'],
    iconName: 'moreFill',
    IconComponent: Icon,
  },
];
