import { AppPushFormType } from '../Form/types';
import { AppPushDetailType } from './types';

export const convertAppPushFormData = (pushDetail: AppPushDetailType): AppPushFormType => {
  const { id, title, message, send_on, targets, filter } = pushDetail;

  return {
    type: 'push',
    mode: 'update',
    isReserve: !!send_on,
    reservationDate: send_on,
    title,
    message,
    target: 'member',
    users: targets,
    counseling_logs: [],
    originalMessage: {
      id,
    },
    filter,
  };
};
