import Divider from 'components/Divider';
import MainLayout from 'sharedComponents/MainLayout';

import CurrentVersion from './components/CurrentVersion';
import SettingBottom from './components/SettingBottom';
import SettingNavigation from './components/SettingNavigation';
import SettingPopupTerms from './components/SettingPopupTerms';

const MoreDetailsSettings = () => {
  return (
    <MainLayout header={{ title: '설정' }}>
      <SettingNavigation />
      <SettingPopupTerms />
      <CurrentVersion />
      <Divider />
      <SettingBottom />
    </MainLayout>
  );
};

export default MoreDetailsSettings;
