import { useMutation } from '@tanstack/react-query';
import delay from 'utils/delay';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MessageMobileParams } from './usePostMessageMobile';

/** 메세지 수정 (예약문자만 수정가능) */
const usePatchMessageMobile = (id?: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<MessageMobileMobileParams>({
    method: `patch`,
    url: `/api/message/mobile/${id}`,
  });

  return useMutation({
    mutationFn: id
      ? async (param: MessageMobileParams) => {
          await delay(2000);
          return mutationFn(param);
        }
      : undefined,
  });
};

export default usePatchMessageMobile;

type MessageMobileMobileParams = MessageMobileParams;
