import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { UserTicketResponse } from '../queries/useInfinityUserTicketProduct';

/** 수강권(상품) 환불 */
const usePatchUserTicketRefund = (userTicketId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<UserTicketRefundParams, UserTicketResponse>({
    method: `patch`,
    url: `/api/userTicket/refund/${userTicketId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePatchUserTicketRefund;

export type UserTicketRefundParams = {
  user_id: number;
  method: string;
  amount: number;
  point_amount: number;
  cash_amount: number;
  card_amount: number;
  wiretransfer_amount: number;
  unpaid_amount: number;
  reward_point: number;
  settlement_at: string;
};
