import { useEffect } from 'react';

const useStatusBarColor = (color: string) => {
  useEffect(() => {
    if (window.ReactNativeWebView && color) {
      const message = {
        type: 'changeStatusBarColor',
        color: color,
      };

      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  }, [color]);
};

export default useStatusBarColor;
