import styled from '@emotion/styled';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import useDeleteHolding from 'hooks/service/mutations/useDeleteHolding';
import { HoldingResponse } from 'hooks/service/queries/useInfinityHolding';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_HOLDING_TEXT } from 'pages/TicketDetail/constants';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';

type Props = UserTicketProps & {
  selectedHolding: HoldingResponse;
};

const HoldingCancelDialog = ({ userTicket, selectedHolding }: Props) => {
  const { setValue } = useFormContext<TicketHoldingFormType>();
  const isAuto = selectedHolding.auto_calculation;

  const errorDialog = useErrorDialog();
  const { setToast } = useToast();
  const { mutate: deleteHoldingMutate } = useDeleteHolding(selectedHolding.id);

  const onClose = () => {
    setValue('selectedHolding', null);
  };

  const initHolding = () => {
    onClose();
    setValue('params.start_on', '');
    setValue('params.end_on', '');
  };

  /** 수강권(or 기간제 상품) 정지 취소 요청 */
  const deleteHolding = () => {
    deleteHoldingMutate(
      { user_ticket_id: userTicket.id },
      {
        onSuccess: () => {
          setToast({
            type: 'success',
            message: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]} ${TICKET_HOLDING_TEXT.successMessage.cancel}`,
          });
          initHolding();
        },
        onError: error => {
          errorDialog.open(error);
        },
      },
    );
  };

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: '취소' }}
      positiveAction={{ text: '확인', onClick: deleteHolding }}
      title={isAuto ? TICKET_HOLDING_TEXT.cancel.title : undefined}>
      <Description tag="pre">{TICKET_HOLDING_TEXT.cancel[isAuto ? 'auto' : 'notAuto']}</Description>
    </Dialog>
  );
};

export default HoldingCancelDialog;

const Description = styled(DialogDescription)`
  margin-top: 16px;
`;
