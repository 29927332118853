import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import usePatchStaffProfile from 'hooks/service/mutations/usePatchStaffProfile';
import usePostAvatar from 'hooks/service/mutations/usePostAvatar';
import useErrorDialog from 'hooks/useErrorDialog';
import usePhoto from 'hooks/usePhoto';
import useQueryString from 'hooks/useQueryString';
import { ChangeEvent, memo, useCallback, useMemo, useRef } from 'react';
import ColorChip from 'sharedComponents/ColorChip';
import InputFile from 'sharedComponents/InputFile';
import { CustomError } from 'types/errorTypes';
import { StaffType } from 'types/staffTypes';
import filters from 'utils/filters';
import getColor, { Color } from 'utils/getColor';
import getImageUrl from 'utils/getImageUrl';

import BirthdaySection from './BirthdaySection';

type Props = {
  staff: StaffType;
};

const StaffProfileCard = ({ staff }: Props) => {
  const { id, name, mobile, account, profile, roles, avatar } = staff;

  const fileRef = useRef<HTMLInputElement>(null);

  const { getSearchParams } = useQueryString();

  const errorDialog = useErrorDialog();
  const { onAddPhoto, onChange } = usePhoto(fileRef, { isProfile: true });
  const { mutate: postAvatarMutate } = usePostAvatar();
  const { mutate: patchStaffProfileMutate } = usePatchStaffProfile({ targetStaffId: id });

  const updateStaffProfile = useCallback(
    (image: string | null) => {
      const params = { staffAvatar: { image } };
      patchStaffProfileMutate(params, {
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    },
    [errorDialog, patchStaffProfileMutate],
  );

  const changeProfile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;

      const params = { file: e.target.files[0] };
      onChange(e);
      postAvatarMutate(params, {
        onSuccess: ({ data }) => {
          updateStaffProfile(data.image);
        },
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    },
    [errorDialog, onChange, postAvatarMutate, updateStaffProfile],
  );

  const formatRegisteredAt = useMemo(() => {
    return `${roles[0].display_name}ㆍ${filters.date(profile.registered_at)} 등록`;
  }, [roles, profile]);

  return (
    <Container>
      <section className="profile-top">
        <div className="avatar-wrapper" onClick={onAddPhoto}>
          <Avatar size={72} imgUrl={getImageUrl(avatar ? avatar.image : null)} />
          <IconButton variant="outlined" widthSize={24} borderRadius="50%" bgColor="white" outlineColor="gray6">
            <Icon name="camera" size={12} />
          </IconButton>
          <InputFile ref={fileRef} onChange={changeProfile} />
        </div>

        <div className="profile-info-wrapper">
          <div className="profile-name">
            <Typography size={19} weight={700}>
              {name}
            </Typography>
          </div>

          <Typography className="mobile-area" size={15} textColor="gray2">
            {filters.mobile(mobile) || '-'}
            {!account && <Icon name="connectOff" size={16} fillColor={theme.color.gray3} />}
          </Typography>

          <div className="role">
            <ColorChip size={8} fillColor={color[getColor(profile.representative_color) as Color]} />
            <Typography size={13} textColor="gray2">
              {formatRegisteredAt}
            </Typography>
          </div>
        </div>
      </section>

      {(profile.birthday || profile.gender) && (
        <BirthdaySection birthday={profile.birthday ? filters.date(profile.birthday) : null} gender={profile.gender} />
      )}
    </Container>
  );
};

export default memo(StaffProfileCard);

const Container = styled.div`
  .profile-top {
    ${theme.flex('row', 'center', '', 16)}
    padding: 16px 20px 20px;

    .avatar-wrapper {
      position: relative;
      width: max-content;

      .icon-button {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .profile-info-wrapper {
      .profile-name {
        ${theme.flex('row', 'center', '', 2)}
      }

      .mobile-area {
        ${theme.flex('row', 'center', 'flex-start')}
      }

      .role {
        ${theme.flex('row', 'center', '', 6)};
        margin-top: 4px;
      }
    }
  }
`;
