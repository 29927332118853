import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

const EtcScheduleCreate = () => {
  return (
    <ApiBoundary>
      <FormMain />
    </ApiBoundary>
  );
};

export default EtcScheduleCreate;
