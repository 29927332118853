import styled from '@emotion/styled';
import { MATCH_REPRESENTATIVE_COLOR, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  member: MemberResponse;
  showRegisteredAt?: boolean;
};

const MemberInfo = ({ member, showRegisteredAt = true }: Props) => {
  const {
    avatar,
    name,
    vaccination_yn,
    mobile,
    account_id,
    profile: { registered_at },
    user_grade,
  } = member;

  return (
    <MemberInfoWrapper>
      <Avatar imgUrl={avatar ? getImageUrl(avatar.image) : undefined} size={40} edge="circular" />
      <div className="detail">
        <Row>
          <TypoWithIcon>
            <Typography span size={15} weight={700} ellipsis={1}>
              {name}
            </Typography>
            {vaccination_yn === 'Y' && <Icon name="vaccinateBold" size={16} fillColor={theme.color.secondary1} />}
          </TypoWithIcon>
          {user_grade && (
            <Label size="small" textColor="white" color={MATCH_REPRESENTATIVE_COLOR[user_grade.representative_color]}>
              {user_grade.name}
            </Label>
          )}
        </Row>
        <Row>
          <TypoWithIcon>
            <Typography span textColor="gray2">
              {filters.mobile(mobile || '') || '-'}
            </Typography>
            {!account_id && <Icon name={'connectOff'} size={16} fillColor={theme.color.gray3} />}
          </TypoWithIcon>
          {showRegisteredAt && (
            <Typography size={13} textColor="gray3">
              {filters.date(registered_at)} 등록
            </Typography>
          )}
        </Row>
      </div>
    </MemberInfoWrapper>
  );
};

export default MemberInfo;

const MemberInfoWrapper = styled.div`
  ${theme.flex('', '', '', 10)};
  .detail {
    flex: 1;
  }
  flex: 1;
`;

const Row = styled.div`
  ${theme.flex('', 'center', 'space-between', 20)};

  flex: 1 0 auto;
  .label {
    max-width: 88px;
    min-width: 32px;
    flex: 0 0 auto;

    .typography {
      ${theme.textEllipsis(1)}
    }
  }
`;

const TypoWithIcon = styled.div`
  ${theme.flex('', 'center', 'initial', 1)};
`;
