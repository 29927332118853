import { useMutation } from '@tanstack/react-query';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostStaff = () => {
  const { mutationFn } = useMutationFnWithCommonParams<StaffParams>({
    method: 'post',
    url: '/api/staff',
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostStaff;

type StaffParams = {
  profile: {
    name: string;
    gender: 'F' | 'M';
    birthday?: string;
    get_noti_all: number;
    registered_at: string;
    representative_color: string;
    self_introduction: string;
    private_start_interval_on_off: boolean;
    private_start_interval_am: 30 | 60;
    private_start_interval_pm: 30 | 60;
    hired_at: string;
  };
  staffAvatar?: { image: string };
  mobile: string;
  role_id: number;
  careers: Array<{ career: string }>;
  schedules: ScheduleType[];
  is_representative: number;
  type_id: number;
};

type ScheduleType = {
  type: ScheduleFormType['type'];
  date: null;
  day_of_week: ScheduleFormType['day_of_week'];
  end_time: string;
  start_time: string;
};
