import { ProductCreateFormType } from 'pages/ProductForm/types';
import getPaymentFormParams from 'utils/getPaymentFormParams';

type Props = {
  tickets: ProductCreateFormType[];
  currentMemberId: number;
  currentStaffId: number;
  currentStudioId: number;
};

const getCreateTicketParams = ({ tickets, currentMemberId, currentStaffId, currentStudioId }: Props) => {
  return tickets.map(values => {
    const {
      ticket,
      detail: { staffs, ...rest },
      period,
      count,
      ...payment
    } = values;

    const paymentParams = getPaymentFormParams({ ...payment });

    return {
      ...rest,
      ...period,
      ...count,
      payment: { ...paymentParams },
      staffs: staffs.length ? staffs.map(({ id }) => id) : undefined,
      user_id: currentMemberId,
      staff_id: currentStaffId,
      studio_id: currentStudioId,
    };
  });
};

export default getCreateTicketParams;
