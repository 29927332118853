import { LecturePrivatePatchParams } from 'hooks/service/mutations/usePatchLecturePrivate';
import filters from 'utils/filters';

import { BookingPrivateUpdateFormType } from '../Private/types';

const formatBookingPrivateUpdateParams = (formValues: BookingPrivateUpdateFormType): LecturePrivatePatchParams => {
  const {
    title,
    startDate,
    endDate,
    startTime,
    endTime,
    booking_start_at,
    booking_end_at,
    booking_cancel_start_at,
    booking_cancel_end_at,
    enter_start_at,
    enter_end_at,
    roomId,
    selectedStaffId,
  } = formValues;

  const start_on = `${filters.dateDash(startDate)} ${filters.timeSecondZero(startTime)}`;
  const end_on = `${filters.dateDash(endDate)} ${filters.timeSecondZero(endTime)}`;

  const result: LecturePrivatePatchParams = {
    title,
    booking_start_at,
    booking_end_at,
    booking_cancel_start_at,
    booking_cancel_end_at,
    enter_start_at,
    enter_end_at,
    room_id: !roomId || roomId === 'notSelected' ? undefined : Number(roomId),
    instructor_id: Number(selectedStaffId),
    start_on,
    end_on,
  };

  return result;
};

export default formatBookingPrivateUpdateParams;
