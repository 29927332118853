import Typography from 'components/Typography';
import {
  PrimaryColorTypography,
  RuleTypeAccordionHeader,
  StyledRuleTypeTimeTypography,
} from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TEXT } from 'pages/Booking/constants';
import { GroupRuleTypeCommonProps } from 'pages/Booking/Group/types';
import { numToTimeColon } from 'pages/Booking/utils/convertTimes';

type Props = Pick<GroupRuleTypeCommonProps, 'isOpen' | 'textType'> & {
  startDays: number;
  startTime: number;
  endDays: number;
  endTime: number;
};

const ARTypeAccordionHeader = ({ isOpen, textType, startDays, startTime, endDays, endTime }: Props) => {
  return (
    <div>
      <RuleTypeAccordionHeader>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{startDays}</PrimaryColorTypography>
          <Typography span size={15}>
            일 전,
          </Typography>
        </StyledRuleTypeTimeTypography>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{numToTimeColon(startTime)}</PrimaryColorTypography>
          <Typography span size={15}>
            부터
          </Typography>
        </StyledRuleTypeTimeTypography>
      </RuleTypeAccordionHeader>
      <RuleTypeAccordionHeader>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{endDays}</PrimaryColorTypography>
          <Typography span size={15}>
            일 전,
          </Typography>
        </StyledRuleTypeTimeTypography>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{numToTimeColon(endTime)}</PrimaryColorTypography>
          <Typography span size={15}>
            {BOOKING_AVAILABLE_TIME_SETTING_TEXT[textType]}
          </Typography>
        </StyledRuleTypeTimeTypography>
      </RuleTypeAccordionHeader>
    </div>
  );
};

export default ARTypeAccordionHeader;
