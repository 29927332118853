import useGetLectureCourse from 'hooks/service/queries/useGetLectureCourse';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import { convertBookingGroupUpdateAllFormState } from 'pages/Booking/utils';
import { useForm } from 'react-hook-form';

import { BookingGroupCreateFormType } from '../../types';
import GroupFormMainLayout from '../components/GroupFormMainLayout';
import GroupCopyForm from './GroupCopyForm';

type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: courseData } = useGetLectureCourse(lectureData.course_id);

  const methods = useForm<BookingGroupCreateFormType>({
    defaultValues: convertBookingGroupUpdateAllFormState({ lectureData, courseData }),
  });

  return (
    <GroupFormMainLayout {...methods} pageMode="copy">
      <GroupCopyForm />
    </GroupFormMainLayout>
  );
};

export default FormMain;
