import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 발송완료 혹은 예약취소 문자 삭제 */
const useDeleteMessageMobile = (id: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/message/mobile/${id}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default useDeleteMessageMobile;
