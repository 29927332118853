import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import SubTitle from 'components/SubTitle';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlerAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import LoginForm from './LoginForm';

const Login = () => {
  const navigate = useNavigate();

  const setCustomBackHandler = useSetRecoilState(customBackHandlerAtom);

  const goBackIntro = useCallback(() => {
    navigate('/intro');
  }, [navigate]);

  /** 안드로이드 물리 백버튼 Drawer 대응 */
  useEffect(() => {
    const customBackEvent = () => () => goBackIntro();
    setCustomBackHandler(customBackEvent);
  }, [goBackIntro, setCustomBackHandler]);

  const goFindPassword = () => navigate('/find-password?step=1');

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={goBackIntro}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <Container>
        <SubTitle title="로그인" />
        <ApiBoundary>
          <LoginForm />
        </ApiBoundary>
        <Button className="find-password-btn" onClick={goFindPassword} textUnderline>
          비밀번호를 잊어버렸나요?
        </Button>
      </Container>
    </MainLayout>
  );
};

export default Login;

const Container = styled.div`
  padding: 8px 20px 0;

  h2 {
    margin-bottom: 56px;
  }

  form {
    ${theme.flex('column', '', '', 8)}

    .submit-btn {
      margin-top: 16px;
    }
  }

  .find-password-btn {
    margin: 16px auto 0;
  }
`;
