import usePatchBoardNotice from 'hooks/service/mutations/usePatchBoardNotice';
import usePostBoardNotice, { BoardNoticeParams } from 'hooks/service/mutations/usePostBoardNotice';
import useErrorDialog from 'hooks/useErrorDialog';
import useQueryString from 'hooks/useQueryString';
import useToast from 'hooks/useToast';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import NoticeForm from './components/NoticeForm';
import NoticeSettings from './components/NoticeSettings';
import Preview from './components/Preview';
import SubmitButton from './components/SubmitButton';
import TitleContents from './components/TitleContents';
import { NOTICE_TYPE_TEXT } from './constants';
import { NoticeFormType } from './types';

const MoreDetailsNoticeForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  // 수정인지 등록인지 확인
  const isEdit = getSearchParams('type') === 'edit' && location.state !== undefined;
  const TEXT = isEdit ? NOTICE_TYPE_TEXT.edit : NOTICE_TYPE_TEXT.create;
  const defaultValues = isEdit ? (location.state as NoticeFormType & { id: number }) : undefined;

  const { mutate: createNoticeMutate } = usePostBoardNotice();
  const { mutate: updateNoticeMutate } = usePatchBoardNotice(defaultValues?.id || 0);
  const mutate = isEdit ? updateNoticeMutate : createNoticeMutate;
  // 공통 mutation 동작
  const mutateAction = useCallback(
    (params: BoardNoticeParams) => {
      mutate(params, {
        onSuccess: () => {
          setToast({ type: 'success', message: TEXT.successMessage, bottom: isEdit ? 92 : 40 });
          navigate(-1);
        },
        onError: error => {
          errorDialog.open(error);
        },
      });
    },
    [TEXT.successMessage, errorDialog, isEdit, mutate, navigate, setToast],
  );

  return (
    <NoticeForm title={TEXT.title} mutateAction={mutateAction} defaultValues={defaultValues}>
      <NoticeSettings />
      <TitleContents />
      <Preview />
      <SubmitButton>{TEXT.submit}</SubmitButton>
    </NoticeForm>
  );
};

export default MoreDetailsNoticeForm;
