import { Payment } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { PAYMENT_STATUS } from 'pages/TicketDetail/constants';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';

import filters from './filters';

export type PaymentMethodParamsType = {
  type: string;
  value: number;
};

/**
 * 회원 상세 > 결제정보 탭,
 * 수강권 상세 > 결제정보 페이지에서 사용되는 결제정보 카드 내용 세팅
 * */
const getPaymentDetail = (payment: Payment): { data: InfoValueRowType[]; discountPercent: number } => {
  const {
    status,
    amount,
    unpaid_amount,
    card_amount,
    cash_amount,
    wiretransfer_amount,
    installment_period,
    reward_point,
    point_amount,
  } = payment;

  const totalAmount = card_amount + cash_amount + wiretransfer_amount + point_amount;
  const discountPercent = amount > totalAmount ? ((amount - totalAmount) / amount) * 100 : 0;
  const installmentStatus = installment_period <= 1 ? '일시불' : `${installment_period}개월`;

  const paymentMethod = getPaymentMethod([
    { type: '카드', value: card_amount },
    { type: '현금', value: cash_amount },
    { type: '계좌이체', value: wiretransfer_amount },
    { type: '포인트', value: point_amount },
  ]);

  const data: InfoValueRowType[] = [
    { label: '구분', value: PAYMENT_STATUS[status] },
    { label: '판매가', value: `${filters.numberComma(amount + unpaid_amount)}원` },
    { label: '포인트 적립', value: `${filters.numberComma(reward_point)}P` },
    { label: '할인율', value: `${discountPercent}%` },
    {
      label: '미수금',
      value: `${filters.numberComma(unpaid_amount)}원`,
      textColor: 'secondary3',
    },
    { label: '결제총액', value: `${status === 'refund' ? '-' : ''}${filters.numberComma(totalAmount)}원`, textColor: 'primary' },
    { label: '결제방법', value: paymentMethod + `${card_amount ? `(${installmentStatus})` : ''}` },
  ];

  return { data, discountPercent };
};

export default getPaymentDetail;

const getPaymentMethod = (methods: PaymentMethodParamsType[]) => {
  const tempMethods: string[] = [];

  methods.forEach(({ type, value }) => {
    if (value > 0) {
      tempMethods.push(type);
    }
  });

  return tempMethods.join('+');
};
