import { MemberParams } from 'hooks/service/queries/useInfinityMember';
import { FilterOptionsType, FilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { DEFAULT_MEMBER_FILTER_OPTIONS, MEMBER_FILTER_TAG_LABEL } from './constants';
import { MemberGradeType, MemberSelectedFilterType, MemberTagCountType } from './types';

/** 회원 목록 필터로 쓸 데이터로 변환 */
export const convertMemberFilter = (tagCounts: MemberTagCountType, grades: MemberGradeType[]): FilterType[] => {
  const tag = {
    key: 'tag',
    type: 'button',
    title: '회원',
    options: convertMemberTagOptions(tagCounts),
  };
  const grade = {
    key: 'user_grade_id',
    type: 'button',
    title: '회원등급',
    options: convertMemberGradeOptions(grades),
  };

  return [tag, DEFAULT_MEMBER_FILTER_OPTIONS[1], grade, ...DEFAULT_MEMBER_FILTER_OPTIONS.slice(3)];
};

/** api로 받은 카운트 응답값을 회원 태그 옵션으로 변환 */
const convertMemberTagOptions = (tagCount: MemberTagCountType): FilterOptionsType[] => {
  const tags = Object.keys(tagCount) as Array<keyof MemberTagCountType>;

  return tags.map(tag => ({
    value: tag === 'total' ? undefined : tag,
    label: `${MEMBER_FILTER_TAG_LABEL[tag]} ${filters.numberComma(tagCount[tag])}`, // 전체회원 nn
  }));
};

/** api로 받은 등급 응답값을 등급 옵션으로 변환 */
const convertMemberGradeOptions = (grades: MemberGradeType[]): FilterOptionsType[] => {
  const defaultOptions = { value: undefined, label: '전체' };
  const gradeOptions = grades.map(({ id, name }) => ({ value: id, label: name }));

  return [defaultOptions, ...gradeOptions];
};

export const convertAllMemberFilterToParams = (selectedFilter: MemberSelectedFilterType): Omit<MemberParams, 'search'> => {
  const { tag, ticket_remaining, attendance_count, sort_target, sort_type, ...params } = selectedFilter;
  const unavailableSort = !attendance_count && sort_target === 'attendance_count';
  return {
    ...params,
    tag: tag ?? 'all',
    remaining_start_date: ticket_remaining?.date?.[0],
    remaining_end_date: ticket_remaining?.date?.[1],
    remaining_coupon: ticket_remaining?.count,
    attendance_count_start_date: attendance_count?.[0],
    attendance_count_end_date: attendance_count?.[1],
    sort_target: unavailableSort ? 'name' : sort_target,
    sort_type: unavailableSort ? 'asc' : sort_type,
    paginate_type: 'detail',
  };
};
