import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import DateCalendarPickerDrawer from 'designedComponents/Pickers/DateCalendarPicker/DateCalendarPickerDrawer';
import { TicketHoldingFormType } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateButton } from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

/** 정지 기간 수정 폼에서 종료일 변경 picker */
const HoldingEndPicker = () => {
  const { control, setValue } = useFormContext<TicketHoldingFormType>();
  const startOn = useWatch({ control, name: 'params.start_on' });
  const endOn = useWatch({ control, name: 'params.end_on' });

  const [isOpen, setIsOpen] = useState(false);
  const [tempEndDate, setTempEndDate] = useState(endOn);

  const changeDate = (e: { value: Date }) => {
    setTempEndDate(filters.dateDash(e.value));
  };

  const closeCalendar = () => {
    setTempEndDate(endOn);
    setIsOpen(false);
  };

  const saveDate = () => {
    setValue('params.end_on', tempEndDate, { shouldDirty: true });
    setIsOpen(false);
  };

  return (
    <>
      <Container>
        <DateButton icon={<Icon name="calendar" color="gray1" />} disabled={true} showIcon={false} textColor="gray3">
          {startOn ? filters.date(startOn) : '시작일'}
        </DateButton>
        <Typography span size={15} weight={500} textColor="gray2">
          ~
        </Typography>
        <DateButton
          icon={<Icon name="calendar" color="gray1" />}
          onClick={() => {
            setIsOpen(true);
          }}
          textColor={endOn ? 'gray1' : 'gray4'}>
          {endOn ? filters.date(endOn) : '종료일'}
        </DateButton>
      </Container>

      <DateCalendarPickerDrawer
        isOpen={isOpen}
        onClose={closeCalendar}
        min={startOn}
        value={tempEndDate}
        onSave={saveDate}
        onChange={changeDate}
      />
    </>
  );
};

export default HoldingEndPicker;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between', 8)};
`;
