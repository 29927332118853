import { ERROR_CODE } from 'constants/text';
import usePostAvatar from 'hooks/service/mutations/usePostAvatar';
import useGetStaffMobile from 'hooks/service/queries/useGetStaffMobile';
import useErrorDialog from 'hooks/useErrorDialog';
import useQueryString from 'hooks/useQueryString';
import { useErrorBoundary } from 'react-error-boundary';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import SelectRepresentativeColor from 'sharedComponents/ProfileField/components/RepresentativeColor/SelectRepresentativeColor';
import { CustomError } from 'types/errorTypes';

import StaffInfoForm from '../../components/StaffInfoForm';
import { StaffCreateFormType } from '../../types';

type Props = {
  disabled: boolean;
  nextStep: () => void;
};

const CreateStepInfo = ({ disabled, nextStep }: Props) => {
  const { control, handleSubmit, setValue, formState } = useFormContext<StaffCreateFormType>();

  const errorDialog = useErrorDialog();
  const { getAllSearchParams } = useQueryString();
  const queryStringParsed = getAllSearchParams();
  const isRepresentativeColor = queryStringParsed.type === 'representativeColor';

  const currentMobile = useWatch({ control, name: 'mobile' });
  const { showBoundary } = useErrorBoundary();

  const { mutate: createProfileImageMutate } = usePostAvatar();

  /** GET 요청 useQuery 사용 시 계속 감지하고 있어서 디테일한 조건을 설정함 */
  const isMobileCheckEnabled = currentMobile.length > 10 && !!formState.dirtyFields.mobile && !formState.errors.mobile;
  const { error: mobileCheckError, isError } = useGetStaffMobile({
    mobile: currentMobile,
    enabled: isMobileCheckEnabled,
  });

  const next: SubmitHandler<StaffCreateFormType> = inputValues => {
    const { file } = inputValues;

    /** 에러 및 성공은 isMobileCheckEnabled가 변함과 동시에 진행되며, 클릭했을 때 해당 에러 활용 */
    if (isError) {
      const customError = mobileCheckError as CustomError;
      const code = customError.response?.status;
      if (code === ERROR_CODE.BAD_REQUEST) {
        errorDialog.open(customError);
      } else {
        showBoundary(customError);
      }
      return;
    }

    if (file) {
      const params = { file };
      createProfileImageMutate(params, {
        onSuccess: res => {
          setValue('image', res.data.image || undefined);
          nextStep();
        },
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    } else {
      nextStep();
    }
  };

  return (
    <>
      {isRepresentativeColor ? (
        <SelectRepresentativeColor />
      ) : (
        <StaffInfoForm onSubmit={handleSubmit(next)} footerButton={{ text: '다음', disabled }} />
      )}
    </>
  );
};

export default CreateStepInfo;
