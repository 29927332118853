import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Typography from 'components/Typography';
import { ComponentProps, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { BookingSelectMemberFormType } from '../types';
import LeaveDialog from './LeaveDialog';
import ReserveUserTickets from './ReserveUserTickets';
import SaveButton from './SaveButton';
import Search from './Search';

type Props = Omit<ComponentProps<typeof Form>, 'children' | 'onSubmit'> &
  ComponentProps<typeof ReserveUserTickets> & {
    memberList: ReactNode;
    onSubmit: (values: BookingSelectMemberFormType) => void;
  };

/** 그룹, 프라이빗 상세페이지에서 인원 추가로 들어올 때 */
const SelectMemberForm = ({ memberList, onSubmit, ...props }: Props) => {
  const { handleSubmit } = useFormContext<BookingSelectMemberFormType>();

  return (
    <>
      <Container>
        <Search />
        <FormContainer {...props} onSubmit={handleSubmit(onSubmit)}>
          <ReserveUserTickets />
          <ListTitleTypography size={13} weight={500} textColor="gray2">
            회원목록
          </ListTitleTypography>
          <ApiBoundary>{memberList}</ApiBoundary>
          <SaveButton />
        </FormContainer>
      </Container>
      <LeaveDialog />
    </>
  );
};

export default SelectMemberForm;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  height: 100%;
`;

const FormContainer = styled(Form)`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 76px;
  ${theme.flex('column', '', '')};
`;

const ListTitleTypography = styled(Typography)`
  width: 100%;
  flex: 0 0 auto;
  padding: 11px 20px 4px;
`;
