import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MemoPostParams } from './usePostMemo';

const usePatchMemo = (memoId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemoPatchParams>({
    method: 'patch',
    url: `/api/memo/${memoId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['memo'],
      });
    },
  });
};

export default usePatchMemo;

export type MemoPatchParams = MemoPostParams;
