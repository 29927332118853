import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom } from 'recoil/schedule';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

type Props = {
  id: string | number;
  title: string;
  isPrivate: boolean;
  cardColor: string;
  isAllDay?: boolean;
  sameTimeEvents: number;
  lastViewItemId?: number;
  eventLength?: number;
};

const EventEtcScheduleCard = ({ ...props }: Props) => {
  const { id, lastViewItemId, eventLength = 0, title, isPrivate, cardColor, isAllDay, sameTimeEvents } = props;

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);

  const isDayView = useMemo(() => calendarViewType === 'day', [calendarViewType]);
  const isWeekView = useMemo(() => calendarViewType === 'week', [calendarViewType]);

  const currentMoreText = useMemo(() => {
    if (isDayView) return `+${eventLength - 2}개 더보기`;
    return `+${eventLength - 2}`;
  }, [isDayView, eventLength]);

  const currentContrastColor = getContrastTextColor(cardColor) as Color;

  return (
    <>
      <Container cardColor={cardColor} isWeekView={isWeekView} sameTimeEvents={sameTimeEvents} isAllDay={!!isAllDay}>
        <div className="title-wrapper">
          {isPrivate && <Icon name="lockBold" size={16} fillColor={theme.color[currentContrastColor]} />}
          <Typography className="title" size={13} weight={600} textColor={currentContrastColor} ellipsis={isDayView ? 1 : 0}>
            {title}
          </Typography>
        </div>
      </Container>

      {eventLength > 2 && lastViewItemId === id && (
        <MoreText isWeekView={isWeekView}>
          <Typography size={13} weight={600}>
            {currentMoreText}
          </Typography>
        </MoreText>
      )}
    </>
  );
};

export default EventEtcScheduleCard;

const Container = styled.div<{ cardColor: string; isWeekView: boolean; sameTimeEvents: number; isAllDay: boolean }>(
  ({ cardColor }) => css`
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    background-color: ${cardColor};
    overflow: hidden;

    .title-wrapper {
      ${theme.flex('row', 'center', '', 4)};
    }
  `,

  /** 주간(week) 기타 일정 카드 */
  ({ isWeekView, sameTimeEvents, isAllDay }) =>
    isWeekView &&
    css`
      padding: ${isAllDay ? '2px' : sameTimeEvents === 2 ? '4px 5px' : '4px 2px'} !important;

      .title-wrapper > .title {
        width: max-content;
      }
    `,
);

const MoreText = styled.div<{ isWeekView: boolean }>(
  css`
    margin: 8px 0 8px;
    padding: 0 !important;

    .typography {
      color: ${theme.color.gray3} !important;
    }
  `,

  ({ isWeekView }) =>
    isWeekView &&
    css`
      padding-top: 22px !important;
    `,
);
