import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Typography from 'components/Typography';
import dayjs from 'dayjs';

import TimePicker from '.';

export type RangeTimePickerProps = {
  /** 배열값의 index로 시작/끝 시간 구분 - [0]: StartTime, [1]: EndTime */
  timeRange: [Date, Date];
  onChange: (value: [Date, Date]) => void;
  /** 분 시간의 간격 (기본: 1분) */
  stepMinute?: number;
  /** 시간 분 사이의 콜론 간격 유무 */
  semiSpacing?: boolean;
  /** 하단의 시간 추가 버튼 유무 */
  showAddTimeButton?: boolean;
  /** wheel 가운데 '부터' 텍스트 유무 */
  showFromText?: boolean;
  startMinTime?: DateType;
  endMinTime?: DateType;
  startMaxTime?: DateType;
  endMaxTime?: DateType;
};

const RangeTimePicker = ({
  timeRange,
  onChange,
  stepMinute = 1,
  semiSpacing = false,
  showFromText = true,
  showAddTimeButton,
  startMinTime,
  endMinTime,
  startMaxTime,
  endMaxTime,
}: RangeTimePickerProps) => {
  const targetDate = dayjs(timeRange[0]);

  const changeEndTime = (e: { value: Date }) => {
    if (targetDate.isBefore(e.value, 'minute')) {
      onChange([timeRange[0], e.value]);
    } else {
      onChange([timeRange[0], timeRange[0]]);
    }
  };

  const clickToChangeEndTime = (value: number) => {
    const updateTargetDate = targetDate.add(value, 'minute').toDate();

    onChange([timeRange[0], updateTargetDate]);
  };

  return (
    <Container
      className="range-time-picker"
      showAddTimeButton={showAddTimeButton}
      showFromText={showFromText}
      semiSpacing={semiSpacing}>
      <div className="range-time-picker-wrapper">
        <TimePicker
          value={timeRange[0]}
          onChange={e => onChange([e.value, timeRange[1]])}
          stepMinute={stepMinute}
          minTime={startMinTime}
          maxTime={startMaxTime}
        />
        {showFromText && (
          <Typography className="from-text" size={19} textColor="gray3" weight={500}>
            부터
          </Typography>
        )}
        <TimePicker
          value={timeRange[1]}
          onChange={changeEndTime}
          stepMinute={stepMinute}
          minTime={endMinTime}
          maxTime={endMaxTime}
        />
      </div>
      {showAddTimeButton && (
        <ButtonGroup gap={8}>
          {timeButtonArray.map(({ title, value }) => (
            <Button key={value} variant="outlined" size="small" widthSize={64} onClick={() => clickToChangeEndTime(value)}>
              <Typography textColor="gray2">{title}</Typography>
            </Button>
          ))}
        </ButtonGroup>
      )}
    </Container>
  );
};

const Container = styled.div<Pick<RangeTimePickerProps, 'showFromText' | 'showAddTimeButton' | 'semiSpacing'>>(
  /** 공통 스타일 */
  css`
    .range-time-picker-wrapper {
      ${theme.flex()};
      /* border-top: 1px solid ${theme.color.gray6}; */

      .time-picker {
        flex: 1;
      }
    }
  `,
  /** '부터' 텍스트 없을 때 */
  ({ showFromText }) =>
    !showFromText &&
    css`
      .range-time-picker-wrapper {
        .time-picker {
          /** 가운데 세로 구분선 */
          &:last-of-type {
            border-left: 1px solid ${theme.color.gray6};
          }
        }
      }
    `,

  /** '부터' 텍스트 있을 때 */
  ({ showFromText }) =>
    showFromText &&
    css`
      .range-time-picker-wrapper {
        .time-picker {
          .mbsc-scroller-wheel-group {
            gap: 24px;
          }

          /** selected bar */
          .mbsc-scroller-wheel-line {
            margin: 0;
            border-radius: 0;
          }

          /** 왼쪽(시작 시간 값) TimePicker */
          &:first-of-type {
            .mbsc-scroller-wheel-line {
              margin-left: 8px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;

              &::before {
                transform: translate(calc(-50% - 3px), calc(-50% - 1px));
              }
            }
          }

          /** 오른쪽(종료 시간값) TimePicker */
          &:last-of-type {
            .mbsc-scroller-wheel-line {
              margin-right: 8px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;

              &::before {
                transform: translate(calc(-50% + 3px), calc(-50% - 1px));
              }
            }
          }
        }

        /** '부터' 텍스트 영역 */
        .from-text {
          flex: 0 0 auto;
          background-color: ${`${theme.color.primary}1a`};
          height: 32px;
          line-height: 32px;
        }
      }
    `,

  /** wheel 하단 30분, 50분, 1시간 버튼 있을 때 */
  ({ showAddTimeButton }) =>
    showAddTimeButton &&
    css`
      .button-group {
        padding: 0 20px;
        justify-content: flex-end;
      }
    `,

  /** 시간과 분 사이 간격이 좁을 때 */
  ({ semiSpacing }) =>
    semiSpacing &&
    css`
      .range-time-picker-wrapper {
        .time-picker {
          .mbsc-scroller-wheel-group {
            gap: 0;
          }
        }
      }
    `,
);

const timeButtonArray = [
  {
    title: '30분',
    value: 30,
  },
  {
    title: '50분',
    value: 50,
  },
  {
    title: '1시간',
    value: 60,
  },
];

export default RangeTimePicker;
