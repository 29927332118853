import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import getEllipsisByLength from 'pages/Booking/utils/getEllipsisByLength';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  bgColor: keyof typeof color;
  contrastColor: keyof typeof color;
  currentLecture: LectureDetailResponse;
};

/** 수업 상세 페이지 상단 컬러 영역  */
const ColoredDetailInfo = ({ currentLecture, bgColor, contrastColor }: Props) => {
  const { id, title, staff, room, policy, memo_count, start_on, end_on, booking_closed_at } = currentLecture;
  const navigate = useNavigate();
  const date = filters.dateWithWeekday(start_on);
  const time = filters.timePeriod(start_on, end_on);
  const roomName = room
    ? `${getEllipsisByLength({
        text: room.name,
        max: 20,
      })} 룸`
    : '룸 선택 안함';
  const staffName = getEllipsisByLength({
    text: staff.name,
    max: 20,
    hasEllipsis: false,
  });

  return (
    <Container className="lecture-detail-colored-wrapper" bgColor={bgColor}>
      <TitleWrapper>
        <Typography size={21} weight={700} textColor={contrastColor}>
          {booking_closed_at ? '[예약마감] ' : ''}
          {title}
        </Typography>
        <Typography weight={500} textColor={contrastColor} opacity={0.8}>
          {date} {time}
        </Typography>
      </TitleWrapper>

      <DetailInfoWrapper>
        <div className="item staff-wrapper">
          <Avatar size={16} imgUrl={getImageUrl(staff.image)} />
          <Typography textColor={contrastColor} weight={500}>
            {staffName} 강사
          </Typography>
        </div>
        {policy.is_use_rooms && (
          <Typography className="item" textColor={contrastColor} weight={500}>
            {roomName}
          </Typography>
        )}
        <Button
          className="item"
          textColor={contrastColor}
          rightIcon={<Icon name="arrowRightBold" size={16} color={contrastColor} />}
          onClick={() => navigate(`/booking/memo/${id}`)}>
          메모 {memo_count}
        </Button>
      </DetailInfoWrapper>
    </Container>
  );
};

export default ColoredDetailInfo;

const Container = styled.div<{ bgColor: keyof typeof color }>(
  ({ bgColor }) => css`
    background-color: ${theme.color[bgColor]};
  `,
);

const TitleWrapper = styled.div`
  ${theme.flex('column', '', '', 2)};
  padding: 8px 20px 32px;
`;

const DetailInfoWrapper = styled.div`
  ${theme.flex('', 'center', 'center', 29)};
  padding: 0 20px 16px;
  position: relative;

  .staff-wrapper {
    ${theme.flex('', 'flex-start', 'center', 4)};

    .avatar {
      flex: 0 0 auto;
      margin-top: 1px;
    }
  }

  .item {
    flex: 1;
    position: relative;
    text-align: center;

    &:before {
      content: '';
      width: 1px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: -15px;
      margin-top: -8px;
      background-color: ${theme.color.white};
      opacity: 0.24;
    }

    &.text-button {
      &:before {
        content: none;
      }

      svg {
        opacity: 0.64;
      }
    }
  }
`;
