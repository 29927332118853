import useInfinityUserTicketHistory, { UserTicketHistoryResponse } from 'hooks/service/queries/useInfinityUserTicketHistory';
import { CONTENT_LABEL, ITEM_PRODUCT_TYPE_REMOVE_KEYS, TYPEOF } from 'pages/TicketDetail/constants';
import { TicketHistoryFormatContentType, UserTicketProps } from 'pages/TicketDetail/types';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { selectedUserTicketHistoryAtom } from 'recoil/TicketDetail';
import { DEFAULT_PERIOD } from 'sharedComponents/Filters/constants';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import { SortFormType } from 'sharedComponents/MemoForm/types';
import NodataTypography from 'sharedComponents/NodataTypography';
import filters from 'utils/filters';

import HistoryCard from './HistoryCard';

const HistoryList = ({ userTicket }: UserTicketProps) => {
  const { watch } = useFormContext<SortFormType>();
  const selectedFilter = useRecoilValue(selectedUserTicketHistoryAtom);
  const period = selectedFilter?.period ?? DEFAULT_PERIOD;

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfinityUserTicketHistory({
    productId: userTicket.id,
    user_id: userTicket.user_id,
    start_date: period.start,
    end_date: period.end,
    order_by: watch('sort'),
  });

  const formatContent = useCallback(
    (history: UserTicketHistoryResponse) => {
      const { kind, old: oldValues, new: newValues, context } = history;
      const { ticket } = userTicket;

      if (kind === 'text') return [{ label: '사유', newValue: context }];
      if (kind === 'staff') {
        return [{ label: '강사변경', oldValue: oldValues.join(', '), newValue: newValues.join(', ') }];
      }

      const keys = Object.keys(CONTENT_LABEL);
      const newValuesKeys = Object.keys(newValues);
      let filteredKeys = keys.filter(key => newValuesKeys.includes(key));

      /** 상품(판매, 대여)의 경우 노출시키지 않아야 할 항목들 필터링 */
      switch (ticket.type) {
        case 'T':
        case 'P':
          break;
        default: {
          const removeKeys = ITEM_PRODUCT_TYPE_REMOVE_KEYS[ticket.type];
          filteredKeys = filteredKeys.filter(key => !removeKeys.includes(key));
        }
      }

      return filteredKeys.reduce((content: TicketHistoryFormatContentType[], key) => {
        const label = CONTENT_LABEL[key];
        const type = TYPEOF[key];
        let oldValue = oldValues[key];
        let newValue = newValues[key];

        switch (type) {
          case 'installment_period':
            oldValue = oldValue === 0 || oldValue === 1 ? '일시불' : oldValue;
            newValue = newValue === 0 || newValue === 1 ? '일시불' : newValue;
            break;

          case 'count':
          case 'price':
            oldValue =
              (key === 'booking_limit_per_week' && oldValue === 0) ||
              (key === 'booking_limit_per_month' && oldValue === 0) ||
              (ticket.type === 'P' && oldValues['max_coupon'] >= 999 && (key === 'max_coupon' || key === 'remaining_coupon')) ||
              (ticket.type === 'P' && oldValues['max_cancel'] >= 999 && key === 'remaining_cancel')
                ? '무제한'
                : key === 'daily_booking_change_limit' && oldValue === 0
                  ? '사용안함'
                  : !oldValue
                    ? '0'
                    : filters.numberComma(Math.max(oldValue, 0));
            newValue =
              (key === 'booking_limit_per_week' && newValue === 0) ||
              (key === 'booking_limit_per_month' && newValue === 0) ||
              (ticket.type === 'P' && (key === 'max_coupon' || key === 'remaining_coupon' || key === 'remaining_cancel'))
                ? '무제한'
                : key === 'daily_booking_change_limit' && newValue === 0
                  ? '사용안함'
                  : !newValue
                    ? '0'
                    : filters.numberComma(Math.max(newValue, 0));
            break;

          case 'date':
            oldValue = oldValue ? filters.date(oldValue) : null;
            newValue = newValue ? filters.date(newValue) : null;
            if (oldValue === newValue) {
              if (kind === 'transfer') {
                oldValue = filters.date(oldValue);
                newValue = filters.date(newValue);
              } else {
                oldValue = filters.dateDashTime(oldValues[key]);
                newValue = filters.dateDashTime(newValues[key]);
              }
            }
            break;

          case 'ticketType':
            oldValue = oldValue === 'T' ? '횟수제' : '기간제';
            newValue = newValue === 'T' ? '횟수제' : '기간제';
            break;

          case 'boolean':
            oldValue = oldValue ? 'Y' : 'N';
            newValue = newValue ? 'Y' : 'N';
        }

        if (oldValue !== newValue) {
          if (kind === 'installment_payment' || kind === 'full_payment') {
            if (label !== '미수금') {
              oldValue = null;
              content.push({ label, oldValue, newValue });
            }
          } else if (kind === 'delete') {
            content.push({ label, oldValue, newValue: '삭제' });
          } else {
            content.push({ label, oldValue, newValue });
          }
        } else {
          if (kind === 'transfer' && key in oldValues) {
            // 취소가능표시는 기간제 수강권의 경우에만 표시
            const except =
              (type === 'T' || ticket.available_class_type === 'I') && key === 'is_show_cancel_count' && oldValues[key] === false;

            if (!except) {
              content.push({ label, oldValue, newValue });
            }
          }
        }

        return content;
      }, []);
    },
    [userTicket],
  );

  const history = useMemo(() => {
    return data.map(history => {
      const kind = history.kind === 'text' ? null : history.kind;
      const contents = formatContent(history) as TicketHistoryFormatContentType[];

      return {
        id: history.id,
        date: filters.dateTimeWithWeekday(history.updated_at),
        staffName: history.staff.name,
        kind,
        contents,
      };
    });
  }, [data, formatContent]);

  return (
    <>
      {!history.length ? (
        <NodataTypography>변경이력이 없습니다.</NodataTypography>
      ) : (
        <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage} gap={12}>
          {history.map(data => (
            <HistoryCard key={data.id} data={data} />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default HistoryList;
