import useGetMessageMobilePoint from 'hooks/service/queries/useGetMessageMobilePoint';
import { APP_LINK_TEXT } from 'pages/MemberDetail/constants';
import { AppLinkMessageFormType, MobileMessageType } from 'pages/MemberDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import NoPointDrawer from 'sharedComponents/Message/NoPointDrawer';
import filters from 'utils/filters';

import SendConfirmDrawer from './SendConfirmDrawer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: AppLinkMessageFormType) => void;
};

const SendConfirm = ({ isOpen, onClose, onSubmit }: Props) => {
  const { control, handleSubmit } = useFormContext<AppLinkMessageFormType>();
  const message = useWatch({ control, name: 'message' });

  const { data: currentPoint } = useGetMessageMobilePoint();
  const messageType = message.length > 90 ? APP_LINK_TEXT.mobileMessage.type.LMS : APP_LINK_TEXT.mobileMessage.type.SMS;
  const deductPoint = APP_LINK_TEXT.mobileMessage.deductPoint[messageType as MobileMessageType];
  const isEnoughPoint = currentPoint > deductPoint;

  return (
    <>
      <NoPointDrawer
        isOpen={isOpen && !isEnoughPoint}
        onClose={onClose}
        currentPoint={filters.numberComma(currentPoint)}
        deductPoint={deductPoint}
      />
      <SendConfirmDrawer
        isOpen={isOpen && isEnoughPoint}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        currentPoint={filters.numberComma(currentPoint)}
        deductPoint={deductPoint}
        messageType={messageType}
      />
    </>
  );
};

export default SendConfirm;
