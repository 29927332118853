import styled from '@emotion/styled';
import Divider from 'components/Divider';
import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MultiTicketFilter from '../MultiTicketFilter';
import ReportMultiButtonFilter from '../ReportMultiButtonFilter';

const ReportSaleWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  return (
    <Container>
      <ButtonFilter currentFilter={filters[0]} selectedOption={get(filterChanged, filters[0].key)} onClick={changeOption} />
      <Divider />
      <ReportMultiButtonFilter
        selectedOptionKey={filters[1].key as 'paymentMethod'}
        currentAllOptionCount={filters[1].options.length - 1}
        currentFilter={filters[1]}
        selectedOption={get(filterChanged, filters[1].key)}
        onClick={changeOption}
      />
      <Divider />
      <ReportMultiButtonFilter
        selectedOptionKey={'paymentMethod'}
        currentAllOptionCount={filters[2].options.length - 1}
        currentFilter={filters[2]}
        selectedOption={get(filterChanged, filters[2].key)}
        onClick={changeOption}
      />
      <Divider />
      <ApiBoundary>
        <MultiTicketFilter
          currentFilter={filters[3]}
          selectedOption={get(filterChanged, filters[3].key)}
          onClick={changeOption}
        />
      </ApiBoundary>
    </Container>
  );
};

export default ReportSaleWholeFilterOptions;

const Container = styled.div`
  .button-filter:first-of-type {
    padding: 16px 20px 24px 20px;
  }
`;
