import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import useDeleteBoardQna from 'hooks/service/mutations/useDeleteBoardQna';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { useState } from 'react';

import { QNA_DETAIL_TEXT } from '../constants';
import { QnaCommentType } from '../types';
import CommentEditPopup from './CommentEditPopup';

type Props = {
  className?: string;
  comment: QnaCommentType;
  canEditMine: boolean;
  canDeleteOthers: boolean;
};

const CommentMenu = ({ className, comment, canEditMine, canDeleteOthers }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const questionId = useParamsId();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: deleteCommentMutate } = useDeleteBoardQna(questionId, comment.id);
  const { setPopup } = usePopup();

  const deleteComment = () => {
    deleteCommentMutate(undefined, {
      onSuccess: () => setToast({ type: 'success', message: QNA_DETAIL_TEXT.deleteSuccess }),
      onError: error => errorDialog.open(error),
      onSettled: () => setIsDeleteDialogOpen(false),
    });
  };

  if (!canEditMine && !canDeleteOthers) return null;
  return (
    <DropDown
      position="right"
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      content={
        <ButtonsWithBorder>
          {canEditMine && (
            <Button
              size="medium48"
              fontSize={15}
              fontWeight={500}
              padding={{ left: 16, right: 12 }}
              gap={32}
              textColor="gray2"
              rightIcon={<Icon name="writing2" size={16} color="gray2" />}
              onClick={() => setPopup(<CommentEditPopup comment={comment} />)}>
              {QNA_DETAIL_TEXT.edit}
            </Button>
          )}
          {(canEditMine || canDeleteOthers) && (
            <Button
              size="medium48"
              fontSize={15}
              fontWeight={500}
              padding={{ left: 16, right: 12 }}
              gap={32}
              textColor="red0"
              rightIcon={<Icon name="delete" size={16} color="red0" />}
              onClick={() => setIsDeleteDialogOpen(true)}>
              {QNA_DETAIL_TEXT.delete}
            </Button>
          )}
          {isDeleteDialogOpen && (
            <Dialog
              onClose={() => setIsDeleteDialogOpen(false)}
              positiveAction={{
                text: QNA_DETAIL_TEXT.deleteButton,
                onClick: deleteComment,
              }}
              negativeAction={{ text: QNA_DETAIL_TEXT.deleteCancel, onClick: () => setIsDeleteDialogOpen(false) }}>
              <DialogDescription>{QNA_DETAIL_TEXT.deleteConfirm}</DialogDescription>
            </Dialog>
          )}
        </ButtonsWithBorder>
      }
      className={className}>
      <IconButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Icon name="more" color="gray3" />
      </IconButton>
    </DropDown>
  );
};

export default CommentMenu;

const ButtonsWithBorder = styled.div`
  flex-direction: column;
  gap: 0;
  button:first-of-type {
    border-bottom: 1px solid ${theme.color.gray7};
  }
`;
