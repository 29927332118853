import styled from '@emotion/styled';
import { Eventcalendar, MbscCalendarEventData, MbscEventClickEvent } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { AgendaDataType } from 'pages/Schedule/ScheduleMain/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { EVENT_CARD_TYPE } from '../../constants';
import MonthAgendaCard from '../agenda/MonthAgendaCard';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  allCalendarData: AgendaDataType;
  currentDate: Date;
};

const CalendarAgendaDrawer = ({ isOpen, onClose, allCalendarData, currentDate }: Props) => {
  const navigate = useNavigate();

  const headerText = dayjs(currentDate).format('YYYY.MM.DD (ddd)');

  const renderAgendaEventCard = useCallback<(data: MbscCalendarEventData) => unknown>((data: MbscCalendarEventData) => {
    if (!data.original) return;
    return <MonthAgendaCard original={data.original} />;
  }, []);

  const clickEvent = ({ event }: MbscEventClickEvent) => {
    switch (event.type) {
      case EVENT_CARD_TYPE.counsel:
        navigate(`/counsel/detail/${event.id}`);
        break;
      case EVENT_CARD_TYPE.etcSchedule:
        navigate(`/schedule/etc/detail/${event.id}`);
        break;
      case EVENT_CARD_TYPE.allDayEtcSchedule:
        navigate(`/schedule/etc/detail/all-day/${event.id}`);
        break;
      default:
        navigate(`/booking/detail/${event.id}`);
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} header={<OneLinedTitle title={headerText} />}>
      <DrawerContainer>
        <Eventcalendar
          theme="ios"
          themeVariant="light"
          showControls={false}
          view={{ agenda: { type: 'day' } }}
          data={allCalendarData}
          renderEventContent={renderAgendaEventCard} // 커스텀 아젠다 카드
          selectedDate={currentDate}
          onEventClick={clickEvent} // 아젠다 카드 클릭
        />
      </DrawerContainer>
    </Drawer>
  );
};

export default CalendarAgendaDrawer;

const DrawerContainer = styled.div`
  margin-bottom: 40px;

  .mbsc-ios {
    /** mobiscroll agenda 기본 헤더 제거 */
    .mbsc-calendar-wrapper,
    .mbsc-schedule-date-header-text,
    .mbsc-schedule-date-header {
      display: none;
    }

    /** 각 카드 사이의 border 제거 */
    .mbsc-list-item::before,
    .mbsc-list-item::after {
      border: 0;
    }

    /** 시간 정보 order 1 */
    .mbsc-event-time {
      order: 1;
    }

    /** 세로선 order 2 */
    .mbsc-event-color {
      order: 2;
    }

    /** 일정 내용 order 3 */
    .mbsc-event-content {
      order: 3;
    }

    .mbsc-event-list {
      .mbsc-event-group {
        ${theme.flex('column', 'flex-start', 'center', 8)};

        .mbsc-list-item {
          ${theme.flex('row', 'center', 'flex-start')};
          padding: 6px 0;
          width: 100%;
          height: 56px;

          &.mbsc-hover {
            &::before {
              background-color: white !important;
            }
          }

          &.mbsc-focus {
            &::after {
              background-color: white !important;
            }
          }

          .mbsc-event-color {
            margin: 0 10px;
            width: 2px;
            height: 100%;
          }

          .mbsc-event-time {
            width: 38px;
            text-align: end;

            /** 종일 타이틀은 위치 스타일 별도 */
            .mbsc-event-all-day {
              margin-bottom: 20px;
              text-align: start;
            }

            .mbsc-event-start,
            .mbsc-event-end {
              text-align: left;
            }

            .mbsc-event-start {
              font-weight: 500;
              color: ${theme.color.gray2};
            }

            .mbsc-event-end {
              font-weight: 400;
              color: ${theme.color.gray3};
            }
          }
        }
      }
    }
  }
`;
