import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import dayjs from 'dayjs';
import { HoldingPatchParams } from 'hooks/service/mutations/usePatchHolding';
import { HoldingPostResponse } from 'hooks/service/mutations/usePostHolding';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_HOLDING_TEXT } from 'pages/TicketDetail/constants';
import { MutationCallbackType, TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import TicketCard from 'sharedComponents/TicketCard';
import filters from 'utils/filters';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import getRemainingDaysText from 'utils/getRemainingDaysText';

import HoldingCancelDialog from './HoldingCancelDialog';
import HoldingCreate from './HoldingCreate';
import HoldingHistory from './HoldingHistory';
import HoldingUpdate from './HoldingUpdate';

const TicketHolding = ({ canUpdateUserTicket, userTicket }: UserTicketProps) => {
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const methods = useForm<TicketHoldingFormType>({
    defaultValues: {
      params: {
        user_ticket_id: userTicket.id,
        auto_calculation: true,
        start_on: userTicket.holdings?.start_on,
        end_on: userTicket.holdings?.end_on,
      },

      selectedHolding: null,
      hasBooking: false,
      bookingCount: undefined,
    },
  });
  const { control, setValue } = methods;
  const selectedHolding = useWatch({ control, name: 'selectedHolding' });

  const isHolding = useMemo(
    () => userTicket.user_ticket_status === 'holding' && !!userTicket.holdings,
    [userTicket.holdings, userTicket.user_ticket_status],
  );

  const userTicketInfo = useMemo(() => {
    const { availability_start_at, expire_at } = userTicket;
    const { bottomOptions, ...rest } = formatSmallUserTicketCard(userTicket);

    /**
     * 수강권 정보 하단에
     * 2024.04.01 ~ 2024.04.30 (29일 남음) 으로 표시
     */
    const period = `${filters.period(availability_start_at, expire_at)} (${getRemainingDaysText(
      expire_at,
      availability_start_at,
    )})`;

    return {
      ...rest,
      bottomOptions: [period],
    };
  }, [userTicket]);

  /** 정지, 정지 수정 요청 후 실행되는 이벤트 */
  const mutateCallback = (): MutationCallbackType<HoldingPostResponse, HoldingPatchParams> => {
    const productText = PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type];
    const message = `${productText}${TICKET_HOLDING_TEXT.successMessage[isHolding ? 'update' : 'create']}`;

    return {
      onSuccess: res => {
        const { start_on, end_on } = res.data;
        const start = dayjs(start_on);
        const end = dayjs(end_on);
        const isHolding = dayjs().isSameOrAfter(start, 'day') && dayjs().isSameOrBefore(end, 'day');

        if (!isHolding) {
          setValue('params.start_on', '', { shouldDirty: true });
          setValue('params.end_on', '', { shouldDirty: true });
        }
        setToast({ type: 'success', message });
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    };
  };

  return (
    <>
      <MainLayout header={{ title: TICKET_HOLDING_TEXT.title }}>
        <FormProvider {...methods}>
          <Container>
            <TicketCard ticket={userTicketInfo} />
            <Divider />
            {userTicket.holdings ? (
              <HoldingUpdate
                canUpdateUserTicket={canUpdateUserTicket}
                userTicket={userTicket}
                holding={userTicket.holdings}
                mutateCallback={mutateCallback()}
              />
            ) : (
              <HoldingCreate
                canUpdateUserTicket={canUpdateUserTicket}
                userTicket={userTicket}
                mutateCallback={mutateCallback()}
              />
            )}

            <HistoryWrapper>
              <Typography className="title" size={15} weight={600}>
                {PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]} 정지 이력
              </Typography>

              <ApiBoundary>
                <HoldingHistory canUpdateUserTicket={canUpdateUserTicket} />
              </ApiBoundary>
            </HistoryWrapper>
          </Container>

          {selectedHolding && <HoldingCancelDialog selectedHolding={selectedHolding} userTicket={userTicket} />}
        </FormProvider>
      </MainLayout>
    </>
  );
};

export default TicketHolding;

const Container = styled.div`
  height: 100%;
  ${theme.flex('column', '', '')};

  hr {
    flex: 0 0 auto;
  }

  .ticket-card {
    padding: 14px 20px 20px;
  }
`;

const HistoryWrapper = styled.div`
  flex: 1;
  padding: 32px 20px;
  background-color: ${theme.color.gray6};

  .title {
    margin-bottom: 20px;
  }
`;
