import { useSuspenseQuery } from '@tanstack/react-query';
import { AvatarImageTypes } from 'types/avatarTypes';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetFilterStaffs = () => {
  const { queryFn } = useQueryFnWithCommonParams<FilterStaffsResponse>({
    url: '/api/filter/staffs',
  });

  return useSuspenseQuery({
    queryKey: ['filter/staffs'],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetFilterStaffs;

export type StaffForFilter = {
  /** 강사 아이디  */
  id: number;
  /** 시설 아이디 */
  studio_id: number;
  /**  강사 이름 */
  name: string;
  /** 강사 전화번호  */
  mobile: string | null;
  /** 어카운트 아이디  */
  account_id: number | null;
  /** 아바타 이미지 */
  avatar: AvatarImageTypes | null;
  /** 강사 대표 색상  */
  color: string;
  /** 역할 이름  */
  role: string;
};

type FilterStaffsResponse = {
  data: StaffForFilter[];
};
