import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import StaffSearchField from 'pages/MoreDetails/Staff/components/StaffSearchField';
import { AssignedMemberPageDataType, AssignedMembersFormTypes } from 'pages/MoreDetails/Staff/Detail/StaffDetail/types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { assignedMemberTotalAtom } from 'recoil/staff';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ListContentHeader from 'sharedComponents/ListContentHeader';

import SkeletonAssignedMemberList from '../SkeletonAssignedMemberList';
import AssignedMemberList from './components/AssignedMemberList';
import { SORT_DATA } from './constants';

const AssignedMember = () => {
  const [pageData, setPageData] = useState<AssignedMemberPageDataType>({
    search: '',
    sort: 'nameAsc',
  });

  const assignedMemberTotal = useRecoilValue(assignedMemberTotalAtom);

  const methods = useForm<AssignedMembersFormTypes>({
    mode: 'onChange',
    defaultValues: {
      sort: pageData.sort || 'nameAsc',
      search: pageData.search || '',
    },
  });

  const changeSort = (e: { target: { value: string } }) => {
    setPageData({ ...pageData, sort: e.target.value as 'nameAsc' | 'nameDesc' | 'dateAsc' | 'dateDesc' });
  };

  const searchCancel = () => {
    setPageData({ ...pageData, search: '' });
  };

  const searchSubmit = () => {
    const search = methods.getValues('search');
    setPageData({ ...pageData, search });
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <StaffSearchField type="assignedMember" onSubmit={searchSubmit} onCancel={searchCancel} />
        <ListContentHeader sortData={SORT_DATA} total={assignedMemberTotal} onChange={changeSort} sort={pageData.sort} />
        <ApiBoundary fallback={<SkeletonAssignedMemberList />}>
          <AssignedMemberList pageData={pageData} setPageData={setPageData} />
        </ApiBoundary>
      </FormProvider>
    </Container>
  );
};

export default AssignedMember;

const Container = styled.section`
  min-height: calc(100vh - 280px);
  background-color: ${theme.color.gray7};

  .search-wrapper {
    position: initial;
    padding-top: 16px;
  }

  .assigned-members {
    ${theme.flex('column', 'center', 'center', 12)};
    padding: 0 20px 40px;
  }

  .search-bar {
    position: sticky;
    top: 0;
    padding: 16px 20px;
    background-color: ${theme.color.white};
    z-index: ${Z_INDEX.stickyTab};
  }
`;
