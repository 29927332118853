import { UnderlinedOptions } from 'components/TextField/components/UnderlinedTextField';
import { TextFieldPropTypes } from 'components/TextField/types';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import { Control, FieldPath, UseFormSetValue, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { TicketPaymentFormType } from '../types';

type KeyType = Extract<
  FieldPath<TicketPaymentFormType>,
  'payment.card_amount' | 'payment.cash_amount' | 'payment.wiretransfer_amount' | 'payment.unpaid_amount'
>;

export type PaymentTextFieldProps = Pick<TextFieldPropTypes, 'disabled'> &
  Pick<UnderlinedOptions, 'left' | 'textColor'> & {
    control: Control<TicketPaymentFormType>;
    setValue: UseFormSetValue<TicketPaymentFormType>;
    keyValue: KeyType;
  };

const PaymentTextField = ({ control, setValue, keyValue, disabled, textColor, left }: PaymentTextFieldProps) => {
  const keyAmount = useWatch({ control, name: keyValue });

  return (
    <UnderlinedTextFieldController
      controllerProps={{ control, name: keyValue }}
      inputMode="numeric"
      placeholder="0"
      textAlign="right"
      disabled={disabled}
      left={left}
      textColor={textColor}
      suffix={
        <Typography span size={15} weight={500}>
          원
        </Typography>
      }
      value={filters.numberComma(keyAmount)}
      onChange={e => {
        if (keyValue !== 'payment.unpaid_amount') {
          setValue('payment.unpaid_amount', 0, { shouldDirty: true });
        }
        setValue(keyValue, filters.numberComma(e.target.value, { reverse: true }), {
          shouldValidate: true,
          shouldDirty: true,
        });
      }}
    />
  );
};

export default PaymentTextField;
