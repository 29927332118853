import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingParams } from './usePostBooking';
import { BookingFailResponse } from './usePostBookingPrivate';

/** 그룹 수업에 정원이 다 찬 경우, 초과예약 */
const usePostBookingGroupOver = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingGroupOverParams>({
    method: `post`,
    url: `/api/booking/group/over`,
  });

  return useMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    BookingGroupOverParams | undefined,
    unknown
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail'],
      });
    },
  });
};

export default usePostBookingGroupOver;

export type BookingGroupOverParams = BookingParams & {
  /** 예약, 예약 대기 정원 다 찬 경우 초과예약 가능 */
  status: 'booked' | 'booking_waiting';
};
