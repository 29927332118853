import Button from 'components/Button';
import Form from 'components/Form';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { PLACEHOLDER } from 'constants/text';
import usePostIntegratedAccountLogin from 'hooks/service/mutations/usePostIntegratedAccountLogin';
import usePostOverlapNickname from 'hooks/service/mutations/usePostOverlapNickname';
import usePostUpdateNameNickname from 'hooks/service/mutations/usePostUpdateNameNickname';
import { useMemo, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'types/errorTypes';
import localStorage from 'utils/localStorage';

import { UnifiedAccountFormTypes } from '../types';
import SubmitButton from './SubmitButton';

type Props = {
  isLoginSuccess: boolean;
};

const UnifiedNameNickname = ({ isLoginSuccess }: Props) => {
  const navigate = useNavigate();

  const [isOverlapPass, setIsOverlapPass] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    reset,
    formState: { isValid, errors, dirtyFields },
  } = useFormContext<UnifiedAccountFormTypes>();

  const { mutate: postOverlapNicknameMutate } = usePostOverlapNickname();

  const { mutate: postIntegratedAccountLoginMutate } = usePostIntegratedAccountLogin();
  const { mutate: postUpdateNameNicknameMutate } = usePostUpdateNameNickname();

  const onError = (error: CustomError) => {
    const message = error.response?.data.message;
    setIsOverlapPass(false);
    setError('nickNameRequired', { message });
  };

  const postOverlapNickname = () => {
    const params = {
      nickname: getValues('nickNameRequired'),
    };

    postOverlapNicknameMutate(params, {
      onSuccess: () => {
        setIsOverlapPass(true);
        reset(
          { teacherNameRequired: getValues('teacherNameRequired'), nickNameRequired: getValues('nickNameRequired') },
          { keepDirtyValues: false },
        );
      },
      onError,
    });
  };

  const postNameNickname = ({ name, nickname }: { name: string; nickname: string }) => {
    const params = { name, nickname };
    postUpdateNameNicknameMutate(params, {
      onSuccess: () => {
        navigate('/select-studio');
      },
      onError,
    });
  };

  const onSubmit: SubmitHandler<UnifiedAccountFormTypes> = async inputValues => {
    const { selectedId, teacherNameRequired, nickNameRequired, mobile } = inputValues;

    const nameNicknameParams = {
      name: teacherNameRequired,
      nickname: nickNameRequired,
    };

    if (isLoginSuccess) {
      postNameNickname(nameNicknameParams);
    } else {
      const params = {
        mobile: mobile as string,
        selected_staff_account_id: selectedId as number,
      };

      postIntegratedAccountLoginMutate(params, {
        onSuccess: ({ data }) => {
          /** 계정통합 성공 시 새로운 토큰으로 갱신 */
          const accessToken = data.account_token;
          localStorage.set('access_token', accessToken);
          postNameNickname(nameNicknameParams);
        },
        onError,
      });
    }
  };

  const handleClear = (name: 'teacherNameRequired' | 'nickNameRequired') => {
    setValue(name, '', { shouldValidate: true });
  };

  const isSubmitDisabled = useMemo(() => {
    if (isOverlapPass && isValid) return false;
    return true;
  }, [isValid, isOverlapPass]);

  return (
    <Form id="name-nickname-form" onSubmit={handleSubmit(onSubmit)}>
      <OutlinedTextFieldController
        controllerProps={{ control, name: 'teacherNameRequired' }}
        placeholder={PLACEHOLDER.STAFF}
        required
        onClear={() => handleClear('teacherNameRequired')}
      />

      <OutlinedTextFieldController
        controllerProps={{ control, name: 'nickNameRequired' }}
        placeholder={PLACEHOLDER.NICKNAME}
        infoMessage={isOverlapPass ? '사용가능한 닉네임' : undefined}
        required
        suffix={
          <Button
            color="gray"
            fontSize={13}
            fontWeight={500}
            textColor="gray2"
            size="small"
            variant="contained"
            onClick={postOverlapNickname}
            padding={{ left: 8, right: 8 }}
            disabled={!dirtyFields.nickNameRequired || !!errors.nickNameRequired}>
            중복확인
          </Button>
        }
        onClear={() => handleClear('nickNameRequired')}
      />

      <SubmitButton disabled={isSubmitDisabled} />
    </Form>
  );
};

export default UnifiedNameNickname;
