import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Form from 'components/Form';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Timer from 'components/Timer';
import { PLACEHOLDER } from 'constants/text';
import usePostCodeConfirm from 'hooks/service/mutations/usePostCodeConfirm';
import usePostSendMobileConfirmCode from 'hooks/service/mutations/usePostSendMobileConfirmCode';
import TitleStep from 'pages/FindPassword/components/TitleStep';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { validationIdCodeAtom } from 'recoil/validationIdCode';
import SendMobileKakaoDrawer from 'sharedComponents/SendMobileKakaoDrawer';
import { CustomError } from 'types/errorTypes';
import { mobileConfirmCode, mobileRequired } from 'utils/validate';
import { object } from 'yup';

import { SUB_TITLE } from '../constants';
import { MobileDataType, MobileFormType } from '../types';

type Props = {
  nextStep: (data: MobileDataType) => void;
};

const yupSchema = object().shape({ mobileRequired, mobileConfirmCode });
const params = {
  studio_id: undefined,
  staff_id: undefined,
};

const MobileStep = ({ nextStep }: Props) => {
  const [isMobilePass, setIsMobilePass] = useState(false);
  const [isKakaoDrawerOpen, setIsKakaoDrawerOpen] = useState(false);

  const [validationIdCode, setValidationIdCode] = useRecoilState(validationIdCodeAtom);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    watch,
    formState: { isValid, dirtyFields, isSubmitting },
  } = useForm<MobileFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      mobileRequired: '',
      mobileConfirmCode: '',
    },
  });

  const { mutate: postSendMobileConfirmCodeMutate, isPending: isSendMobileConfirmCodeLoading } = usePostSendMobileConfirmCode({
    mobile: getValues('mobileRequired'),
  });

  const { mutate: postCodeConfirmMutate } = usePostCodeConfirm({ type: 'mobile-confirm' });

  const sendMobileConfirmCode = () => {
    postSendMobileConfirmCodeMutate(undefined, {
      onSuccess: ({ data }) => {
        const validationId = data.validation_code;
        setValidationIdCode(prev => ({ ...prev, id: validationId }));
        setIsMobilePass(true);
        setIsKakaoDrawerOpen(true);
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('mobileRequired', { message });
      },
    });
  };

  const save: SubmitHandler<MobileFormType> = inputValues => {
    const { mobileRequired } = inputValues;

    /** 휴대폰 번호 확인이 끝나지 않았으면, 인증코드 발송 */
    if (!isMobilePass) {
      sendMobileConfirmCode();
      return;
    }

    setValidationIdCode({
      id: validationIdCode.id,
      code: getValues('mobileConfirmCode'),
    });

    postCodeConfirmMutate(params, {
      onSuccess: () => {
        nextStep({ mobile: mobileRequired });
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('mobileConfirmCode', { message });
      },
    });
  };

  const bottomButtonText = useMemo(() => (isMobilePass ? '확인' : '인증번호 전송'), [isMobilePass]);

  const handleClear = (name: 'mobileRequired') => {
    setValue(name, '', { shouldValidate: true });
  };

  const subTitleOptions = {
    bottom: 40,
    title: isMobilePass ? SUB_TITLE.CHECK_MOBILE : SUB_TITLE.ENTER_MOBILE,
    message: { position: 'bottom', text: '로그인 시 아이디로 사용됩니다.' },
  };

  return (
    <>
      <TitleStep currentStep={1} subTitleOptions={subTitleOptions} />
      <Form
        onSubmit={handleSubmit(save)}
        footerButton={{
          variant: 'contained',
          color: 'point',
          fullWidth: true,
          size: 'large56',
          fontSize: 15,
          text: bottomButtonText,
          disabled:
            !isValid ||
            isSubmitting ||
            (isMobilePass && (watch('mobileConfirmCode').length !== 6 || !dirtyFields.mobileConfirmCode)),
        }}>
        <OutlinedTextFieldController
          controllerProps={{ control, name: 'mobileRequired' }}
          placeholder={PLACEHOLDER.MOBILE}
          onClear={() => handleClear('mobileRequired')}
          readOnly={isMobilePass}
        />
        {isMobilePass && (
          <>
            <OutlinedTextFieldController
              controllerProps={{ control, name: 'mobileConfirmCode' }}
              suffixMarginRight={8}
              suffix={
                <Button
                  color="gray"
                  fontSize={13}
                  fontWeight={500}
                  textColor="gray2"
                  size="small"
                  variant="contained"
                  onClick={sendMobileConfirmCode}
                  padding={{ left: 8, right: 8 }}
                  disabled={isSendMobileConfirmCodeLoading}>
                  인증번호 재발송
                </Button>
              }
            />
            {!isSendMobileConfirmCodeLoading && <Timer count={60 * 5} />}
          </>
        )}
      </Form>

      <SendMobileKakaoDrawer isOpen={isKakaoDrawerOpen} onClose={() => setIsKakaoDrawerOpen(false)} />
    </>
  );
};

export default MobileStep;
