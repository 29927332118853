import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';

type Props = {
  start_date: string;
  end_date: string;
  /**
   * 빈 배열일 경우 type 전체 필터 적용
   */
  type: Array<'G' | 'P'>;
  /**
   * 빈 배열일 경우 staff 전체 필터 적용
   */
  staff_ids: number[];
  enabled: boolean;
};

const useGetAllScheduleLecture = ({ enabled, ...params }: Props) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<ScheduleLectureResponse, ScheduleLectureParams>({
    url: '/api/schedule/lecture',
    params: {
      ...params,
    },
  });

  return useQuery({
    queryKey: ['schedule/lecture', { ...params, enabled }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllScheduleLecture;

export type ScheduleLectureResponse = ScheduleLectureType[];

type ScheduleLectureType = {
  id: number;
  studio_id: number;
  title: string;
  course_id: number;
  staff: StaffType;
  booking_closed_at: string | null;
  division_id: number | null;
  attendance_type: string;
  is_booking_only: boolean;
  start_on: string;
  end_on: string;
  max_trainee: number;
  min_trainee: number;
  current_trainee_count: number;
  total_current_trainee_count: number;
  comparison_min_trainee_of_total_current_count: number | null;
  waiting_trainee_limit: number;
  coupon_deduction: boolean;
  booking_start_at: string | null;
  booking_end_at: string | null;
  enter_start_at: string;
  enter_end_at: string;
  booking_cancel_start_at: string | null;
  booking_cancel_end_at: string;
  booking_auto_shift_available_minutes_from_start: string;
  daily_change_booking_end_at: string;
  autoclose_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  deleted_for: string | null;
  deleted_by: number | null;
  type: 'G' | 'P';
};

type StaffType = {
  id: number;
  studio_id: number;
  user_grade_id: number | null;
  name: string;
  account_type: 'userAccount' | 'staffAccount';
  account_id: number | null;
  mobile: string | null;
  vaccination_yn: 'Y' | 'N';
  registered_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  profile: ProfileType;
};

type ProfileType = {
  id: number;
  studio_id: number;
  studio_user_id: number;
  name: string;
  self_introduction: string | null;
  representative_color: string;
  hired_at: string | null;
  get_noti_all: 0 | 1;
  private_start_interval_on_off: boolean;
  private_start_interval_am: 30 | 60;
  private_start_interval_pm: 30 | 60;
  birthday: string | null;
  gender: 'F' | 'M';
  registered_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  web_token: string | null;
};

type ScheduleLectureParams = Omit<Props, 'enabled'>;
