export const color = {
  primary: '#6C85FB',
  point: '#353D49',
  secondary1: '#0DC0A0',
  secondary2: '#AB89E9',
  secondary3: '#F96E6E',
  error: '#EE1100',

  // Grey Scale
  white: '#FFFFFF',
  gray8: '#F8F9FA',
  gray7: '#F3F5F7',
  gray6: '#EEF0F3',
  gray5: '#E5E8ED',
  gray4: '#C3C6CB',
  gray3: '#9FA2A5',
  gray2: '#616264',
  gray1: '#2E2E2E',

  /** 대표 컬러 -- */
  red0: '#FF8982',
  red1: '#FF6A62',
  red2: '#CB3F39',
  red3: '#8D1D19',
  red4: '#560300',

  pink0: '#FFACC7',
  pink1: '#FF79A5',
  pink2: '#C36382',
  pink3: '#90435E',
  pink4: '#5E1A30',

  softRed0: '#FFAFAF',
  softRed1: '#FF7A7A',
  softRed2: '#CB5555',
  softRed3: '#954445',
  softRed4: '#5E3434',

  orange0: '#FF9B69',
  orange1: '#FF8C53',
  orange2: '#CD581C',
  orange3: '#95390B',
  orange4: '#61270A',

  softYellow0: '#FED04D',
  softYellow1: '#FDBC00',
  softYellow2: '#BF8F00',
  softYellow3: '#9A771C',
  softYellow4: '#63511C',

  green0: '#B8E371',
  green1: '#90CD2C',
  green2: '#64A015',
  green3: '#627F3C',
  green4: '#425527',

  emeraldGreen0: '#69DDC1',
  emeraldGreen1: '#2BCFA8',
  emeraldGreen2: '#20A975',
  emeraldGreen3: '#3E8369',
  emeraldGreen4: '#285746',

  modernCyan0: '#95D9F2',
  modernCyan1: '#59C3EA',
  modernCyan2: '#339CC3',
  modernCyan3: '#457D92',
  modernCyan4: '#2D5361',

  skyBlue0: '#9BC6F8',
  skyBlue1: '#6DA8F5',
  skyBlue2: '#3F79C6',
  skyBlue3: '#466895',
  skyBlue4: '#2F4463',

  blueViolet0: '#858FC6',
  blueViolet1: '#616EB5',
  blueViolet2: '#4F5B92',
  blueViolet3: '#343D64',
  blueViolet4: '#222843',

  softViolet0: '#BEA8FB',
  softViolet1: '#A688FA',
  softViolet2: '#8F74BF',
  softViolet3: '#66528C',
  softViolet4: '#44375D',
  /** // */

  /** 기타일정 컬러 */
  magenta: '#C63361',
  purple: '#822CA4',
  violet: '#5837AA',
  sapphire: '#3C48A5',
  easternBlue: '#0F9AB9',
  horizonBlue: '#5A8D9B',
  prussianBlue: '#3D7398',
  oliveGreen: '#5C9E51',
  ashGreen: '#778163',
  gray: '#757575',
  redOrange: '#E25D33',
  burntUmber: '#B7643E',
  woodBrown: '#99826B',
  sandBrown: '#B58149',
  /** // */

  mainTopBackground: 'linear-gradient(99.32deg, #5f98ff -3.93%, #a589f7 127.09%)',

  /** 배경-텍스트 대비를 위한 예외 컬러 */
  pink0Contrast: '#663330',
  softRed0Contrast: '#6D2622',
  orange0Contrast: '#713408',
  softYellow0Contrast: '#5A4401',
  softYellow1Contrast: '#4C3800',
  green0Contrast: '#3B5610',
  emeraldGreen0Contrast: '#356256',
  modernCyan0Contrast: '#1C5469',
  skyBlue0Contrast: '#334B68',
  softViolet0Contrast: '#40375A',
};

export const buttonColor = {
  point: {
    contained: color.point,
    active: '#1e2227',
  },
  blue: {
    contained: 'rgba(108, 133, 251, 0.16)',
    active: 'rgba(108, 133, 251, 0.32)',
  },
};

export const buttonHeight = {
  tiny: 20,
  tiny2: 20,
  small: 28,
  medium40: 40,
  medium48: 48,
  large56: 56,
  large60: 60,
};

export const flex = (direction = 'row', align = 'center', justify = 'center', gap = 0) => `
display: flex;
flex-direction: ${direction};
align-items: ${align};
justify-content: ${justify};
gap: ${gap + 'px'};
`;

export const textEllipsis = (clamp = 1) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${clamp};
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
`;

export const theme = {
  color,
  buttonColor,
  buttonHeight,
  flex,
  textEllipsis,
};

/**
 * 강사 대표 컬러와 리뉴얼 강사 대표 컬러 매칭
 * ex: MATCH_REPRESENTATIVE_COLOR[서버색상코드]
 */
export const MATCH_REPRESENTATIVE_COLOR: ColorType = {
  fa8787: 'red0',
  f95454: 'red1',
  c74343: 'red2',
  '953232': 'red3',
  '632121': 'red4',

  ffb7ce: 'pink0',
  ff99b9: 'pink1',
  cc7a94: 'pink2',
  '995b6f': 'pink3',
  '663d4a': 'pink4',

  ffa4a8: 'softRed0',
  ff7e83: 'softRed1',
  cc6468: 'softRed2',
  '994b4e': 'softRed3',
  '663234': 'softRed4',

  ffad87: 'orange0',
  ff8a54: 'orange1',
  cc6e43: 'orange2',
  '995232': 'orange3',
  '663721': 'orange4',

  ffda62: 'softYellow0',
  ffcb1f: 'softYellow1',
  cca218: 'softYellow2',
  '997912': 'softYellow3',
  '66510c': 'softYellow4',

  b5e384: 'green0',
  '96d750': 'green1',
  '78ac40': 'green2',
  '5a8130': 'green3',
  '3c5620': 'green4',

  '68e7c5': 'emeraldGreen0',
  '27dead': 'emeraldGreen1',
  '1fb18a': 'emeraldGreen2',
  '178567': 'emeraldGreen3',
  '0f5845': 'emeraldGreen4',

  '81e0fa': 'modernCyan0',
  '4bd4f8': 'modernCyan1',
  '3ca9c6': 'modernCyan2',
  '2d7f94': 'modernCyan3',
  '1e5463': 'modernCyan4',

  '92c6ff': 'skyBlue0',
  '64aeff': 'skyBlue1',
  '508bcc': 'skyBlue2',
  '3c6899': 'skyBlue3',
  '284566': 'skyBlue4',

  '8792c5': 'blueViolet0',
  '5464ad': 'blueViolet1',
  '43508a': 'blueViolet2',
  '323c67': 'blueViolet3',
  '212845': 'blueViolet4',

  c9aaf4: 'softViolet0',
  b286f0: 'softViolet1',
  '8e6bc0': 'softViolet2',
  '6a5090': 'softViolet3',
  '473560': 'softViolet4',
};

export const ETC_SCHEDULE_COLOR: ColorType = {
  C63361: 'magenta',
  '822CA4': 'purple',
  '5837AA': 'violet',
  '3C48A5': 'sapphire',
  '0F9AB9': 'easternBlue',
  '5A8D9B': 'horizonBlue',
  '3D7398': 'prussianBlue',
  '5C9E51': 'oliveGreen',
  '778163': 'ashGreen',
  '757575': 'gray',
  E25D33: 'redOrange',
  B7643E: 'burntUmber',
  '99826B': 'woodBrown',
  B58149: 'sandBrown',
};

export type ColorType = {
  [type: string]: keyof typeof color;
};
