import dayjs from 'dayjs';
import useToast from 'hooks/useToast';
import { MemberCounselSelectedFilterType } from 'pages/Member/types';
import { useState } from 'react';
import PeriodFilter from 'sharedComponents/Filters/PeriodFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

type CounselPeriod = { type: number; start: string; end: string };

const CounselPeriodFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const { setToast } = useToast();
  const previousOptions = (selectedOption as MemberCounselSelectedFilterType['period']) ?? [];

  const isCounselPeriod = (option: MemberCounselSelectedFilterType['period'] | any): option is CounselPeriod => {
    return Array.isArray(option) === false && option !== null && 'start' in option && 'end' in option;
  };

  let initialDate: string[] = [];
  if (isCounselPeriod(previousOptions)) {
    initialDate = [previousOptions.start, previousOptions.end];
  }

  const [date, setDate] = useState<string[]>(initialDate);

  const setFixedPeriod = (option: FilterOptionsType) => {
    const unit = option.value;
    let startDate: string;
    const endDate = filters.dateDash(dayjs());
    startDate = filters.dateDash(dayjs().subtract(1, 'month'));

    if (unit === 90) {
      startDate = filters.dateDash(dayjs().subtract(3, 'month'));
    } else if (unit === 180) {
      startDate = filters.dateDash(dayjs().subtract(6, 'month'));
    }
    setDate([startDate, endDate]);
    changeOptions({ value: { type: option.value as string, start: startDate, end: endDate }, label: '' }, 'period');
  };

  const saveCustomPeriod = (period: string[]) => {
    if (dayjs(period[0]).isBefore(dayjs(period[1]).subtract(3, 'year'))) {
      setToast({ message: '최대 3년까지 선택 가능합니다.', bottom: 92 });
      if (date.length === 2) {
        setDate([date[0], date[1]]); // 기간선택 초기화
      }
      return true;
    }
    const dateFormatted = period.map(filters.dateDash);
    setDate(dateFormatted);
    changeOptions({ value: { type: 'custom', start: dateFormatted[0], end: dateFormatted[1] }, label: '' }, 'period');
    return false;
  };

  return (
    <PeriodFilter
      description="최근 3년까지의 이력만 조회 가능합니다."
      currentFilter={currentFilter}
      selectedOption={selectedOption}
      onClick={setFixedPeriod}
      initialDate={date}
      onSave={saveCustomPeriod}
    />
  );
};

export default CounselPeriodFilter;
