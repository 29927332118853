import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import MobileDetail from './components/MobileDetail';

const MoreDetailsMessageMobileDetail = () => {
  const id = useParamsId();
  return (
    <ApiBoundary>
      <MobileDetail id={id} />
    </ApiBoundary>
  );
};

export default MoreDetailsMessageMobileDetail;
