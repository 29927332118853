import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import React from 'react';
import SortButton from 'sharedComponents/SortButton';

import CheckMember from './CheckMember';

type Props = {
  currentTab: string;
};

const MemberSkeletonList = ({ currentTab }: Props) => {
  const DUMMY_LENGTH = 5;
  const dummyArray = Array.from({ length: DUMMY_LENGTH }, (_, index) => index);
  const isCounsel = currentTab === 'counsel';

  return (
    <>
      <TotalWrapper>
        <Typography size={13} weight={500} textColor="gray3">
          총 <b>0</b>명
        </Typography>
        {isCounsel && <CheckMember />}
        {!isCounsel && <SortButton>이름 오름차순</SortButton>}
      </TotalWrapper>
      {dummyArray.map(index => (
        <Card key={index} isCounsel={isCounsel}>
          <div className="skeleton-card-header">
            <Skeleton height={22} type="round" />
            <Skeleton height={22} type="round" />
            <Skeleton height={16} type="round" />
          </div>
          <StyledDivider thin />
          {!isCounsel && <Skeleton width={100} height={16} className="margin" />}
          <Skeleton width={isCounsel ? 120 : 150} height={isCounsel ? 22 : 14} type="round" />
          <Skeleton height={isCounsel ? 36 : 60} type="round" />
        </Card>
      ))}
    </>
  );
};

export default MemberSkeletonList;

const TotalWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  margin-bottom: 16px;
  padding-top: 24px;
  b {
    color: ${theme.color.primary};
  }
`;

const Card = styled.div<{ isCounsel: boolean }>`
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 18px 20px;
  margin-bottom: 12px;

  [type='round'] {
    border-radius: 3px;
  }

  .skeleton-card-header {
    display: grid;
    gap: 4px;
    ${({ isCounsel }) => (isCounsel ? counselCardHeaderStyle : memberCardHeaderStyle)}
  }
  .margin {
    margin-bottom: 5px;
  }
  > :last-of-type {
    margin-top: ${({ isCounsel }) => (isCounsel ? '10px' : '14px')};
  }
`;

const memberCardHeaderStyle = `
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 50px 1fr;
  > :first-of-type {
    border-radius: 100%;
    grid-row: 1 / span 2;
    width: 40px;
    height: 40px;
  }
  > :last-of-type {
    width: 100px;
  }
`;

const counselCardHeaderStyle = `
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 56px;
  > :nth-of-type(2) {
    width: 44px;
    margin-left: auto;
  }
  > :last-of-type {
    grid-column: 1 / span 2;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 14px 0;
`;
