import { useQueryClient } from '@tanstack/react-query';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import StudioException from 'sharedComponents/StudioException';

import EmptyList from './components/EmptyList';
import NotificationHeader from './components/NotificationHeader';
import NotificationList from './components/NotificationList';
import SkeletonHeader from './components/SkeletonHeader';
import SkeletonList from './components/SkeletonList';
import { NOTIFICATION_TEXT } from './constants';

const Notification = () => {
  const queryClient = useQueryClient();
  const refreshNotifications = () => {
    queryClient.invalidateQueries({ queryKey: ['staff/notice'] });
  };

  const header = {
    title: NOTIFICATION_TEXT.title,
    leftAlign: true,
    rightButton: (
      <IconButton onClick={refreshNotifications}>
        <Icon name="headerReset" />
      </IconButton>
    ),
  };

  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0;
  if (!isStudioSelected) {
    return (
      <ApiBoundary>
        <StudioException
          noConnectedBackground={
            <MainLayout bottomNav header={header}>
              <EmptyList />
            </MainLayout>
          }
        />
      </ApiBoundary>
    );
  }
  return (
    <MainLayout bottomNav header={header}>
      <ApiBoundary fallback={<SkeletonHeader />}>
        <NotificationHeader />
      </ApiBoundary>
      <ApiBoundary fallback={<SkeletonList />}>
        <NotificationList />
      </ApiBoundary>
    </MainLayout>
  );
};

export default Notification;
