import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';

import formatCommonTicketInfo from './formatCommonTicketInfo';
import getDaysDiff from './getDaysDiff';
import getTicketInfoByType from './getTicketInfoByType';

/**
 * 리스트 형식 디자인에 사용되는 부모 수강권 정보 세팅 함수
 */
const formatTicketCard = (ticket: TicketsResponse): FormatTicketCard => {
  const { ticketColor, classType, convertTicketType, maxTrainee } = formatCommonTicketInfo(ticket);

  const { showCoupon } = getTicketInfoByType(ticket.type);
  const totalDays = ticket.available_period
    ? `${ticket.available_period}일`
    : `${getDaysDiff(ticket.expire_at, ticket.availability_start_at)}일`;
  const maxCoupon = showCoupon ? `전체 ${ticket.max_coupon}회` : null;
  const remainingCoupon = !showCoupon || !ticket.remaining_coupon ? null : `잔여 ${ticket.remaining_coupon}회`;
  const maxCancel = showCoupon ? `취소 ${ticket.max_cancel}회` : null;
  const dailyBookingChangeLimit = ticket.daily_booking_change_limit ? `당일변경 ${ticket.daily_booking_change_limit}회` : null;

  let limitText = null;
  if (ticket.booking_limit_per_month) {
    limitText = `월 ${ticket.booking_limit_per_month}회`;
  } else if (ticket.booking_limit_per_week) {
    limitText = `주 ${ticket.booking_limit_per_week}회`;
  }

  let bottomOptions: (string | null)[] = [];
  /** 프라이빗 */
  if (ticket.available_class_type === 'P') {
    if (ticket.type === 'T') {
      // 횟수제 or 차감제
      bottomOptions = [totalDays, maxCoupon, maxCancel];
    } else if (ticket.type === 'P') {
      // 기간제
      bottomOptions = [totalDays];
    }
  }

  /** 그룹 */
  if (ticket.available_class_type === 'G') {
    if (ticket.type === 'T') {
      // 횟수제
      bottomOptions = [totalDays, dailyBookingChangeLimit, remainingCoupon, maxCoupon, maxCancel];
    } else if (ticket.type === 'P') {
      // 기간제
      bottomOptions = [totalDays, dailyBookingChangeLimit, maxCancel];
    }
  }

  /** 상품 */
  switch (ticket.type) {
    case 'RM': // 혼합
      bottomOptions = [totalDays, maxCoupon];
      break;
    case 'RT': // 횟수제
      bottomOptions = [maxCoupon];
      break;
    case 'RP': // 기간제
      bottomOptions = [totalDays];
      break;
    case 'S': // 판매
      bottomOptions = [];
      break;
  }

  return {
    id: `${ticket.id}`,
    title: ticket.title,
    isFavorite: !!ticket.favorite_stamp,
    ticketColor,
    topOptions: [classType, convertTicketType, maxTrainee, limitText],
    bottomOptions,
  };
};

export default formatTicketCard;
