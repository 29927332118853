import Icon from 'components/Icon';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import { useFormContext } from 'react-hook-form';

import { BOOKING_GROUP_FORM_TEXT } from '../../constants';
import { BookingGroupCreateFormType } from '../../types';

/** 수업 소개 */
const Description = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();

  return (
    <UnderlinedTextareaController
      controllerProps={{ control, name: 'description' }}
      placeholder={BOOKING_GROUP_FORM_TEXT.description.placeholder}
      left={<Icon name="explain" color="gray2" />}
      showBottom={false}
      maxLength={200}
    />
  );
};

export default Description;
