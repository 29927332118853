import styled from '@emotion/styled';
import Form from 'components/Form';
import useQueryString from 'hooks/useQueryString';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import Room from 'pages/Booking/components/Room';
import ConfirmNoRoomDialog from 'pages/Booking/components/Room/ConfirmNoRoomDialog';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import convertTargetsText from 'utils/convertTargetsText';

import { BOOKING_PRIVATE_FORM_TEXT } from '../../constants';
import { BookingPrivateFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import NextButton from '../components/NextButton';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';

const PrivateCreateForm = () => {
  const { handleSubmit, getValues, setValue } = useFormContext<BookingPrivateFormType>();
  const [isOpenNoRoomDialog, setIsOpenNoRoomDialog] = useState(false);

  const { setSearchParams, getAllSearchParams } = useQueryString();
  const goNextPage = () => {
    /** 수업명 입력하지 않았을 때, 수강회원 이름으로 세팅 */
    if (!getValues('title')) {
      const member = getValues('selectedUserTickets').map(({ member }) => member);
      const title = convertTargetsText(member);
      setValue('title', title, { shouldDirty: true });
    }

    setSearchParams({
      ...getAllSearchParams(),
      step: '2',
    });
  };

  const submit = (values: BookingPrivateFormType) => {
    const { roomId } = values;

    /** 룸 선택없이 다음 버튼 누를 경우 확인 팝업 노출 */
    if (roomId === null || roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value) {
      setIsOpenNoRoomDialog(true);
      return;
    }
    goNextPage();
  };

  return (
    <>
      <PrivateFormContainer onSubmit={handleSubmit(submit)}>
        <SelectStaff />
        <BookingRangeDate isRange={true} />
        <BookingRangeTime pageMode="create" />
        <RepeatField />
        <Room />
        <LectureSize />
        <SelectMembersField />
        <ClassTitle description={BOOKING_PRIVATE_FORM_TEXT.title.description} />
        <NextButton />
      </PrivateFormContainer>

      {isOpenNoRoomDialog && (
        <ConfirmNoRoomDialog
          onClick={() => {
            setIsOpenNoRoomDialog(false);
            goNextPage();
          }}
          onClose={() => setIsOpenNoRoomDialog(false)}
        />
      )}
    </>
  );
};

export const PrivateFormContainer = styled(Form)`
  min-height: 100%;
  padding-bottom: 116px;

  .underline-textarea {
    border-bottom: none;
  }
`;

export default PrivateCreateForm;
