import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Form, { Props as FormProps } from 'components/Form';
import Icon from 'components/Icon';
import Textarea from 'components/Textarea';
import OutlinedTextField from 'components/TextField/components/OutlinedTextField';
import Typography from 'components/Typography';
import { Controller, UseFieldArrayPrepend, UseFieldArrayRemove, useFormContext } from 'react-hook-form';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';
import { ProfileUpdateFormType, StaffCreateFormType } from '../types';

type Props = {
  onSubmit: () => void;
  careerFields: StaffCreateFormType['careers'];
  prepend: UseFieldArrayPrepend<StaffCreateFormType, 'careers'> | UseFieldArrayPrepend<ProfileUpdateFormType, 'careers'>;
  remove: UseFieldArrayRemove;
  footerButton?: FormProps['footerButton'];
};

const StaffProfileForm = ({ onSubmit, careerFields, prepend, remove, footerButton }: Props) => {
  const { control } = useFormContext();

  return (
    <Container onSubmit={onSubmit} footerButton={footerButton}>
      <Controller
        name="profile.self_introduction"
        control={control}
        render={({ field }) => {
          return (
            <Textarea
              placeholder="자기소개 입력"
              label={STAFF_PROFILE_FORM_TEXT.subTitle.selfIntroduction}
              height={164}
              {...field}
            />
          );
        }}
      />

      <Typography className="sub-title" size={13} weight={500} textColor="gray2">
        {STAFF_PROFILE_FORM_TEXT.subTitle.careers}
      </Typography>

      <ul className="careers">
        {careerFields.map((field, index) => {
          return (
            <li className="career" key={field.id}>
              <Controller
                control={control}
                name={`careers.${index}.career`}
                render={({ field }) => (
                  <OutlinedTextField placeholder="주요이력 입력" id={field.name} suffix={<></>} {...field} />
                )}
              />
              <IconButton onClick={() => (index === 0 ? prepend({ career: '' }) : remove(index))}>
                <Icon name={index === 0 ? 'plusFill' : 'deleteFill'} fillColor={theme.color.gray3} />
              </IconButton>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default StaffProfileForm;

const Container = styled(Form)`
  padding: 16px 20px 100px;

  .sub-title {
    margin: 34px 0 14px;
  }

  .careers {
    ${theme.flex('column', 'center', 'center', 8)};

    .career {
      ${theme.flex()};
      gap: 8px;
      width: 100%;

      .outlined-text-field {
        flex: 1;
      }
    }
  }
`;
