import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Form from 'components/Form';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import usePatchAuthChangeStaffAccountName from 'hooks/service/mutations/usePatchAuthChangeStaffAccountName';
import useToast from 'hooks/useToast';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';
import { name } from 'utils/validate';
import { object } from 'yup';

import { CHANGE_NAME_TEXT } from '../constants';

type Props = {
  currentName: string;
};

const yupSchema = object().shape({ name });

const ChangeNameForm = ({ currentName }: Props) => {
  const { handleSubmit, control, formState, setFocus, setValue, watch } = useForm<{ name: string }>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      name: currentName,
    },
  });

  // 화면진입시 자동포커스
  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const { mutate: changeNameMutate } = usePatchAuthChangeStaffAccountName();

  const { setToast } = useToast();
  const navigate = useNavigate();
  const submitNameChange = ({ name }: { name: string }) => {
    const params = { name };
    changeNameMutate(params, {
      onSuccess: () => {
        setToast({ message: CHANGE_NAME_TEXT.success, type: 'success' });
        navigate(-1); // 이름 변경 페이지로 올 수 있는 경로가 내 계정 정보 밖에 없음
      },
    });
  };

  const name = watch('name');
  const { isDirty, isValid, isSubmitted } = formState; // footerButton disabled가 제대로 먹히지 않아 구조분해로 활용
  return (
    <Form onSubmit={handleSubmit(submitNameChange)}>
      <OutlinedTextFieldController
        controllerProps={{ control, name: 'name' }}
        onClear={() => setValue('name', '', { shouldValidate: true })}
        placeholder={CHANGE_NAME_TEXT.placeholder}
      />
      <StyledButton
        type="submit"
        fullWidth
        color="point"
        size="medium48"
        variant="contained"
        disabled={!isDirty || !isValid || !name.length}>
        {CHANGE_NAME_TEXT.submit}
      </StyledButton>
      <DialogBeforeLeave isBlocked={isDirty && !isSubmitted} />
    </Form>
  );
};

export default ChangeNameForm;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;
