export const HOLIDAY_SETTING_TEXT = {
  headerTitle: '휴일 설정',
  autoDeductionEnabled: {
    enabled: '자동차감',
    notEnabled: '자동차감 실행 안함',
    tooltipMessage: ['해당 주에 대한 자동차감이', '실행되지 않습니다.'],
  },
  submit: {
    confirm: '휴일로 설정하시겠습니까?',
    warnings: [
      '이미 등록되어 있는 수업/예약/상담은 모두 취소 되며 복구할 수 없습니다.',
      '휴일로 설정된 날짜에는 그룹 수업을 생성할 수 없습니다.',
    ],
  },
  historys: {
    title: '설정 이력',
  },
  create: {
    success: '휴일이 추가되었습니다.',
  },
  cancel: {
    success: '휴일이 취소되었습니다.',
    confirm: '휴일을 취소하시겠습니까?',
  },
};
