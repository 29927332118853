import { ETC_SCHEDULE_PERMISSION } from 'pages/EtcSchedule/constants';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { EtcScheduleDetailResponse } from './service/queries/useGetEtcScheduleEtcScheduleTime';
import usePermission from './usePermission';

export type EtcDetailPermissionKeyType = 'create' | 'update' | 'delete';
const initialValue: Record<EtcDetailPermissionKeyType, boolean> = {
  create: false,
  update: false,
  delete: false,
};

/**
 * 기타 일정 상세 페이지에서 강사별 일정 생성/수정/삭제 권한 체크
 * @param staffs 기타 일정에 포함되어 있는 강사 리스트
 */
const useCheckPermissionEtcDetail = (staffs: EtcScheduleDetailResponse['staffs']) => {
  const currentStaffId = useRecoilValue(staffIdAtom);

  const { hasPermission } = usePermission();
  const staffType = staffs.some(({ id }) => id === currentStaffId) ? 'mine' : 'others';

  const {
    create: canCreateEtcSchedule,
    update: canUpdateEtcSchedule,
    delete: canDeleteEtcSchedule,
  } = Object.entries(ETC_SCHEDULE_PERMISSION[staffType]).reduce((acc, [key, value]) => {
    acc[key as EtcDetailPermissionKeyType] = hasPermission(value);
    return acc;
  }, initialValue);

  return { canCreateEtcSchedule, canUpdateEtcSchedule, canDeleteEtcSchedule };
};

export default useCheckPermissionEtcDetail;
