import styled from '@emotion/styled';
import SearchBar from 'components/SearchBar';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useState } from 'react';

const Search = () => {
  // 리스트에 반영되는 검색어, 다른 탭에도 검색결과가 노출되어야 함
  const { searchKeyword, setSearchKeyword } = useSearchKeywords('member');

  // 검색창 UI에 표시되는 검색어
  const [keyword, setKeyword] = useState(searchKeyword);

  const clearSearchKeywords = () => {
    setSearchKeyword('');
    setKeyword('');
  };

  return (
    <Container>
      <SearchBar
        id="member-search-bar"
        placeholder="회원명, 주소, 메모, 휴대폰 번호 검색"
        hasInputFieldColor={false}
        value={keyword}
        onCancel={clearSearchKeywords}
        onClear={clearSearchKeywords}
        onChange={e => setKeyword(e.target.value)}
        onSubmitForm={() => setSearchKeyword(keyword)}
      />
    </Container>
  );
};

export default Search;

const Container = styled.div`
  margin-bottom: 20px;
  padding: 56px 20px 0;
`;
