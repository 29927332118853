import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { ReportCommonType, Ticket, UserTicket } from 'pages/MoreDetails/Report/type';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';

const useInfinityReportSale = (params: ReportSaleParams) => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<ReportSaleResponse, ReportSaleParams>({
    url: `/api/report/sale`,
    params: { ...params },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`infinity/report/sale`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        sales: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
        totalAmount: data.pages[0].data.total_amount,
        totalCount: data.pages[0].data.total_count,
      };
    },
  });
};

export default useInfinityReportSale;

type Payment = {
  /**결제금액  */
  amount: number;
  /** 커미션 비용i */
  commission_amount: number;
  /** 	카드 결제 금액 */
  card_amount: number;
  /** 현금 결제 금액 */
  cash_amount: number;
  /** 미수금 금액 */
  unpaid_amount: number;
  /** 포인트 결제 금액 */
  point_amount: number;
  /** 직전 결제 금액 */
  before_user_ticket_amount: number;
  /** 양도금 비용 */
  transfer_amount: number;
  /** 커미션 비용 */
  wiretransfer_amount: number;
  /** 할부 기간 */
  installment_period: number;
};

export type SalesDataResponse = {
  /** 결제정보 */
  payment: Payment;
  /** 수강권 결제 구분 */
  label: 'new' | 're-take' | 'experience' | 'refund' | 'upgrade' | 'installment_payment' | 'commission' | 'transfer' | 'unpaid';
  /** 결제 구분
   * 'cash' : 현금 ,'card' : 카드 ,'mixed' : 복합
   */
  method: 'cash' | 'card' | 'mixed';
  /** 수강권 */
  ticket: Ticket;
  /** 유저 수강권 */
  user_ticket: UserTicket;
  /** 결제일 */
  settlement_at: string;
} & ReportCommonType;

export type ReportSaleParams = {
  /** 검색 시작일 */
  start_date: string;
  /** 검색 종료일 */
  end_date: string;
  /** 검색어 */
  search?: string;
  /** 수강권 ID */
  ticket_id?: number[];
  /** 결제 구분 */
  payment_type?: string;
  /** 상품 구분 */
  product_type?: string;
  /** 결제 방법 */
  payment_method?: string[];
  /** 페이지네이션 종류 */
  paginate_type: string;
};

export type ReportSaleResponse = {
  data: SalesDataResponse[];
  /** 수강권/상품 총 매출 */
  total_amount: number;
  /** 조회된 총 건수 */
  total_count: number;
};
