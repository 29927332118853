import styled from '@emotion/styled';
import Typography from 'components/Typography';
import _ from 'lodash';
import { FieldType } from 'pages/MoreDetails/Staff/Form/types';
import { useRecoilValue } from 'recoil';
import { eventScheduleSelectDateSelector } from 'recoil/staff';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

import { Props } from '..';
import { NO_CONTENT_TEXT } from '../constants';
import EventWorkTimeCard from './EventWorkTimeCard';

const EventWorkTimeCardList = ({
  eventWorkTimeFields,
  eventRestTimeFields,
  removeRestTime,
  appendTime,
  replaceFields,
  updateTime,
  selectEventWorkTimeFields,
  shapeCard,
}: Props) => {
  const selectDateTexts = useRecoilValue(eventScheduleSelectDateSelector);

  if (!selectDateTexts.length) {
    return (
      <NoContentText className="no-content-text" size={15} textColor="gray2">
        {NO_CONTENT_TEXT}
      </NoContentText>
    );
  }

  return (
    <Container shapeCard={shapeCard}>
      {selectEventWorkTimeFields.map((eventWorkTimeField, __, list) => {
        const { date, day_of_week } = eventWorkTimeField;

        /** 같은 요일 일괄 적용 버튼 노출 유무 */
        const uniqDays = _.uniqBy(list, 'day_of_week');
        const isSameDayApply = uniqDays.findIndex(uniqDay => {
          return uniqDay.date === date && uniqDay.day_of_week === day_of_week;
        });

        return (
          <EventWorkTimeCard
            key={`${date}-${day_of_week}`}
            index={eventWorkTimeFields.findIndex(field => field.id === eventWorkTimeField.id)}
            eventWorkTimeField={eventWorkTimeField}
            filterdEventWorkTimeFields={eventWorkTimeFields.filter(eventWorkTimeField => {
              return selectDateTexts.includes(eventWorkTimeField.date);
            })}
            eventRestTimeFields={eventRestTimeFields.filter(restTimeField => restTimeField.date === date)}
            updateTime={updateTime}
            removeRestTime={(fieldIndex: number) => removeRestTime(fieldIndex)}
            appendTime={appendTime}
            replaceFields={(newFields: ScheduleFormType[], type: FieldType) => replaceFields(newFields, type)}
            eventRestTimeFieldsOrigin={eventRestTimeFields}
            isSameDayApply={isSameDayApply > -1}
            shapeCard={shapeCard}
          />
        );
      })}
    </Container>
  );
};

export default EventWorkTimeCardList;

const Container = styled.section<{ shapeCard: boolean }>`
  padding: ${({ shapeCard }) => shapeCard && '20px'};
  width: 100%;
`;

const NoContentText = styled(Typography)`
  margin-top: 120px;
  opacity: 0.8;
`;
