import { bookingParamsAtom, checkinParamsAtom, deletedLectureParamsAtom } from 'recoil/moreDetail/atoms';

import BookingFilter from './BookingFilter';
import CheckinFilter from './CheckinFilter';
import DeletedLectureFilter from './DeletedLectureFilter';

type Props = {
  currentTab: string;
  isShowMoreDetailSchedule: boolean;
};

const ScheduleFilters = ({ currentTab, isShowMoreDetailSchedule }: Props) => {
  return (
    <>
      {currentTab === 'booking' && (
        <BookingFilter filterAtom={bookingParamsAtom} isShowMoreDetailSchedule={isShowMoreDetailSchedule} />
      )}
      {currentTab === 'checkin' && (
        <CheckinFilter filterAtom={checkinParamsAtom} isShowMoreDetailSchedule={isShowMoreDetailSchedule} />
      )}
      {currentTab === 'deletedLecture' && (
        <DeletedLectureFilter filterAtom={deletedLectureParamsAtom} isShowMoreDetailSchedule={isShowMoreDetailSchedule} />
      )}
    </>
  );
};

export default ScheduleFilters;
