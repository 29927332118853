import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { Color } from 'utils/getColor';

type Props = {
  startTime?: string;
  endTime?: string;
  sameTimeEvents: number;
  staffName: string;
  isCardBackground: boolean;
  currentContrastColor: Color;
};

const ScheduleContentInfo = ({
  startTime,
  endTime,
  sameTimeEvents,
  staffName,
  isCardBackground,
  currentContrastColor,
}: Props) => {
  const textColor = isCardBackground ? currentContrastColor : 'gray3';
  const textOpacity = isCardBackground ? 0.8 : 1;
  return (
    <Container>
      <Typography size={12} textColor={textColor} ellipsis={1} opacity={textOpacity}>
        {startTime} - {endTime}
      </Typography>

      {sameTimeEvents === 1 && (
        <Typography size={12} textColor={textColor} opacity={textOpacity}>
          <span className="vertical-line">|</span>
          {staffName}
        </Typography>
      )}
    </Container>
  );
};

export default ScheduleContentInfo;

const Container = styled.div`
  ${theme.flex('', '', '', 2)};
  padding-top: 2px;

  .vertical-line {
    margin: 0 6px;
    font-size: 1.1rem;
    opacity: 0.32;
  }
`;
