import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { ReactNode, useState } from 'react';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { FilterOptionsProps, FilterOptionsType, FilterType, ParamsType } from 'sharedComponents/Filters/types';
import WholeFilters from 'sharedComponents/Filters/WholeFilters';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  filters: FilterType[];
  isShowMoreDetailSchedule: boolean;
  renderWholeFilterPopupOptions: (props: FilterOptionsProps) => ReactNode;
};

const ScheduleWholeFilterPopup = <T extends ParamsType>({
  filterAtom,
  filters,
  isShowMoreDetailSchedule,
  renderWholeFilterPopupOptions,
}: Props<T>) => {
  const { setToast } = useToast();
  // 리스트에 반영되는 필터, 필터 적용버튼 누르면 변경됨
  const [selectedFilter, setSelectedFilter] = useRecoilState(filterAtom);
  const resetSelectedFilter = useResetRecoilState(filterAtom);

  // 필터 UI용 상태
  const [filterChanged, setFilterChanged] = useState(selectedFilter);

  const changeOption = (option: FilterOptionsType, key: string) => {
    setFilterChanged({ ...filterChanged, [key]: option.value });
  };

  const { setPopup } = usePopup();
  const closePopup = () => {
    setPopup(null);
  };

  const handleSave = () => {
    setSelectedFilter(filterChanged);
    closePopup();
    setToast({
      type: !isShowMoreDetailSchedule ? 'error' : 'success',
      message: !isShowMoreDetailSchedule ? '다른 강사 조회 권한이 없습니다.' : '필터가 적용되었습니다.',
      bottom: 68,
    });
  };

  return (
    <WholeFilters onReset={resetSelectedFilter} onSave={handleSave} onClose={closePopup}>
      {renderWholeFilterPopupOptions({ filters, filterChanged, changeOption })}
    </WholeFilters>
  );
};

export default ScheduleWholeFilterPopup;
