import useGetLectureCourse from 'hooks/service/queries/useGetLectureCourse';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useInfinityBookingList from 'hooks/service/queries/useInfinityBookingList';
import { convertBookingFormCommonState, convertRepeatFormState } from 'pages/Booking/utils';
import { useForm } from 'react-hook-form';
import filters from 'utils/filters';

import { BookingPrivateFormType, LectureSizeType } from '../../types';
import PrivateFormLayout from '../components/PrivateFormLayout';
import PrivateUpdateAllForm from './PrivateUpdateAllForm';
type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: courseData } = useGetLectureCourse(lectureData.course_id);
  const { data: bookings } = useInfinityBookingList(currentLectureId);

  const lectureSize = `${lectureData.max_trainee}` as LectureSizeType;
  const selectedUserTickets = bookings.map(({ member, user_ticket }) => {
    return { ...user_ticket, member };
  });
  const userTicketIds = bookings.map(booking => booking.user_ticket.id);

  const { repeat, repeatCustomWeekday } = convertRepeatFormState(
    courseData.schedules,
    courseData.start_date,
    courseData.end_date,
  );

  const { endDate, ...rest } = convertBookingFormCommonState(lectureData);
  const methods = useForm<BookingPrivateFormType>({
    defaultValues: {
      ...rest,
      endDate: filters.dateDash(courseData.end_date),
      lectureSize,
      repeat,
      repeatCustomWeekday,
      tempRepeatCustomWeekday: repeatCustomWeekday,
      selectedUserTickets,
      userTicketIds,
      tempUserTicketIds: userTicketIds,
      tempSelectedUserTickets: selectedUserTickets,
      originUserTicketIds: userTicketIds,
    },
  });

  return (
    <PrivateFormLayout pageMode="updateAll" {...methods}>
      <PrivateUpdateAllForm currentLecture={lectureData} course={courseData} />
    </PrivateFormLayout>
  );
};

export default FormMain;
