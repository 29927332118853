import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
/** Lottie 애니메이션 json 파일 경로  */
import { theme } from 'assets/styles';

import { LOADINGS } from './constants';

type Props = {
  type?: keyof typeof LOADINGS;
  loop?: IPlayerProps['loop'];
};

const Loading = ({ type = 'circles', loop }: Props) => {
  return (
    <Container width={LOADINGS[type].width}>
      <Player src={LOADINGS[type].src} loop={loop} autoplay />
    </Container>
  );
};

export default Loading;

const Container = styled.div<{ width: number }>`
  ${theme.flex('', 'center', 'center')};

  .lf-player-container {
    ${({ width }) => {
      return css`
        width: ${width}px;
        height: ${width}px;
      `;
    }}
  }
`;
