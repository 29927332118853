import { css } from '@emotion/react';
import styled from '@emotion/styled';
import LinkButton, { LinkButtonProps } from 'components/Button/LinkButton';
import Icon, { IconProps } from 'components/Icon';

type Props = LinkButtonProps & {
  iconName?: IconProps['name'];
  iconColor?: IconProps['color'];
  hasValue?: boolean;
};

/** 폼 내부의 왼쪽에 아이콘 있는 링크버튼 필드 */
const FormFieldLinkButton = ({ children, iconName, iconColor = 'gray2', hasValue, ...props }: Props) => {
  return (
    <StyledLinkButton
      gap={10}
      arrowBold
      arrowSize={16}
      fontWeight={500}
      leftIcon={iconName ? <Icon name={iconName} color={props.disabled ? 'gray3' : iconColor} /> : undefined}
      textColor={hasValue ? 'gray1' : 'gray3'}
      {...props}>
      {children}
    </StyledLinkButton>
  );
};

export default FormFieldLinkButton;

const StyledLinkButton = styled(LinkButton)<Pick<LinkButtonProps, 'disabled'>>(
  ({ disabled }) =>
    disabled &&
    css`
      svg {
        opacity: 0.8;
      }
    `,
);
