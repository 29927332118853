import { MemberFormType } from 'pages/MemberForm/types';
import ProductUpdateForm from 'pages/ProductForm/components/ProductUpdateForm';
import { ProductUpdateFormType } from 'pages/ProductForm/types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const ProductUpdate = () => {
  const { control } = useFormContext<MemberFormType>();
  const selectedUpdatedTicket = useWatch({ control, name: 'selectedUpdatedTicket' });

  const navigate = useNavigate();

  const { fields: fieldsUserTickets, update: updateCurrentUserTickets } = useFieldArray({
    control,
    name: 'userTickets',
    keyName: 'key',
  });
  const {
    fields: fieldsUpdateUserTickets,
    append: addUserTicket,
    update: updateUserTicket,
  } = useFieldArray({
    control,
    name: 'updateUserTickets',
    keyName: 'key',
  });

  const save = (values: ProductUpdateFormType) => {
    if (!fieldsUserTickets) return;

    const { userTicket, count, period, payment } = values;
    const userTicketId = userTicket.id;

    const updateIndex = fieldsUserTickets.findIndex(fieldItem => fieldItem.id === userTicketId);
    const updatedUserTicket = {
      ...userTicket,
      ...count,
      ...period,
      ...payment,
      userTicketId,
    };
    updateCurrentUserTickets(updateIndex, updatedUserTicket);

    const updatedTicket = {
      ...values,
      userTicketId,
    };
    if (!fieldsUpdateUserTickets.length) {
      addUserTicket(updatedTicket);
    } else {
      const index = fieldsUpdateUserTickets.findIndex(fieldItem => fieldItem.userTicket.id === values.userTicket.id);
      updateUserTicket(index, updatedTicket);
    }
    navigate(-1);
  };

  if (!selectedUpdatedTicket) {
    return <></>;
  }

  return <ProductUpdateForm userTicket={selectedUpdatedTicket} onSubmit={save} />;
};

export default ProductUpdate;
