import usePatchLectureGroupBulk from 'hooks/service/mutations/usePatchLectureGroupBulk';
import { LectureCourseResponse } from 'hooks/service/queries/useGetLectureCourse';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailUpdateAllBooking from 'pages/Booking/components/AfterRequestBooking/FailUpdateAllBooking';
import BookingEndDate from 'pages/Booking/components/BookingRangeDate/BookingEndDate';
import BookingStartDate from 'pages/Booking/components/BookingRangeDate/BookingStartDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { formatBookingGroupParams } from 'pages/Booking/utils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

import { BookingGroupCreateFormType } from '../../types';
import CommonGroupForm from '../components/CommonGroupForm';

type Props = {
  currentLecture: LectureDetailResponse;
  course: LectureCourseResponse;
};

const GroupUpdateAllForm = ({ currentLecture, course }: Props) => {
  const { handleSubmit } = useFormContext<BookingGroupCreateFormType>();

  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();
  const { mutate: groupBulkUpdateMutate, isPending } = usePatchLectureGroupBulk(currentLecture);

  const submit = (values: BookingGroupCreateFormType) => {
    const { start_date, ...rest } = formatBookingGroupParams(values);

    const params = {
      ...rest,
      start: currentLecture.start_on,
      start_date: course.start_date,
      is_force: false,
    };
    groupBulkUpdateMutate(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(
            <FailUpdateAllBooking
              fails={error.response?.data}
              onSubmit={options => groupBulkUpdateMutate({ ...params, is_force: true }, options)}
            />,
          );
        } else {
          errorDialog.open(error.response?.data.message);
        }
      },
    });
  };

  useEffect(() => {
    if (isPending) {
      setPopup(<ProcessingPopupContents title={BOOKING_COMMON_FORM_TEXT.loadingTitle} />);
    }
  }, [isPending, setPopup]);

  return (
    <CommonGroupForm pageMode="updateAll" currentLecture={currentLecture} onSubmit={handleSubmit(submit)}>
      <BookingStartDate />
      <BookingEndDate pageType="updateAll" />
      <BookingRangeTime pageMode="update" />
    </CommonGroupForm>
  );
};

export default GroupUpdateAllForm;
