import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { UserTicketResponse } from './useInfinityUserTicketProduct';

/** 전체 회원 조회 */
const useInfinityMember = (params: MemberParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MemberResponse, MemberParams>({
    url: `/api/member`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['member', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => ({
      members: data.pages.flatMap(page => page.data.data),
      total: data.pages[0].data.meta.total,
    }),
  });
};

export default useInfinityMember;

export type MemberResponse = {
  id: number;
  name: string;
  account_id: number | null;
  account_type: string | null;
  mobile: string | null;
  vaccination_yn: string;
  attendance_count: number | null;
  profile: {
    id: number;
    studio_id: number;
    studio_user_id: number;
    name: string;
    registered_at: string;
    last_attendance_at: string;
  };
  avatar: AccountAvatar | null;
  userTicket: Array<
    UserTicketResponse & {
      last_attendance_at?: string | null; // 수강권별 최근 출석일, 필터설정에 따라 생략될 수 있음
      attendance_count?: number; // 수강권별 누적 출석일, 필터설정에 따라 생략될 수 있음
    }
  >;
  user_grade: {
    id: number;
    studio_id: number;
    name: string;
    representative_color: string;
  };
};

export type AccountAvatar = {
  id: number;
  owner_type: string;
  owner_id: number;
  is_representative: boolean;
  image: Image;
  created_at: string;
  updated_at: string;
  deleted_at: null;
};
export type Image = {
  extension: string;
  path: string;
  name: string;
};

export type MemberParams = {
  /**
   * all: 전체회원
   * active: 이용회원
   * holding: 정지회원
   * expired: 만료회원
   * unpaid: 미결제회원
   */
  tag: 'all' | 'active' | 'holding' | 'expired' | 'unpaid';
  /**
   * 정렬기준
   * name: 이름,
   * registered_at: 등록일
   * attendance_count: 누적출석일
   */
  sort_target?: 'name' | 'registered_at' | 'attendance_count';
  sort_type?: 'asc' | 'desc';
  /** 회원 검색(이름, 전화번호 등) */
  search?: string;
  user_grade_id?: number;
  remaining_coupon?: number;
  /**
   * 수강권 유형
   * G: 그룹
   * P: 프라이빗
   */
  ticket_type?: 'G' | 'P';
  /** 날짜(date) 파라미터는 '2023-01-01' 형태의 string */
  remaining_start_date?: string;
  remaining_end_date?: string;
  last_attendance_day?: number;
  attendance_count_start_date?: string;
  attendance_count_end_date?: string;
  /** 수강권 상세 > 양도 페이지에서 양도 받을 회원 목록 요청을 위한 양도자의 회원 id */
  transferor_id?: number;
  paginate_type?: 'detail' | 'simple';

  /** 휴대폰번호 존재 여부 */
  has_mobile?: 0 | 1;
  /** 회원 앱 연동 여부 */
  is_app_linked?: 0 | 1;
};
