import { CalendarViewType } from 'pages/Schedule/ScheduleMain/types';
import { atom } from 'recoil';
import localStorage from 'utils/localStorage';

import { SelectedFiltersAtomType } from './types';

const calendarSetting = localStorage.get('calendar_setting');

export const calendarViewTypeAtom = atom<CalendarViewType>({
  key: 'calendarViewTypeAtom',
  default: calendarSetting ? calendarSetting.defaultViewType : 'week',
});

export const currentDateAtom = atom({
  key: 'currentDateAtom',
  default: new Date(),
});

export const currentPeriodAtom = atom<{ startDate: Date; endDate: Date }>({
  key: 'currentPeriodAtom',
  default: {
    startDate: new Date(),
    endDate: new Date(),
  },
});

export const selectedFiltersAtom = atom<SelectedFiltersAtomType>({
  key: 'selectedFiltersAtom',
  default: {
    schedules: ['all'],
    staffs: ['all'],
  },
});

export const headerDatePickerOpenAtom = atom({
  key: 'headerDatePickerOpenAtom',
  default: false,
});
