import { RecoilState } from 'recoil';
import { ParamsType } from 'sharedComponents/Filters/types';

import { DEFAULT_BOOKING_FILTER_OPTIONS, DEFAULT_BOOKING_FILTER_TABS } from '../../constant';
import ScheduleCommonFilter from '../ScheduleCommonFilter';
import BookingDrawerFilterOptions from './BookingDrawerFilterOptions';
import BookingWholeFilterOptions from './BookingWholeFilterOptions';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  assigned?: boolean;
  isShowMoreDetailSchedule: boolean;
};

const BookingFilter = <T extends ParamsType>({ filterAtom, isShowMoreDetailSchedule }: Props<T>) => {
  return (
    <ScheduleCommonFilter
      filterAtom={filterAtom}
      filters={DEFAULT_BOOKING_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_BOOKING_FILTER_TABS}
      isShowMoreDetailSchedule={isShowMoreDetailSchedule}
      renderDrawerOptions={BookingDrawerFilterOptions}
      renderWholeFilterPopupOptions={BookingWholeFilterOptions}
    />
  );
};

export default BookingFilter;
