import { MEMBER_DETAIL_PAYMENT_TEXT } from 'pages/MemberDetail/constants';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { paymentSelectedFilterAtom } from 'recoil/MemberDetail';
import PeriodFilterSet from 'sharedComponents/Filters/PeriodFilterSet';

const Filter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useRecoilState(paymentSelectedFilterAtom);

  return (
    <PeriodFilterSet
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      selectedFilter={selectedFilter}
      saveChangedFilter={setSelectedFilter}
      description={MEMBER_DETAIL_PAYMENT_TEXT.filter.description}
      min={MEMBER_DETAIL_PAYMENT_TEXT.filter.min}
      max={MEMBER_DETAIL_PAYMENT_TEXT.filter.max}
    />
  );
};

export default Filter;
