import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import Repeat from 'pages/Booking/components/Repeat';
import { AllPageModeType } from 'pages/Booking/types';
import getFormHeaderTitle from 'pages/Booking/utils/getFormHeaderTitle';
import { isCompareArrayDifferent } from 'pages/Booking/utils/selectMembers';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlerAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';

import SelectMemberLeaveDialog from '../components/SelectMembers/SelectMemberLeaveDialog';
import LeaveDialog from './LeaveDialog';
import SelectMembers from './SelectMembers';

type Props<T extends FieldValues> = UseFormReturn<T> & {
  pageMode: Exclude<AllPageModeType, 'update'>;
};

/** 프라이빗 수업 폼에서 회원 선택이 가능한 생성/복사/이후 모든 수정 폼에서 사용 */
const PrivateFormLayout = <T extends FieldValues>({ children, pageMode, ...methods }: PropsWithChildren<Props<T>>) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const setCustomBackHandler = useSetRecoilState(customBackHandlerAtom);

  const { getAllSearchParams } = useQueryString();
  const { type, setting, step } = getAllSearchParams();
  const isConfirm = !type && step === '2';

  const headerTitle = getFormHeaderTitle({ bookingType: 'private', mode: pageMode, type, setting, hasTitle: !isConfirm });

  const clickBack = useCallback(() => {
    const { userTicketIds, tempUserTicketIds } = methods.watch();
    if (isCompareArrayDifferent({ userTicketIds, tempUserTicketIds }) && type === 'selectMember') {
      setIsOpen(true);
      return;
    }
    navigate(-1);
  }, [methods, navigate, type]);

  /** 안드로이드 물리 백버튼 클릭 시 실행되도록 로직 저장 */
  useEffect(() => {
    // 메인 폼에서는 회원 선택 LeaveDialog 뜨지 않도록 커스텀 로직에 null 저장
    if (!type) {
      setCustomBackHandler(null);
    }

    // 회원 선택 페이지에서
    if (type === 'selectMember') {
      if (!isOpen) {
        setCustomBackHandler(() => clickBack);
      } else {
        setCustomBackHandler(() => () => setIsOpen(false));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCustomBackHandler, type, isOpen]);

  return (
    <MainLayout
      header={{
        title: headerTitle,
        leftButton: (
          <IconButton onClick={clickBack}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        {type === 'repeat' && pageMode !== 'copy' && <Repeat pageMode={pageMode} />}
        {type === 'selectMember' && <SelectMembers pageMode={pageMode} />}
        {!type && children}
        <LeaveDialog pageMode={pageMode} />
        {isOpen && <SelectMemberLeaveDialog onClose={() => setIsOpen(false)} />}
      </FormProvider>
    </MainLayout>
  );
};

export default PrivateFormLayout;
