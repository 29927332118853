import styled from '@emotion/styled';
import { POINT_DEFAULT_PERIOD } from 'pages/MemberDetail/constants';
import { PointSelectedFilterType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';

import PeriodSelectCustom from './PeriodSelectCustom';
import PeriodSelectMonth from './PeriodSelectMonth';

const PeriodSelectFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const previousOptions = (selectedOption as PointSelectedFilterType['period']) || POINT_DEFAULT_PERIOD;

  const [buttonOption, setButtonOption] = useState(previousOptions.type);

  const clickOption = (option: FilterOptionsType) => {
    const buttonValue = option.value as string;
    setButtonOption(buttonValue);
    changeOptions(
      {
        value: {
          ...previousOptions,
          type: buttonValue,
        },
        label: '',
      },
      'period',
    );
  };

  return (
    <Container>
      <ButtonFilter currentFilter={currentFilter} onClick={clickOption} selectedOption={buttonOption} />
      {buttonOption === 'month' && <PeriodSelectMonth previousOptions={previousOptions} onClick={changeOptions} />}
      {buttonOption === 'custom' && <PeriodSelectCustom previousOptions={previousOptions} onClick={changeOptions} />}
    </Container>
  );
};

export default PeriodSelectFilter;

const Container = styled.div`
  padding: 0 20px 24px;

  .button-filter {
    padding: 20px 0 0;
  }

  .filter-option-button-wrapper {
    margin-bottom: 12px;
  }

  .month-picker-drawer-button {
    justify-content: space-between;
  }
`;
