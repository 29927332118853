import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { PropsWithChildren, useMemo } from 'react';

import { DROPDOWN_DATA } from '../constants';
import { MemberDropDownValueType } from '../types';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onClick: (value: MemberDropDownValueType['value']) => void;
};

const MemberDropDown = ({ children, isOpen, setIsOpen, onClick }: PropsWithChildren<Props>) => {
  const dropdownContent = useMemo(
    () => (
      <ContentsWrapper>
        {DROPDOWN_DATA.map(({ value, label, icon }) => {
          return (
            <li key={value}>
              <Button
                fullWidth
                fontSize={15}
                fontWeight={500}
                textColor={value === 'delete' ? 'secondary3' : 'gray2'}
                rightIcon={<Icon size={16} {...icon} />}
                onClick={() => onClick(value)}>
                {label}
              </Button>
            </li>
          );
        })}
      </ContentsWrapper>
    ),
    [onClick],
  );

  return (
    <DropDown isOpen={isOpen} content={dropdownContent} position="right" bottomSpacing={8} onClose={() => setIsOpen(false)}>
      {children}
    </DropDown>
  );
};

export default MemberDropDown;

const ContentsWrapper = styled.ul`
  width: 128px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 2px 8px 0px rgba(145, 145, 145, 0.2);

  li {
    padding: 13px 12px 13px 16px;
    :not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray7};
    }

    button {
      height: 100%;
      justify-content: space-between;
    }
  }
`;
