import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useGetStaffEventSchedules from 'hooks/service/queries/useGetStaffEventSchedules';
import useGetStaffWorkSchedules from 'hooks/service/queries/useGetStaffWorkSchedules';
import useParamsId from 'hooks/useParamsId';
import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';
import filters from 'utils/filters';
import getEventTimeDataset from 'utils/getEventTimeDataset';
import getWorkTimeDataset from 'utils/getWorkTimeDataset';

import NoContentText from './components/NoContentText';
import TabContentHeader from './components/TabContentHeader';
import TimeScheduleSubTitle from './components/TimeScheduleSubTitle';
import TimeScheduleTable from './components/TimeScheduleTable';
import { DAYS, PRIVATE_START_INTERVAL, TITLE } from './constants';

type Props = {
  /**
   * isEnabled: 프라이빗 수업 예약시간 단위 항목 노출 여부
   * am: 오전 수업 예약시간 단위
   * pm: 오후 수업 예약시간 단위
   */
  privateStartInterval: {
    isEnabled: boolean;
    am: number;
    pm: number;
  };
};

const TimeSchedule = ({ privateStartInterval }: Props) => {
  const navigate = useNavigate();

  const targetStaffId = useParamsId();
  const { data: workSchedules } = useGetStaffWorkSchedules({ targetStaffId });
  const { data: eventSchedules } = useGetStaffEventSchedules({ targetStaffId });

  const workTimeDataset = useMemo(() => getWorkTimeDataset(workSchedules), [workSchedules]);
  const eventTimeDataset = useMemo(() => getEventTimeDataset(eventSchedules), [eventSchedules]);

  const { isEnabled, am, pm } = privateStartInterval;

  const goEventUpdatePage = () => {
    navigate('event-schedule');
  };

  const isModifyButton = !!eventTimeDataset.eventWorkTimes.length;

  return (
    <Container>
      <TabContentHeader title={TITLE.header.timeInfoSetting} privateStartInterval={privateStartInterval} />

      {isEnabled && (
        <>
          <section className="private-start-interval">
            <TimeScheduleSubTitle text={TITLE.sub.timeInfo.privateBookingTime} />
            {PRIVATE_START_INTERVAL.timeTable.map(({ label, value }, index) => {
              const currentDataset = PRIVATE_START_INTERVAL.dataset[value];
              const interval = value === 'am' ? am : pm;
              const detailText = interval === 60 ? '정시로 표시' : '30분으로 표시';
              return <TimeScheduleTable key={value} label={label} time={currentDataset.time} detailText={detailText} />;
            })}
          </section>

          <Divider thin />
        </>
      )}

      <section className="work-time-table">
        <TimeScheduleSubTitle text={TITLE.sub.timeInfo.workTime} />
        {workSchedules.work_time.length ? (
          workTimeDataset.workTimes.map((workTime, index) => {
            const { day_of_week, start_time, end_time } = workTime;
            const currentRestTimes = _.filter(workTimeDataset.restTimes, { day_of_week });
            return (
              <TimeScheduleTable
                key={day_of_week}
                label={DAYS[day_of_week]}
                time={filters.timePeriodForOnlyTime(start_time, end_time)}
                restTimes={currentRestTimes.length ? currentRestTimes : undefined}
                gap={37}
              />
            );
          })
        ) : (
          <NoContentText text="등록된 일정이 없습니다" top={64} textColor="gray3" />
        )}
      </section>

      <Divider thin />

      <section className="event-work-time-table">
        <TimeScheduleSubTitle
          text={TITLE.sub.timeInfo.eventWorkTime}
          onClick={goEventUpdatePage}
          buttonText={isModifyButton ? '수정' : '추가'}
          isShowButton
        />
        {eventTimeDataset.eventWorkTimes.length ? (
          eventTimeDataset.eventWorkTimes.map(eventWorkTime => {
            const { id, date, start_time, end_time } = eventWorkTime;
            const currentRestTimes = _.filter(eventTimeDataset.eventRestTimes, { date });
            const isClosedDay = start_time === CLOSED_DAY_TIME.start && end_time === CLOSED_DAY_TIME.end;
            return (
              <TimeScheduleTable
                key={id}
                label={filters.dateWithWeekday(eventWorkTime.date)}
                time={isClosedDay ? '휴무일' : filters.timePeriodForOnlyTime(start_time, end_time)}
                restTimes={currentRestTimes.length ? currentRestTimes : undefined}
                gap={17}
              />
            );
          })
        ) : (
          <NoContentText text="등록된 일정이 없습니다" top={64} textColor="gray3" />
        )}
      </section>
    </Container>
  );
};

export default TimeSchedule;

const Container = styled.section`
  padding: 20px 0 100px;

  section {
    padding: 0 20px;
  }

  hr {
    margin: 24px 0;
  }

  .private-start-interval,
  .work-time-table,
  .event-work-time-table {
    ${theme.flex('column', 'flex-start', 'center', 16)};
  }
`;
