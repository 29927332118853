import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostRole = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<RoleParams>({
    method: 'post',
    url: '/api/role',
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['role'],
      });
    },
  });
};

export default usePostRole;

type RoleParams = {
  staff_id: undefined;
  display_name: string;
  is_immutable: boolean;
};
