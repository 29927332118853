import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MemoCommonParams } from '../queries/useInfinityMemo';

const usePostMemo = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemoPostParams>({
    method: 'post',
    url: '/api/memo',
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['memo'],
      });
    },
  });
};

export default usePostMemo;

export type MemoPostParams = MemoCommonParams & {
  memo: string;
  attachment_id?: Array<number>;
};
