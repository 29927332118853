import usePostBookingPrivate from 'hooks/service/mutations/usePostBookingPrivate';
import { LectureCourseResponse } from 'hooks/service/queries/useGetLectureCourse';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { formatBookingPrivateParams } from 'pages/Booking/utils';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BOOKING_PRIVATE_FORM_TEXT } from '../../constants';
import { BookingPrivateFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';
import { PrivateFormContainer } from '../Create/PrivateCreateForm';
import SubmitButton from './SubmitButton';

type Props = {
  currentLecture: LectureDetailResponse;
  course: LectureCourseResponse;
};

const PrivateCopyForm = ({ currentLecture }: Props) => {
  const { handleSubmit } = useFormContext<BookingPrivateFormType>();

  const { mutate: bookingPrivateMutate, isPending } = usePostBookingPrivate();
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const submit = (values: BookingPrivateFormType) => {
    const params = formatBookingPrivateParams(values);
    bookingPrivateMutate(params, {
      onSuccess: res => {
        if (res.data.success) {
          setToast({ type: 'success', message: '예약이 완료되었습니다.' });
          navigate(-1);
        } else {
          errorDialog.open(res.data.fail[0].message);
        }
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <>
      <PrivateFormContainer onSubmit={handleSubmit(submit)}>
        <SelectStaff lectureStaff={currentLecture.staff} />
        <BookingRangeDate isRange={false} />
        <BookingRangeTime pageMode="create" />
        <Room />
        <LectureSize />
        <SelectMembersField />
        <ClassTitle description={BOOKING_PRIVATE_FORM_TEXT.title.description} />
        <SubmitButton disabled={isPending} />
      </PrivateFormContainer>
    </>
  );
};

export default PrivateCopyForm;
