import { useIsMutating } from '@tanstack/react-query';
import { ButtonProps } from 'components/Button';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

const SubmitButton = ({ children, disabled, ...props }: ButtonProps) => {
  const {
    control,
    formState: { isDirty },
  } = useFormContext<EtcScheduleCreateFormType>();
  const title = useWatch({ control, name: 'title' });
  const isMutating = useIsMutating() > 0;

  return (
    <>
      <FooterButton type="submit" disabled={disabled || isMutating || !title || !isDirty} {...props}>
        {children}
      </FooterButton>
    </>
  );
};

export default SubmitButton;
