import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { MEMBER_FORM_TEXT } from 'pages/MemberForm/constants';
import { MemberFormPageModeType, MemberFormType } from 'pages/MemberForm/types';
import { getCreateTicketParams, getUpdateTicketParams } from 'pages/ProductForm/utils';
import { useCallback } from 'react';
import { SubmitErrorHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import filters from 'utils/filters';

import usePatchUserTicketUpdate from './service/mutations/usePatchUserTicketUpdate';
import usePostAvatar from './service/mutations/usePostAvatar';
import { MemberCreateParams, MemberCreateResponse } from './service/mutations/usePostMember';
import usePostUserTicket from './service/mutations/usePostUserTicket';
import { MemberDetailResponse } from './service/queries/useGetMemberDetail';
import useErrorDialog from './useErrorDialog';
import useToast from './useToast';

type Props = {
  pageMode: MemberFormPageModeType;
  mutationFunc: UseMutateFunction<
    AxiosResponse<MemberCreateResponse | MemberDetailResponse>,
    Error,
    MemberCreateParams | undefined,
    unknown
  >;
};

/** 회원 등록, 수정 요청 hook */
export const useMemberForm = ({ pageMode, mutationFunc }: Props) => {
  const {
    getValues,
    handleSubmit,
    formState: { dirtyFields },
  } = useFormContext<MemberFormType>();

  const currentStudioId = useRecoilValue(studioIdAtom);
  const currentStaffId = useRecoilValue(staffIdAtom);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: createTicketMutate } = usePostUserTicket();
  const { mutate: updateTicketMutate } = usePatchUserTicketUpdate();
  const { mutate: createProfileImageMutate } = usePostAvatar();

  const createTickets = useCallback(
    (userId: number) => {
      const { tickets } = getValues();
      if (!tickets.length) return;

      const ticketsParamsArray = getCreateTicketParams({
        tickets,
        currentMemberId: userId,
        currentStaffId,
        currentStudioId,
      });

      createTicketMutate({ user_tickets: ticketsParamsArray }, { onError: error => errorDialog.open(error) });
    },
    [createTicketMutate, currentStaffId, currentStudioId, errorDialog, getValues],
  );

  const updateUserTickets = useCallback(
    (userId: number) => {
      const { updateUserTickets } = getValues();
      if (!updateUserTickets || !updateUserTickets.length) return;

      const ticketsParamsArray = getUpdateTicketParams({
        userTickets: updateUserTickets,
        currentMemberId: userId,
        currentStaffId,
        currentStudioId,
      });

      updateTicketMutate({ user_tickets: ticketsParamsArray }, { onError: error => errorDialog.open(error) });
    },
    [currentStaffId, currentStudioId, errorDialog, getValues, updateTicketMutate],
  );

  const submitMember = useCallback(
    (params: MemberCreateParams) => {
      mutationFunc(params, {
        onSuccess: res => {
          createTickets(res.data.id);
          if (pageMode === 'update') {
            updateUserTickets(res.data.id);
            navigate(-1);
          } else {
            navigate(`/member/detail/${res.data.id}`, { replace: true });
          }
          setToast({ type: 'success', message: MEMBER_FORM_TEXT.successMessage[pageMode] });
        },
        onError: error => {
          errorDialog.open(error);
        },
      });
    },
    [createTickets, errorDialog, mutationFunc, navigate, pageMode, setToast, updateUserTickets],
  );

  const submit = useCallback(
    (values: MemberFormType) => {
      const {
        address,
        userGradeId,
        mobile,
        file,
        vaccination_yn,
        profile: { name, gender, birthday, registered_at },
      } = values;

      const defaultParams: MemberCreateParams = {
        profile: {
          name,
          gender,
          birthday,
          registered_at: filters.dateDash(registered_at),
        },
        vaccination_yn: vaccination_yn ? 'Y' : 'N',
        user_grade: userGradeId === MEMBER_FORM_TEXT.userGrade.notSelected.value ? undefined : Number(userGradeId),
        mobile: !dirtyFields.mobile ? undefined : mobile,
        address: address ? { detail_address: address } : undefined,
      };

      if (file) {
        createProfileImageMutate(
          { file },
          {
            onSuccess: res => {
              const params = {
                ...defaultParams,
                image: res.data.image || undefined,
              };
              submitMember(params);
            },
            onError: () => {
              const params = {
                ...defaultParams,
                image: undefined,
              };
              submitMember(params);
            },
          },
        );
      } else {
        submitMember(defaultParams);
      }
    },
    [dirtyFields.mobile, createProfileImageMutate, submitMember],
  );

  const validationError: SubmitErrorHandler<MemberFormType> = error => {
    errorDialog.open(error.mobile?.message || error.profile?.name?.message);
  };

  return {
    onSubmit: handleSubmit(submit, validationError),
  };
};
