import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { TicketCommon } from './useInfinityUserTicketProduct';

const useGetLectureDetail = (lectureId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<{ data: LectureDetailResponse }>({
    url: `/api/lecture/detail/${lectureId}`,
  });

  return useSuspenseQuery({
    queryKey: ['lecture', 'detail', lectureId],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetLectureDetail;

export type LectureDetailResponse = {
  id: number;
  course_id: number;
  bulk_count: number;
  memo_count: number;
  studio_id: number;
  type: Exclude<TicketCommon['available_class_type'], 'I'>;
  title: string;
  policy: {
    is_use_rooms: boolean; // 룸 사용 여부
    is_enter: 0 | 1; // 체크인 사용 여부
    weekly_waiting_limit: number; // 예약 대기 가능 시설 여부 (주간 예약 대기 가능 횟수 제한)
  };
  staff: {
    id: number;
    name: string;
    account_type: string;
    account_id: null;
    representative_color: string;
    image: Image | null;
  };
  room: {
    id: number;
    name: string;
  } | null;
  booking_closed_at: string | null;
  division_id: number | null;
  is_booking_only: boolean;
  start_on: string;
  end_on: string;
  min_trainee: number;
  max_trainee: number;
  current_trainee_count: number;
  total_current_trainee_count: number;
  waiting_trainee_limit: number;
  booking_start_at: string | null;
  booking_end_at: string;
  enter_start_at: string;
  enter_end_at: string;
  booking_cancel_start_at: string;
  booking_cancel_end_at: string;
  booking_auto_shift_available_minutes_from_start: string;
  daily_change_booking_end_at: string;
  autoclose_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  deleted_for: string | null;
  deleted_by: number | null;
};

export type Image = {
  path: string;
  name: string;
  extension: string;
};
