import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import { useFormContext, useWatch } from 'react-hook-form';

import { BookingSelectMemberFormType } from '../types';
import CommonList from './CommonList';

type Props = {
  userTickets: Array<UserTicketLectureResponse>;
  hasMore: boolean;
  isLoading: boolean;
  loadMore: () => void;
};

const UserTicketList = ({ userTickets, hasMore, isLoading, loadMore }: Props) => {
  const { control, getValues } = useFormContext<BookingSelectMemberFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const { type, maxCount } = getValues();
  /** 예약 도중 인원 초과 됐을 때 */
  const isExceeding = type === 'booking' && !maxCount;
  /** 예약/예약대기 페이지에서 max 만큼 회원 선택했을 때  */
  const isMax = (type === 'booking' || type === 'waiting') && selectedUserTickets.length >= maxCount;
  const disabledSelectMembers = isExceeding || isMax;

  return (
    <CommonList
      userTickets={userTickets}
      selectedUserTickets={selectedUserTickets}
      disabledSelectMembers={disabledSelectMembers}
      hasMore={hasMore}
      isLoading={isLoading}
      loadMore={loadMore}
    />
  );
};

export default UserTicketList;
