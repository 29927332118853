import { AccordionProps } from 'components/Accordion';
import AvailableTimePicker, { AvailableTimeType } from 'designedComponents/Pickers/NumberPicker/AvailableTimePicker';
import RuleTypeAccordion from 'pages/Booking/components/AvailableTime/RuleType/RuleTypeAccordion';
import { RuleTypeContainer } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { GroupRuleTypeCommonProps } from 'pages/Booking/Group/types';
import { splitHourMinTypeR } from 'pages/Booking/utils/convertTimes';

import RRTypeAccordionHeader from './header/RRTypeAccordionHeader';

type Props = Omit<GroupRuleTypeCommonProps, 'textType'> & {
  textType: GroupRuleTypeCommonProps['textType'] | 'checkIn';
  startline: number;
  deadline: number;
  accordionHeader?: AccordionProps['header'];
  changeDate: (value: { left: AvailableTimeType; right: AvailableTimeType }) => void;
};

/**
 * 수업시간 기준 - 시작시간부터 마감시간까지
 * 체크인 가능 시간
 */
const RRType = ({ iconName, isOpen, onToggle, textType, startline, deadline, accordionHeader, changeDate }: Props) => {
  const { hour: startHour, min: startMin } = splitHourMinTypeR(startline);
  const { hour: deadHour, min: deadMin } = splitHourMinTypeR(deadline);

  const changeLeft = (value: AvailableTimeType) => {
    changeDate({
      left: value,
      right: { hour: startHour, min: startMin },
    });
  };

  const changeRight = (value: AvailableTimeType) => {
    changeDate({
      left: { hour: deadHour, min: deadMin },
      right: value,
    });
  };

  return (
    <RuleTypeContainer className={`rule-type RR ${textType}`}>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={isOpen}
        onChange={() => onToggle(!isOpen)}
        height={80}
        isLast={textType !== 'booking'}
        noBottomBorder={textType === 'booking'}
        header={
          accordionHeader ? (
            accordionHeader
          ) : (
            <RRTypeAccordionHeader
              isOpen={isOpen}
              textType={textType}
              startHour={startHour}
              startMin={startMin}
              deadHour={deadHour}
              deadMin={deadMin}
            />
          )
        }>
        <AvailableTimePicker
          deadline={{ hour: deadHour, min: deadMin }}
          onChangeDeadline={changeLeft}
          startline={{ hour: startHour, min: startMin }}
          onChangeStartline={changeRight}
          deadHourMax={textType === 'checkIn' ? 5 : undefined}
          startHourMax={textType === 'checkIn' ? 5 : undefined}
        />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default RRType;
