import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';

import UserTicketListItem from './UserTicketListItem';

type Props = {
  userTickets: Array<UserTicketLectureResponse>;
  selectedUserTickets: Array<UserTicketLectureResponse>;
  disabledSelectMembers?: boolean;
  hasMore: boolean;
  isLoading: boolean;
  loadMore: () => void;
};

const CommonList = ({ userTickets, selectedUserTickets, disabledSelectMembers, hasMore, isLoading, loadMore }: Props) => {
  /** 전체 회원 보유 수강권 목록 중 선택된 수강권 제외 */
  const allExceptSelectedUserTickets = userTickets.filter(
    ticket => !selectedUserTickets.some(selectedTicket => ticket.id === selectedTicket.id),
  );

  if (!userTickets.length) {
    return <NoDataTypography textColor="gray3">등록된 정보가 없습니다.</NoDataTypography>;
  }

  return (
    <InfiniteScrollContainer hasMore={hasMore} isLoading={isLoading} loadMore={loadMore}>
      <ul>
        {allExceptSelectedUserTickets.map(data => (
          <UserTicketListItem key={data.id} data={data} disabled={disabledSelectMembers} />
        ))}
      </ul>
    </InfiniteScrollContainer>
  );
};

export default CommonList;

const InfiniteScrollContainer = styled(StyledSelectInfiniteScroll)`
  li:not(:last-of-type) {
    border-bottom: none;
  }
`;

const NoDataTypography = styled(Typography)`
  margin: 80px 0;
  text-align: center;
`;
