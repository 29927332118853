import { StaffEventSchedulesResponse } from 'hooks/service/queries/useGetStaffEventSchedules';
import _ from 'lodash';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

const getEventTimeDataset = (eventSchedules: StaffEventSchedulesResponse) => {
  const eventWorkTimes: ScheduleFormType[] = [...eventSchedules.event_work_time];
  const eventRestTimes: ScheduleFormType[] = [];

  /** 휴무일을 판단할 수 있는 부분이 따로 없고, start_time, end_time 고정값으로 판단해야함 */
  eventSchedules.event_rest_time.forEach(eventRestTime => {
    const { start_time, end_time } = eventRestTime;
    const isClosedDay = start_time === CLOSED_DAY_TIME.start && end_time === CLOSED_DAY_TIME.end;
    if (isClosedDay) {
      eventWorkTimes.push({ ...eventRestTime, isClosed: true });
    } else {
      eventRestTimes.push(eventRestTime);
    }
  });

  return {
    eventWorkTimes: _.sortBy(eventWorkTimes, 'date'),
    eventRestTimes,
  };
};

export default getEventTimeDataset;
