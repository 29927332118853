import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/**
 * 기타 일정 1개 수정/이후 모든 수정/모든 수정
 * 종일 일정은 모든 수정만 가능
 */
const EtcScheduleUpdate = () => {
  return (
    <ApiBoundary>
      <FormMain />
    </ApiBoundary>
  );
};

export default EtcScheduleUpdate;
