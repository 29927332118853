import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  targetStaffId: number;
};

const usePatchStaffEventTime = ({ targetStaffId }: Props) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<StaffEventTimeParams>({
    method: 'patch',
    url: `/api/staff/eventTime/${targetStaffId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['event-schedules'],
      });
    },
  });
};

export default usePatchStaffEventTime;

export type StaffEventTimeParams = {
  schedules: Array<Pick<ScheduleFormType, 'date' | 'type' | 'day_of_week' | 'start_time' | 'end_time'>>;
};
