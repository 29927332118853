import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import useGetPointDetail from 'hooks/service/queries/useGetPointDetail';
import usePopup from 'hooks/usePopup';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

import PointUpdatePopup from './PointUpdatePopup';

const PointDashBoard = ({ currentMemberId }: CurrentMemberIdProps) => {
  const {
    data: { current_point, reward_point, paid_point },
  } = useGetPointDetail(currentMemberId);

  const { setPopup } = usePopup();

  return (
    <Container showBoxShadow={false} bgColor="primary">
      <TopWrapper>
        <div className="current-point-wrapper">
          <Typography span size={13} weight={500} textColor="white" opacity={0.8}>
            잔여 포인트
          </Typography>
          <Typography size={24} weight={700} textColor="white">
            {filters.numberComma(current_point)}
            <Typography span size={21} weight={700} textColor="white">
              P
            </Typography>
          </Typography>
        </div>
        <Button
          size="tiny"
          edge="circular"
          variant="outlined"
          textColor="white"
          widthSize={61}
          onClick={() => setPopup(<PointUpdatePopup currentPoint={current_point} currentMemberId={currentMemberId} />)}>
          수정하기
        </Button>
      </TopWrapper>
      <InfoFlexWrapper>
        <InfoFlexRow>
          <Typography size={15} textColor="white" opacity={0.88}>
            적립 포인트
          </Typography>
          <Typography size={15} weight={600} textColor="white">
            {filters.numberComma(reward_point)}P
          </Typography>
        </InfoFlexRow>
        <InfoFlexRow>
          <Typography size={15} textColor="white" opacity={0.88}>
            사용 포인트
          </Typography>
          <Typography size={15} weight={600} textColor="white" opacity={0.72}>
            {filters.numberComma(paid_point)}P
          </Typography>
        </InfoFlexRow>
      </InfoFlexWrapper>
    </Container>
  );
};

export default PointDashBoard;

const Container = styled(CommonStyleCard)`
  ${theme.flex('column', '', '', 20)};
  margin: 24px 20px;
  border-radius: 16px;
`;

const TopWrapper = styled.div`
  ${theme.flex('', '', 'space-between', 8)};

  .current-point-wrapper {
    ${theme.flex('column', '', '', 2)};
  }

  button {
    margin-top: 16px;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.56);
    height: 20px;
  }
`;
