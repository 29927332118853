import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

const CounselRangetime = () => {
  const { control, setValue } = useFormContext();
  const time = useWatch({ control, name: 'time' });

  const [isOpen, setIsOpen] = useState(false);

  const timeText = useMemo(() => {
    return (
      <Typography span textColor={isOpen ? 'primary' : 'gray1'} size={15} weight={500} lineHeight={22}>
        {filters.timePeriod(time[0], time[1])}
      </Typography>
    );
  }, [time, isOpen]);

  const changeTime = useCallback((value: [Date, Date]) => setValue('time', value, { shouldDirty: true }), [setValue]);

  return (
    <Container className="counsel-range-time" isOpen={isOpen}>
      <Accordion
        showArrow={false}
        isOpen={isOpen}
        onChange={() => setIsOpen(!isOpen)}
        size="medium"
        leftIcon={<Icon name="time" color="gray2" />}
        header={timeText}>
        <RangeTimePicker timeRange={time} onChange={changeTime} stepMinute={5} showAddTimeButton />
      </Accordion>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  padding-bottom: ${({ isOpen }) => (isOpen ? '24px' : '0')};
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${({ isOpen }) => (isOpen ? theme.color.gray6 : theme.color.gray8)};

  .range-time-picker {
    border-top: 1px solid ${theme.color.gray8};
  }
`;

export default CounselRangetime;
