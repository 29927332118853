import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { MessageMobileResponse } from 'hooks/service/queries/useInfinityMessageMobile';
import MessageLabel from 'pages/MoreDetails/Message/components/MessageLabel';
import { Link } from 'react-router-dom';
import { InfoTypography } from 'sharedComponents/InfoTypography';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

type Props = {
  message: MessageMobileResponse;
};

const MobileMessageListItem = ({ message }: Props) => {
  return (
    <StyledLink to={`mobile/${message.id}`}>
      <Typography size={15} weight={600} className="date">
        {filters.dateTimeWithWeekday(message.send_on)}
      </Typography>
      <ArrangeTypographyWrapper>
        <InfoTypography size={14}>
          {message.all_count > 1 ? `${message.send_person} ${message.all_count - 1}명` : message.send_person}
        </InfoTypography>
        <InfoTypography size={14}>{message.message_type}</InfoTypography>
      </ArrangeTypographyWrapper>
      <StyledLabel status={message.status} width={54} />
      <Divider thin />
      <MessageSummary textColor="gray2" ellipsis={1} className="message">
        {message.title}
      </MessageSummary>
    </StyledLink>
  );
};

export default MobileMessageListItem;

const StyledLink = styled(Link)`
  background-color: ${theme.color.white};
  padding: 18px 20px;
  border-radius: 12px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  hr {
    margin: 16px 0 14px;
  }
  .date {
    margin-bottom: 2px;
  }
`;

const StyledLabel = styled(MessageLabel)`
  position: absolute;
  top: 18px;
  right: 20px;
`;

const MessageSummary = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
