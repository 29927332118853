import { yupResolver } from '@hookform/resolvers/yup';
import Divider from 'components/Divider';
import Form from 'components/Form';
import usePatchMessagePush from 'hooks/service/mutations/usePatchMessagePush';
import usePostMessagePush from 'hooks/service/mutations/usePostMessagePush';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import SendMessageLoading from 'pages/MemberDetail/components/AppLinkMessage/SendMessageLoading';
import MessageFooter from 'pages/MoreDetails/Message/components/MessageFooter';
import { Container, Contents } from 'pages/MoreDetails/Message/Mobile/Form/components/MobileForm';
import SelectedTargetList from 'pages/MoreDetails/Message/Mobile/Form/components/SelectedTargetList';
import TitleAndMessage from 'pages/MoreDetails/Message/Mobile/Form/components/TitleAndMessage';
import { convertSuccessMessage, convertToMobilePostParams } from 'pages/MoreDetails/Message/Mobile/Form/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { messageTargetMemberSelectedFilter } from 'recoil/message';
import SendReservation from 'sharedComponents/Message/SendReservation';
import { object, string } from 'yup';

import { APP_PUSH_DEFAULT_VALUES, APP_PUSH_FORM_TEXT, APP_PUSH_MAX_LENGTH } from '../constants';
import { AppPushFormType } from '../types';
import AppPushConfirmButton from './AppPushConfirmButton';
import AppPushPreview from './AppPushPreview';
import AppPushTargets from './AppPushTargets';

type Props = {
  defaultValues?: AppPushFormType;
};

const yupSchema = object().shape({
  title: string().max(APP_PUSH_MAX_LENGTH.title, `최대 ${APP_PUSH_MAX_LENGTH.title}자 이내로 입력해 주세요.`),
  message: string().max(APP_PUSH_MAX_LENGTH.message, `최대 ${APP_PUSH_MAX_LENGTH.message}자 이내로 입력해 주세요.`),
});

const AppPushForm = ({ defaultValues }: Props) => {
  const methods = useForm<AppPushFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: { ...APP_PUSH_DEFAULT_VALUES, ...defaultValues } || APP_PUSH_DEFAULT_VALUES,
  });

  const { mutate: sendPushMessage } = usePostMessagePush();
  const { mutate: updatePushMessage } = usePatchMessagePush(defaultValues?.originalMessage?.id);

  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const navigate = useNavigate();

  const resetMemberFilter = useResetRecoilState(messageTargetMemberSelectedFilter);
  const saveMessage = () => {
    const params = convertToMobilePostParams(methods.getValues());

    const mode = methods.getValues('mode');
    const mutateFunction = mode === 'create' ? sendPushMessage : updatePushMessage;

    setPopup(<SendMessageLoading />);
    mutateFunction(params, {
      onSuccess: () => {
        const toastPosition = mode === 'update' ? 40 : 92;
        setToast({
          message: convertSuccessMessage(methods.getValues()),
          type: 'success',
          bottom: toastPosition,
        });
        if (mode === 'update') {
          navigate(-1);
        }

        methods.reset(APP_PUSH_DEFAULT_VALUES);
        resetMemberFilter();
      },
      onSettled: () => {
        setPopup(null);
      },
      onError: error => errorDialog.open(error),
    });
  };

  return (
    <FormProvider {...methods}>
      <Form>
        <Container>
          <Contents>
            <AppPushTargets />
            <SelectedTargetList />
            <TitleAndMessage {...APP_PUSH_FORM_TEXT.titleAndMessage} />
          </Contents>
          <Divider />
          <SendReservation />
        </Container>
        <MessageFooter>
          <AppPushPreview />
          <AppPushConfirmButton onConfirm={saveMessage} />
        </MessageFooter>
      </Form>
    </FormProvider>
  );
};

export default AppPushForm;
