import Accordion from 'components/Accordion';

import WorkTimeCardList from './components/WorkTimeCardList';

const WorkTime = () => {
  return (
    <Accordion header="근무시간">
      <WorkTimeCardList />
    </Accordion>
  );
};

export default WorkTime;
