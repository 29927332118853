import styled from '@emotion/styled';
import CheckRadio from 'components/Radio/CheckRadio';
import useInfinityMember from 'hooks/service/queries/useInfinityMember';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import NodataTypography from 'sharedComponents/NodataTypography';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';

import MemberCard from '../MemberCard';

const MemberList = () => {
  const { control, setValue, getValues } = useFormContext<TicketTransferFormType>();
  const search = useWatch({ control, name: 'search' });
  useScrollRestoration('.infinite-scroll');

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityMember({
    transferor_id: getValues('currentMemberId'),
    tag: 'all',
    sort_target: 'name',
    sort_type: 'asc',
    search,
  });

  if (!data.members.length) {
    return <NodataTypography>회원이 없습니다.</NodataTypography>;
  }

  return (
    <InfiniteScrollContainer hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      <ul>
        {data.members.map(member => (
          <li key={member.id}>
            <Controller
              control={control}
              name="selectedMemberId"
              render={({ field: { value } }) => (
                <CheckRadio
                  id={`${member.id}`}
                  value={member.id}
                  checked={member.id === Number(value)}
                  onChange={e => {
                    setValue('selectedMemberId', Number(e.target.value), { shouldDirty: true });
                    setValue('selectedMember', member, { shouldDirty: true });
                  }}
                  label={<MemberCard member={member} isSelected={member.id === Number(value)} />}
                />
              )}
            />
          </li>
        ))}
      </ul>
    </InfiniteScrollContainer>
  );
};

export default MemberList;

const InfiniteScrollContainer = styled(StyledSelectInfiniteScroll)`
  li {
    padding: 14px 0;

    .radio-check-label {
      gap: 8px;
    }
  }
`;
