import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 시설에 등록된 회원 등급 목록 */
const useGetUserGrade = () => {
  const { queryFn } = useQueryFnWithCommonParams<Array<UserGradeResponse>>({
    url: '/api/member/grade',
  });

  return useSuspenseQuery({
    queryKey: ['user-grade'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetUserGrade;

export type UserGradeResponse = {
  created_at: string | null;
  deleted_at: string | null;
  deleted_for: string | null;
  id: number;
  name: string;
  representative_color: string;
  studio_id: number;
  updated_at: string | null;
  updated_for: number | null;
};
