import Icon from 'components/Icon';
import dayjs from 'dayjs';
import MonthPickerDrawer from 'designedComponents/Pickers/MonthPicker/MonthPickerDrawer';
import { PointPeriodParamsType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { pointFilterPeriodAtom } from 'recoil/MemberDetail';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterProps } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

type Props = Pick<FilterProps, 'onClick'> & {
  previousOptions: PointPeriodParamsType;
};

const PeriodSelectMonth = ({ previousOptions, onClick: changeOptions }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date] = useState([previousOptions.start_date, previousOptions.end_date]);
  /** 선택한 기간에 따라 강사 목록 호출용도 */
  const setTempPeriod = useSetRecoilState(pointFilterPeriodAtom);
  const monthText = filters.dateYearMonth(previousOptions.start_date);

  const changeDate = (e: { value: Date }) => {
    const endValue = dayjs(e.value).endOf('month') > dayjs() ? dayjs() : dayjs(e.value).endOf('month');
    const startDate = filters.dateDash(dayjs(e.value).startOf('month'));
    const endDate = filters.dateDash(endValue);

    changeOptions(
      {
        value: {
          type: 'month',
          start_date: startDate,
          end_date: endDate,
        },
        label: '',
      },
      'period',
    );
  };

  const save = () => {
    setTempPeriod({
      type: 'month',
      start_date: previousOptions.start_date,
      end_date: previousOptions.end_date,
    });

    // 필터 내에서 날짜 변경하면 기존 선택한 강사 리셋
    changeOptions({ value: undefined, label: '' }, 'selectedStaffs');
    setIsOpen(false);
  };

  const cancelSelect = () => {
    changeOptions(
      {
        value: {
          type: 'month',
          start_date: date[0],
          end_date: date[1],
        },
        label: '',
      },
      'period',
    );
    setIsOpen(false);
  };

  return (
    <>
      <ButtonWithRightIconOutlined
        className="month-picker-drawer-button"
        icon={<Icon name="calendar" color="gray1" />}
        textColor="gray1"
        onClick={() => setIsOpen(true)}>
        {monthText}
      </ButtonWithRightIconOutlined>

      <MonthPickerDrawer
        isOpen={isOpen}
        value={previousOptions.start_date}
        onClose={cancelSelect}
        onChange={changeDate}
        onSave={save}
      />
    </>
  );
};

export default PeriodSelectMonth;
