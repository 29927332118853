import { PRODUCT_TYPE_TEXT } from 'constants/text';
import useQueryString from 'hooks/useQueryString';
import { useMemo, useState } from 'react';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';
import { USER_TICKET_STATUS } from 'sharedComponents/UserTicketCard/constants';
import filters from 'utils/filters';
import formatUserTicketCard from 'utils/formatUserTicketCard';

import { UserTicketProps } from '../types';
import InfoAccordion from './InfoAccordion';

type Props = UserTicketProps & {
  currentMemberId: number;
  canUpdate: boolean;
};

const TicketDetailAccordions = ({ canUpdate, userTicket, currentMemberId }: Props) => {
  const { ticketStatus } = formatUserTicketCard(userTicket);
  const [expanded, setExpanded] = useState<string | false>('ticket');

  const { getAllSearchParams, setSearchParams } = useQueryString();

  const changeAccordionOpen = (panel: string) => {
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  const goToDetail = (type: 'edit' | 'payment') => {
    setSearchParams({
      ...getAllSearchParams(),
      memberId: `${currentMemberId}`,
      type: `${type}`,
    });
  };

  /** 결제정보 아코디언 데이터 */
  const paymentData: InfoValueRowType[] = useMemo(() => {
    const {
      payments: { total_amount, unpaid_amount_total, sale_price },
    } = userTicket;

    return [
      { label: '판매가', value: `${filters.numberComma(sale_price)}원` },
      { label: '결제총액', value: `${filters.numberComma(total_amount)}원`, textColor: 'primary' },
      {
        label: `${userTicket.user_ticket_status === 'refund' ? '위약금' : '미수금'}`,
        value: `${filters.numberComma(unpaid_amount_total)}원`,
        textColor: 'secondary3',
      },
    ];
  }, [userTicket]);

  /** 수강권정보 아코디언 데이터 */
  const ticketData = useMemo(() => {
    const {
      availability_start_at,
      expire_at,
      remaining_cancel,
      max_coupon,
      remaining_coupon,
      is_show_cancel_count,
      staffs,
      ticket,
    } = userTicket;

    const staffsValue = !staffs.length
      ? '-'
      : staffs.length === 1
        ? `${staffs[0].name} 강사`
        : `${staffs[0].name} 강사 외 ${staffs.length - 1}명`;

    const defaultArray = [
      { label: '사용여부', value: USER_TICKET_STATUS[ticketStatus] },
      { label: '기간', value: filters.period(availability_start_at, expire_at) },
      { label: '잔여횟수', value: `${remaining_coupon}회` },
      { label: '전체횟수', value: `${max_coupon}회` },
      { label: '예약취소 가능 횟수', value: `${remaining_cancel}회` },
      { label: '담당강사', value: staffsValue },
    ];

    const array = [...defaultArray];
    /** 상품(판매) */
    if (ticket.type === 'S') {
      return array.splice(5, 1);
    }
    /** 상품(대여 - 혼합) */
    if (ticket.type === 'RM') {
      array.splice(4, 1);
      return array;
    }
    /** 상품(대여 - 횟수제) */
    if (ticket.type === 'RT') {
      array.splice(1, 1);
      array.splice(3, 1);
      return array;
    }
    /** 기간제 (상품 및 수강권 - 취소 가능 횟수 노출 X) */
    if (ticket.type === 'RP' || (ticket.type === 'P' && !is_show_cancel_count)) {
      array.splice(2, 3);
      return array;
    }
    /** 수강권(기간제 - 취소 가능 횟수 노출 O) */
    if (ticket.type === 'P' && is_show_cancel_count) {
      array.splice(2, 2);
      return array;
    }

    /** 수강권(횟수제) 전부 노출 */
    return array;
  }, [ticketStatus, userTicket]);

  return (
    <>
      <InfoAccordion
        data={ticketData}
        accordionProps={{
          header: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]} 정보`,
          isOpen: expanded === 'ticket',
          onChange: () => changeAccordionOpen('ticket'),
        }}
        buttonProps={
          canUpdate
            ? {
                children: '수정',
                variant: 'contained',
                color: 'gray',
                onClick: () => goToDetail('edit'),
              }
            : undefined
        }
      />

      <InfoAccordion
        data={paymentData}
        accordionProps={{
          header: '결제정보',
          isOpen: expanded === 'payment',
          onChange: () => changeAccordionOpen('payment'),
        }}
        buttonProps={{
          children: '상세보기',
          variant: 'outlined',
          onClick: () => goToDetail('payment'),
        }}
      />
    </>
  );
};

export default TicketDetailAccordions;
