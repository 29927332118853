import { MessageMobileParams } from 'hooks/service/mutations/usePostMessageMobile';
import filters from 'utils/filters';

import { MessageTargetType, MessageType } from '../../types';
import { MOBILE_FORM_TEXT, MOBILE_POINT } from './constants';
import { MobileFormType } from './types';

export const convertToMobilePostParams = (values: MessageType & { from?: string }): MessageMobileParams => {
  const { type, mode, target, isReserve, reservationDate, from, users, counseling_logs, ...rest } = values;

  return {
    ...rest,
    from: from || '',
    users: users.map(user => user.id),
    counseling_logs: counseling_logs.map(counsel => counsel.id),
    send_on: isReserve ? reservationDate : undefined,
  };
};

export const calculateRequiredPoint = (values: MobileFormType): number => {
  const targets = values.target === 'member' ? values.users : values.counseling_logs;
  const mobileType = values.message.length > 90 ? 'LMS' : 'SMS';
  const requiredPoint = MOBILE_POINT[mobileType] * targets.length;

  if (values.mode === 'create' || !values.originalMessage) {
    return requiredPoint;
  }
  // else (values.mode === 'update')
  const paidPoint = values.originalMessage.usedPoints;
  return requiredPoint - paidPoint;
};

export const convertTargets = (values: MobileFormType): MessageTargetType[] => {
  return values.target === 'member' ? values.users : values.counseling_logs;
};

export const convertSuccessMessage = (values: MessageType): string => {
  if (values.mode === 'create') {
    const targets = convertTargets(values);
    const action = values.isReserve ? 'successMessageReserved' : 'successMessage';
    return `${filters.numberComma(targets.length)}건의 ${MOBILE_FORM_TEXT.create[action]}`;
  }
  // else (values.mode === 'update')
  return MOBILE_FORM_TEXT.update.successMessage;
};
