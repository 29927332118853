import styled from '@emotion/styled';
import Divider from 'components/Divider';
import { get } from 'lodash';
import { Fragment } from 'react';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import AttendanceCountFilter from './AttendanceCountFilter';
import LastAttendanceDayFilter from './LastAttendanceDayFilter';
import TicketPeriodFilter from './TicketPeriodFilter';

const AllMemberWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  const buttonFilters = filters.filter(filter => filter.type === 'button');

  return (
    <Container>
      {buttonFilters.map((filter, index) => (
        <Fragment key={index}>
          <ButtonFilter currentFilter={filter} selectedOption={get(filterChanged, filter.key)} onClick={changeOption} />
          <Divider />
        </Fragment>
      ))}
      <TicketPeriodFilter
        currentFilter={filters[3]}
        selectedOption={get(filterChanged, 'ticket_remaining')}
        onClick={changeOption}
      />
      <Divider />
      <LastAttendanceDayFilter
        currentFilter={filters[4]}
        selectedOption={get(filterChanged, 'last_attendance_day')}
        onClick={changeOption}
      />
      <Divider />
      <AttendanceCountFilter
        currentFilter={filters[5]}
        selectedOption={get(filterChanged, 'attendance_count')}
        onClick={changeOption}
      />
    </Container>
  );
};

export default AllMemberWholeFilterOptions;

const Container = styled.div`
  > div:first-of-type {
    padding-top: 16px;
  }
`;
