import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarSettingsAtom } from 'recoil/calendarSettings';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import { CardStyled } from './CardStyled';

type Props = {
  lectureType: 'G' | 'P';
  title: string;
  cardColor: string;
  isBookingClosed: boolean;
  maxTrainee: number;
  currentTraineeCount: number;
};

const EventLectureCard = ({ lectureType, title, cardColor, isBookingClosed, maxTrainee, currentTraineeCount }: Props) => {
  const { closedClassBackground } = useRecoilValue(calendarSettingsAtom);

  /** 캘린더 설정 > 마감시 배경 채우기 설정 여부 */
  const isClosedBackground = useMemo(() => {
    const isIncludes = closedClassBackground.includes(lectureType);
    if (isIncludes) return true;
    return false;
  }, [lectureType, closedClassBackground]);

  const isCardBackground = isClosedBackground && (isBookingClosed || currentTraineeCount === maxTrainee);

  return (
    <Container cardColor={cardColor} isCardBackground={isCardBackground}>
      <Typography
        size={12}
        weight={600}
        textColor={isCardBackground ? (getContrastTextColor(cardColor) as Color) : 'gray1'}
        ellipsis={1}>
        {title}
      </Typography>
    </Container>
  );
};

export default EventLectureCard;

const Container = styled(CardStyled)<{ cardColor: string; isCardBackground: boolean }>(
  ({ cardColor }) => css`
    border: 1px solid ${cardColor};
    background-color: ${theme.color.white};
  `,

  ({ isCardBackground, cardColor }) =>
    isCardBackground &&
    css`
      background-color: ${cardColor};
    `,
);
