import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { SalesDataResponse } from 'hooks/service/queries/useInfinityReportSale';
import { useState } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { ColorType } from 'types/colorTypes';
import filters from 'utils/filters';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import { REPORT_PAYMENT_LABEL, REPORT_PRODUCT_TYPE_LABEL } from '../../constants';
import ReportSaleDetailInfo from './ReportSaleDetailInfo';

type Props = {
  sales: SalesDataResponse[][];
};

const ListCard = ({ sales }: Props) => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [detailData, setDetailData] = useState<SalesDataResponse | null>(null);

  const showDetailInfo = (saleData: SalesDataResponse) => {
    setIsShowDrawer(true);
    setDetailData(saleData);
  };

  const closeDetailInfo = () => {
    setIsShowDrawer(false);
  };

  const getPaymentMethodLabel = (payment: SalesDataResponse['payment']): string => {
    const method = [];

    payment['card_amount'] > 0 && method.push('카드');
    payment['point_amount'] > 0 && method.push('포인트');
    if (payment['cash_amount'] > 0 || payment['wiretransfer_amount'] > 0) {
      method.push('현금');
    }

    return method.join(' + ');
  };

  return (
    <>
      {sales.map(sale => {
        return (
          <Container key={filters.dateMonthDayKor(sale[0].created_at)}>
            <Typography className="date-text" size={13} weight={500} textColor="gray2">
              {filters.dateMonthDayKor(sale[0].created_at)}
            </Typography>
            {sale.map(saleData => {
              const productTypeLabel =
                REPORT_PRODUCT_TYPE_LABEL[saleData.ticket.product_type as keyof typeof REPORT_PRODUCT_TYPE_LABEL];
              const paymentName = REPORT_PAYMENT_LABEL[saleData.label as keyof typeof REPORT_PAYMENT_LABEL].name;
              const paymentLabelColor = REPORT_PAYMENT_LABEL[saleData.label as keyof typeof REPORT_PAYMENT_LABEL].color;
              const paymentMethod = getPaymentMethodLabel(saleData.payment);
              const memberName = getEllipsisByLength({ text: saleData.member.name, max: 5, hasEllipsis: true });

              return (
                <CardWrapper key={saleData.id} onClick={() => showDetailInfo(saleData)}>
                  <Label
                    size="small"
                    height={18}
                    borderColor={paymentLabelColor as ColorType}
                    textColor={paymentLabelColor as ColorType}>
                    {paymentName}
                  </Label>
                  <div className="class-info-wrapper">
                    <Typography ellipsis={1} size={15} weight={700} textColor="gray1">
                      {`${productTypeLabel} (${saleData.ticket.title})`}
                    </Typography>
                    <Typography className="no-shrink" size={15} weight={700} textColor="gray1">
                      {`${paymentName === '환불' ? '-' : ''}${filters.numberComma(saleData.payment.amount)}원`}
                    </Typography>
                  </div>
                  <div className="member-info-wrapper">
                    <div>
                      <Typography span ellipsis={1} size={15} textColor="gray2">
                        {`${memberName}`}
                      </Typography>
                      <Typography span size={15} textColor="gray2">
                        회원
                      </Typography>
                    </div>
                    <div className="no-shrink pay-method">
                      <Typography span size={15} textColor="gray3">
                        {filters.time(saleData.created_at)}
                      </Typography>
                      <Divider />
                      <Typography span size={15} textColor="gray3">
                        {paymentMethod}
                      </Typography>
                    </div>
                  </div>
                </CardWrapper>
              );
            })}
          </Container>
        );
      })}
      {isShowDrawer && detailData && (
        <ApiBoundary>
          <ReportSaleDetailInfo isOpen={isShowDrawer} detailData={detailData} onClose={closeDetailInfo} />
        </ApiBoundary>
      )}
    </>
  );
};

export default ListCard;

const Container = styled.div`
  .date-text {
    padding-bottom: 20px;
  }

  :not(:first-of-type) {
    .date-text {
      padding-top: 20px;
    }
  }
`;

const CardWrapper = styled.div`
  padding: 18px 20px;
  margin-bottom: 12px;
  border-radius: 16px;
  background: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  .label {
    margin-bottom: 8px;
  }

  .class-info-wrapper {
    ${theme.flex('row', '', 'space-between')}

    p:nth-of-type(1) {
      padding-right: 8px;
    }
  }

  .member-info-wrapper {
    ${theme.flex('row', 'center', 'space-between', 8)};

    > div:first-of-type {
      ${theme.flex('row', '', '', 2)}
      span:last-of-type {
        padding-right: 8px;
      }
    }

    .pay-method {
      ${theme.flex('', 'center', '', 6)}
    }
  }

  .no-shrink {
    flex-shrink: 0;
  }
`;

const Divider = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${theme.color.gray4};
`;
