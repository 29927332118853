import { LectureGroupParams } from 'hooks/service/mutations/usePostLectureGroup';

import { BookingGroupCreateFormType } from '../Group/types';
import formatLectureCommonParams from './formatLectureCommonParams';

const formatBookingGroupParams = (formValues: BookingGroupCreateFormType): LectureGroupParams => {
  const { is_booking_only, description, divisionId, maxTrainee, minTrainee, waitingTrainee, policy } = formValues;

  const commonParams = formatLectureCommonParams(formValues);

  return {
    ...commonParams,
    is_booking_only,
    description: description || undefined,
    division_id: divisionId ? Number(divisionId) : undefined,
    max_trainee: maxTrainee,
    min_trainee: minTrainee,
    waiting_trainee_limit: waitingTrainee,
    enter_start_deadline: policy.enterStartline,
    enter_end_deadline: policy.enterDeadline,
    daily_booking_change_deadline: policy.dailyBookingChangeDeadline,
    autoclose_deadline: policy.autocloseDeadline,
    booking_auto_shift_deadline: policy.bookingAutoShiftDeadline,
    group_booking_rule_type: policy.booking.ruleType,
    group_booking_startline: policy.booking.startline,
    group_booking_deadline: policy.booking.deadline,
    group_booking_start_days: policy.booking.startDays,
    group_booking_end_days: policy.booking.endDays,
    group_booking_start_time: policy.booking.startTime,
    group_booking_end_time: policy.booking.endTime,
    group_booking_cancel_rule_type: policy.bookingCancel.ruleType,
    group_booking_cancel_startline: policy.bookingCancel.startline,
    group_booking_cancel_deadline: policy.bookingCancel.deadline,
    group_booking_cancel_start_days: policy.bookingCancel.startDays,
    group_booking_cancel_end_days: policy.bookingCancel.endDays,
    group_booking_cancel_start_time: policy.bookingCancel.startTime,
    group_booking_cancel_end_time: policy.bookingCancel.endTime,
  };
};

export default formatBookingGroupParams;
