import { PERMISSION } from 'constants/permission';
import useGetUserTicket from 'hooks/service/queries/useGetUserTicket';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import History from '../History';
import PaymentDetail from '../PaymentDetail';
import TicketEdit from '../TicketEdit';
import TicketHolding from '../TicketHolding';
import TicketInactive from '../TicketInactive';
import TicketMemo from '../TicketMemo';
import TicketRefund from '../TicketRefund';
import TicketTransfer from '../TicketTransfer';
import TicketUpgrade from '../TicketUpgrade';

type Props = {
  currentTicketId: number;
  currentMemberId: number;
};

const DetailPages = ({ currentTicketId, currentMemberId }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);

  const { hasPermission } = usePermission();
  const canUpdateUserTicket = hasPermission(PERMISSION.ticket.edit);
  const { data: userTicket } = useGetUserTicket({
    ticket_id: currentTicketId,
    user_id: currentMemberId,
  });

  const { getSearchParams, getAllSearchParams, setSearchParams } = useQueryString();
  const step = Number(getSearchParams('step'));

  const clickNextPage = () => {
    const next = step + 1;
    setSearchParams({
      ...getAllSearchParams(),
      step: `${next}`,
    });
  };

  switch (getSearchParams('type')) {
    case 'payment':
      return <PaymentDetail canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} />;
    case 'holding':
      return <TicketHolding canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} />;
    case 'history':
      return <History userTicket={userTicket} />;
    case 'memo':
      return <TicketMemo productId={userTicket.id} />;
    case 'edit':
      return <TicketEdit userTicket={userTicket} currentStaffId={currentStaffId} />;
    case 'upgrade':
      return <TicketUpgrade userTicket={userTicket} step={step} onClickNextPage={clickNextPage} />;
    case 'transfer':
      return (
        <TicketTransfer userTicket={userTicket} currentMemberId={currentMemberId} step={step} onClickNextPage={clickNextPage} />
      );
    case 'refund':
      return <TicketRefund userTicket={userTicket} />;
    case 'not_usable':
      return <TicketInactive userTicket={userTicket} />;
    default:
      return null;
  }
};

export default DetailPages;
