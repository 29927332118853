import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useGetStaffEventSchedules from 'hooks/service/queries/useGetStaffEventSchedules';
import useParamsId from 'hooks/useParamsId';
import _ from 'lodash';
import { StaffFieldFormType } from 'pages/MoreDetails/Staff/List/types';
import { Fragment, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { staffTotalAtom } from 'recoil/staff';
import getEventTimeDataset from 'utils/getEventTimeDataset';

import EventScheduleCard from './EventScheduleCard';

const EventScheduleCardList = () => {
  const setStaffTotal = useSetRecoilState(staffTotalAtom);

  const targetStaffId = useParamsId();
  const { data: eventSchedules } = useGetStaffEventSchedules({ targetStaffId });
  const eventWorkTimeDataset = useMemo(() => getEventTimeDataset(eventSchedules), [eventSchedules]);

  const { control } = useFormContext<StaffFieldFormType>();
  const sort = useWatch({ control, name: 'sort' });

  const { eventWorkTimes: workTimes, eventRestTimes: restTimes } = eventWorkTimeDataset;

  useEffect(() => {
    setStaffTotal(workTimes.length);
  }, [setStaffTotal, workTimes]);

  const sortSchedules = sort === 'desc' ? _.orderBy(workTimes).reverse() : workTimes;

  if (!workTimes.length) {
    return (
      <NoContentContainer>
        <Typography size={15} textColor="gray2" opacity={0.8}>
          등록된 일정이 없습니다.
        </Typography>
      </NoContentContainer>
    );
  }

  return (
    <ul>
      {sortSchedules.map((workTime, index, list) => {
        const currentRestTimes = _.filter(restTimes, { date: workTime.date });
        return (
          <Fragment key={workTime.id}>
            <EventScheduleCard
              eventWorkTime={workTime}
              eventRestTimes={currentRestTimes}
              allEventWorkTimes={list}
              allEventRestTimes={restTimes}
            />
            {list.length - 1 !== index && <Divider thin />}
          </Fragment>
        );
      })}
    </ul>
  );
};

export default EventScheduleCardList;

const NoContentContainer = styled.div`
  ${theme.flex()};
  margin-top: 100px;
`;
