import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { customBackHandlerAtom } from 'recoil/common';
import { popupAtom } from 'recoil/popup';
import { postMessageToApp } from 'utils/communicateWithNative';

import useHistory from './useHistory';
import useToast from './useToast';

const BACK_PRESS_INTERVAL = 0.5 * 1000; // 두 번 연속의 기준 : 간격 0.5초 이내

const useHardwareBackKey = () => {
  const lastPressedTime = useRef<number>(0);
  const navigate = useNavigate();
  const { setToast } = useToast();
  const [popup, setPopup] = useRecoilState(popupAtom);
  const [customBackHandler, setCustomBackHandler] = useRecoilState(customBackHandlerAtom);
  const history = useHistory();

  const handleBackKey = useCallback(() => {
    // 커스텀 로직
    if (customBackHandler) {
      customBackHandler();
      setCustomBackHandler(null);
      return;
    }

    // 팝업이 열려 있다면 팝업 닫기
    if (popup) {
      setPopup(null);
      return;
    }

    // 이전 히스토리가 있는 경우 뒤로가기
    if (history.length > 1) {
      navigate(-1);
      return;
    }

    // 뒤로가기 버튼을 빠르게 두번 누를 경우 앱 종료
    const isPressedDouble = Date.now() - lastPressedTime.current < BACK_PRESS_INTERVAL;
    if (isPressedDouble) {
      postMessageToApp('EXIT_APP');
      return;
    }

    setToast({ message: '두번 연속으로 누르면 앱이 종료됩니다.', bottom: 72 });
    lastPressedTime.current = Date.now();
  }, [customBackHandler, popup, history.length, setToast, setCustomBackHandler, setPopup, navigate]);

  return handleBackKey;
};

export default useHardwareBackKey;
