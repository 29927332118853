import styled from '@emotion/styled';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';

import { MENUS } from '../constants';

type Props = {
  onBasicMenuClick?: () => void;
};

/**
 * 메뉴만 보이고 이동하지 않음
 * 시설미연동, fallback 등 상황에서 활용
 * 기획상 서브메뉴는 제대로 이동
 */
const FakeNavigation = ({ onBasicMenuClick }: Props) => {
  return (
    <>
      {MENUS.basic.map(menu => (
        <BasicMenu key={menu.name} onClick={onBasicMenuClick}>
          <Typography size={15} weight={600}>
            {menu.name}
          </Typography>
        </BasicMenu>
      ))}
      <DividerWithMargin thin thinColor="gray6" />
      {MENUS.sub.map(menu => (
        <SubMenu key={menu.name} to={menu.path}>
          <Typography weight={500} textColor="gray2">
            {menu.name}
          </Typography>
        </SubMenu>
      ))}
    </>
  );
};

export default FakeNavigation;

const BasicMenu = styled(Button)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 13px 20px 13px;
`;

const DividerWithMargin = styled(Divider)`
  margin: 12px 0;
`;

const SubMenu = styled(Link)`
  display: block;
  padding: 12px 20px 12px;
`;
