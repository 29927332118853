import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import dayjs from 'dayjs';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isSomePermissionAtom } from 'recoil/calendarSettings';
import { calendarViewTypeAtom, currentDateAtom, headerDatePickerOpenAtom, selectedFiltersAtom } from 'recoil/schedule';

import ViewTypeSelectDropdown from './dropdown/ViewTypeSelectDropdown';
import FilterSettings from './popupPage/FilterSettings';

const CustomCalendarHeader = () => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useRecoilState(headerDatePickerOpenAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const { schedules, staffs } = useRecoilValue(selectedFiltersAtom);
  const [currentDate, setCurrentDate] = useRecoilState(currentDateAtom);

  const { hasPermission, somePermissions } = usePermission();
  const isStaffViewPermission = hasPermission(PERMISSION.staff.view);

  const setIsSomePermission = useSetRecoilState(isSomePermissionAtom);

  /** 다른 스태프의 일정 조회 권한들 중 하나라도 있으면 true */
  const isSomePermission = somePermissions([
    PERMISSION.counsel.view,
    PERMISSION.lecture.others.view.group,
    PERMISSION.lecture.others.view.private,
    PERMISSION.etcSchedule.others.view,
  ]);

  useEffect(() => {
    setIsSomePermission(isSomePermission);
  }, [setIsSomePermission, isSomePermission]);

  const { setPopup } = usePopup();

  const { data: allStaffs, isLoading } = useGetFilterStaffs();

  const changeDate = (e: { value: Date }) => setCurrentDate(e.value);

  const openFilterPopupPage = () => {
    setPopup(<FilterSettings allStaffs={allStaffs} isStaffViewPermission={isStaffViewPermission} isLoading={isLoading} />);
  };

  const headerDateText = useMemo(() => {
    const format = calendarViewType === 'day' ? 'MM.DD (ddd)' : 'YYYY.MM';
    return dayjs(currentDate).format(format);
  }, [calendarViewType, currentDate]);

  const isApplyFilter = useMemo(() => {
    const isSchedulesFilterDirty = !schedules.includes('all');
    const isStaffsFilterDirty = !staffs.includes('all');
    return isSchedulesFilterDirty || isStaffsFilterDirty;
  }, [schedules, staffs]);

  return (
    <Container>
      <div className="header-wrapper">
        <div className="header-days-controls">
          <Button
            fontSize={19}
            fontWeight={700}
            textColor="gray1"
            rightIcon={<Icon name={isDatePickerOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} />}
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
            {headerDateText}
          </Button>
        </div>

        <div className="icons-wrapper">
          <IconButton
            className={isApplyFilter ? 'apply-filter' : undefined}
            onClick={isLoading ? undefined : openFilterPopupPage}>
            <Icon name="headerFilter" />
          </IconButton>

          <ViewTypeSelectDropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} />
        </div>
      </div>

      {isDatePickerOpen && <DatePicker dateFormat={'YYYY/MM/DD'} value={currentDate} onChange={changeDate} />}
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;

  .header-wrapper {
    ${theme.flex('row', 'center', 'space-between')}
    margin: 0 20px;
    padding: 20px 0;
    background-color: ${theme.color.white};
  }

  .icons-wrapper {
    ${theme.flex('', '', '', 16)};

    .apply-filter {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 4px;
        height: 4px;
        background-color: ${theme.color.secondary3};
        border-radius: 50%;
      }
    }
  }

  .date-picker {
    position: absolute;
    width: 100%;
    left: 0;
  }
`;

export default CustomCalendarHeader;
