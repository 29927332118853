import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckRadio, { CheckRadioProps } from 'components/Radio/CheckRadio';
import Typography from 'components/Typography';
import { ChangeEvent } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export type CheckRadioFilterDataType = Pick<CheckRadioProps, 'id' | 'label' | 'value'>;

export interface ICheckRadioFilterContentsProps<T extends FieldValues> {
  /** 타이틀 텍스트 */
  title?: string;
  /** radio input 데이터 */
  data: Array<CheckRadioFilterDataType>;
  /** input group 용도의 name */
  name: FieldPath<T>;
  /** useForm의 control */
  control: Control<T>;
  /** label을 전부 보여줄 건지 말지 (default: 1줄 말줄임) */
  showLabelAll?: boolean;
  /** onChange 내부에 커스텀 이벤트 삽입 */
  onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const CheckRadioFilterContents = <T extends FieldValues>({
  title,
  data,
  name,
  control,
  showLabelAll,
  className,
  onClick,
}: ICheckRadioFilterContentsProps<T>) => {
  return (
    <Container className={`check-radio-filter-contents ${className}`} showLabelAll={showLabelAll}>
      {title && (
        <div className="check-radio-filter-header-wrapper">
          <Typography weight={700} size={19}>
            {title}
          </Typography>
        </div>
      )}
      <ul className="check-radio-filter-data-wrapper">
        {data.map(item => (
          <li key={item.id}>
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <CheckRadio
                  id={item.id}
                  label={
                    typeof item.label === 'string' ? (
                      <Typography
                        className="check-radio-filter-label-text"
                        span
                        size={17}
                        weight={item.value === value ? 600 : 400}
                        textColor={item.value === value ? 'primary' : 'gray1'}
                        ellipsis={!showLabelAll ? 1 : undefined}>
                        {item.label}
                      </Typography>
                    ) : (
                      item.label
                    )
                  }
                  value={item.value}
                  checked={item.value === value}
                  onChange={e => {
                    onChange(e);
                    onClick?.(e);
                  }}
                  {...field}
                />
              )}
            />
          </li>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div<{ showLabelAll?: boolean }>`
  padding: 16px 20px 40px;

  .check-radio-filter-header-wrapper {
    padding-bottom: 16px;
  }

  .check-radio-filter-data-wrapper {
    ${theme.flex('column', 'initial', 'initial', 8)};
    padding-top: 8px;

    li {
      height: ${({ showLabelAll }) => (showLabelAll ? 'auto' : '40px')};
      min-height: ${({ showLabelAll }) => (showLabelAll ? '40px' : 'auto')};

      label {
        height: 100%;
      }
    }
  }
`;

export default CheckRadioFilterContents;
