import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Textarea from 'components/Textarea';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import usePatchUserTicketActive from 'hooks/service/mutations/usePatchUserTicketActive';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_INACTIVE_TEXT } from 'pages/TicketDetail/constants';
import { TicketInactiveFormType } from 'pages/TicketDetail/types';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SubmitButton from './SubmitButton';

const ReasonForm = () => {
  const { control, handleSubmit, getValues } = useFormContext<TicketInactiveFormType>();

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const navigate = useNavigate();

  const { mutate: inActiveUserTicketMutate } = usePatchUserTicketActive(getValues('userTicket.id'));

  const submit = (values: TicketInactiveFormType) => {
    const { ticketInactiveReason, userTicket } = values;

    const params = {
      user_id: userTicket.user_id,
      reason: ticketInactiveReason,
    };
    inActiveUserTicketMutate(params, {
      onSuccess: () => {
        const message = `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]}이 ${TICKET_INACTIVE_TEXT.successMessage.inactive}`;

        setToast({ type: 'success', message });
        navigate(-1);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <div className="wrapper">
        <Controller
          name="ticketInactiveReason"
          control={control}
          render={({ field, fieldState }) => (
            <Textarea
              label={TICKET_INACTIVE_TEXT.form.textarea.label}
              placeholder={TICKET_INACTIVE_TEXT.form.textarea.placeholder}
              errorMessage={fieldState.error?.message}
              currentLength={field.value.length}
              maxLength={20}
              rows={1}
              {...field}
            />
          )}
        />
      </div>
      <SubmitButton />
    </StyledForm>
  );
};

export default ReasonForm;

const StyledForm = styled(Form)`
  flex: 1;
  ${theme.flex('column', '', '')};

  .wrapper {
    padding: 0 20px;
    flex: 1;
  }
`;
