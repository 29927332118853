import { TypographyProps } from 'components/Typography';
import dayjs from 'dayjs';
import { ScheduleManagementBookingResponse } from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import { ScheduleManagementDeletedLectureResponse } from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import { ColorType } from 'types/colorTypes';

import { SCHEDULE_DEFAULT_DAYS } from './constant';
import { ScheduleStaff } from './type';

type StatusLabel = {
  name: string;
  textColor: TypographyProps['textColor'];
  color: ColorType;
};

/** 현재시간 구하기 */
export const changeNearestFiveMinutes = (): string => {
  const currentDate = dayjs();
  const minute = currentDate.minute();

  // 현재 분이 16분 이상인 경우 가장 가까운 5분 단위로 조정
  const adjustedMinute = Math.floor(minute / 5) * 5;

  // 시간과 분을 조정하여 새로운 시간 생성
  const adjustedTime = currentDate.minute(adjustedMinute);

  return adjustedTime.format('YYYY-MM-DD HH:mm');
};

/** 요일 정렬 */
export const makeDayTabLabel = (dayValue: string[]): string => {
  // 정렬된 요일 값을 label로 변환하고 콤마로 연결된 문자열로 반환
  const label = dayValue
    .toSorted((a, b) => Number(a) - Number(b)) // 요일 값을 순서대로 정렬
    .map(day => SCHEDULE_DEFAULT_DAYS.find(d => d.value === day)?.label || '') // label로 변환
    .join(', '); // 콤마로 연결

  return label;
};

/** 강사 아이디 문자열로 변환 */
export const convertToStringStaffIds = (staffs: ScheduleStaff[]): string | undefined => {
  return staffs.length === 0 ? undefined : staffs.map(staff => staff.id).join(';');
};

/** 요일 배열 문자열로 변환 */
export const convertTostringDays = (days: string[]): string | undefined => {
  return days.length === 0 ? undefined : days.map(day => day).join(';');
};

/** 스케쥴을 날짜로 그룹핑 */
export const getScheduleGroupByStartOn = (
  schedules: (ScheduleManagementBookingResponse | ScheduleManagementDeletedLectureResponse)[],
): (ScheduleManagementBookingResponse | ScheduleManagementDeletedLectureResponse)[][] => {
  const newSchedules: (ScheduleManagementBookingResponse | ScheduleManagementDeletedLectureResponse)[][] = [];

  schedules.forEach(schedule => {
    const startDate = schedule.start_on.split(' ')[0];
    const findDuplicateIndex = newSchedules.findIndex(newShcedule => newShcedule[0].start_on.split(' ')[0] === startDate);
    if (findDuplicateIndex === -1) {
      newSchedules.push([schedule]);
    } else {
      newSchedules[findDuplicateIndex].push(schedule);
    }
  });

  return newSchedules;
};

/** 예약 상태 라벨  */
export const getStatusLabel = (status: string): StatusLabel => {
  switch (status) {
    case 'booked':
      return {
        name: '예약',
        textColor: 'primary',
        color: 'primary',
      };
    case 'booking_confirmed':
      return {
        name: '예약확정',
        textColor: 'primary',
        color: 'primary',
      };
    case 'booking_waiting':
      return {
        name: '예약대기',
        textColor: 'primary',
        color: 'primary',
      };
    case 'cancel':
      return {
        name: '취소',
        textColor: 'secondary3',
        color: 'secondary3',
      };
    case 'absence':
      return {
        name: '결석',
        textColor: 'secondary3',
        color: 'secondary3',
      };
    case 'noshow':
      return {
        name: '노쇼',
        textColor: 'secondary3',
        color: 'secondary3',
      };
    default:
      return {
        name: '출석',
        textColor: 'blueViolet1',
        color: 'blueViolet1',
      };
  }
};

/** 검색 키워드가 이름인지 번호인지 판단 */
export const isKeywordNumeric = (searchKeyword: string) => {
  return !isNaN(Number(searchKeyword));
};

/** 그룹, 개인 수업 인원수 계산 */
export const calculateTotal = (
  schedules: (ScheduleManagementBookingResponse | ScheduleManagementDeletedLectureResponse)[][],
  type: string,
) => {
  let total = 0;
  const flattenedSchedules = schedules.flat();
  for (let i = 0; i < flattenedSchedules.length; i++) {
    if (flattenedSchedules[i].type === type) {
      total += flattenedSchedules[i].bookings.length;
    }
  }
  return total;
};

/** 검색 키워드로 목록 필터링 */
export const filterByKeyword = (
  items: (ScheduleManagementBookingResponse | ScheduleManagementDeletedLectureResponse)[],
  keyword: string,
) => {
  return items.filter(item => {
    return item.bookings.some(book => {
      if (isKeywordNumeric(keyword)) {
        return book.member.mobile === keyword;
      } else {
        return book.member.name.includes(keyword);
      }
    });
  });
};
