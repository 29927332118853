import useInfinityHolding from 'hooks/service/queries/useInfinityHolding';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';

import HistoryCard from './HistoryCard';

type Props = Pick<UserTicketProps, 'canUpdateUserTicket'>;

const HoldingHistory = ({ canUpdateUserTicket }: Props) => {
  const { getValues, setValue } = useFormContext<TicketHoldingFormType>();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityHolding(getValues('params.user_ticket_id'));

  if (!data.length) {
    return <NodataTypography>수강권 정지이력이 없습니다.</NodataTypography>;
  }

  return (
    <InfiniteScroll gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      {data.map(holding => (
        <HistoryCard
          key={holding.id}
          history={holding}
          canUpdateUserTicket={canUpdateUserTicket}
          onCancel={() => setValue('selectedHolding', holding, { shouldDirty: true })}
        />
      ))}
    </InfiniteScroll>
  );
};

export default HoldingHistory;
