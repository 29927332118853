import Divider from 'components/Divider';
import useGetMemberDetail from 'hooks/service/queries/useGetMemberDetail';
import useToast from 'hooks/useToast';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { currentMemberAtom } from 'recoil/MemberDetail';
import { postMessageToApp } from 'utils/communicateWithNative';

import { CurrentMemberIdProps } from '../types';
import MainTopInfo from './MainTopInfo';
import MemberInfoAccordion from './MemberInfoAccordion';

const MemberInfo = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { data: member, error } = useGetMemberDetail(currentMemberId);
  const { setToast } = useToast();

  const setCurrentMember = useSetRecoilState(currentMemberAtom);

  /**
   * TODO: error 처리
   */
  useEffect(() => {
    if (error) {
      const { message } = error;
      /** 이미 삭제된 회원일 때 에러 코드 */
      // if (code === 11108) {
      //   navigate(MEMBER_MAIN_URL);
      // }
      setToast({ type: 'error', message });
    }
  }, [error, setToast]);

  useEffect(() => {
    if (member) {
      setCurrentMember(member);
    }
  }, [member, setCurrentMember]);

  return (
    <>
      <MainTopInfo
        member={member}
        onClickCopyEmail={(text: string) => postMessageToApp('COPY_CLIPBOARD', { text, target: '이메일' })}
      />
      <Divider />
      <MemberInfoAccordion member={member} />
    </>
  );
};

export default MemberInfo;
