import useGetEtcScheduleEtcSchedule from 'hooks/service/queries/useGetEtcScheduleEtcSchedule';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const AllDayDetail = ({ currentEtcScheduleId }: Props) => {
  const {
    data: { start_date, end_date, staffs, ...rest },
  } = useGetEtcScheduleEtcSchedule(currentEtcScheduleId);
  const { canUpdateEtcSchedule, canDeleteEtcSchedule } = useCheckPermissionEtcDetail(staffs);
  const allDayData = {
    ...rest,
    staffs,
    start_on: start_date,
    end_on: end_date,
    etc_schedule_id: rest.id,
    etc_schedule: { start_date, end_date, weekday: rest.weekday },
  };

  return (
    <DetailMain
      currentEtcScheduleId={currentEtcScheduleId}
      data={allDayData}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default AllDayDetail;
