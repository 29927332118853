import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import { MOBILE_DEFAULT_PERIOD } from 'pages/MoreDetails/Message/Mobile/List/constants';
import { useRecoilState } from 'recoil';
import { appPushSelectedFilterAtom } from 'recoil/message';
import DateFilter from 'sharedComponents/DateFilter';
import { FilterOptionValuesType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { APP_PUSH_FILTER } from '../constants';

// 스티키 헤더에 존재하는 필터 버튼
const AppPushFilterButton = () => {
  const [selectedFilter, setSelectedFilter] = useRecoilState(appPushSelectedFilterAtom);

  const openFilter = () => {
    setSelectedFilter({
      ...selectedFilter,
      isFilterOpened: !selectedFilter.isFilterOpened,
    });
  };

  const currentPeriod = selectedFilter?.period ?? MOBILE_DEFAULT_PERIOD;

  return (
    <StyledDateFilter
      isOpened={selectedFilter.isFilterOpened}
      period={filters.period(currentPeriod.start, currentPeriod.end)}
      text={APP_PUSH_FILTER.tabConverter?.(currentPeriod as unknown as FilterOptionValuesType)}
      onClick={openFilter}
    />
  );
};

export default AppPushFilterButton;

const StyledDateFilter = styled(DateFilter)<{ isOpened?: boolean }>`
  background-color: ${theme.color.white};
  position: relative;
  z-index: ${Z_INDEX.stickyTab - 1}; // AppPushFilterContents와 겹치지 않도록
  svg {
    ${({ isOpened }) => isOpened && `transform: rotate(180deg);`}
  }
`;
