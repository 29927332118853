import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import useQueryString from 'hooks/useQueryString';
import { ComponentProps } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import { Birthday, Gender, Mobile, Profile, RegisteredDate } from 'sharedComponents/ProfileField';

import Address from '../components/Address';
import Tickets from '../components/Tickets';
import UserGrade from '../components/UserGrade';
import Vaccination from '../components/Vaccination';
import { MEMBER_FORM_TEXT } from '../constants';
import SubmitButton from './SubmitButton';
import UserTickets from './UserTickets';

type Props = Omit<ComponentProps<typeof Form>, 'children'> & {
  isUserGrade: boolean;
  pageMode: 'create' | 'update';
};

const CommonMemberForm = ({ pageMode, isUserGrade, ...props }: Props) => {
  const { setSearchParams } = useQueryString();

  return (
    <MemberFormContainer {...props}>
      <Profile onClick={() => setSearchParams({ type: 'profile' })} />
      <Mobile desc={MEMBER_FORM_TEXT.mobile.description} />
      <RegisteredDate />
      {isUserGrade && <UserGrade />}
      <Gender />
      <Birthday />
      <Address />
      <ApiBoundary
        fallback={
          <FormFieldLinkButton iconName="ticket" disabled>
            수강권/상품
          </FormFieldLinkButton>
        }>
        <Tickets />
      </ApiBoundary>
      {pageMode === 'update' && (
        <ApiBoundary>
          <UserTickets />
        </ApiBoundary>
      )}
      <Vaccination />
      <SubmitButton pageMode={pageMode} />
    </MemberFormContainer>
  );
};

export default CommonMemberForm;

export const MemberFormContainer = styled(Form)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 116px;

  .accordion {
    border-bottom: 1px solid ${theme.color.gray8};
  }

  .switch {
    border-top: 1px solid ${theme.color.gray8};
    width: 100%;
    padding: 0 20px;
    height: 56px;

    .switch-contents-wrapper {
      height: 100%;
    }
  }

  .ticket-list {
    padding-top: 8px;
    padding-bottom: 4px;

    + .switch {
      margin-top: 16px;
    }
  }
`;
