import dayjs from 'dayjs';

const isPastOrFutureDate = (date: Date) => {
  const today = dayjs();
  const targetDate = dayjs(date);

  if (targetDate.isBefore(today, 'day')) return 'past';
  if (targetDate.isAfter(today, 'day')) return 'future';
  return 'today';
};

export default isPastOrFutureDate;
