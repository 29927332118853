export const STAFF_ERROR_KEYS = ['10901', '10920'];
export const ROOM_ERROR_KEYS = ['13001'];
export const BOOKING_ERROR_DATA: { [key: string]: string } = {
  10503: '휴일 설정',
  10902: '일정중복',
  10004: '예약불가 수업',
  10012: '정지 기간',
  15002: '예약 실패',
  15004: '예약 실패',
  10000: '예약횟수 부족',
  10016: '일정중복',
  10017: '일정중복',
  10901: '강사중복',
  10920: '강사중복',
  13001: '룸중복',
  10904: '일정중복',
  10506: '예약횟수 초과',
  10006: '정원 초과',
  10003: '예약된 수업',
  10101: '예약대기 불가',
  10001: '예약대기 초과',
  10102: '예약대기 불가',
  10103: '대기횟수 초과',
};

export const BOOKING_PRIVATE_FORM_HEADER_TITLE_TEXT = {
  create: '프라이빗 수업 예약',
  update: '프라이빗 수업 수정',
  repeat: '반복 설정',
  custom: '맞춤 설정',
  selectMember: '수강회원 선택',
};

export const BOOKING_PRIVATE_FORM_TEXT = {
  lectureSize: {
    placeholder: '수업인원',
    drawer: {
      title: '수업인원 선택',
    },
  },
  title: {
    description: ['수업명을 입력하지 않으면', '회원명으로 수업이 등록됩니다.'],
  },
  selectMember: {
    dialog: {
      positiveText: '확인',
      description: `수업인원 선택 후, \n수강회원 선택이 가능합니다.`,
    },
    search: {
      placeholder: '회원명, 휴대폰 번호 검색',
    },
    footerButton: '선택 완료',
  },
  dialog: {
    beforeLeave: {
      positiveText: '나가기',
      negativeText: '취소',
      description: {
        selectMember: `변경사항이 저장되지 않았습니다. \n페이지를 나가시겠습니까?`,
        default: '예약정보가 저장되지 않았습니다. \n페이지를 나가시겠습니까?',
      } as { [key: string]: string },
    },
  },
};

export const LECTURE_SIZE_CHECK_RADIO_DATA = [
  { id: 'alone', label: '개인 (1:1)', value: '1' },
  { id: 'duet', label: '듀엣 (2:1)', value: '2' },
  { id: 'triple', label: '트리플 (3:1)', value: '3' },
];

export const VALID_ERROR_TYPE_TEXT = {
  total: {
    daily: '일일 예약가능 횟수: ',
    weekly: '최대 예약가능 횟수: 주 ',
    monthly: '최대 예약가능 횟수: 월 ',
  },
  count: {
    daily: '',
    weekly: '주 ',
    monthly: '월 ',
  },
};
