import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import AllDayDetail from './AllDayDetail';

/** 기타일정 종일 상세 */
const EtcScheduleAllDayDetail = () => {
  const currentEtcScheduleId = useParamsId();

  return (
    <ApiBoundary>
      <AllDayDetail currentEtcScheduleId={currentEtcScheduleId} />
    </ApiBoundary>
  );
};

export default EtcScheduleAllDayDetail;
