import styled from '@emotion/styled';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useMemo, useState } from 'react';

dayjs.extend(utc);

type Props = {
  /** 타이머 진행할 시간 입력, 초 기준 */
  count: number;
  /** 타임아웃 시 실행할 함수 */
  onTimeOut?: () => void;
};

const Timer = ({ count, onTimeOut }: Props) => {
  const [remainingTime, setRemainingTime] = useState<number>(count);

  useEffect(() => {
    const interval = setInterval(() => (remainingTime ? setRemainingTime(prev => prev - 1) : false), 1000);
    return () => clearInterval(interval);
  }, [remainingTime, setRemainingTime]);

  useEffect(() => {
    if (!onTimeOut) return;
    const timeout = setTimeout(onTimeOut, count * 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const time = useMemo(() => remainingTime && dayjs.utc(remainingTime * 1000).format('mm:ss'), [remainingTime]);

  if (!time) return null;
  return (
    <Container>
      <Typography size={14} weight={500} textColor={time ? 'primary' : 'error'}>
        남은 시간 {time}
      </Typography>
    </Container>
  );
};

export default Timer;

const Container = styled.div`
  margin-top: 4px;
`;
