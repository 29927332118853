import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import SubTitle from 'components/SubTitle';
import Typography from 'components/Typography';
import MainLayout from 'sharedComponents/MainLayout';

import WithdrawForm from './components/WithdrawForm';
import { WITHDRAW_TEXT } from './constants';

const MoreDetailsAccountWithdraw = () => {
  return (
    <MainLayout header={{ title: WITHDRAW_TEXT.headerTitle }}>
      <Container>
        <Notifications>
          <SubTitle title={WITHDRAW_TEXT.title} bottom={8} />
          {WITHDRAW_TEXT.contents.map(content => (
            <Description key={content} size={15} textColor="gray2">
              {content}
            </Description>
          ))}
        </Notifications>
        <Divider />
        <WithdrawSection>
          {WITHDRAW_TEXT.guides.map(guide => (
            <Typography key={guide} size={17} weight={600}>
              {guide}
            </Typography>
          ))}
        </WithdrawSection>
        <WithdrawForm />
      </Container>
    </MainLayout>
  );
};

export default MoreDetailsAccountWithdraw;

const Container = styled.div`
  ${theme.flex('column', '', '')}
  height: 100%;
`;
const Notifications = styled.section`
  padding: 8px 20px 32px;
  ${theme.flex('column', 'start', 'center', 8)}
  flex: 0 0 auto;
`;

const Description = styled(Typography)`
  position: relative;
  padding: 0 6px;
  :before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${theme.color.gray3};
  }
`;

const WithdrawSection = styled.section`
  padding: 32px 20px 0;
  width: 100%;
  ${theme.flex('column', '', 'center')}
`;
