import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const useDeleteBoardNotice = (id: number) => {
  const queryClient = useQueryClient();
  const { mutationFn } = useMutationFnWithCommonParams<BoardNoticeParams>({
    method: `delete`,
    url: `/api/board/notice/${id}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ['board/notice'] });
    },
  });
};

export default useDeleteBoardNotice;

type BoardNoticeParams = {
  board: number;
};
