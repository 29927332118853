import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { TicketTransferCommonProps } from 'pages/TicketDetail/types';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import TicketSubTitle from '../../TicketSubTitle';
import MemberList from './MemberList';
import NextButton from './NextButton';
import Search from './Search';

const SelectMember = ({ productTypeText, onClickNextPage, title, subTitle }: TicketTransferCommonProps) => {
  return (
    <Container>
      <>
        <TicketSubTitle title={title} message={`${productTypeText}${subTitle}`} />
        <Search />
      </>

      <ApiBoundary>
        <MemberList />
      </ApiBoundary>

      <NextButton onClickNextPage={onClickNextPage} />
    </Container>
  );
};

export default SelectMember;

const Container = styled.div`
  height: 100%;
  padding-bottom: 76px;
  position: relative;
  ${theme.flex('column', '', '')};
`;
