import { BookingType } from 'pages/Booking/types';
import { useNavigate } from 'react-router-dom';

import { BOOKING_DETAIL_UPDATE_DRAWER_LIST } from '../constants';
import MoreButtonListDrawer from './MoreButtonListDrawer';

type Props = {
  currentLectureId: number;
  bookingType: BookingType;
  isOpen: boolean;
  onClose: () => void;
};

const LectureUpdateListDrawer = ({ currentLectureId, bookingType, isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const goBookingUpdateForm = (mode: string) => {
    navigate(`/booking/${bookingType}/form/${mode}/${currentLectureId}`);
  };

  return (
    <MoreButtonListDrawer
      buttonList={BOOKING_DETAIL_UPDATE_DRAWER_LIST}
      onClick={goBookingUpdateForm}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default LectureUpdateListDrawer;
