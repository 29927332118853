import { useSuspenseQuery } from '@tanstack/react-query';
import { MemberTagCountType } from 'pages/Member/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 회원분류 별 숫자, 목록 조회 필터에서 활용 */
const useGetMemberCount = (params: MemberCountParams) => {
  const { queryFn } = useQueryFnWithCommonParams<MemberCountResponse, MemberCountParams>({
    url: `/api/member/count`,
    params,
  });

  return useSuspenseQuery({
    queryKey: ['member', 'count', { ...params }],
    queryFn,
    select: data => data.data,
  });
};

export default useGetMemberCount;

type MemberCountParams = {
  is_assigned?: 1 | 0; // 담당회원 여부
  has_mobile?: 1 | 0; // 휴대폰번호 보유 여부
  is_app_linked?: 1 | 0; // 앱연동 여부
};

type MemberCountResponse = MemberTagCountType;
