export const MEMO_TEXT = {
  title: '메모 작성',
  placeholder: '메모를 입력해 주세요.',
  formDialog: {
    negativeText: '취소',
    positiveText: '나가기',
  },
  deleteDialog: {
    negativeText: '취소',
    positiveText: '삭제',
  },
  successMessage: {
    create: '메모가 추가되었습니다.',
    update: '메모가 수정되었습니다.',
    delete: '메모가 삭제되었습니다.',
  },
};

export const MEMO_SORTING_DATA = [
  { id: 'desc', label: '최신순', value: 'desc' },
  { id: 'asc', label: '과거순', value: 'asc' },
];
