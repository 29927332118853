import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import BookingFailList from 'pages/Booking/components/AfterRequestBooking/BookingFailList';
import { InfoTypography } from 'sharedComponents/InfoTypography';

type Props = {
  fails: Array<BookingFailResponse>;
};

const BookingFail = ({ fails }: Props) => {
  return (
    <Container>
      <div className="fail-title-wrapper">
        <Typography size={17} weight={700}>
          실패
        </Typography>
        <Typography size={17} weight={700} textColor="error">
          {fails.length}
        </Typography>
      </div>
      <InfoTypography size={14}>예약실패 사유를 확인해 주세요.</InfoTypography>

      <BookingFailList fails={fails} />
    </Container>
  );
};

export default BookingFail;

const Container = styled.div`
  padding: 24px 20px;

  .fail-title-wrapper {
    ${theme.flex('', 'center', '', 4)};
    margin-bottom: 2px;
  }
`;
