import useQueryString from 'hooks/useQueryString';
import Repeat from 'pages/Booking/components/Repeat';
import { ETC_FORM_DEFAULT_HEADER_TITLE_TEXT } from 'pages/EtcSchedule/constants';
import { EtcPageModeType, EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { PropsWithChildren } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';

import CommonEtcScheduleForm from './CommonEtcScheduleForm';

type Props = UseFormReturn<EtcScheduleCreateFormType> & {
  pageMode: EtcPageModeType;
  onSubmit: (values: EtcScheduleCreateFormType) => void;
};

const EtcFormMainLayout = ({ children, pageMode, onSubmit, ...methods }: PropsWithChildren<Props>) => {
  const { getSearchParams } = useQueryString();
  const type = getSearchParams('type') as string;
  const setting = getSearchParams('setting') as string;
  const headerTitle = ETC_FORM_DEFAULT_HEADER_TITLE_TEXT[setting || type || pageMode];

  return (
    <MainLayout header={{ title: headerTitle }}>
      <FormProvider {...methods}>
        {type === 'repeat' && <Repeat pageMode={pageMode === 'create' ? 'create' : 'update'} />}
        {!type && (
          <CommonEtcScheduleForm pageMode={pageMode} onSubmit={onSubmit}>
            {children}
          </CommonEtcScheduleForm>
        )}
      </FormProvider>
    </MainLayout>
  );
};

export default EtcFormMainLayout;
