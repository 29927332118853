import { PERMISSION } from 'constants/permission';

import { DropdownType } from '../types';

/** 수업 상세 페이지에서 예약 변경/취소, 수업 삭제 등 권한 체크 */
export const BOOKING_STATUS_CHANGE_PERMISSION = {
  mine: {
    group: {
      update: PERMISSION.lecture.mine.editLecture.group,
      change: PERMISSION.lecture.mine.editBookings.group,
      cancel: PERMISSION.lecture.mine.cancelBookings.group,
      delete: PERMISSION.lecture.mine.deleteLecture.group,
    },
    private: {
      update: PERMISSION.lecture.mine.editLecture.private,
      change: PERMISSION.lecture.mine.editBookings.private,
      cancel: PERMISSION.lecture.mine.cancelBookings.private,
      delete: PERMISSION.lecture.mine.deleteLecture.private,
    },
  },
  others: {
    group: {
      update: PERMISSION.lecture.others.editLecture.group,
      change: PERMISSION.lecture.others.editBookings.group,
      cancel: PERMISSION.lecture.others.cancelBookings.group,
      delete: PERMISSION.lecture.others.deleteLecture.group,
    },
    private: {
      update: PERMISSION.lecture.others.editLecture.private,
      change: PERMISSION.lecture.others.editBookings.private,
      cancel: PERMISSION.lecture.others.cancelBookings.private,
      delete: PERMISSION.lecture.others.deleteLecture.private,
    },
  },
};

export const BOOKING_DETAIL_DROPDOWN_DATA: Array<DropdownType> = [
  { value: 'open', label: '마감해제', textColor: 'gray2', icon: { name: 'finishDone', color: 'gray2' } },
  { value: 'close', label: '예약마감', textColor: 'gray2', icon: { name: 'finish', color: 'gray2' } },
  { value: 'update', label: '수정하기', textColor: 'gray2', icon: { name: 'writing2', color: 'gray2' } },
  { value: 'copy', label: '복사하기', textColor: 'gray2', icon: { name: 'copy', color: 'gray2' } },
  { value: 'delete', label: '삭제하기', textColor: 'secondary3', icon: { name: 'delete', color: 'secondary3' } },
];

export const BOOKING_DETAIL_UPDATE_DRAWER_LIST = [
  { label: '이 수업만 수정', value: 'update' },
  { label: '이후 수업 모두 수정', value: 'update-all' },
];

export const BOOKING_DETAIL_DELETE_DRAWER_LIST = [
  { label: '이 수업만 삭제', value: 'delete' },
  { label: '이후 수업 모두 삭제', value: 'deleteAfterDate' },
  { label: '모든 수업 삭제', value: 'deleteAll' },
];
