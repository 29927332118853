import styled from '@emotion/styled';
import { Eventcalendar } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import MainLayout from 'sharedComponents/MainLayout';
import { MainBackgroundColorWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import CustomCalendarHeader from './CustomCalendarHeader';
import { Container as CalendarContainer } from './ScheduleCalendar';

const ScheduleStudioExceptionLayout = () => {
  return (
    <MainLayout bottomNav>
      <Container>
        <section className="exception-header">
          <Typography size={15} weight={600} textColor="white" opacity={0.64}>
            시설 없음
          </Typography>

          <div className="setting-button">
            <IconButton onClick={() => null}>
              <Icon name="headerSetting" color="white" />
            </IconButton>
          </div>
        </section>

        <CalendarContainer viewType="day" allDaysLength={0}>
          <Eventcalendar
            theme="ios"
            themeVariant="light"
            view={{ schedule: { type: 'day' } }}
            renderHeader={() => <CustomCalendarHeader />}
          />
        </CalendarContainer>
      </Container>
    </MainLayout>
  );
};

export default ScheduleStudioExceptionLayout;

const Container = styled(MainBackgroundColorWrapper)`
  .exception-header {
    ${theme.flex()};
    padding: 17px 20px;

    .setting-button {
      position: absolute;
      right: 20px;
    }
  }
`;
