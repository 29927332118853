import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Radio from 'components/Radio';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Typography from 'components/Typography';
import { POINT_POPUP_TEXT } from 'pages/MemberDetail/constants';
import { PointFormType } from 'pages/MemberDetail/types';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

type Props = {
  currentPoint: number;
};

const PointType = ({ currentPoint }: Props) => {
  const { control, setValue, setFocus } = useFormContext<PointFormType>();
  const type = useWatch({ control, name: 'type' });
  const rewardAmount = useWatch({ control, name: 'rewardAmount' });
  const deductAmount = useWatch({ control, name: 'deductAmount' });

  useEffect(() => {
    setFocus(`${type}Amount`);
  }, [setFocus, type]);

  /** 포인트 차감 입력 시, 현재 보유 포인트 보다 많이 입력할 경우 현재 보유 포인트로 세팅 */
  useEffect(() => {
    if (currentPoint < Number(deductAmount)) {
      setValue('deductAmount', filters.numberComma(currentPoint, { reverse: true }), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [currentPoint, deductAmount, setValue]);

  return (
    <Container>
      <div className="inputs-wrapper">
        <Controller
          name="type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Radio
              id="reward"
              label={POINT_POPUP_TEXT.reward.label}
              checked={value === POINT_POPUP_TEXT.reward.value}
              value={POINT_POPUP_TEXT.reward.value}
              onChange={onChange}
            />
          )}
        />

        <OutlinedTextFieldController
          controllerProps={{ control, name: 'rewardAmount' }}
          inputMode="numeric"
          placeholder="0"
          textAlign="right"
          readOnly={type === POINT_POPUP_TEXT.deduct.value}
          suffix={
            <Typography size={15} weight={500} textColor={type === POINT_POPUP_TEXT.deduct.value ? 'gray3' : 'gray1'}>
              P
            </Typography>
          }
          value={filters.numberComma(rewardAmount)}
          onChange={e => {
            setValue('rewardAmount', filters.numberComma(e.target.value, { reverse: true }), {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
        />
      </div>
      <div className="inputs-wrapper">
        <Controller
          name="type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Radio
              id="deduct"
              label={POINT_POPUP_TEXT.deduct.label}
              value={POINT_POPUP_TEXT.deduct.value}
              checked={value === POINT_POPUP_TEXT.deduct.value}
              onChange={onChange}
            />
          )}
        />
        <OutlinedTextFieldController
          controllerProps={{ control, name: 'deductAmount' }}
          inputMode="numeric"
          placeholder="0"
          textAlign="right"
          readOnly={type === POINT_POPUP_TEXT.reward.value}
          suffix={
            <Typography size={15} weight={500} textColor={type === POINT_POPUP_TEXT.reward.value ? 'gray3' : 'gray1'}>
              P
            </Typography>
          }
          value={filters.numberComma(deductAmount)}
          onChange={e => {
            setValue('deductAmount', filters.numberComma(e.target.value, { reverse: true }), {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
        />
      </div>
    </Container>
  );
};

export default PointType;

const Container = styled.div`
  margin-top: 48px;
  ${theme.flex('column', '', '', 8)};

  .inputs-wrapper {
    ${theme.flex('', '', '', 20)};

    .outlined-text-field {
      flex: 1;
    }
  }
`;
