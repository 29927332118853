import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useGetUserTicket from 'hooks/service/queries/useGetUserTicket';
import { ReportLectureResponse } from 'hooks/service/queries/useInfinityReportLecture';
import filters from 'utils/filters';

type Props = {
  userTicketParams: { ticket_id: number; user_id: number };
  lectureData: ReportLectureResponse;
  isOpen: boolean;
  isAutoTicket: boolean;
};

type Item = {
  label: string;
  value: string;
  isPadding: boolean;
  fontWeight?: 700 | 600 | 500 | 400;
  fontSize?: number;
  opacity?: number;
};
const ReportLectureDetail = ({ userTicketParams, lectureData, isOpen, isAutoTicket }: Props) => {
  const { data: userTicket } = useGetUserTicket(userTicketParams);

  const topArray: Item[] = [
    {
      label: '수강권명',
      value: userTicket.ticket.title,
      isPadding: true,
      fontWeight: 500,
      fontSize: 15,
    },
    {
      label: '유형',
      value: isAutoTicket ? '차감제' : lectureData.type === 'T' ? '횟수제' : '기간제',
      isPadding: true,
      fontWeight: 500,
      fontSize: 15,
    },
    {
      label: '결제금액',
      value: `${filters.numberComma(userTicket.payments.total_amount)}원`,
      isPadding: true,
      fontWeight: 500,
      fontSize: 15,
    },
    {
      label: '회당금액',
      value: `${filters.numberComma(lectureData.per_amount)}원`,
      isPadding: false,
      fontWeight: 500,
      fontSize: 15,
    },
    {
      label: '(전체횟수)',
      value: `(${userTicket.max_coupon}회)`,
      opacity: 0.8,
      isPadding: false,
    },
  ];

  const bottomArray: Item[] = [
    {
      label: '차감 금액',
      value: `${filters.numberComma(isAutoTicket ? lectureData.per_amount * lectureData.per_count : lectureData.per_amount)}원`,
      isPadding: false,
      fontWeight: 500,
      fontSize: 15,
    },
    {
      label: '(차감횟수)',
      value: isAutoTicket ? `(${lectureData.per_count}회)` : '(1회)',
      opacity: 0.8,
      isPadding: true,
    },
    {
      label: '누적사용 금액',
      value: `${filters.numberComma(lectureData.per_amount * (lectureData.cumulative.booked + lectureData.cumulative.deducted))}원`,
      isPadding: false,
      fontWeight: 500,
      fontSize: 15,
    },
    {
      label: '(누적사용 횟수)',
      value: `(${lectureData.cumulative.booked + lectureData.cumulative.deducted}회)`,
      opacity: 0.8,
      isPadding: true,
    },
    {
      label: '미수업금',
      value: `${filters.numberComma((userTicket.max_coupon - (lectureData.cumulative.booked + lectureData.cumulative.deducted)) * lectureData.per_amount)}원`,
      isPadding: false,
      fontWeight: 600,
      fontSize: 15,
    },
    {
      label: '(잔여횟수)',
      value: `(${userTicket.max_coupon - (lectureData.cumulative.booked + lectureData.cumulative.deducted)}회)`,
      opacity: 0.8,
      isPadding: true,
    },
  ];

  return (
    <LectureInfoAccordionBody isOpenLectureInfo={isOpen}>
      <LectureInfoSection className="top-section" isOpenLectureInfo={isOpen}>
        {topArray.map((item, index) => (
          <div key={index} className={`report-info-text-wrapper ${item.isPadding ? 'text-padding-bottom' : ''}`}>
            <Typography
              className={item.label === '수강권명' ? 'no-shrink' : ''}
              size={item.fontSize ?? 14}
              textColor="gray2"
              opacity={item.opacity}>
              {item.label}
            </Typography>
            <Typography size={item.fontSize ?? 14} weight={item.fontWeight ?? 400} textColor="gray2" opacity={item.opacity}>
              {item.value}
            </Typography>
          </div>
        ))}
      </LectureInfoSection>
      <Divider thin thinColor="gray6" />
      <LectureInfoSection className="bottom-section" isOpenLectureInfo={isOpen}>
        {bottomArray.map((item, index) => (
          <div key={index} className={`report-info-text-wrapper ${item.isPadding ? 'text-padding-bottom' : ''}`}>
            <Typography
              className={item.label === '차감 금액' ? 'no-shrink' : ''}
              size={item.fontSize ?? 14}
              weight={item.label === '미수업금' ? 600 : 400}
              textColor="gray2"
              opacity={item.opacity}>
              {item.label}
            </Typography>
            <Typography size={item.fontSize ?? 14} weight={item.fontWeight ?? 400} textColor="gray2" opacity={item.opacity}>
              {item.value}
            </Typography>
          </div>
        ))}
      </LectureInfoSection>
    </LectureInfoAccordionBody>
  );
};

export default ReportLectureDetail;

const LectureInfoAccordionBody = styled.div<{ isOpenLectureInfo: boolean }>`
  margin-bottom: ${({ isOpenLectureInfo }) => (isOpenLectureInfo ? '12px' : '0px')};
  background-color: ${theme.color.white};
  border-radius: 0px 0px 16px 16px;

  .top-section {
    padding: 20px;
  }

  .bottom-section {
    padding: 20px 20px 18px 20px;
  }
`;

const LectureInfoSection = styled.div<{ isOpenLectureInfo: boolean }>`
  .report-info-text-wrapper {
    ${theme.flex('', '', 'space-between', 8)}

    .no-shrink {
      flex-shrink: 0;
    }

    .ticket-name {
      max-width: 150px;
      text-align: right;
    }
  }

  .text-padding-bottom {
    padding-bottom: 8px;
  }
`;
