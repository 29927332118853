import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { MemberDetailPoinTFilterOptionType, PointStaffsSelectFormType, SelectedPointStaff } from 'pages/MemberDetail/types';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type Props = {
  selectedStaffs: Array<SelectedPointStaff>;
  staffOptions: MemberDetailPoinTFilterOptionType['options'];
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedStaffs: Array<SelectedPointStaff>) => void;
};

const StaffsSelectDrawer = ({ selectedStaffs, staffOptions, isOpen, onClose, onSave }: Props) => {
  const methods = useForm<PointStaffsSelectFormType>({
    defaultValues: {
      selectedStaffs,
    },
  });
  const { control } = methods;

  useEffect(() => {
    methods.reset({
      selectedStaffs,
    });
  }, [methods, selectedStaffs]);

  return (
    <FormProvider {...methods}>
      <StyledDrawer
        isOpen={isOpen}
        onClose={onClose}
        header={<OneLinedTitle title="강사 선택" />}
        button={{ text: '선택 완료', onClick: () => onSave(selectedStaffs), disabled: !selectedStaffs.length }}>
        <Controller
          control={control}
          name="selectedStaffs"
          render={({ field: { value, onChange } }) => (
            <CheckBox
              className="checkbox-all"
              id="all"
              label="전체"
              labelFontWeight={500}
              labelFontsize={17}
              gap={16}
              value="all"
              checked={value.length === staffOptions.length}
              onChange={e => {
                if (!e.target.checked) {
                  onChange([]);
                } else {
                  const allStaffs = staffOptions.map(staff => {
                    return {
                      id: staff.value as number,
                      name: staff.label,
                      imgUrl: staff.staffImgUrl,
                    };
                  });
                  onChange(allStaffs);
                }
              }}
            />
          )}
        />
        {!!staffOptions.length && <Divider thin />}
        <StyledUl>
          {staffOptions.map(staff => {
            const staffId = staff.value as number;

            return (
              <li key={staffId}>
                <Controller
                  control={control}
                  name="selectedStaffs"
                  render={({ field: { value, onChange } }) => {
                    const checked = value.some(field => field.id === staff.value);
                    return (
                      <CheckBox
                        id={`${staff.value}`}
                        label={
                          <AvatarLabel
                            id={`${staff.value}`}
                            label={staff.label}
                            imgUrl={staff.staffImgUrl}
                            isMe={staff.isMe}
                            weight={500}
                            gap={8}
                          />
                        }
                        gap={16}
                        checked={checked}
                        onChange={e => {
                          const staffValue = {
                            id: staffId,
                            name: staff.label,
                            imgUrl: staff.staffImgUrl,
                          };
                          if (e.target.checked) {
                            onChange([...value, staffValue]);
                          } else {
                            onChange([...value.filter(staff => staff.id !== staffId)]);
                          }
                        }}
                      />
                    );
                  }}
                />
              </li>
            );
          })}
        </StyledUl>
      </StyledDrawer>
    </FormProvider>
  );
};

export default StaffsSelectDrawer;

const StyledDrawer = styled(SingleButtonDrawer)`
  padding-top: 0;

  .checkbox-all {
    padding: 8px 0;
  }

  hr {
    margin-top: 12px;
  }
`;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};
  margin-top: 12px;
  padding: 0 20px;

  li {
    padding: 8px 0;
  }

  .staff-label-wrapper {
    ${theme.flex('', 'center', '', 8)}
  }
`;
