import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';
import { BookingFailResponse, BookingPrivateParams } from './usePostBookingPrivate';

/** 프라이빗 이후 모든 수업 수정 (코스 수정) */
const usePatchLecturePrivateBulk = (lecture: LectureDetailResponse) => {
  const { course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingPrivateBulkParams, BookingPrivateBulkResponse>({
    method: `patch`,
    url: `/api/lecture/private/bulk/${course_id}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture'],
      });
    },
  });
};

export default usePatchLecturePrivateBulk;

export type BookingPrivateBulkParams = BookingPrivateParams & {
  start: string;
  is_force?: boolean;
};

export type BookingPrivateBulkResponse = {
  /**
   * 수업 모두 수정 성공 시 fail 에 빈 배열,
   * 1개라도 실패가 있으면 fail 에 실패만 반환되고 성공된 것은 수정되지 않음.
   * 실패 화면에서 계속 버튼 클릭하여 is_force: true 를 보내면 그 때 성공, 실패 모두 수정됨
   */
  fail: Array<BookingFailResponse>;
};
