import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from 'components/Button';
import { BottomWrapper } from 'components/Form';
import SelectBoxSquare from 'components/SelectBox/SelectBoxSquare';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

const Studios = () => {
  const [selectStudio, setSelectStudio] = useState('');

  const setCurrentStaffId = useSetRecoilState(staffIdAtom);
  const setStudioId = useSetRecoilState(studioIdAtom);

  const navigate = useNavigate();
  const { data: studiosResponse } = useGetStudio();

  const updateCommonIds = useCallback(
    (studioId: number, staffId: number, roleId: number) => {
      setStudioId(studioId);
      setCurrentStaffId(staffId);
    },
    [setStudioId, setCurrentStaffId],
  );

  useEffect(() => {
    if (!studiosResponse.studios.length) {
      navigate('/lounge');
    } else if (studiosResponse.studios.length === 1) {
      const { id, staff_id, staff } = studiosResponse.studios[0];
      const roleId = staff.roles[0].id;
      updateCommonIds(id, staff_id, roleId);
      navigate('/schedule');
    }
  }, [studiosResponse.studios, navigate, updateCommonIds]);

  const confirmStudio = () => {
    if (!selectStudio) return;

    const currentStudio = studiosResponse.studios.find(studio => studio.name === selectStudio);
    if (currentStudio) {
      const { id, staff_id, staff } = currentStudio;
      const roleId = staff.roles[0].id;
      updateCommonIds(id, staff_id, roleId);
      navigate('/schedule');
    }
  };

  return (
    <section>
      <SelectBoxSquareWrapper gridColumnsNumber={1}>
        {studiosResponse.studios.map(({ id, subdomain, name }) => {
          return (
            <SelectBoxSquare
              key={id}
              ref={el => el}
              type="radio"
              id={subdomain}
              name="studio"
              label={name}
              value={name}
              checked={name === selectStudio}
              onChange={e => {
                setSelectStudio(e.target.value);
              }}
            />
          );
        })}
      </SelectBoxSquareWrapper>

      <BottomWrapper>
        <Button
          color="point"
          fontSize={15}
          fullWidth
          size="large56"
          variant="contained"
          disabled={!selectStudio}
          onClick={confirmStudio}>
          선택 완료
        </Button>
      </BottomWrapper>
    </section>
  );
};

export default Studios;

const SelectBoxSquareWrapper = styled.div<{ gridColumnsNumber: number }>(
  ({ gridColumnsNumber }) => css`
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(${gridColumnsNumber}, 1fr);
  `,
);
