export const MEMBER_FORM_HEADER_TITLE: { [key: string]: string } = {
  profile: '프로필 설정',
  create: '발급',
  update: '정보 수정',
  memberCreate: '등록',
};

export const MEMBER_FORM_TEXT = {
  footerButton: {
    create: '등록 완료',
    update: '수정 완료',
  },
  successMessage: {
    create: '회원이 등록되었습니다.',
    update: '회원이 수정되었습니다.',
  },
  profile: {
    label: '회원명',
  },
  address: {
    placeholder: '주소',
  },
  vaccination: {
    label: '백신접종 14일 경과',
  },
  mobile: {
    description: ['회원앱과 연결되는 중요한 정보입니다.', '신중히 입력해주세요'],
  },
  userGrade: {
    defaultLabel: '회원등급',
    title: '회원등급 선택',
    notSelected: {
      value: 'userGrade-id-notSelected',
      label: '선택안함',
    },
  },
};

export const MEMBER_FORM_HEADER_TITLE_TEXT = {
  create: '회원 등록',
  update: '회원 수정',
  profile: '프로필 설정',
};

export const PRODUCT_FORM_HEADER_TITLE_TEXT: { [key: string]: { [key: string]: string } } = {
  userTicket: {
    create: '수강권 발급',
    update: '수강권 수정',
  },
  item: {
    create: '상품 발급',
    update: '상품 수정',
  },
};
