import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { useFormContext } from 'react-hook-form';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';
import { convertTargets } from '../utils';

type Props = {
  deductPoint: number;
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
};

const MobileSendConfirmDrawer = ({ deductPoint, isOpen, onClose, onSend }: Props) => {
  const { getValues } = useFormContext<MobileFormType>();
  const mode: 'create' | 'update' = getValues('mode');
  const isReserved = getValues('isReserve');
  const title = mode === 'create' && isReserved ? 'drawerTitleReserved' : 'drawerTitle';

  const targets = convertTargets(getValues());
  const mobileType = getValues('message').length > 90 ? 'LMS' : 'SMS';

  return (
    <DoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={onClose}
      header={
        <TwoLinedTitle
          title={
            <TitleTypography size={19} weight={700}>
              총 <b>{filters.numberComma(targets.length)}</b>건의
              <br />
              {MOBILE_FORM_TEXT[mode][title]}
            </TitleTypography>
          }
        />
      }
      leftButton={{
        text: MOBILE_FORM_TEXT.cancel,
        onClick: onClose,
      }}
      rightButton={{
        text: MOBILE_FORM_TEXT[mode].sendButton,
        onClick: onSend,
      }}>
      <Container>
        <InfoFlexRow>
          <InfoTypography>{MOBILE_FORM_TEXT.type}</InfoTypography>
          <InfoTypography weight={500} className="aligned">
            {mode === 'update' && (
              <>
                {getValues('originalMessage.messageType')}
                <Icon name="arrowBottomFill" color="gray3" size={16} />
              </>
            )}
            {mobileType}
          </InfoTypography>
        </InfoFlexRow>
        <InfoFlexRow>
          <InfoTypography>{MOBILE_FORM_TEXT.count}</InfoTypography>
          <InfoTypography weight={500} className="aligned">
            {mode === 'update' && (
              <>
                {filters.numberComma(getValues('originalMessage.count'))}건
                <Icon name="arrowBottomFill" color="gray3" size={16} />
              </>
            )}
            {filters.numberComma(targets.length)}건
          </InfoTypography>
        </InfoFlexRow>
        {isReserved && (
          <InfoFlexRow>
            <InfoTypography>{MOBILE_FORM_TEXT.sendOn}</InfoTypography>
            <InfoTypography weight={500}>[예약] {filters.dateTimeWithWeekday(getValues('reservationDate'))}</InfoTypography>
          </InfoFlexRow>
        )}
        <InfoFlexRow>
          <InfoTypography>{deductPoint >= 0 ? MOBILE_FORM_TEXT.deductionPoint : MOBILE_FORM_TEXT.refundPoint}</InfoTypography>
          <InfoTypography weight={500} textColor="primary">
            <Tooltip
              isOpen={getValues('isReserve') && deductPoint > 0}
              message={['예약 발송 시, 포인트는 선차감 됩니다.']}
              position="right">
              {filters.numberComma(Math.abs(deductPoint))}P
            </Tooltip>
          </InfoTypography>
        </InfoFlexRow>
      </Container>
    </DoubleButtonDrawer>
  );
};

export default MobileSendConfirmDrawer;

const Container = styled(InfoFlexWrapper)`
  padding: 18px 20px;
  border-radius: 12px;
  background-color: ${theme.color.gray7};
  .aligned {
    vertical-align: middle;
    ${theme.flex('', 'center', 'end')}
    svg {
      transform: rotate(-90deg);
    }
  }

  // 툴팁 위치 조정
  div.tooltip-contents-container {
    right: -17px;
    transform: none;
  }
`;

const TitleTypography = styled(Typography)`
  b {
    color: ${theme.color.primary};
  }
`;
