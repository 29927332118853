import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import usePatchStaffEventTime, { StaffEventTimeParams } from 'hooks/service/mutations/usePatchStaffEventTime';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import _ from 'lodash';
import { useMemo } from 'react';
import { SubmitHandler, useFieldArray, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { eventScheduleSelectDateSelector } from 'recoil/staff';
import EventWorkTime from 'sharedComponents/ProfileField/components/EventWorkTime';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import { CustomError } from 'types/errorTypes';

import { STAFF_PROFILE_FORM_TEXT } from '../../constants';
import { CommonPropsType, EventScheduleUpdateFormType, FieldType } from '../../types';
import DateMultiCalendarPicker from './DateMultiCalendarPicker';

type Props = {
  shapeCard?: boolean;
  eventTimeDataset: Record<'eventWorkTimes' | 'eventRestTimes', ScheduleFormType[]>;
};

/** EventScheduleCard.tsx, EventScheduleUpdateForm/index.tsx가 공유하고 있습니다. */
const EventScheduleUpdateForm = ({ shapeCard = false, eventTimeDataset }: Props) => {
  const navigate = useNavigate();

  const selectDateTexts = useRecoilValue(eventScheduleSelectDateSelector);

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const targetStaffId = useParamsId();

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useFormContext<EventScheduleUpdateFormType>();

  const {
    fields: eventWorkTimeFields,
    update: updateWorkTime,
    remove: removeWorkTime,
    append: appendWorkTime,
    replace: replaceWorkTimeFields,
  } = useFieldArray({ control, name: 'schedules.eventWorkTimes' });
  const {
    fields: eventRestTimeFields,
    update: updateRestTime,
    remove: removeRestTime,
    append: appendRestTime,
    replace: replaceRestTimeFields,
  } = useFieldArray({ control, name: 'schedules.eventRestTimes' });

  const { mutate: patchStaffEventTimeMutate } = usePatchStaffEventTime({ targetStaffId });

  const removeTime = (index: number, type: FieldType = 'work') => {
    if (index > -1) {
      if (type === 'work') {
        removeWorkTime(index);
      } else {
        removeRestTime(index);
      }
    }
  };

  const appendTime = (newField: ScheduleFormType, type: FieldType = 'work') => {
    if (type === 'work') {
      appendWorkTime(newField);
    } else {
      appendRestTime(newField);
    }
  };

  const updateTime = (index: number, value: ScheduleFormType, type: FieldType = 'work') => {
    if (type === 'work') {
      updateWorkTime(index, value);
    } else {
      updateRestTime(index, value);
    }
  };

  const replaceFields = (newFields: ScheduleFormType[], type: FieldType = 'work') => {
    if (type === 'work') {
      replaceWorkTimeFields(newFields);
    } else {
      replaceRestTimeFields(newFields);
    }
  };

  /** 선택한 날짜에 해당하는 리스트만 필터링 */
  const filteredWorkTimeFields = useMemo(() => {
    return eventWorkTimeFields.filter(({ date }) => selectDateTexts.includes(date));
  }, [eventWorkTimeFields, selectDateTexts]);

  const selectEventWorkTimeFields = useMemo(() => {
    return _.sortBy(filteredWorkTimeFields, 'date');
  }, [filteredWorkTimeFields]);

  const submit: SubmitHandler<EventScheduleUpdateFormType> = inputValues => {
    const { eventRestTimes, eventWorkTimes } = inputValues.schedules;
    const combineSchedules = [...eventRestTimes, ...eventWorkTimes];
    const params: StaffEventTimeParams = {
      schedules: combineSchedules.map(({ id, isClosed, updated_at, created_at, ...rest }) => {
        if (isClosed) {
          return {
            ...rest,
            type: 'eventRestTime',
            start_time: CLOSED_DAY_TIME.start,
            end_time: CLOSED_DAY_TIME.end,
          };
        }
        return {
          ...rest,
        };
      }),
    };

    const successMessage = shapeCard
      ? STAFF_PROFILE_FORM_TEXT.success.eventSchedulesAdd
      : STAFF_PROFILE_FORM_TEXT.success.eventSchedulesUpdate;

    patchStaffEventTimeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: successMessage });
        if (shapeCard) navigate(-1);
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  const footerButton = shapeCard
    ? {
        text: '추가 완료',
        disabled: !isDirty || !selectDateTexts.length,
      }
    : undefined;

  const commonProps: CommonPropsType = {
    selectEventWorkTimeFields,
    eventWorkTimeFields,
    eventRestTimeFields,
    appendTime,
    replaceFields,
    shapeCard,
  };

  return (
    <Container shapeCard={shapeCard}>
      {shapeCard && (
        <DateMultiCalendarPicker {...commonProps} eventWorkTimes={eventTimeDataset.eventWorkTimes} removeTime={removeTime} />
      )}

      <Form
        id="event-work-time-form"
        className="event-work-time-form"
        onSubmit={handleSubmit(submit)}
        footerButton={footerButton}>
        <EventWorkTime {...commonProps} removeRestTime={removeRestTime} updateTime={updateTime} />
      </Form>
    </Container>
  );
};

export default EventScheduleUpdateForm;

const Container = styled.div<{ shapeCard: boolean }>`
  ${theme.flex('column')};
  padding-bottom: ${({ shapeCard }) => shapeCard && '40px'};
  min-height: 100%;

  .event-work-time-form {
    ${theme.flex('column', 'center', 'flex-start')};
    width: 100%;
    background-color: ${({ shapeCard }) => (shapeCard ? theme.color.gray6 : theme.color.white)};
    flex: 1;
  }
`;
