import { useMutation } from '@tanstack/react-query';
import { MessageDetailType } from 'pages/MoreDetails/Message/types';
import delay from 'utils/delay';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 문자 발송 */
const usePostMessageMobile = () => {
  const { mutationFn } = useMutationFnWithCommonParams<MessageMobileParams>({
    method: `post`,
    url: `/api/message/mobile`,
  });

  return useMutation({
    mutationFn: async (param: MessageMobileParams) => {
      await delay(2000);
      return mutationFn(param);
    },
  });
};

export default usePostMessageMobile;

export type MessageMobileParams = {
  filter: MessageDetailType['filter'];
  send_on?: string;
  from: string;
  title: string;
  message: string;
  users: number[];
  counseling_logs: number[];
};
