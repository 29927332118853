import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 그룹 수업 1개 삭제 */
const useDeleteLectureGroup = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/lecture/group/${lectureId}`,
  });

  return useMutation({
    mutationFn,
    mutationKey: ['lecture', 'delete', 'group', lectureId],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default useDeleteLectureGroup;
