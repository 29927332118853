import Divider from 'components/Divider';
import useGetMessagePushDetail from 'hooks/service/queries/useGetMessagePushDetail';
import MobileDetailCommonInfo from 'pages/MoreDetails/Message/Mobile/Detail/components/MobileDetailCommonInfo';
import MobileDetailContents from 'pages/MoreDetails/Message/Mobile/Detail/components/MobileDetailContents';
import TargetsDetailList from 'pages/MoreDetails/Message/Mobile/Detail/components/TargetsDetailList';
import MainLayout from 'sharedComponents/MainLayout';
import convertTargetsText from 'utils/convertTargetsText';

import { MESSAGE_PUSH_STATUS_ADAPTER } from '../../List/constants';
import { APP_PUSH_DETAIL_TEXT } from '../constants';
import AppPushMenuButton from './AppPushMenuButton';

type Props = {
  id: number;
};

const AppPushDetail = ({ id }: Props) => {
  const { data: push } = useGetMessagePushDetail(id);

  return (
    <MainLayout header={{ title: APP_PUSH_DETAIL_TEXT.title, rightButton: <AppPushMenuButton push={push} /> }}>
      <MobileDetailCommonInfo
        status={MESSAGE_PUSH_STATUS_ADAPTER[push.status]}
        sendOn={push.send_on}
        targetText={convertTargetsText(push.targets)}
        targetContents={<TargetsDetailList targets={push.targets} />}
        count={push?.targets?.length || 0}
      />
      <Divider />
      <MobileDetailContents title={push.title} message={push.message} />
    </MainLayout>
  );
};

export default AppPushDetail;
