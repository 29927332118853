import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetPointHistoryStaffList = (params: PointStaffParams) => {
  const { queryFn } = useQueryFnWithCommonParams<Array<PointStaffResponse>, PointStaffParams>({
    url: `/api/point/history/staff-list`,
    params,
  });

  return useSuspenseQuery({
    queryKey: ['member', 'point', 'staff-list', { ...params }],
    queryFn,
    select: data => data.data,
  });
};

export default useGetPointHistoryStaffList;

export type PointStaffResponse = {
  id: number;
  name: string;
  avatar: {
    path: string;
    name: string;
    extension: string;
  } | null;
};

export type PointStaffParams = {
  member_id: number;
  start_date: string;
  end_date: string;
};
