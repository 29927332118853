import usePostEtcSchedule from 'hooks/service/mutations/usePostEtcSchedule';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import { ETC_FORM_TEXT } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import EtcFormMainLayout from '../components/EtcFormMainLayout';
import convertFormState from '../utils/convertFormState';
import formatEtcParams from '../utils/formatEtcParams';

/** 기타 일정 복사는 항상 등록 디자인과 동일 */
const FormMain = () => {
  const { data: me } = useGetStaffMe();
  const {
    state: { data },
  } = useLocation() as { state: { data: EtcScheduleDetailResponse } };

  const methods = useForm<EtcScheduleCreateFormType>({
    defaultValues: {
      ...convertFormState(data, me),
    },
  });

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const errorDialog = useErrorDialog();
  const { mutate: createEtcMutate } = usePostEtcSchedule();

  const submit = (values: EtcScheduleCreateFormType) => {
    const { is_repeat, ...rest } = formatEtcParams(values);
    const params = {
      ...rest,
      is_repeat: false,
    };
    createEtcMutate(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: ETC_FORM_TEXT.successMessage.create, bottom: 76 });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(<FailAllBooking fails={error.response?.data} />);
        } else {
          errorDialog.open(error.response?.data.message);
        }
      },
    });
  };

  return (
    <EtcFormMainLayout {...methods} pageMode="copy" onSubmit={submit}>
      <BookingRangeDate isRange isInitialOpen />
    </EtcFormMainLayout>
  );
};

export default FormMain;
