import { useMutation } from '@tanstack/react-query';
import delay from 'utils/delay';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MessageMobileParams } from './usePostMessageMobile';

/** 앱푸시 발송 */
const usePostMessagePush = () => {
  const { mutationFn } = useMutationFnWithCommonParams<MessagePushParams>({
    method: `post`,
    url: `/api/message/push`,
  });

  return useMutation({
    mutationFn: async (param: MessagePushParams) => {
      await delay(2000);
      return mutationFn(param);
    },
  });
};

export default usePostMessagePush;

export type MessagePushParams = Omit<MessageMobileParams, 'from' | 'counseling_logs'>;
