import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import FloatingActionButton from 'components/FloatingActionButton';
import Tabs from 'components/Tabs';
import useSearchKeywords from 'hooks/useSearchKeywords';
import useToast from 'hooks/useToast';
import { allMemberSelectedFiltersAtom } from 'recoil/Member';
import Filter from 'sharedComponents/Filters';
import MainLayout from 'sharedComponents/MainLayout';

import { DEFAULT_MEMBER_FILTER_OPTIONS, MAIN_TAB_DATA, MEMBER_FILTER_DEFAULT_TAB_LABEL } from '../constants';
import AllMemberDrawerFilterOptions from './AllMemberFilter/AllMemberDrawerFilterOptions';
import AllMemberWholeFilterOptions from './AllMemberFilter/AllMemberWholeFilterOptions';
import { Container, ContentsContainer, ContentsWrapper, StickyWrapper } from './MemberLayout';
import NoData from './NoData';
import Search from './Search';
import Sorting from './Sorting';
import Total from './Total';

const NoConnected = () => {
  const { setToast } = useToast();

  const changeTab = () => {
    setToast({
      message: '시설과 연동이 되어있지 않습니다.',
      bottom: 72,
    });
  };

  const { searchKeyword } = useSearchKeywords('member');

  return (
    <MainLayout bottomNav scrollable childrenFloatingButton={<FloatingActionButton type="single" />}>
      <Container>
        <Search />
        <ContentsContainer>
          <StickyWrapper>
            <Tabs data={MAIN_TAB_DATA} value={MAIN_TAB_DATA[0].value} onChange={changeTab} />
            <Filter
              filterAtom={allMemberSelectedFiltersAtom}
              filters={DEFAULT_MEMBER_FILTER_OPTIONS}
              defaultTabLabels={MEMBER_FILTER_DEFAULT_TAB_LABEL}
              renderDrawerOptions={AllMemberDrawerFilterOptions}
              renderWholeFilterPopupOptions={AllMemberWholeFilterOptions}
            />
          </StickyWrapper>
          <ContentsWrapper>
            <TotalWrapper>
              <Total total={0} />
              <Sorting filterAtom={allMemberSelectedFiltersAtom} />
            </TotalWrapper>
            <NoData currentTabLabel="등록된" currentKeyword={searchKeyword} />
          </ContentsWrapper>
        </ContentsContainer>
      </Container>
    </MainLayout>
  );
};

export default NoConnected;

const TotalWrapper = styled.div`
  ${theme.flex()};
`;
