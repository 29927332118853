import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Label from 'components/Label';
import Typography from 'components/Typography';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import useGetUserTicket from 'hooks/service/queries/useGetUserTicket';
import { SalesDataResponse } from 'hooks/service/queries/useInfinityReportSale';
import { useNavigate } from 'react-router-dom';
import TicketCard from 'sharedComponents/TicketCard';
import { ColorType } from 'types/colorTypes';
import filters from 'utils/filters';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import { REPORT_PAYMENT_LABEL } from '../../constants';

type Props = {
  isOpen: boolean;
  detailData: SalesDataResponse;
  onClose: () => void;
};

const ReportSaleDetailInfo = ({ isOpen, detailData, onClose }: Props) => {
  const navigate = useNavigate();

  const { data: userTicket } = useGetUserTicket({ ticket_id: detailData.user_ticket.id, user_id: detailData.member.id });

  const paymentLabel = detailData?.label;
  const paymentType = REPORT_PAYMENT_LABEL[paymentLabel as keyof typeof REPORT_PAYMENT_LABEL];
  const originalPrice = userTicket.payments.original_price;
  const paidAmount =
    detailData.payment.card_amount +
    detailData.payment.cash_amount +
    detailData.payment.wiretransfer_amount +
    detailData.payment.point_amount +
    detailData.payment.unpaid_amount;
  const discountAmount = originalPrice - paidAmount;
  const discountRate = isNaN((discountAmount / originalPrice) * 100) ? 0 : (discountAmount / originalPrice) * 100;
  const isRefundDetail = paymentLabel === 'refund';
  const memberName = getEllipsisByLength({ text: detailData.member.name ?? '', max: 10, hasEllipsis: true });

  const goToMemberDetail = () => {
    navigate(`/member/detail/${detailData.member.id}`);
  };

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      header={
        <HeaderTextWrapper>
          <div className="member-name-wrapper">
            <Typography ellipsis={1} size={19} weight={700} textColor="gray1">
              {memberName}
            </Typography>
            <Typography size={19} weight={700} textColor="gray1">
              회원
            </Typography>
          </div>
          <Label size="regular" borderColor={paymentType.color as ColorType} textColor={paymentType.color as ColorType}>
            {paymentType.name}
          </Label>
        </HeaderTextWrapper>
      }
      onClose={onClose}
      button={{ text: '수강권 상세', color: 'gray', onClick: () => goToMemberDetail() }}>
      <Container>
        <ClassInfoSection>
          <TicketCard ticket={formatSmallUserTicketCard(userTicket)} />
        </ClassInfoSection>
        {paymentLabel !== 'unpaid' && (
          <PriceSection paymentType={paymentType.name}>
            <Divider />
            <InfoWrapper>
              <Typography size={15} textColor="gray2">
                판매가
              </Typography>
              <Typography size={15} weight={500} textColor="gray1">
                {`${filters.numberComma(originalPrice)}원`}
              </Typography>
            </InfoWrapper>
            {paymentLabel !== 'refund' && (
              <>
                <Divider thin />
                <InfoWrapper>
                  <Typography size={15} textColor="gray2">
                    할인율
                  </Typography>
                  <Typography size={15} weight={500} textColor="gray1">
                    {`${Math.ceil(discountRate)}%`}
                  </Typography>
                </InfoWrapper>
              </>
            )}
          </PriceSection>
        )}

        <Divider />
        <PaymentSection>
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              {`${detailData.payment.installment_period === 1 ? '카드(일시불)' : `카드 (할부 ${detailData.payment.installment_period}개월)`}`}
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && detailData.payment.card_amount > 0 ? '-' : ''}  ${filters.numberComma(detailData.payment.card_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              현금
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && detailData.payment.cash_amount > 0 ? '-' : ''} ${filters.numberComma(detailData.payment.cash_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              계좌이체
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && detailData.payment.wiretransfer_amount > 0 ? '-' : ''} ${filters.numberComma(detailData.payment.wiretransfer_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              포인트
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${isRefundDetail && detailData.payment.point_amount > 0 ? '-' : ''} ${filters.numberComma(detailData.payment.point_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2" weight={500}>
              {paymentLabel === 'transfer' ? '총 양도금액' : isRefundDetail ? '총 환불금액' : '총 결제금액'}
            </Typography>
            <Typography size={15} weight={600} textColor={`${isRefundDetail ? 'secondary3' : 'primary'}`}>
              {`${isRefundDetail ? '-' : ''} ${filters.numberComma(paidAmount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
        </PaymentSection>
        <Divider />
        <UnPaidSection>
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              {paymentLabel === 'refund' ? '위약금' : '미수금'}
            </Typography>
            <Typography size={15} weight={500} textColor="secondary3">
              {`${isRefundDetail ? filters.numberComma(detailData.payment.commission_amount) : filters.numberComma(detailData.payment.unpaid_amount)}원`}
            </Typography>
          </InfoWrapper>
          <Divider thin />
          <InfoWrapper>
            <Typography size={15} textColor="gray2">
              {paymentLabel === 'transfer' ? '양도일시' : isRefundDetail ? '환불일시' : '결제일시'}
            </Typography>
            <Typography size={15} weight={500} textColor="gray1">
              {`${filters.dateWithWeekday(detailData.settlement_at)} ${filters.time(detailData.settlement_at)}`}
            </Typography>
          </InfoWrapper>
        </UnPaidSection>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ReportSaleDetailInfo;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: calc(32px + 76px);
`;

const Container = styled.div``;

const HeaderTextWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 16px 20px;

  .member-name-wrapper {
    ${theme.flex('row', '', '', 4)}
  }

  .label {
    margin: 2px 0px;
  }
`;

const ClassInfoSection = styled.section`
  padding: 0 16px 20px 16px;
  .ticket-card {
    padding: 0;
    gap: 16px;

    .ticket-image {
      width: 63px;
      height: 36px;
      padding: 0px;
    }
  }
`;

const PriceSection = styled.section<{ paymentType: string }>(
  ({ paymentType }) => css`
    display: ${paymentType === '양도' ? 'none' : 'block'};
  `,
);

const PaymentSection = styled.section``;

const UnPaidSection = styled.section``;

const InfoWrapper = styled.div`
  ${theme.flex('', '', 'space-between')}
  padding: 17px 20px;
`;
