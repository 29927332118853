import { TabDataElementType } from 'components/Tabs';
import useQueryString from 'hooks/useQueryString';
import { ErrorBoundary } from 'react-error-boundary';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import BookingScheduleList from './components/BookingScheduleList';
import CheckinScheduleList from './components/CheckinScheduleList';
import DeletedLectureScheduleList from './components/DeletedLectureScheduleList';
import ScheduleErrorFallback from './components/ScheduleErrorFallback';
import ScheduleLayout from './components/ScheduleLayout';
import { SCHEDULE_TABS } from './constant';

const MoreDetailSchedule = () => {
  const { getSearchParams, setSearchParams } = useQueryString();

  const tab = getSearchParams('tab');
  const currentTab = SCHEDULE_TABS.find(tabData => tabData.value === tab) || SCHEDULE_TABS[0];

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value }, { replace: true });
  };

  return (
    <ApiBoundary>
      <ErrorBoundary FallbackComponent={ScheduleErrorFallback}>
        <ScheduleLayout currentTab={currentTab.value} changeTab={changeTab}>
          {currentTab.value === 'booking' && <BookingScheduleList currentTab={currentTab.value} />}
          {currentTab.value === 'checkin' && <CheckinScheduleList currentTab={currentTab.value} />}
          {currentTab.value === 'deletedLecture' && <DeletedLectureScheduleList currentTab={currentTab.value} />}
        </ScheduleLayout>
      </ErrorBoundary>
    </ApiBoundary>
  );
};

export default MoreDetailSchedule;
