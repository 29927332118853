import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';

import MemberInfo from './components/MemberInfo';
import MemberTicket from './components/MemberTicket';

type Props = {
  member: MemberResponse;
  onClick: () => void;
};

const MemberCard = ({ member, onClick }: Props) => {
  return (
    <CardContainer onClick={onClick}>
      <MemberInfo member={member} />

      {member.userTicket.map(userTicket => (
        <MemberTicket key={userTicket.id} userTicket={userTicket} />
      ))}
    </CardContainer>
  );
};

export default MemberCard;

export const memberCardStyle = `
  padding: 18px 20px;
  box-shadow: 1px 3px 10px 0px rgba(145, 145, 145, 0.08);
  border-radius: 16px;
  background-color: ${theme.color.white};
`;

const CardContainer = styled.div`
  ${memberCardStyle}
`;
