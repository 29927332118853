import Icon from 'components/Icon';
import UnderlinedTextField from 'components/TextField/components/UnderlinedTextField';
import Typography from 'components/Typography';
import { PaymentRow } from 'sharedComponents/TicketForm/TicketPaymentForm/StyledComponents';
import filters from 'utils/filters';

type Props = {
  upgradeSalePrice: number;
};

/** 업그레이드 하는 수강권의 판매가 보여주기용 */
const UpgradeTicketSalePrice = ({ upgradeSalePrice }: Props) => {
  return (
    <PaymentRow>
      <UnderlinedTextField
        id="upgradeSalePrice"
        readOnly
        textAlign="right"
        left={{ prefix: <Icon name="money" color="gray3" />, text: '판매가' }}
        suffix={
          <Typography span size={15} weight={500} textColor="gray3">
            원
          </Typography>
        }
        value={filters.numberComma(upgradeSalePrice)}
      />
    </PaymentRow>
  );
};

export default UpgradeTicketSalePrice;
