import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { MemberCardProps, Profile, UserTicket } from 'types/memberTypes';
import filters from 'utils/filters';
import getDaysDiff from 'utils/getDaysDiff';
import getRemainingDaysText from 'utils/getRemainingDaysText';

type Props = {
  userTicket: UserTicket;
  lastAttendanceAt: Profile['last_attendance_at'];
  attendanceCount: MemberCardProps['attendance_count'];
};

const MemberTicket = ({ userTicket, lastAttendanceAt, attendanceCount }: Props) => {
  const {
    availability_start_at,
    expire_at,
    remaining_coupon,
    max_coupon,
    user_ticket_status,
    holdings,
    ticket: { title, type, available_class_type },
  } = userTicket;

  const holdingDays = useMemo(() => {
    if (!holdings) return 0;

    const { start_on, end_on } = holdings;
    return getDaysDiff(end_on, start_on);
  }, [holdings]);

  const notUsableTicketLabel = useMemo(() => {
    switch (user_ticket_status) {
      case 'holding':
        return `${holdingDays}일 정지`;
      case 'expired':
        return '기간만료';
      default:
        return null;
    }
  }, [user_ticket_status, holdingDays]);

  const opacity = useMemo(() => (notUsableTicketLabel ? 0.48 : 1), [notUsableTicketLabel]);

  const showAttendanceDate = useMemo(() => {
    if (!lastAttendanceAt && !attendanceCount) return false;
    if (available_class_type === 'I') return false;
    return true;
  }, [attendanceCount, available_class_type, lastAttendanceAt]);

  const showPeriod = useMemo(() => {
    switch (type) {
      case 'P':
      case 'T':
      case 'RP':
      case 'RM':
        return true;
      default:
        return false;
    }
  }, [type]);

  const showCoupon = useMemo(() => {
    switch (type) {
      case 'T':
      case 'RT':
      case 'RM':
        return true;
      default:
        return false;
    }
  }, [type]);

  return (
    <Container>
      <div className="ticket-top-wrapper">
        <div className="ticket-name-wrapper">
          <Typography weight={600} textColor="gray2" opacity={opacity}>
            {title}
          </Typography>
          {notUsableTicketLabel && (
            <Label size="small" textColor="secondary3" borderColor="secondary3" opacity={0.64}>
              {notUsableTicketLabel}
            </Label>
          )}
        </div>
        {showPeriod && (
          <Typography span size={13} textColor="gray3" opacity={opacity}>
            {filters.period(availability_start_at, expire_at)}
          </Typography>
        )}
      </div>

      <InfoFlexWrapper gap={4}>
        {showPeriod && (
          <InfoFlexRow>
            <Typography textColor="gray2" opacity={opacity}>
              잔여기간
            </Typography>
            <Typography textColor="gray2" opacity={opacity} weight={500}>
              {getRemainingDaysText(expire_at, availability_start_at)}
            </Typography>
          </InfoFlexRow>
        )}

        {showCoupon && (
          <InfoFlexRow>
            <Typography textColor="gray2" opacity={opacity}>
              잔여횟수
            </Typography>
            <Typography textColor="gray2" opacity={opacity} weight={500}>
              {remaining_coupon}/{max_coupon}회 남음
            </Typography>
          </InfoFlexRow>
        )}

        {showAttendanceDate && (
          <InfoFlexRow>
            <Typography textColor="gray2" opacity={opacity}>
              {attendanceCount ? '누적 출석일' : '최근 출석일'}
            </Typography>
            <Typography textColor="gray2" opacity={opacity} weight={500}>
              {attendanceCount ? `${attendanceCount}일 누적` : filters.date(lastAttendanceAt || '')}
            </Typography>
          </InfoFlexRow>
        )}
      </InfoFlexWrapper>
    </Container>
  );
};

export default MemberTicket;

const Container = styled.div`
  border-top: 1px solid ${theme.color.gray7};
  padding-top: 14px;
  margin-top: 14px;

  .ticket-top-wrapper {
    margin-bottom: 10px;

    .ticket-name-wrapper {
      ${theme.flex('', 'center', 'flex-start', 6)};

      p {
        margin-bottom: 1px;
      }

      .label {
        padding: 0 3px;
        min-width: max-content;
      }
    }
  }

  .ticket-bottom-wrapper {
    ${theme.flex('column', 'initial', '', 4)};

    .ticket-info-row {
      ${theme.flex('', 'center', 'space-between')};
    }
  }
`;
