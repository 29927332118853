import { useSuspenseQuery } from '@tanstack/react-query';
import { AvatarImageTypes } from 'types/avatarTypes';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { WeekdayNum } from '../mutations/usePostBookingPrivate';

/** 기타일정 종일이 아닌 일정 상세 */
const useGetEtcScheduleEtcScheduleTime = (etcScheduleTimeId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<{ data: EtcScheduleDetailResponse }>({
    url: `/api/etcSchedule/etcScheduleTime/${etcScheduleTimeId}`,
  });

  return useSuspenseQuery({
    queryKey: ['etc/schedule', 'detail', etcScheduleTimeId],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetEtcScheduleEtcScheduleTime;

export type CommonEtcScheduleDetailResponse = {
  id: number;
  studio_id: number;
  title: string;
  color: string;
  staffs: Array<{ id: number; name: string; image: AvatarImageTypes | null }>;
  is_overlap: boolean;
  is_private: boolean;
  is_all_day: boolean;
  created_at: string;
  updated_at: string;
};

export type EtcScheduleDetailResponse = CommonEtcScheduleDetailResponse & {
  /** etc schedule 의 course_id */
  etc_schedule_id: number;
  start_on: string; // '2024-07-17 23:59:59';
  end_on: string;
  etc_schedule: {
    /** 기간 선택해서 생성했을 때, 시작 날짜 */
    start_date: string; // 2024-07-17
    /** 기간 선택해서 생성했을 때, 종료 날짜 */
    end_date: string;
    /** 반복설정 */
    weekday: Array<WeekdayNum>;
  };
};
