import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { Z_INDEX } from 'constants/zIndex';
import isPastOrFutureDate from 'pages/Schedule/ScheduleMain/utils/isPastOrFutureDate';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
};

const TodayButton = ({ currentDate, setCurrentDate }: Props) => {
  const isToday = isPastOrFutureDate(currentDate) === 'today';
  const isFuture = isPastOrFutureDate(currentDate) === 'future';
  const isPast = isPastOrFutureDate(currentDate) === 'past';

  return (
    <Container className={isToday ? 'hidden' : ''}>
      <Button
        size="medium40"
        edge="circular"
        fontSize={13}
        textColor="primary"
        padding={{
          left: isFuture ? 18 : 23,
          right: isPast ? 18 : 23,
        }}
        leftIcon={isFuture && <Icon name="arrowLeftBold" size={16} fillColor={theme.color.primary} />}
        rightIcon={isPast && <Icon name="arrowRightBold" size={16} fillColor={theme.color.primary} />}
        onClick={() => setCurrentDate(new Date())}>
        오늘
      </Button>
    </Container>
  );
};

export default TodayButton;

const Container = styled.div`
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
  background: ${theme.color.white};
  border-radius: 999px;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  z-index: ${Z_INDEX.floatingButton};
  transition: bottom 0.5s ease;

  &.hidden {
    position: absolute;
    bottom: -100px;
  }
`;
