import styled from '@emotion/styled';
import useInfinityPaymentUserTicket from 'hooks/service/queries/useInfinityPaymentUserTicket';
import { Payment } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useQueryString from 'hooks/useQueryString';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { selectedPaymentAtom } from 'recoil/TicketDetail';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import getPaymentDetail from 'utils/getPaymentDetail';

import PaymentDetailCard from './PaymentDetailCard';
import UnPaidDeleteDialog from './UnPaidDeleteDialog';

const PaymentList = ({ canUpdateUserTicket, userTicket }: UserTicketProps) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const { getAllSearchParams, setSearchParams } = useQueryString();
  const [selectedPayment, setSelectedPayment] = useRecoilState(selectedPaymentAtom);

  const goToEditForm = (mode: 'update' | 'unpaid', payment: Payment) => {
    setSelectedPayment(payment);
    setSearchParams({
      ...getAllSearchParams(),
      mode,
      paymentId: `${payment.id}`,
    });
  };

  const clickDeleteButton = (payment: Payment) => {
    setIsOpenDeleteDialog(true);
    setSelectedPayment(payment);
  };

  const {
    data: payments,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityPaymentUserTicket({
    userTicketId: userTicket.id,
    user_id: userTicket.user_id,
  });

  return (
    <>
      {!payments.length ? (
        <NodataTypography>결제내역이 없습니다.</NodataTypography>
      ) : (
        <InfiniteScrollContainer gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {payments.map((payment, index) => {
            const { data } = getPaymentDetail(payment);
            const { unpaid_amount, status, settlement_at } = payment;

            let leftButton = null;
            /**
             * 미수금 결제 항목 중 미수금 완납 시 미수금 삭제 버튼 노출
             */
            if (
              canUpdateUserTicket &&
              (status === 'full_payment' || (status === 'installment_payment' && !userTicket.payments.unpaid_amount_total))
            ) {
              leftButton = {
                children: '삭제',
                onClick: () => clickDeleteButton(payment),
              };
            }

            /**
             * 결제 항목 중 미수금을 기입했거나,
             * 미수금 결제 항목을 결제 했는데 미수금이 아직 남아있거나
             */
            if (
              canUpdateUserTicket &&
              (unpaid_amount || (status === 'installment_payment' && userTicket.payments.unpaid_amount_total))
            ) {
              leftButton = {
                children: '미수금 결제',
                onClick: () => goToEditForm('unpaid', payment),
              };
            }

            // 가장 최신 결제 내역만 수정 버튼 노출
            const clickUpdateButton = canUpdateUserTicket && index === 0 ? () => goToEditForm('update', payment) : undefined;

            return (
              <PaymentDetailCard
                key={payment.id}
                data={data}
                settlementAt={settlement_at}
                leftButton={leftButton}
                onClick={clickUpdateButton}
              />
            );
          })}
        </InfiniteScrollContainer>
      )}

      {isOpenDeleteDialog && selectedPayment && (
        <UnPaidDeleteDialog paymentId={selectedPayment.id} onClose={() => setIsOpenDeleteDialog(false)} />
      )}
    </>
  );
};

export default PaymentList;

const InfiniteScrollContainer = styled(InfiniteScroll)`
  padding: 20px;
`;
