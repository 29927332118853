import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import DateCalendarPickerDrawer from 'designedComponents/Pickers/DateCalendarPicker/DateCalendarPickerDrawer';
import PickerButtonOutlined from 'designedComponents/Pickers/PickerButton/PickerButtonOutlined';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import filters from 'utils/filters';

import { NOTICE_FORM_TEXT } from '../constants';
import { NoticeFormType } from '../types';

const NoticePeriodPicker = () => {
  const { setValue, watch } = useFormContext<NoticeFormType>();
  const period = watch('period');
  const startOn = watch('start_on');
  const endOn = watch('end_on');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tempEndDate, setTempEndDate] = useState(endOn);
  const updateEndDate = () => {
    setValue('end_on', filters.dateDash(tempEndDate), { shouldDirty: true });
    setIsDrawerOpen(false);
  };
  const endDateDisabled = period !== 'limited';
  return (
    <>
      <Container>
        <PickerButtonOutlined disabled={true}>{filters.date(startOn)}</PickerButtonOutlined>
        <Typography size={15} weight={500} textColor={endDateDisabled ? 'gray4' : 'gray2'}>
          ~
        </Typography>
        <PickerButtonOutlined
          disabled={endDateDisabled}
          onClick={() => setIsDrawerOpen(true)}
          placeholder={NOTICE_FORM_TEXT.endDate}>
          {endOn ? filters.date(endOn) : undefined}
        </PickerButtonOutlined>
      </Container>
      <DateCalendarPickerDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSave={updateEndDate}
        onChange={e => setTempEndDate(e.value)}
        value={tempEndDate}
      />
    </>
  );
};

export default NoticePeriodPicker;

const Container = styled.div`
  padding: 16px 20px 8px;
  box-sizing: border-box;
  ${theme.flex('row', 'center', 'flex-start', 8)}
`;
