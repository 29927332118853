import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import React from 'react';

const PrivacyPolicyDetail = () => {
  return (
    <Container id="paper">
      <h1 className="title">스튜디오메이트 개인정보 처리방침</h1>
      <header>
        <section className="outline">
          <p>
            스튜디오메이트(이하”회사”)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
            원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다. 스튜디오메이트는 이용자를
            위하여 반드시 필요한 최소한의 정보만을 수집하여 필요한 기간까지 안전하게 보호하고 이후 안전하게 파기하는 것을
            개인정보취급방침의 핵심으로 두고 있습니다.
          </p>
          <br />
          <p>스튜디오메이트의 개인정보취급방침은 다음과 같은 내용을 담고 있습니다.</p>
        </section>

        <ol>
          <li>개인정보의 수집 및 이용목적</li>
          <li>수집하는 개인정보의 항목 및 수집방법</li>
          <li>개인정보의 공유 및 제공</li>
          <li>처리하는 개인정보 항목</li>
          <li>개인정보의 제3자 제공</li>
          <li>개인정보처리의 위탁</li>
          <li>개인정보의 보유 및 이용기간</li>
          <li>개인정보 파기절차 및 방법</li>
          <li>이용자 및 법정대리인의 권리와 의무 및 행사방법</li>
          <li>개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</li>
          <li>개인정보의 기술적/관리적 보호대책</li>
          <li>개인정보 보호 책임자</li>
          <li>권익 침해 구제 방법</li>
          <li>고지의 의무</li>
        </ol>
      </header>
      <section className="chapter">
        <h4 className="chapter_sub_title">1. 개인정보의 수집 및 이용목적</h4>

        <ul>
          <li>
            ① 서비스 제공에 관한 계약 이행: 서비스 이용을 위한 콘텐츠 제공, 물품배송, 본인인증 및 금융서비스를 통한 이용권 결제
          </li>
          <li>
            ② 회원관리: 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인,
            가입 및 가입횟수 제한, 연령확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
          </li>
          <li>
            ③ 서비스 개발 및 마케팅 광고에의 활용: 맞춤 서비스 제공 및 신규 서비스 개발, 인구통계학적 특성에 따른 서비스 제공 및
            광고 게재, 신/인기 상품 홍보, 이벤트/광고성 정보 제공과 참여기회 제공, 접속 빈도 파악 또는 회원의 서비스 이용에 대한
            통계/분석을 통한 마케팅 자료로서 회원 취향에 맞는 최적의 서비스 제공
          </li>
        </ul>
      </section>
      <section className="chapter">
        <h4 className="chapter_sub_title">2. 수집하는 개인정보의 항목 및 수집방법</h4>

        <ol className="no-style">
          <li>
            <p>
              ① 스튜디오메이트의 서비스를 이용하고자 할 경우 다음의 정보를 입력하여야 하며 선택항목은 입력하지 않아도 서비스
              이용에 제한은 없습니다.
            </p>
            <ol>
              <li>
                회원가입시 수집하는 항목: 시설명, 지역, 시설 상세 주소, 시설 담당자/대표 휴대전화번호, 시설전화번호, 사진정보(시설
                로고, 대표 사진, 시설 내부 사진 등), 마케팅수신동의여부
              </li>
              <li>서비스 이용과정에서 생성 및 수집되는 항목:</li>
              <ul>
                <li>
                  결제정보 및 내역, 서비스 이용기록, 단말기명, OS버전, UDID, 접속로그, 쿠키, 접속IP정보, 불량이용기록, 빌링키
                </li>
                <li>시설에 등록한 회원의 개인정보(이름, 휴대폰 번호, 이메일, 결제내역 등)</li>
              </ul>
              <li>이용자의 선택에 의해 수집하는 항목: 이벤트 응모 등의 진행 및 사은품 배송을 위한 정보</li>
            </ol>
          </li>

          <li>
            <p>② 개인정보 수집 방법</p>
            <ol>
              <li>홈페이지, 모바일APP, 서면양식, 팩스, 전화, 상담게시판, 이메일, 이벤트 응모</li>
              <li>서비스 이용 시설로부터의 제공 (시설 회원 개인정보)</li>
            </ol>
          </li>
        </ol>
      </section>
      <section className="chapter">
        <h4 className="chapter_sub_title">3. 개인정보의 공유 및 제공</h4>

        <p>스튜디오메이트는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.</p>

        <ol>
          <li>이용자들이 사전에 공개에 동의한 경우</li>
          <li>
            전기통신기본법, 전기통신사업법 등 관계 법령의 규정에 의거하거나 수사목적으로 법령에 정해진 절차와 방법에 따라
            수사기관의 요구가 있는 경우
          </li>
        </ol>
      </section>
      <section className="chapter">
        <h4 className="chapter_sub_title">4. 개인정보의 제3자 제공</h4>
        <ol>
          <li>
            스튜디오메이트는 정보주체의 개인정보를 제1조(개인정보수집 및 이용목적)에서 명시한 범위 내에서만 처리하며, 정보주체의
            동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
          </li>
        </ol>
      </section>
      <section className="chapter">
        <h4 className="chapter_sub_title">5. 개인정보처리의 위탁</h4>

        <p>스튜디오메이트는 개인정보의 처리와 관련하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
        <ol>
          <li>
            회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적/관리적 보호조치,
            재위탁 제한, 수탁자에 대한 관리/감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고 수탁자가 개인정보를
            안전하게 처리하는지를 감독하고 있습니다.
          </li>
          <li>
            계약 이행을 위해 필요한 경우가 아닌 다른 여타목적을 위한 업무 위탁의 경우, 이용자에게 미리 서면, 전자우편, 전화 또는
            홈페이지, APP을 통하여 수탁자와 위탁업무에 대해 통지하고 사전 동의를 받도록 하겠습니다.
          </li>
        </ol>

        <table>
          <tbody>
            <tr>
              <th>위탁업체</th>
              <th>위탁업체 업종</th>
              <th>위탁범위</th>
              <th>공유정보</th>
            </tr>
            <tr>
              <td>금융결제원, (주)케이에스넷</td>
              <td>IT 업체</td>
              <td>결제대행</td>
              <td>카드정보, 결제정보</td>
            </tr>
            <tr>
              <td>(주) 다우기술</td>
              <td>IT 업체</td>
              <td>카카오 알림톡 서비스 API 제공업무</td>
              <td>전화번호</td>
            </tr>
            <tr>
              <td>(주) 엘지유플러스</td>
              <td>IT 업체</td>
              <td>대용량 SMS API 제공 업무</td>
              <td>전화번호</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="chapter">
        <h4 className="chapter_sub_title">6. 개인정보의 보유 및 이용기간</h4>

        <p>
          스튜디오메이트는 개인정보의 수집 및 제공받은 목적이 달성되면 지체없이 개인정보를 파기함을 원칙으로 합니다. 다만, 다음 각
          호의 경우 일정기간 동안 예외적으로 수집한 회원정보의 전부 또는 일부를 보관할 수 있습니다.
        </p>

        <ol>
          <li>보유기간을 미리 고지하고 별도의 동의를 받은 경우 해당 보유기간까지 보유</li>
          <li>소멸시효가 남은 상사채권(이용권, 상품권 등)을 이용자가 보유하고 있는 경우 시효 만료 시점까지 보유</li>
          <li>
            전자상거래 등에서의 소비자 보호에 관한 법률 및 상법 등 관계법령에 따라 일정기간 보유할 필요가 있는 경우 관계 법령이
            정한 기간에 따라 보유
          </li>
          <li>
            <ul>
              <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
              <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
              <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 1년</li>
              <li>게시판 이용자의 본인확인에 관한 기록 : 6개월</li>
            </ul>
          </li>
        </ol>
      </section>

      <section className="chapter">
        `<h4 className="chapter_sub_title">7. 개인정보 파기절차 및 방법</h4>
        <ol>
          <li>
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
            파기합니다.
          </li>
          <li>
            정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
            계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
          </li>
          <li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
          <li>
            <ul>
              <li>
                파기절차: 회사는 이용자가 회원탈퇴를 신청하는 경우, 소비자의 불만 및 분쟁해결 등을 위한 목적으로 30일간 개인정보를
                보관한 후 재생이 불가능한 방법으로 파기합니다.
              </li>
              <li>
                파기방법: 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없도록 로우 레벨 포맷(Low Level Format) 등의
                방법을 이용하여 파기하며 종이 문서에 기록/저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
              </li>
            </ul>
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h4 className="chapter_sub_title">8. 이용자 및 법정대리인의 권리와 의무 및 행사방법</h4>
        <p>이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
        <ol>
          <li>개인정보 열람요구</li>
          <li>오류 등이 있을 경우 정정 요구</li>
          <li>삭제요구</li>
          <li>처리정지 요구</li>
          <li>
            제1항에 따른 권리행사는 회사에 대해 서면, 전화, 전자우편, 팩스 등을 통하여 할 수 있으며 회사는 이에 대해 지체없이
            조치하겠습니다.
          </li>
          <li>
            이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해
            개인정보를 이용하거나 제공하지 않습니다.
          </li>
          <li>
            만 14세 미만 아동의 경우, 제1항에 따른 권리행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수
            있습니다. 이 경우, 법정대리인은 이용자의 모든 권리를 가집니다.
          </li>
          <li>
            이용자는 정보통신망법, 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는 이용자 본인이나 타인의 개인정보 및
            사생활을 침해하여서는 안됩니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h4 className="chapter_sub_title">9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h4>
        <ol>
          <li>
            회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키( Cookie)’를
            사용합니다.
          </li>
          <li>
            쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의
            컴퓨터 또는 디바이스에 저장됩니다.
          </li>
          <li>
            <ul>
              <li>
                쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹사이트들에 대한 방문 및 이용형태, 인기검색어, 보안접속 여부 등을
                파악하여 이용자에게 최적화된 정보제공을 위해 사용됩니다.
              </li>
              <li>
                쿠키의 설치/운영 및 거부: 웹브라우저 상단의 도구 - 인터넷옵션 - 보안 -사용자정의수준을 선택하거나 모바일 기기 설정
                또는 옵션에서 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나 모든 쿠키의 저장을 거부할
                수 있습니다.
              </li>
              <li>쿠키 저장을 거부할 경우 쿠키를 통해 회사에서 제공하는 서비스를 이용할 수 없습니다.</li>
            </ul>
          </li>
          <li>
            Google Analytics를 활용하여 이용자의 서비스 이용에 대해 분석합니다. Google Analytics는 이용자의 사이트 활용 방식을
            이해하는데 도움을 주는 웹로그 분석 도구입니다. 개별 이용자의 개인정보를 식별하지 않고 익명의 사용자 정보를 활용하여
            웹사이트 사용 통계를 보고하기 위해 쿠키를 사용할 수 있습니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h4 className="chapter_sub_title">10. 개인정보의 기술적/관리적 보호대책</h4>
        <p>
          회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 다음과 같은 조치를
          취하고 있습니다.
        </p>
        <ol className="no-style">
          <li>
            <p>① 관리적대책</p>
            <ol>
              <li>
                회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며, 그 최소한의 인원에 해당하는 자는
                다음과 같습니다.
                <ul>
                  <li>
                    이용자를 직접 상대로 하여 마케팅, 이벤트, 고객지원, 게임운영, 배송업무를 수행하는 자 (위탁, 협력 업체 직원
                    포함)
                  </li>
                  <li>개인정보보호책임자 등 개인 정보 관리업무를 수행하는 자</li>
                  <li>기타 업무상 개인정보 처리가 불가피한 자</li>
                </ul>
              </li>
              <li>
                회사는 개인정보취급자와 수탁자를 대상으로 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다.
                <ul>
                  <li>
                    이용자를 직접 상대로 하여 마케팅, 이벤트, 고객지원, 게임운영, 배송업무를 수행하는 자 (위탁, 협력 업체 직원
                    포함)
                  </li>
                  <li>개인정보보호책임자 등 개인 정보 관리업무를 수행하는 자</li>
                  <li>기타 업무상 개인정보 처리가 불가피한 자</li>
                </ul>
              </li>
            </ol>
          </li>

          <li>② 회사는 개인정보취급자와 수탁자를 대상으로 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다.</li>
          <li>
            ③ 회사는 개인정보보호 업무를 전담하는 부서에서 개인정보처리방침 및 내부 규정 준수여부를 확인하여 문제가 발견될 경우,
            즉시 바로잡을 수 있도록 노력하고 있습니다.
          </li>
          <li>
            ④ 회사가 개인정보처리자로서 의무를 다하였음에도 불구하고 이용자 본인의 부주의나 회사가 관리하지 않는 영역내에서의 사고
            등 회사의 귀책에 기인하지 않은 손해에 대해서는 회사는 책임을 지지 않습니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h4 className="chapter_sub_title">11. 개인정보 보호 책임자</h4>
        <ol>
          <li>
            <p>
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임을 지고 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
              위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.
            </p>
            <br />
            <p>
              <strong>
                <u>개인정보 보호책임자</u>
              </strong>
              <br />
              성명: 이승욱 <br />
              직책: 이사 <br />
              연락처: 02-6952-5533 <br />
              메일: privacy@studiomate.kr
            </p>
            <br />
            <p>
              <strong>
                <u>개인정보 보호 담당부서</u>
              </strong>
              <br />
              담당부서: 개발팀 <br />
              연락처: 02-6952-5533 <br />
              메일: privacy@studiomate.kr
            </p>
            <br />
          </li>
          <li>
            이용자는 서비스를 이용하면서 발생하는 모든 개인정보 보호관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
            보호책임자 및 담당부서로 문의할 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 답변 및 처리해 드릴 것입니다.
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h4 className="chapter_sub_title">12. 권익 침해 구제 방법</h4>
        <p>
          이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의할 수 있습니다. 아래 기관은 회사와는 별개의
          기관으로서 회사의 자체적인 개인정보 불만처리, 피해 구제 결과에 만족하지 못하거나 보다 자세한 도움이 필요한 경우 문의하여
          주시기 바랍니다.
        </p>
        <ol>
          <li>
            <p>개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
            <ul>
              <li>홈페이지 : www.118.or.kr</li>
              <li>전화번호 : (국번없이) 118</li>
            </ul>
          </li>

          <li>
            <p>개인정보 분쟁조정위원회</p>
            <ul>
              <li>홈페이지 : www.kopico.go.kr</li>
              <li>전화번호 : (국번없이) 1833-6972</li>
            </ul>
          </li>

          <li>
            <p>대검찰청 사이버범죄수사단</p>
            <ul>
              <li>홈페이지 : www.spo.go.kr</li>
              <li>전화번호 : (국번없이) 1301</li>
            </ul>
          </li>

          <li>
            <p>경찰청 사이버안전국</p>
            <ul>
              <li>홈페이지 : https://cyberbureau.police.go.kr</li>
              <li>전화번호 : (국번없이) 182</li>
            </ul>
          </li>
        </ol>
      </section>

      <section className="chapter">
        <h4 className="chapter_sub_title">13. 고지의 의무</h4>
        <p>
          본 개인정보 처리방침은 2018년 6월 18일에 제정되었으며 법령/정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이
          있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일 전에 스튜디오메이트 서비스 홈페이지를 통해 변경 이유 및 내용
          등을 공지하도록 하겠습니다.
        </p>
      </section>

      <section className="footer">
        <p>개인정보처리방침 시행일자(v1.0) : 2018년 6월 18일</p>
        <p>개인정보처리방침 변경공고일자 : 2020년 3월 6일</p>
      </section>
    </Container>
  );
};

export default React.memo(PrivacyPolicyDetail);

const Container = styled.div`
  ${theme.flex('column', '', '', 20)};
  margin: 20px auto 0;
  padding-bottom: 120px;

  .no-style {
    margin-bottom: 14px;

    > li {
      list-style: none;
    }

    p {
      margin-bottom: 14px;
    }
  }

  /** 리스트 스타일 */
  ol {
    ${theme.flex('column', '', '', 8)};
    padding-left: 20px;

    li {
      list-style: decimal;
    }
  }

  ul {
    padding-left: 14px;

    li {
      list-style-type: lower-alpha;
    }
  }

  /** 테이블 */
  td,
  th {
    padding: 4px 10px;
    border: 1px solid #000;
    font-size: 1.4rem;
    line-height: 22px;
  }

  .outline {
    margin-bottom: 14px;
  }

  /** 메인 타이틀 */
  .title {
    font-size: 1.6rem;
    font-weight: 700;
  }

  /** 각 항목간 거리 */
  .chapter {
    ${theme.flex('column', '', '', 8)};
  }

  /** 각 항목 타이틀 */
  .chapter_sub_title {
    margin-top: 10px;
    font-size: 1.4rem;
    font-weight: 700;
  }

  /** 본문 */
  p,
  li {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
  }

  table {
    th,
    tr,
    td {
      padding: 8px;
      font-size: 1.3rem;
      text-align: center;
    }
  }
`;
