import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import SubTitle from 'components/SubTitle';
import Typography from 'components/Typography';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import Studios from './components/Studios';

const SelectStudio = () => {
  return (
    <MainLayout>
      <Container>
        <SubTitle title="이용할 시설을 선택해 주세요" bottom={40} />
        <ContentLayout>
          <ApiBoundary
            fallback={
              <div>
                {/* // TODO: 로딩 처리 */}
                로딩..
              </div>
            }>
            <Studios />
          </ApiBoundary>
          <HelpTextWrapper>
            <Typography size={13} weight={500} textColor="gray3">
              무엇을 도와드릴까요?
            </Typography>
            <Button fontSize={13} textColor="primary">
              자주 묻는 질문
            </Button>
          </HelpTextWrapper>
        </ContentLayout>
      </Container>
    </MainLayout>
  );
};
export default SelectStudio;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  padding: 64px 20px 0;
  height: 100%;
  form {
    ${theme.flex('column', '', '', 8)};
    padding: 0;
  }

  h2 {
    margin-top: 8px;
  }
`;

const ContentLayout = styled.section`
  ${theme.flex('column', '', 'space-between')};
  height: 100%;
`;

const HelpTextWrapper = styled.section`
  ${theme.flex('', 'center', 'center', 4)};
  margin-bottom: 60px;
`;
