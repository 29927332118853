import styled from '@emotion/styled';
import { ButtonProps } from 'components/Button';
import Form from 'components/Form';
import Switch from 'components/Switch';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import { ETC_FORM_TEXT } from 'pages/EtcSchedule/constants';
import { EtcPageModeType, EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { PropsWithChildren } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormField from 'sharedComponents/FormField';
import LeaveDialog from 'sharedComponents/LeaveDialog';

import AllDayField from './AllDayField';
import SelectStaffsAndColor from './SelectStaffsAndColor';
import SubmitButton from './SubmitButton';

type Props = {
  pageMode: EtcPageModeType;
  footerButtonProps?: Omit<ButtonProps, 'children'>;
  onSubmit: (values: EtcScheduleCreateFormType) => void;
};

const CommonEtcScheduleForm = ({ children, pageMode, footerButtonProps, onSubmit }: PropsWithChildren<Props>) => {
  const { handleSubmit, control } = useFormContext<EtcScheduleCreateFormType>();

  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <ClassTitle placeholder={ETC_FORM_TEXT.title.placeholder} />
        {children}
        <AllDayField />
        {(pageMode === 'create' || pageMode === 'updateAll') && <RepeatField />}
        <SelectStaffsAndColor />
        <FormField iconName="interselection">
          <Controller
            name="is_overlap"
            control={control}
            render={({ field: { value, ...field } }) => (
              <Switch id="is_overlap" label={ETC_FORM_TEXT.isOverlap.label} defaultChecked={value} {...field} />
            )}
          />
        </FormField>
        <FormField iconName="lockRegular" hasBorder={false}>
          <Controller
            name="is_private"
            control={control}
            render={({ field: { value, ...field } }) => (
              <Switch id="is_private" label={ETC_FORM_TEXT.isPrivate.label} defaultChecked={value} {...field} />
            )}
          />
        </FormField>
        <SubmitButton {...footerButtonProps}>{ETC_FORM_TEXT.footerButton[pageMode]}</SubmitButton>
      </FormContainer>
      <LeaveDialog />
    </>
  );
};

export default CommonEtcScheduleForm;

const FormContainer = styled(Form)`
  min-height: 100%;
  padding-bottom: 122px;
`;
