import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useInfinityScheduleManagementBooking, {
  ScheduleManagementBookingParams,
  ScheduleManagementBookingResponse,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { bookingParamsAtom } from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import { calculateTotal, convertToStringStaffIds, filterByKeyword, getScheduleGroupByStartOn } from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const BookingScheduleList = ({ currentTab }: Props) => {
  const scheduleBooking = useRecoilValue(bookingParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleManagementBookingResponse[][]>([]);

  const scheduleBookingParams: ScheduleManagementBookingParams = {
    staff: convertToStringStaffIds(scheduleBooking.staffs || []),
    start_date: scheduleBooking.period?.start || filters.dateDash(),
    end_date: scheduleBooking.period?.end || filters.dateDash(),
    type: scheduleBooking.type,
    paginate_type: 'detail',
    status: scheduleBooking.status,
    order_by: scheduleBooking.sort ?? 'asc',
  };

  const {
    data: { schedules, total },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfinityScheduleManagementBooking(scheduleBookingParams);

  const groupTotal = useMemo(() => calculateTotal(filteredSchedules, 'G'), [filteredSchedules]);
  const privateTotal = useMemo(() => calculateTotal(filteredSchedules, 'P'), [filteredSchedules]);

  useEffect(() => {
    // 조건에 따라 스케줄 필터링 및 그룹화
    const filtered = searchKeyword === '' ? schedules : filterByKeyword(schedules, searchKeyword);

    const groupedSchedules = getScheduleGroupByStartOn(filtered);
    setFilteredSchedules(groupedSchedules as ScheduleManagementBookingResponse[][]);
  }, [searchKeyword, schedules]);

  if (total === 0 || filteredSchedules.length === 0)
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={groupTotal} privateTotal={privateTotal} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.booking} />
      </>
    );

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={total} groupTotal={groupTotal} privateTotal={privateTotal} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <ContentSection>
        <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
          <ScheduleCard schedules={filteredSchedules} currentTab={currentTab} />
        </InfiniteScroll>
      </ContentSection>
    </Container>
  );
};

export default BookingScheduleList;

const Container = styled.div`
  & > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;
