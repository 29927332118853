import { useIsMutating } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';

type Props = {
  pageMode: 'create' | 'update';
};

const SubmitButton = ({ pageMode }: Props) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
  } = useFormContext<MemberFormType>();
  const profileName = useWatch({ control, name: 'profile.name' });
  const isMutating = useIsMutating() > 0;

  const disabled = useMemo(() => {
    if (!profileName || isMutating || isSubmitting) return true;
    if (profileName && !isDirty) return false;
    return false;
  }, [isDirty, isSubmitting, profileName, isMutating]);

  return (
    <FooterButton type="submit" disabled={disabled}>
      {MEMBER_FORM_TEXT.footerButton[pageMode]}
    </FooterButton>
  );
};

export default SubmitButton;
