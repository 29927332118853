import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 회원 등록 폼 */
const MemberCreate = () => {
  return (
    <ApiBoundary>
      <FormMain />
    </ApiBoundary>
  );
};

export default MemberCreate;
