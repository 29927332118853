import styled from '@emotion/styled';
import { CalendarNav, Datepicker, MbscDatepickerOptions } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { Container as OriginPickerContainer } from 'components/DateCalendarPicker';
import DatePicker from 'components/DatePicker';
import Drawer from 'components/Drawer';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import useGetStaffWorkSchedules from 'hooks/service/queries/useGetStaffWorkSchedules';
import useParamsId from 'hooks/useParamsId';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { drawerAtom } from 'recoil/drawer';
import { eventScheduleSelectDateAtom } from 'recoil/staff';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import filters from 'utils/filters';
import getWorkTimeDataset from 'utils/getWorkTimeDataset';

import { CALENDAR_DEFAULT_PROPS } from '../../constants';
import { CalendarOnCellClickParams, CommonPropsType, FieldType } from '../../types';

type Props = MbscDatepickerOptions &
  CommonPropsType & {
    eventWorkTimes: ScheduleFormType[];
    removeTime: (index: number, type: FieldType) => void;
  };

let firstRender = true;

const DateMultiCalendarPicker = ({
  eventWorkTimes,
  eventWorkTimeFields,
  eventRestTimeFields,
  appendTime,
  removeTime,
  replaceFields,
  selectEventWorkTimeFields,
  ...props
}: Props) => {
  const calendarRef = useRef<Datepicker>(null);
  const tempMonthRef = useRef<Date | null>(null);

  const setSelectDates = useSetRecoilState(eventScheduleSelectDateAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(drawerAtom);

  const targetStaffId = useParamsId();
  const { data: staffWorkSchedules } = useGetStaffWorkSchedules({ targetStaffId });
  const workSchedulesDataset = getWorkTimeDataset(staffWorkSchedules, 'update') as Record<
    'workTimes' | 'restTimes',
    ScheduleFormType[]
  >;

  useEffect(() => {
    if (firstRender) {
      firstRender = false;
      setSelectDates([]);
    }
  }, [setSelectDates]);

  const renderCalendarHeader = useCallback(() => {
    return (
      <CalendarHeaderContainer>
        <Button
          widthSize={120}
          className="button-overlay"
          variant="contained"
          color="gray"
          onClick={e => {
            e.stopPropagation();
            setIsDrawerOpen(true);
          }}>
          Overlay
        </Button>
        <CalendarNav />
        <Icon name="arrowBottomFill" className="arrow-bottom-fill" fillColor={theme.color.gray2} size={16} />
      </CalendarHeaderContainer>
    );
  }, [setIsDrawerOpen]);

  const changeDate = useCallback((e: { value: Date }) => {
    if (!calendarRef.current) return;
    tempMonthRef.current = dayjs(e.value).set('date', 1).toDate();
  }, []);

  const marked = useMemo(() => {
    return eventWorkTimes.map(({ date }) => {
      return {
        date: dayjs(date).toDate(),
        color: theme.color.primary,
      };
    });
  }, [eventWorkTimes]);

  const onCellClick = useCallback(
    ({ date, selected }: CalendarOnCellClickParams) => {
      const isSelected = !selected;
      const dateFormat = filters.dateDash(date);
      const isAlreadyDate = marked.findIndex(mark => filters.dateDash(mark.date) === dateFormat) > -1;
      const dayOfWeek = Number(dayjs(date).format('d')) as ScheduleFormType['day_of_week'];

      if (isAlreadyDate) return;

      const originRestTime = workSchedulesDataset.restTimes
        .filter(restTime => {
          return restTime.day_of_week === dayOfWeek;
        })
        .map(restTime => {
          return {
            ...restTime,
            date: filters.dateDash(date),
          };
        });

      if (isSelected) {
        /** 일반 근무시간(특정일X) - 근무시간 초기값 세팅 */
        const originWorkTime = workSchedulesDataset.workTimes.filter(workTime => {
          return workTime.day_of_week === dayOfWeek;
        })[0];

        const commonAppendParams = {
          id: 0,
          date: dateFormat,
          day_of_week: dayOfWeek,
          created_at: '',
          updated_at: '',
        };

        appendTime(
          {
            ...commonAppendParams,
            type: 'eventWorkTime',
            start_time: originWorkTime.start_time,
            end_time: originWorkTime.end_time,
            isClosed: originWorkTime.isClosed,
          },
          'work',
        );

        originRestTime.forEach(restTime => {
          appendTime(
            {
              ...commonAppendParams,
              type: 'eventRestTime',
              start_time: restTime.start_time,
              end_time: restTime.end_time,
            },
            'rest',
          );
        });
      } else {
        /** 근무시간 초기값 날짜 선택 해제 시 삭제  */
        const currentWorkTimeIndex = eventWorkTimeFields.findIndex(workTime => workTime.date === dateFormat);
        removeTime(currentWorkTimeIndex, 'work');

        /** 휴식시간 초기값 날짜 선택 해제 시 삭제  */
        const restTimeIndexArray: number[] = [];
        originRestTime.forEach(({ date, start_time, end_time }) => {
          const currentIndex = eventRestTimeFields.findIndex(restTime => {
            if (date !== restTime.date) return false;
            if (start_time === restTime.start_time && end_time === restTime.end_time) {
              return true;
            }
            return false;
          });
          restTimeIndexArray.push(currentIndex);
        });

        const sortRestTimeIndex = _.sortBy(restTimeIndexArray).reverse();
        sortRestTimeIndex.forEach(index => {
          removeTime(index, 'rest');
        });
      }
    },
    [appendTime, eventRestTimeFields, eventWorkTimeFields, marked, removeTime, workSchedulesDataset],
  );

  return (
    <>
      <Container>
        <Datepicker
          ref={calendarRef}
          renderCalendarHeader={renderCalendarHeader}
          marked={marked}
          onChange={({ value: dates }) => {
            setSelectDates(dates);
          }}
          onCellClick={onCellClick}
          selectMultiple
          {...CALENDAR_DEFAULT_PROPS}
          {...props}
        />
      </Container>

      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <DrawerContainer>
          {isDrawerOpen && <DatePicker dateFormat="YYYY/MM" rows={5} onChange={changeDate} />}
          <ButtonGroup leftButtonWidth={115}>
            <Button size="large56" variant="outlined" onClick={() => setIsDrawerOpen(false)}>
              취소
            </Button>
            <Button
              size="large56"
              variant="contained"
              color="point"
              onClick={() => {
                if (calendarRef.current && tempMonthRef.current) {
                  calendarRef.current.navigate(tempMonthRef.current);
                  setIsDrawerOpen(false);
                }
              }}>
              완료
            </Button>
          </ButtonGroup>
        </DrawerContainer>
      </Drawer>
    </>
  );
};

export default DateMultiCalendarPicker;

const Container = styled(OriginPickerContainer)`
  ${theme.flex('column', 'center', 'center')};
  width: 100%;

  /** 캘린더 전체 컨테이너 */
  .mbsc-datepicker {
    margin-bottom: 6px;
    width: 100%;

    .mbsc-calendar-marks {
      position: absolute;
      top: 8px;

      .mbsc-calendar-mark {
        width: 4px;
        height: 4px;
      }
    }

    /** selected 날짜는 mark를 표시하지 않아도 됨 */
    .mbsc-selected {
      .mbsc-calendar-cell-text {
        ${theme.flex()};
        width: 30px !important;
        height: 30px !important;
      }

      .mbsc-calendar-mark {
        opacity: 0;
      }
    }

    /** outer 날짜의 mark 스타일 */
    .mbsc-calendar-day-outer .mbsc-calendar-mark {
      opacity: 0.6;
    }

    .mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text {
      color: ${theme.color.white} !important;
    }
  }

  /** 헤더 버튼("YYYY년 MM월") */
  .mbsc-calendar-controls {
    padding-bottom: 18px;
    ${theme.flex('column')};
  }

  .mbsc-calendar-day,
  .mbsc-calendar-day-inner {
    ${theme.flex('row', 'center', 'center')};
    padding: 0;
  }

  .mbsc-calendar-day-inner {
    width: 44px;
    height: 40px;

    .mbsc-calendar-today {
      font-weight: 500;
    }
  }

  .mbsc-calendar-row {
    gap: 8px;
    padding: 0 9.5px;

    /** 날짜 텍스트(숫자) */
    .mbsc-calendar-day-text {
      color: ${theme.color.gray2};
    }
  }

  /** 요일 텍스트 커스텀 */
  .mbsc-calendar-week-days {
    ${theme.flex('row', 'center', 'center', 8)};
    padding: 0 10px;
  }

  /** 캘린더 테이블 스타일 */
  .mbsc-calendar-table {
    gap: 0;
  }
`;

const DrawerContainer = styled.div`
  .button-group {
    margin: 51px 0 10px;
    padding: 0 20px;
  }

  .mbsc-scrollview-scroll.mbsc-ltr {
    margin-left: 24px;
  }

  .mbsc-scroller-wheel-group {
    gap: 0;
  }
`;

const CalendarHeaderContainer = styled.div`
  position: relative;
  ${theme.flex()};
  margin-top: 8px;

  /** mobiscroll 헤더 버튼 이벤트 방지용 */
  .button-overlay {
    position: absolute;
    width: 76px;
    height: 22px;
    opacity: 0;
    z-index: 2;
  }

  .mbsc-calendar-button {
    padding: 0;
    line-height: inherit;

    .mbsc-calendar-title {
      padding: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: ${theme.color.gray1};

      &.mbsc-calendar-year {
        margin-right: 6px;
      }
    }

    span {
    }
  }
`;
