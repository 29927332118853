import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  onClick: () => void;
  onClose: () => void;
  description: string;
  buttonText?: string;
};

/** 에러 dialog 클릭 이벤트에 로직이 필요한 경우 */
const CustomErrorDialog = ({ onClick, onClose, buttonText = '확인', description }: Props) => {
  return (
    <Dialog
      isError
      onClose={onClose}
      positiveAction={{
        text: buttonText,
        onClick,
      }}>
      <DialogDescription>{description}</DialogDescription>
    </Dialog>
  );
};

export default CustomErrorDialog;
