import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { Member, ReportCommonType, Staff } from 'pages/MoreDetails/Report/type';

const useInfinityReportLecture = (params: ReportLectureParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<ReportLectureResponse, ReportLectureParams>({
    url: `/api/report/lectures`,
    params: { ...params },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`infinity/report/lecture`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityReportLecture;

type Lecture = {
  /** 아이디 */
  id: number;
  /** 수업명 */
  title: string;
  /** 수업 시작 시간 */
  start_on: string;
  /** 수업 종료 시간 */
  end_on: string;
  /** 수정일 */
  updated_at: string;
  /** 삭제일 */
  deleted_at: string | null;
};

export type ReportLectureParams = {
  /** 검색 시작일 */
  start_date: string;
  /** 검색 종료일 */
  end_date: string;
  /** 회원이름 검색 */
  search?: string;
  /** 수업유형 */
  class_type?: 'G' | 'P';
  /** 수강권 유형 */
  type?: string;
  /** 강사 검색 */
  staff_ids?: number[];
  /** 수강권 검색 */
  ticket_ids?: number[];
  /** 페이지네이션 종류 */
  paginate_type: string;
  /** 리스트 솔트 기준 */
  order_by?: string;
};

export type ReportLectureResponse = {
  /** id
   * 실제로는 action_at 의 년도가 짤려서 표시된 숫자 솔팅을 위해서 필요
   */
  id: number;
  /** 수강권 유형
   * T: 횟수제, P: 기간제, A: 차감제
   */
  type: string;
  /** 수업 아이디
   * 차감제의 경우 null로 표시됨
   */
  lecture_id: number | null;
  /** 수업 유형
   * G: 그룹, P: 프라이빗
   */
  class_type: 'G' | 'P';
  /** 예약 상태
   * 차감제의 경우 null로 표시됨
   */
  status: string;
  /**  회당 요금 */
  per_amount: number;
  /** 총 수익금
   * 일반 수업의 경우는 회당요금, 차감제의 경우는 회당 요금 * per_count
   */
  total_amount: number;
  /** 차감 횟수 */
  per_count: number;
  /** 티켓 아이디 */
  ticket_id: number;
  /** 수강권 아이디 */
  user_ticket_id: number;
  /** 강사 정보 */
  staff: Staff | null;
  /** 수업 정보 */
  lecture: Lecture | null;
  /**  액션 시간
   * 수업의 경우 수업 시작 시간, 차감의 경우는 차감일자의 23:59:59
   */
  action_at: string;
  /** 수강권 출석 히스토리 */
  cumulative: { booked: number; deducted: number };
} & ReportCommonType;
