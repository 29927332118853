import { useIsMutating } from '@tanstack/react-query';
import { TicketPaymentUpdateFormType } from 'pages/TicketDetail/types';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { PaymentSubmitButton } from 'sharedComponents/TicketForm';

const SubmitButton = ({ children }: PropsWithChildren) => {
  const {
    formState: { isDirty },
    getValues,
  } = useFormContext<TicketPaymentUpdateFormType>();
  const isMutating = useIsMutating() > 0;

  return (
    <PaymentSubmitButton disabled={isMutating || (getValues('paymentStatus') === 'update' && !isDirty)}>
      {children}
    </PaymentSubmitButton>
  );
};

export default SubmitButton;
