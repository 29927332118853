import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import TextFieldBase, { ITextFieldBasePropTypes } from 'components/TextFieldBase';
import Typography from 'components/Typography';
import { useEffect } from 'react';
import { hexToRgba } from 'utils/styles';

export type CounterProps = Omit<ITextFieldBasePropTypes, 'onChange' | 'value' | 'min' | 'max'> & {
  onChange: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
  suffix?: string;
};

const Counter = ({ value, onChange, min = 0, max = 999, suffix, ...props }: CounterProps) => {
  const isDisabledMinus = value === min || props.disabled;
  const isDisabledPlus = value === max || props.disabled;

  const clickMinus = () => {
    value === min || onChange(--value);
  };

  const clickPlus = () => {
    value === max || onChange(++value);
  };

  /** 입력값이 max 보다 많을 때 max로 자동 세팅 */
  useEffect(() => {
    if (value > max) {
      onChange(max);
    }
  }, [max, onChange, value]);

  /** 입력값이 min 보다 적을 때 min으로 자동 세팅 */
  useEffect(() => {
    if (value < min) {
      onChange(min);
    }
  }, [min, onChange, value]);

  return (
    <Container className="counter" suffix={suffix}>
      <StyledButton borderRadius="50%" variant="outlined" widthSize={24} onClick={clickMinus} disabled={isDisabledMinus}>
        <Icon name="minusBold" size={16} color={isDisabledMinus ? 'gray4' : 'gray1'} />
      </StyledButton>

      <div className="counter-text-wrapper">
        <TextFieldBase
          {...props}
          inputMode="numeric"
          value={value && String(value).replace(/(^0+)/, '')}
          onChange={e => onChange(Number(e.target.value))}
          min={min}
          max={max}
        />
        {suffix && (
          <Typography span size={15} weight={500} textColor={props.disabled ? 'gray4' : 'gray1'}>
            {suffix}
          </Typography>
        )}
      </div>

      <StyledButton borderRadius="50%" variant="outlined" widthSize={24} onClick={clickPlus} disabled={isDisabledPlus}>
        <Icon name="plusBold" size={16} color={isDisabledPlus ? 'gray4' : 'gray1'} />
      </StyledButton>
    </Container>
  );
};

export default Counter;

const Container = styled.div<Pick<CounterProps, 'suffix' | 'disabled'>>`
  ${theme.flex('', 'center', '', 12)};

  .icon-button {
    background-color: ${theme.color.white};
    :disabled {
      background-color: ${theme.color.gray8};
    }
  }

  .counter-text-wrapper {
    ${theme.flex('', 'center', '', 2)};
    flex: 1;

    input {
      padding: 0;
      flex: 1;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 22px;
      text-align: ${({ suffix }) => (suffix ? 'right' : 'center')};
      background-color: transparent;

      :disabled {
        color: ${theme.color.gray4};
      }
    }
  }
`;

const StyledButton = styled(IconButton)<{ disabled?: boolean }>`
  border-color: ${({ disabled }) => (disabled ? theme.color.gray5 : hexToRgba(theme.color.gray4, 0.72))};
`;
