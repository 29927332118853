import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { useState } from 'react';
import PeriodFilter from 'sharedComponents/Filters/PeriodFilter';
import { FilterOptionsType, FilterProps, PeriodFilterProps, PeriodFilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { DEFAULT_PERIOD } from '../constants';

type Props = FilterProps & Partial<PeriodFilterProps>;

const PeriodFilterContents = ({ currentFilter, selectedOption, onClick: changeOptions, ...props }: Props) => {
  const previousOptions = (selectedOption as PeriodFilterType['period']) || DEFAULT_PERIOD;
  const [date, setDate] = useState<string[]>([previousOptions.start, previousOptions.end]);

  const saveCustomPeriod = (period: string[]) => {
    const dateFormatted = period.map(filters.dateDash);
    setDate(dateFormatted);
    changeOptions({ value: { type: 'custom', start: dateFormatted[0], end: dateFormatted[1] }, label: '' }, 'period');
    return false;
  };

  const clickOptionButton = (option: FilterOptionsType) => {
    const unit = option.value === 'day' || option.value === 'isoWeek' ? option.value : 'month';
    const startDate = filters.dateDash(dayjs().startOf(unit));
    const unitEndDate = dayjs().endOf(unit);
    const endDate = filters.dateDash(props.max === 'today' ? dayjs() : unitEndDate);
    setDate([startDate, endDate]);
    changeOptions(
      {
        value: option.value
          ? {
              type: option.value as string,
              start: startDate,
              end: endDate,
            }
          : undefined,
        label: '',
      },
      'period',
    );
  };

  return (
    <StyledPeriodFilter
      currentFilter={currentFilter}
      selectedOption={get(selectedOption, 'type')}
      onClick={clickOptionButton}
      initialDate={date}
      onSave={props.onSave ?? saveCustomPeriod}
      {...props}
    />
  );
};

export default PeriodFilterContents;

const StyledPeriodFilter = styled(PeriodFilter)`
  padding: 0 20px 24px;
`;
