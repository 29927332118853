import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  waitingCountLimit: number;
  onClose: () => void;
  onClick: () => void;
};

const WaitingExceedDialog = ({ waitingCountLimit, onClose, onClick }: Props) => {
  return (
    <Dialog onClose={onClose} negativeAction={{ text: '취소' }} positiveAction={{ text: '예약대기', onClick }}>
      <DialogDescription>
        예약대기 가능인원({waitingCountLimit}명)을 초과했습니다.
        <br />
        계속 하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default WaitingExceedDialog;
