import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { TICKET_HOLDING_TEXT } from 'pages/TicketDetail/constants';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = Pick<UserTicketProps, 'canUpdateUserTicket'> & {
  holding: UserTicketResponse['holdings'];
};

const SubmitButton = ({ canUpdateUserTicket, holding }: Props) => {
  const { control, setValue } = useFormContext<TicketHoldingFormType>();
  const startOn = useWatch({ control, name: 'params.start_on' });
  const endOn = useWatch({ control, name: 'params.end_on' });

  const isMutating = useIsMutating() > 0;
  const disabled = !canUpdateUserTicket || !startOn || !endOn || isMutating;
  const isHolding = !!holding;

  return (
    <ButtonGroup leftButtonWidth={115}>
      {isHolding && (
        <Button
          variant="outlined"
          size="medium48"
          onClick={() => {
            setValue('selectedHolding', holding, { shouldDirty: true });
          }}
          disabled={isMutating}>
          취소
        </Button>
      )}
      <Button type="submit" fullWidth variant="contained" color="point" size="medium48" disabled={disabled}>
        {TICKET_HOLDING_TEXT.form.footerButton[isHolding ? 'update' : 'create']}
      </Button>
    </ButtonGroup>
  );
};

export default SubmitButton;
