import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import usePatchUserTicketUpgrade from 'hooks/service/mutations/usePatchUserTicketUpgrade';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { TICKET_UPGRADE_TEXT } from 'pages/TicketDetail/constants';
import { TicketUpgradeFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';
import filters from 'utils/filters';
import getPaymentFormParams from 'utils/getPaymentFormParams';

import PaymentInfo from './PaymentInfo';
import SelectUpgradeTicket from './SelectUpgradeTicket';
import TitleWithButton from './SelectUpgradeTicket/TitleWithButton';
import UpgradeInfo from './UpgradeInfo';

type Props = UserTicketProps & {
  step: number;
  onClickNextPage: () => void;
};

const TicketUpgrade = ({ userTicket, step, onClickNextPage }: Props) => {
  const methods = useForm<TicketUpgradeFormType>({
    defaultValues: {
      search: '',
      tempSearchKeyword: '',
      selectedTicket: null,

      installment: 'single',
      isCheckedCard: true,
      isCheckedCash: false,
      isCheckedWireTransfer: false,
      isCheckedPoint: false,
      isAutoWriteUnpaid: false,
      salePrice: userTicket.payments.initial_payment.amount,
      remainingPoint: userTicket.payments.current_point,

      payment: {
        amount: userTicket.payments.initial_payment.amount,
        installment_period: 2,
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        reward_point: 0,
        point_amount: 0,
        unpaid_amount: 0,
        settlement_at: filters.dateDashTimeSecond(),
      },
    },
  });

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: upgradeMutate } = usePatchUserTicketUpgrade(userTicket.id);

  const title = TICKET_UPGRADE_TEXT.title[step - 1];
  const message = TICKET_UPGRADE_TEXT.subTitle[step - 1];

  const submit = (values: TicketUpgradeFormType) => {
    const { selectedTicket, payment, installment } = values;
    if (!selectedTicket) return;

    const paymentParams = getPaymentFormParams({ payment, installment });
    const params = {
      ...paymentParams,
      user_id: userTicket.user_id,
      ticket_id: selectedTicket.id,
    };

    upgradeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: TICKET_UPGRADE_TEXT.successMessage });
        navigate(-3);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  useEffect(() => {
    if (step > 1 && !methods.getValues('selectedTicket')) {
      navigate(-step);
    }
  }, [methods, navigate, step]);

  return (
    <MainLayout
      progress={{ step, max: 3 }}
      header={{ title: TICKET_UPGRADE_TEXT.title[step - 1], titleScrollThreshold: 40 }}
      resetScrollTrigger={String(step)}>
      <FormProvider {...methods}>
        <Container>
          <TitleWithButton step={step} title={title} message={message} />
          {step === 1 && <SelectUpgradeTicket userTicket={userTicket} />}
          {step === 2 && <UpgradeInfo userTicket={userTicket} onClickNextPage={onClickNextPage} />}
          {step === 3 && <PaymentInfo userTicket={userTicket} onSubmit={submit} />}
        </Container>
      </FormProvider>
    </MainLayout>
  );
};

export default TicketUpgrade;

const Container = styled.div`
  height: 100%;
  ${theme.flex('column', 'flex-start', 'flex-start')};

  > div {
    width: 100%;
  }

  hr {
    width: 100%;
    flex: 0 0 auto;
  }
`;
