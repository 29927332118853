import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { ReportLectureResponse } from 'hooks/service/queries/useInfinityReportLecture';
import usePopup from 'hooks/usePopup';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useMemo, useState } from 'react';
import { reportLectureParamAtom } from 'recoil/report/atom';
import WholeFilterPopup from 'sharedComponents/Filters/WholeFilterPopup';
import filters from 'utils/filters';

import { DEFAULT_REPORT_LECTURE_FILTER_OPTIONS } from '../../constants';
import Search from '../Search';
import ReportLectureWholeFilterOptions from './ReportLectureWholeFilterOptions';

type Props = {
  lectures: ReportLectureResponse[];
};

const ReportLectureSummary = ({ lectures }: Props) => {
  const { setPopup } = usePopup();
  const { searchKeyword, setSearchKeyword } = useSearchKeywords('report');

  const [showTotalAmountInfo, setShowTotalAmountInfo] = useState(false);

  const changeShowTotalAmountInfo = () => {
    setShowTotalAmountInfo(!showTotalAmountInfo);
  };

  const clearSearchParam = () => {
    setSearchKeyword('');
  };

  const openWholeDrawer = () => {
    setPopup(
      <WholeFilterPopup
        filterAtom={reportLectureParamAtom}
        filters={DEFAULT_REPORT_LECTURE_FILTER_OPTIONS}
        renderWholeFilterPopupOptions={ReportLectureWholeFilterOptions}
      />,
    );
  };

  const lectureTotalCount = useMemo(() => {
    const lectureSet = new Set(lectures.map(lecture => lecture.lecture_id));
    return lectureSet.size;
  }, [lectures]);

  const totalAmount = useMemo(() => {
    return lectures.reduce((acc, lecture) => acc + lecture.total_amount, 0);
  }, [lectures]);

  const lectureTypeCount = useMemo(() => {
    return lectures.reduce(
      (acc, curr: ReportLectureResponse) => {
        if (curr.class_type === 'G') {
          acc.group.count += 1;
          acc.group.amount += curr.total_amount;
        } else {
          acc.private.count += 1;
          acc.private.amount += curr.total_amount;
        }

        return acc;
      },
      {
        group: {
          count: 0,
          amount: 0,
        },
        private: {
          count: 0,
          amount: 0,
        },
      },
    );
  }, [lectures]);

  return (
    <>
      <Accordion
        isOpen={showTotalAmountInfo}
        showArrow={false}
        size="none"
        header={
          <TotalSection isPadding={showTotalAmountInfo}>
            <div className="total-count-wrapper">
              <Typography span weight={500} textColor="gray2">
                총
              </Typography>
              <Typography span weight={600} textColor="primary">
                {lectureTotalCount}
              </Typography>
              <Typography span weight={500} textColor="gray2">
                건
              </Typography>
              <Typography span textColor="gray3" weight={500}>{`ㆍ 회원 ${lectures.length}명`}</Typography>
            </div>
            <div className="total-amount-wrapper">
              <div>
                <div>
                  <Typography span size={24} weight={700} textColor="gray1">
                    {filters.numberComma(totalAmount)}
                  </Typography>
                  <Typography span size={21} weight={700} textColor="gray1">
                    원
                  </Typography>
                </div>
                <Button onClick={changeShowTotalAmountInfo}>
                  <Icon name={showTotalAmountInfo ? 'arrowTopBold' : 'arrowBottomBold'} size={16} fillColor={theme.color.gray1} />
                </Button>
              </div>
              <Button
                edge="circular"
                heightSize={28}
                variant="outlined"
                padding={{ left: 12, right: 12 }}
                fontSize={13}
                fontWeight={500}
                textColor="gray2"
                onClick={openWholeDrawer}>
                상세조회
              </Button>
            </div>
          </TotalSection>
        }>
        <TotalAmountInfoWrapper isMargin={showTotalAmountInfo}>
          <div>
            <Typography span textColor="gray2">
              그룹
            </Typography>
            <Typography span textColor="gray2">
              프라이빗
            </Typography>
          </div>
          <div className="lecture-total-count">
            <Typography span weight={500} textColor="gray2">
              {lectureTypeCount.group.count}건
            </Typography>
            <Typography span weight={500} textColor="gray2">
              {lectureTypeCount.private.count}건
            </Typography>
          </div>
          <div>
            <Typography span weight={500} textColor="gray2">
              {filters.numberComma(lectureTypeCount.group.amount)}원
            </Typography>
            <Typography span weight={500} textColor="gray2">
              {filters.numberComma(lectureTypeCount.private.amount)}원
            </Typography>
          </div>
        </TotalAmountInfoWrapper>
      </Accordion>
      <SearchBarSection>
        <Search initialKeyword={searchKeyword} onClear={clearSearchParam} onSubmit={keyword => setSearchKeyword(keyword)} />
      </SearchBarSection>
    </>
  );
};

export default ReportLectureSummary;

const TotalSection = styled.section<{ isPadding: boolean }>`
  width: 100%;
  padding: 28px 20px 8px 20px;
  background-color: ${theme.color.white};

  .total-count-wrapper {
    padding-bottom: 2px;

    > span:first-of-type {
      padding-right: 4px;
    }
  }
  .total-amount-wrapper {
    ${theme.flex('', 'center', 'space-between')}

    > div {
      ${theme.flex('row', 'center', '', 4)}
    }
  }
`;

const TotalAmountInfoWrapper = styled.div<{ isMargin: boolean }>`
  ${theme.flex('row', '', 'space-between')}
  margin: ${isMargin => (isMargin ? '8px 0px' : '0px')};
  padding: 20px;
  background-color: ${theme.color.gray8};

  div {
    width: 100%;
    ${theme.flex('column', '', 'space-between', 8)}

    :not(:first-of-type) {
      text-align: end;
    }
  }

  .lecture-total-count {
    margin-right: 16px;
  }
`;

const SearchBarSection = styled.section`
  padding: 8px 20px 16px;
`;
