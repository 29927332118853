import useGetAllMemberCounsel from 'hooks/service/queries/useGetAllMemberCounsel';
import usePopup from 'hooks/usePopup';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { MessageTargetFormType, MessageType } from 'pages/MoreDetails/Message/types';
import { convertCounselToTargets } from 'pages/MoreDetails/Message/utils';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import FooterButton from 'sharedComponents/FooterButton';

import { MESSAGE_TARGET_TEXT } from '../../constants';
import ProcessingPopupContents from '../../Member/components/ProcessingPopupContents';
import { convertCounselFilterToMessageFilter } from '../utils';

type Props = {
  goToForm: (updater?: (state: MessageType) => MessageType) => void;
};

const CompleteButton = ({ goToForm }: Props) => {
  const { control, getValues } = useFormContext<MessageTargetFormType>();
  const isAllSelected = useWatch({ control, name: 'isAllSelected' });
  const selectedFilter = useRecoilValue(messageTargetCounselSelectedFilter);
  const { searchKeyword } = useSearchKeywords('message');

  const [isAllFetchEnabled, setIsAllFetchEnabled] = useState(false);

  const { data, isFetching } = useGetAllMemberCounsel(
    {
      ...selectedFilter,
      search: searchKeyword,
      register_type: selectedFilter.register ?? 'both',
      paginate_type: 'detail',
      has_mobile: 1,
    },
    isAllFetchEnabled,
  );
  const { setPopup } = usePopup();

  useEffect(() => {
    if (data && !isFetching) {
      setPopup(null);
      goToForm(
        data.length
          ? state => ({
              ...state,
              target: 'counsel',
              counseling_logs: convertCounselToTargets(data),
              filter: convertCounselFilterToMessageFilter(selectedFilter, searchKeyword),
            })
          : undefined, // 빈 목록일 때 갱신 안함
      );
    }
  }, [data, isFetching, goToForm, setPopup, selectedFilter, searchKeyword]);

  const confirmTargets = () => {
    if (isAllSelected) {
      setPopup(<ProcessingPopupContents title="선택 회원 추가중" />);
      setIsAllFetchEnabled(true);
      return;
    }

    const selectedMember = getValues('selected');
    goToForm(
      selectedMember.length
        ? state => ({
            ...state,
            target: 'counsel',
            counseling_logs: selectedMember,
            filter: convertCounselFilterToMessageFilter(selectedFilter, searchKeyword),
          })
        : undefined, // 빈 목록일 때 갱신 안함
    );
  };

  return <FooterButton onClick={confirmTargets}>{MESSAGE_TARGET_TEXT.confirm}</FooterButton>;
};

export default CompleteButton;
