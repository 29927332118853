import Typography from 'components/Typography';
import {
  PrimaryColorTypography,
  RuleTypeAccordionHeader,
  StyledRuleTypeTimeTypography,
} from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TEXT } from 'pages/Booking/constants';
import { GroupRuleTypeCommonProps } from 'pages/Booking/Group/types';
import { addZero } from 'utils/getNumberWheel';

type Props = {
  startHour: number;
  startMin: number;
  deadHour: number;
  deadMin: number;
  isOpen: boolean;
  textType: GroupRuleTypeCommonProps['textType'] | 'checkIn';
};

const RRTypeAccordionHeader = ({ isOpen, startHour, startMin, deadHour, deadMin, textType }: Props) => {
  return (
    <div>
      <RuleTypeAccordionHeader>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{addZero(startHour)}</PrimaryColorTypography>
          <Typography span size={15}>
            시간
          </Typography>
        </StyledRuleTypeTimeTypography>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{addZero(startMin)}</PrimaryColorTypography>
          <Typography span size={15}>
            분 전부터
          </Typography>
        </StyledRuleTypeTimeTypography>
      </RuleTypeAccordionHeader>
      <RuleTypeAccordionHeader>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{addZero(deadHour)}</PrimaryColorTypography>
          <Typography span size={15}>
            시간
          </Typography>
        </StyledRuleTypeTimeTypography>
        <StyledRuleTypeTimeTypography>
          <PrimaryColorTypography isOpen={isOpen}>{addZero(deadMin)}</PrimaryColorTypography>
          <Typography span size={15}>
            분 전{BOOKING_AVAILABLE_TIME_SETTING_TEXT[textType]}
          </Typography>
        </StyledRuleTypeTimeTypography>
      </RuleTypeAccordionHeader>
    </div>
  );
};

export default RRTypeAccordionHeader;
