import styled from '@emotion/styled';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import { BottomWrapper } from 'components/Form';
import Icon from 'components/Icon';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import MainLayout from 'sharedComponents/MainLayout';

import FilterResetDialog from './FilterResetDialog';
import { FiltersCommonProps } from './types';

const WholeFilters = ({ children, onClose, onReset, onSave, setTempParams }: FiltersCommonProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { setToast } = useToast();

  const handleReset = () => {
    onReset();
    setIsOpenDialog(false);
    onClose();
    setTempParams?.();
    setToast({ type: 'success', message: '필터가 초기화되었습니다.', bottom: 68 });
  };

  return (
    <>
      <MainLayout
        header={{
          title: '전체 필터',
          leftButton: (
            <IconButton onClick={onClose}>
              <Icon name="headerClose" />
            </IconButton>
          ),
          rightButton: (
            <Button fontSize={15} fontWeight={600} lineHeight={22} textColor="gray3" onClick={() => setIsOpenDialog(true)}>
              초기화
            </Button>
          ),
        }}>
        <Container>{children}</Container>

        <BottomWrapper>
          <Button fullWidth size="large56" variant="contained" color="point" onClick={onSave}>
            필터 적용
          </Button>
        </BottomWrapper>
      </MainLayout>

      {isOpenDialog && <FilterResetDialog onClose={() => setIsOpenDialog(false)} onReset={handleReset} />}
    </>
  );
};

export default WholeFilters;

const Container = styled.div`
  min-height: 100%;
  padding-bottom: 135px;
`;
