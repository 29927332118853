import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  roleId: number;
};

const usePostRolePermissions = ({ roleId }: Props) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<RolePermissionsParams>({
    method: 'post',
    url: `/api/role/${roleId}/permissions`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['permission', roleId],
      });
    },
  });
};

export default usePostRolePermissions;

type RolePermissionsParams = {
  permissions: number[];
};
