import styled from '@emotion/styled';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import CheckRadioTypography from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioTypography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import { MemberCounselSelectedFilterType } from 'pages/Member/types';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterProps } from 'sharedComponents/Filters/types';

type CounselStaffForm = {
  selectedStaffId: string;
};

const StaffFilter = ({ selectedOption, onClick: changeOptions }: FilterProps) => {
  const prevSelectedStaffs = selectedOption as MemberCounselSelectedFilterType['staffs'];
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(prevSelectedStaffs?.id.toString() ?? 'all');
  const { data: staffs } = useGetFilterStaffs();

  const { control } = useForm<CounselStaffForm>({
    defaultValues: {
      selectedStaffId: selectedStaffId ?? 'all',
    },
  });

  const onClose = () => {
    setIsOpen(false);
  };

  const changeSelectStaff = (e: ChangeEvent<HTMLInputElement>) => {
    const targetStaffId = e.target.value;
    setSelectedStaffId(targetStaffId);
    const counselStaff = getStaffInfo(targetStaffId);

    changeOptions({ value: counselStaff, label: counselStaff.name }, 'staffs');
    onClose();
  };

  const data = useMemo(() => {
    const filterStaffs = staffs.map(({ id, avatar, name }) => {
      const staffId = id.toString();
      return {
        id: staffId,
        label: (
          <AvatarLabel
            key={id}
            id={staffId}
            selectStaffId={selectedStaffId.toString()}
            imgUrl={avatar}
            label={name}
            showLabelAll
          />
        ),
        value: staffId,
      };
    });
    const filterAll = [
      {
        id: 'all',
        label: <CheckRadioTypography isSelected={selectedStaffId === 'all'}>전체</CheckRadioTypography>,
        value: 'all',
      },
    ];

    return filterAll.concat(filterStaffs);
  }, [selectedStaffId, staffs]);

  const selectButtonLabel = useMemo(() => prevSelectedStaffs?.name || '전체', [prevSelectedStaffs]);

  const getStaffInfo = useCallback(
    (staffId: string) => {
      const findStaff = staffs.find(staff => String(staff.id) === staffId);
      return { id: findStaff?.id, name: findStaff?.name || '전체' };
    },
    [staffs],
  );

  return (
    <Container>
      <Typography size={15} weight={700}>
        담당스태프
      </Typography>
      <StaffSelectButton icon={<Icon name="arrowBottom" color="gray2" />} onClick={() => setIsOpen(true)}>
        <Typography textColor="gray1" size={15} weight={500}>
          {selectButtonLabel}
        </Typography>
      </StaffSelectButton>
      <CheckRadioDrawer
        header={<OneLinedTitle title="담당스태프 선택" />}
        isOpen={isOpen}
        onClose={onClose}
        control={control}
        name="selectedStaffId"
        data={data}
        onClick={changeSelectStaff}
      />
    </Container>
  );
};

export default StaffFilter;

const Container = styled.div`
  padding: 20px 20px 24px;

  .select-drawer {
    margin-top: 16px;
  }
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;
