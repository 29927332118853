import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentMemberAtom, pointFilterPeriodAtom, pointFiltersAtom, pointStaffsFilterOptionsAtom } from 'recoil/MemberDetail';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import DeleteMemberDialog from './components/DeleteMemberDialog';
import DetailInfo from './components/DetailInfo';
import MemberDropDown from './components/MemberDropDown';
import MemberInfo from './components/MemberInfo';
import MemberInfoSkeleton from './components/MemberInfoSkeleton';
import { MemberDropDownValueType } from './types';

/** 다른 페이지 이동 시, 초기화가 필요한 회원 상세 페이지에서 사용하는 recoil state  */
export const memberDetailStateToReset = [
  // 포인트 내역
  pointFiltersAtom,
  pointFilterPeriodAtom,
  pointStaffsFilterOptionsAtom,
];

const MemberDetail = () => {
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const currentMemberId = useParamsId();

  const currentMember = useRecoilValue(currentMemberAtom);

  const resetAllMemberDetailRecoilState = useResetAllRecoilState(memberDetailStateToReset);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const clickDropDownContents = (value: MemberDropDownValueType['value']) => {
    switch (value) {
      case 'update':
        navigate(`/member/form/update/${currentMemberId}`);
        break;
      case 'delete':
        setIsOpenDialog(true);
    }
    setIsOpenDropDown(false);
  };

  useEffect(() => {
    if (!currentMemberId) {
      navigate(-1);
    }
  }, [currentMemberId, navigate]);

  useEffect(() => {
    return () => {
      resetAllMemberDetailRecoilState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      header={{
        title: getEllipsisByLength({ text: currentMember?.name || '', max: 10 }),
        titleScrollThreshold: 144,
        rightButton: (
          <MemberDropDown isOpen={isOpenDropDown} setIsOpen={setIsOpenDropDown} onClick={clickDropDownContents}>
            <IconButton onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
              <Icon name="headerMore" />
            </IconButton>
          </MemberDropDown>
        ),
      }}
      resetScrollTrigger={getSearchParams('tab') || 'basic'}>
      <Container className="member-detail">
        <ApiBoundary fallback={<MemberInfoSkeleton />}>
          <MemberInfo currentMemberId={currentMemberId} />
        </ApiBoundary>

        <DetailInfo currentMemberId={currentMemberId} />
      </Container>

      {isOpenDialog && <DeleteMemberDialog currentMemberId={currentMemberId} onClose={() => setIsOpenDialog(false)} />}
    </MainLayout>
  );
};

export default MemberDetail;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  min-height: 100%;

  hr {
    flex: 0 0 auto;
  }
`;
