import styled from '@emotion/styled';
import { UseMutateFunction } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

import { MESSAGE_DETAIL_TEXT } from '../constants';

type Props<T extends AxiosResponse> = {
  message: {
    count: number;
    sendOn: string;
    point?: number;
  };
  editMessage: () => void;
  cancelMutate: UseMutateFunction<T>;
};

/**
 * 메세지 상세 우측 상단 더보기 메뉴
 * 예약 메세지 수정 / 취소
 */
const DetailEditCancelMenu = <T extends AxiosResponse>({ message, editMessage, cancelMutate }: Props<T>) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const cancelMessage = () => {
    cancelMutate(undefined, {
      onSuccess: () => {
        setToast({ type: 'success', message: MESSAGE_DETAIL_TEXT.cancel.success });
      },
      onError: error => {
        errorDialog.open(error);
      },
      onSettled: () => {
        setIsDrawerOpened(false);
      },
    });
  };

  return (
    <>
      <Button
        size="medium48"
        widthSize={128}
        fontSize={15}
        fontWeight={500}
        padding={{ left: 16, right: 12 }}
        gap={32}
        textColor="gray2"
        rightIcon={<Icon name="writing2" size={16} color="gray2" />}
        onClick={editMessage}>
        {MESSAGE_DETAIL_TEXT.edit.button}
      </Button>
      <Divider thin />
      <Button
        size="medium48"
        widthSize={128}
        fontSize={15}
        fontWeight={500}
        padding={{ left: 16, right: 12 }}
        gap={32}
        textColor="secondary3"
        rightIcon={<Icon name="messageCancel" size={16} color="secondary3" />}
        onClick={() => setIsDrawerOpened(true)}>
        {MESSAGE_DETAIL_TEXT.cancel.button}
      </Button>
      <DoubleButtonDrawer
        type={3}
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        leftButton={{ text: MESSAGE_DETAIL_TEXT.cancel.cancel, onClick: () => setIsDrawerOpened(false) }}
        rightButton={{ text: MESSAGE_DETAIL_TEXT.cancel.ok, onClick: cancelMessage }}
        header={
          <TwoLinedTitle
            title={
              <Title size={19} weight={700}>
                총 <em>{filters.numberComma(message.count)}</em>건의 <br />
                예약 메시지를 취소하시겠습니까?
              </Title>
            }
          />
        }>
        <MessageSummary gap={6}>
          <InfoFlexRow>
            <InfoTypography>{MESSAGE_DETAIL_TEXT.cancel.count}</InfoTypography>
            <InfoTypography weight={500}>{`${filters.numberComma(message.count)}건`}</InfoTypography>
          </InfoFlexRow>
          <InfoFlexRow>
            <InfoTypography>{MESSAGE_DETAIL_TEXT.cancel.sendOn}</InfoTypography>
            <InfoTypography weight={500}>{`[예약] ${filters.dateTimeWithWeekday(message.sendOn)}`}</InfoTypography>
          </InfoFlexRow>
          {message?.point && (
            <InfoFlexRow>
              <InfoTypography>{MESSAGE_DETAIL_TEXT.cancel.refundPoint}</InfoTypography>
              <InfoTypography
                weight={600}
                textColor="primary">{`${filters.numberComma(Math.abs(message.point))}P`}</InfoTypography>
            </InfoFlexRow>
          )}
        </MessageSummary>
      </DoubleButtonDrawer>
    </>
  );
};

export default DetailEditCancelMenu;

const Title = styled(Typography)`
  em {
    color: ${theme.color.primary};
    font-weight: inherit;
  }
`;

const MessageSummary = styled(InfoFlexWrapper)`
  padding: 18px 20px;
  border-radius: 12px;
  background-color: ${theme.color.gray7};
`;
