import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const useDeleteStaff = (targetStaffId: number | null) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'delete',
    url: `/api/staff/${targetStaffId}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default useDeleteStaff;
