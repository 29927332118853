import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import usePatchLectureGroup from 'hooks/service/mutations/usePatchLectureGroup';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { formatBookingGroupUpdateParams } from 'pages/Booking/utils';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingGroupUpdateFormType } from '../../types';
import CommonGroupForm from '../components/CommonGroupForm';

type Props = {
  currentLecture: LectureDetailResponse;
};

const GroupUpdateForm = ({ currentLecture }: Props) => {
  const { handleSubmit } = useFormContext<BookingGroupUpdateFormType>();
  const [isOpenDuplicateErrorDialog, setIsOpenDuplicateErrorDialog] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: groupUpdateMutate } = usePatchLectureGroup(currentLecture.id);

  const submit = (is_force?: boolean) => (values: BookingGroupUpdateFormType) => {
    setIsOpenDuplicateErrorDialog(false);

    const params = {
      ...formatBookingGroupUpdateParams(values),
      is_force,
    };
    groupUpdateMutate(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setIsOpenDuplicateErrorDialog(true);
        } else {
          errorDialog.open(error.response?.data.message);
        }
      },
    });
  };

  return (
    <>
      <CommonGroupForm pageMode="update" currentLecture={currentLecture} onSubmit={handleSubmit(submit(false))}>
        <BookingRangeDate isRange={false} />
        <BookingRangeTime pageMode="update" />
      </CommonGroupForm>
      {isOpenDuplicateErrorDialog && (
        <Dialog
          onClose={() => setIsOpenDuplicateErrorDialog(false)}
          negativeAction={{ text: '취소' }}
          positiveAction={{ text: '계속', onClick: handleSubmit(submit(true)) }}>
          <DialogDescription>
            해당 시간대에 다른 수업이 있습니다. <br />
            계속 하시겠습니까?
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default GroupUpdateForm;
