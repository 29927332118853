import { useIsMutating } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteLectureGroup from 'hooks/service/mutations/useDeleteLectureGroup';
import useDeleteLectureGroupAll from 'hooks/service/mutations/useDeleteLectureGroupAll';
import useDeleteLectureGroupBulk from 'hooks/service/mutations/useDeleteLectureGroupBulk';
import useDeleteLecturePrivate from 'hooks/service/mutations/useDeleteLecturePrivate';
import useDeleteLecturePrivateAll from 'hooks/service/mutations/useDeleteLecturePrivateAll';
import useDeleteLecturePrivateBulk from 'hooks/service/mutations/useDeleteLecturePrivateBulk';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { BOOKING_DETAIL_DELETE_TEXT } from 'pages/Booking/constants';
import { BookingType } from 'pages/Booking/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

import { BOOKING_DETAIL_DELETE_DRAWER_LIST } from '../constants';
import MoreButtonListDrawer from './MoreButtonListDrawer';

type Props = {
  currentLecture: LectureDetailResponse;
  bookingType: BookingType;
  isPastLecture: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const LectureDelete = ({ currentLecture, bookingType, isPastLecture, isOpen, onClose }: Props) => {
  const [selectedOption, setSelectedOption] = useState<'delete' | 'deleteAfterDate' | 'deleteAll' | 'deleteBeforeLecture' | null>(
    null,
  );

  const isDeleting = useIsMutating({ mutationKey: ['lecture', 'delete'] });
  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const errorDialog = useErrorDialog();
  const { mutate: deletePrivateSingle } = useDeleteLecturePrivate(currentLecture.id);
  const { mutate: deletePrivateBulk } = useDeleteLecturePrivateBulk(currentLecture);
  const { mutate: deletePrivateAll } = useDeleteLecturePrivateAll(currentLecture);
  const { mutate: deleteGroupSingle } = useDeleteLectureGroup(currentLecture.id);
  const { mutate: deleteGroupBulk } = useDeleteLectureGroupBulk(currentLecture);
  const { mutate: deleteGroupAll } = useDeleteLectureGroupAll(currentLecture);

  const mutateCallback = () => {
    const message = BOOKING_DETAIL_DELETE_TEXT.successMessage;

    return {
      onSuccess: () => {
        navigate('/schedule');
        setToast({ type: 'success', message, bottom: 76 });
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    };
  };

  const deleteLecture = () => {
    switch (selectedOption) {
      case 'delete':
      case 'deleteBeforeLecture':
        if (bookingType === 'private') {
          deletePrivateSingle(undefined, mutateCallback());
        } else {
          deleteGroupSingle(undefined, mutateCallback());
        }
        break;
      case 'deleteAfterDate': {
        const start = currentLecture.start_on;
        if (bookingType === 'private') {
          deletePrivateBulk({ start }, mutateCallback());
        } else {
          deleteGroupBulk({ start }, mutateCallback());
        }
        break;
      }
      case 'deleteAll':
        if (bookingType === 'private') {
          deletePrivateAll(undefined, mutateCallback());
        } else {
          deleteGroupAll(undefined, mutateCallback());
        }
        break;
    }
    setSelectedOption(null);
  };

  const clickOption = (option: string) => {
    const selectedOption =
      option === 'delete' && isPastLecture
        ? 'deleteBeforeLecture'
        : (option as 'delete' | 'deleteAfterDate' | 'deleteAll' | 'deleteBeforeLecture');
    setSelectedOption(selectedOption);
    onClose();
  };

  const close = () => {
    onClose();
    setSelectedOption(null);
  };

  useEffect(() => {
    if (isDeleting) {
      setPopup(<ProcessingPopupContents title={BOOKING_DETAIL_DELETE_TEXT.loadingTitle} />);
    } else {
      setPopup(null);
    }
  }, [isDeleting, setPopup]);

  useEffect(() => {
    if (isOpen && isPastLecture) {
      setSelectedOption('deleteBeforeLecture');
    }
  }, [isOpen, isPastLecture]);

  return (
    <>
      <MoreButtonListDrawer
        buttonList={BOOKING_DETAIL_DELETE_DRAWER_LIST}
        isOpen={!isPastLecture && isOpen}
        onClose={onClose}
        onClick={clickOption}
      />

      {!!selectedOption && (
        <Dialog
          onClose={close}
          title={BOOKING_DETAIL_DELETE_TEXT.dialog.title}
          negativeAction={{
            text: BOOKING_DETAIL_DELETE_TEXT.dialog.negativeText,
            onClick: close,
            style: 'outlined',
          }}
          positiveAction={{
            text: BOOKING_DETAIL_DELETE_TEXT.dialog.positiveText,
            onClick: deleteLecture,
          }}>
          <DialogDescription tag="pre">{BOOKING_DETAIL_DELETE_TEXT.dialog.description[selectedOption]}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default LectureDelete;
