import styled from '@emotion/styled';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { DialogInfoContent, DialogInfoItem } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import { TICKET_UPGRADE_TEXT } from 'pages/TicketDetail/constants';
import { StepTitleProps } from 'pages/TicketDetail/types';
import { useState } from 'react';

import TicketSubTitle from '../../TicketSubTitle';

type Props = StepTitleProps & {
  step: number;
};

const TitleWithButton = ({ step, title, message }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Container className="upgrade-title">
        <TicketSubTitle title={title} message={message} />
        {step === 1 && (
          <Button
            className="upgrade-button"
            size="tiny"
            variant="outlined"
            edge="circular"
            textColor="gray2"
            padding={{ left: 8, right: 8 }}
            onClick={() => setIsOpen(true)}>
            업그레이드 조건
          </Button>
        )}
      </Container>

      {isOpen && (
        <Dialog showCloseButton isInfo title={TICKET_UPGRADE_TEXT.dialog.info.title} onClose={() => setIsOpen(false)}>
          <DialogInfoContent>
            <ul>
              {TICKET_UPGRADE_TEXT.dialog.info.description.map(text => (
                <DialogInfoItem key={text}>{text}</DialogInfoItem>
              ))}
            </ul>
            <Typography textColor="gray2">{TICKET_UPGRADE_TEXT.dialog.info.annotation}</Typography>
          </DialogInfoContent>
        </Dialog>
      )}
    </>
  );
};

export default TitleWithButton;

const Container = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 auto;

  .upgrade-button {
    position: absolute;
    top: 11px;
    right: 20px;
  }
`;
