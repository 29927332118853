import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { BoardNoticeType } from './useGetBoardNotice';

/** 시설 공지사항 리스트 */
const useInfinityBoardNotice = (search?: string, sort?: 'asc' | 'desc') => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<BoardNoticeType, BoardNoticeParams>({
    url: `/api/board/notice`,
    params: {
      search,
      sort_type: sort ?? 'desc',
      paginate_type: 'simple',
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['board/notice', { search, sort }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityBoardNotice;

type BoardNoticeParams = {
  search?: string;
  sort_type: 'asc' | 'desc';
  paginate_type: 'simple' | 'detail';
};
