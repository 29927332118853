import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import MoreButtonListDrawer from 'pages/Booking/Detail/components/MoreButtonListDrawer';
import { ETC_DETAIL_UPDATE_DRAWER_LIST } from 'pages/EtcSchedule/constants';
import { useNavigate } from 'react-router-dom';

type Props = {
  currentEtcSchedule: EtcScheduleDetailResponse;
  isOpen: boolean;
  onClose: () => void;
};

const EtcUpdateListDrawer = ({ currentEtcSchedule, isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const goEtcUpdateForm = (mode: string) => {
    navigate(`/schedule/etc/form/update/${currentEtcSchedule.id}?mode=${mode}`, { state: { data: currentEtcSchedule } });
  };

  return (
    <MoreButtonListDrawer
      buttonList={
        currentEtcSchedule.is_all_day
          ? ETC_DETAIL_UPDATE_DRAWER_LIST.filter(item => item.value === 'update-all')
          : ETC_DETAIL_UPDATE_DRAWER_LIST
      }
      onClick={goEtcUpdateForm}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default EtcUpdateListDrawer;
