import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { HolidayResponse } from 'hooks/service/queries/useInfinityHoliday';
import { useMemo, useState } from 'react';
import filters from 'utils/filters';

import { HOLIDAY_SETTING_TEXT } from '../constants';
import HolidayCancelDialog from './HolidayCancelDialog';

type Props = {
  holiday: HolidayResponse;
};

dayjs.extend(isSameOrBefore);

const HolidayHistoryItem = ({ holiday }: Props) => {
  const { id, date, created_at: createdAt, deleted_at: deleteAt, not_action_auto_balance: notActionAutoBalance } = holiday;

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  /** 오늘보다 과거이면 true */
  const isPastToday = !dayjs().isSameOrBefore(date, 'day');
  const isCancel = useMemo(() => !!deleteAt, [deleteAt]);
  const isAutoDeduct = !notActionAutoBalance;

  const formatTitleText = filters.date(date);
  const formatInfoText = dayjs(createdAt).format('YYYY.MM.DD HH:mm');

  const isShowCancelTag = useMemo(() => {
    if (!isPastToday) return true;
    if (isCancel) return true;
    return false;
  }, [isPastToday, isCancel]);

  return (
    <>
      <Container isCancel={isCancel}>
        <div className="title-info-wrapper">
          <div className="title-wrapper">
            <Typography className="text-style" size={15} weight={500} textColor={isCancel ? 'gray3' : 'gray1'}>
              {formatTitleText}
            </Typography>

            {isShowCancelTag && (
              <Button
                variant="contained"
                widthSize={46}
                color="gray"
                borderRadius={2}
                size="tiny"
                disabled={isCancel}
                onClick={() => setIsCancelDialogOpen(true)}>
                {isCancel ? '취소됨' : '취소'}
              </Button>
            )}
          </div>

          <div className="auto-deduct-text">
            <Typography className="text-style" textColor={isCancel ? 'gray3' : 'gray2'}>
              {formatInfoText}
              <span className="vertical-line">|</span>
              {isAutoDeduct
                ? HOLIDAY_SETTING_TEXT.autoDeductionEnabled.enabled
                : HOLIDAY_SETTING_TEXT.autoDeductionEnabled.notEnabled}
            </Typography>
          </div>
        </div>
      </Container>

      <HolidayCancelDialog id={id} date={date} isOpen={isCancelDialogOpen} onClose={() => setIsCancelDialogOpen(false)} />
    </>
  );
};

export default HolidayHistoryItem;

const Container = styled.div<{ isCancel: boolean }>(
  css`
    ${theme.flex('row', 'center', 'space-between')};
    padding: 16px;
    border: 1px solid ${theme.color.gray6};
    border-radius: 12px;

    .title-info-wrapper {
      ${theme.flex('column', '', '', 2)};
      width: 100%;

      .title-wrapper {
        ${theme.flex('row', 'center', 'space-between')};
      }

      .auto-deduct-text .vertical-line {
        margin: 0 6px;
        font-size: 1.3rem;
        color: ${theme.color.gray4};
      }
    }

    .text-button {
      background-color: ${theme.color.white};
      border: 1px solid ${theme.color.gray5};
      border-radius: 3px;
    }
  `,

  ({ isCancel }) =>
    isCancel &&
    css`
      .title-info-wrapper .text-style {
        text-decoration: ${isCancel && 'line-through'};
      }

      .text-button {
        border-color: ${theme.color.gray6};
        background-color: ${theme.color.gray6};

        span {
          color: ${theme.color.gray3};
        }
      }
    `,
);
