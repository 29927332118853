import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import usePatchRole from 'hooks/service/mutations/usePatchRole';
import usePostRole from 'hooks/service/mutations/usePostRole';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { CustomError } from 'types/errorTypes';

import { SUCCESS_MESSAGE } from '../../constants';
import { DisplayNameType, RoleRemoveType, RoleUpdateType } from '../types';

type Props = {
  roleIndex: number;
  role: DisplayNameType;
  inputName: string;
  remove: RoleRemoveType;
  update: RoleUpdateType;
};

const ModifyIconGroup = ({ roleIndex, role, inputName, remove, update }: Props) => {
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const { mutate: postRoleMutate } = usePostRole();
  const { mutate: patchRoleMutate } = usePatchRole({ roleId: role.roleId });

  const updateRole = () => {
    if (role.isNew) {
      const params = {
        staff_id: undefined,
        display_name: inputName,
        is_immutable: false,
      };

      postRoleMutate(params, {
        onSuccess: ({ data: roleId }) => {
          setToast({ type: 'success', message: SUCCESS_MESSAGE.post });
          update(roleIndex, {
            ...role,
            roleId: roleId as number,
            displayName: inputName,
            isModify: false,
            isNew: false,
          });
        },
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    } else {
      const params = {
        display_name: inputName,
      };

      patchRoleMutate(params, {
        onSuccess: () => {
          setToast({ type: 'success', message: SUCCESS_MESSAGE.patch });
          update(roleIndex, {
            ...role,
            displayName: inputName,
            isModify: false,
          });
        },
        onError: (error: CustomError) => {
          errorDialog.open(error);
        },
      });
    }
  };

  const removeRole = () => {
    if (role.isNew) {
      remove(roleIndex);
    } else {
      update(roleIndex, {
        ...role,
        isModify: false,
      });
    }
  };

  return (
    <Container>
      <IconButton className="select-check" onClick={updateRole}>
        <Icon name="selectCheckBold" size={16} fillColor={theme.color.white} />
      </IconButton>

      <IconButton onClick={removeRole}>
        <Icon name="closeBold" fillColor={theme.color.gray3} />
      </IconButton>
    </Container>
  );
};

export default ModifyIconGroup;

const Container = styled.div`
  ${theme.flex('row', 'center', 'center', 8)};

  .select-check {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${theme.color.primary};
  }
`;
