import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { ScheduleBookingParamType } from '../type';

type StaffOptions = { id: number | string; name: string; imgUrl: string | undefined };

type Props = {
  selectedOption: FilterOptionsType['value'];
  isOpen: boolean;
  staffOptions: FilterOptionsType[];
  onClose: () => void;
  onSave: (option: FilterOptionsType, key: string) => void;
};

const ScheduleStaffDrawer = ({ selectedOption, isOpen, staffOptions, onClose, onSave: changeOptions }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const prevSelectedStaffs = (selectedOption as ScheduleBookingParamType['staffs']) || [];
  const [options, setOptions] = useState<StaffOptions[]>([]);
  const [tempSelectedOption, setTempSelectedOption] = useState<StaffOptions[]>(prevSelectedStaffs);

  const isAllChecked = tempSelectedOption.length === staffOptions.length;
  const isOnlySomeChecked = !isAllChecked && tempSelectedOption.length > 0;

  const changeStaffOptions = () => {
    changeOptions({ value: tempSelectedOption, label: '강사 전체' }, 'staffs');
    onClose();
  };

  const changeTempSelectedOption = (value: StaffOptions[]) => {
    setTempSelectedOption(value);
  };

  useEffect(() => {
    const staffInfos = staffOptions.map(staffOption => staffOption.value as StaffOptions);
    setOptions(staffInfos);
  }, [staffOptions]);

  return (
    <StyleSingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="강사 선택" />}
      button={{ text: '선택 완료', onClick: () => changeStaffOptions(), disabled: !tempSelectedOption?.length }}>
      <Container isOnlySomeChecked={isOnlySomeChecked}>
        <CheckBox
          variant="box"
          className="header-checkbox"
          id="all"
          label="전체"
          labelFontsize={17}
          labelFontWeight={500}
          gap={16}
          value="all"
          checked={isAllChecked}
          iconName={isOnlySomeChecked ? 'lineBold' : 'selectCheckBold'}
          checkColor={isOnlySomeChecked || isAllChecked ? 'white' : 'gray5'}
          onChange={e => {
            if (!e.target.checked) {
              changeTempSelectedOption([]);
            } else {
              changeTempSelectedOption(options);
            }
          }}
        />
        <Divider thin thinColor="gray6" />
        <StyledUl>
          {options.length > 0 &&
            options.map(staff => {
              const checked = tempSelectedOption.find(targetStaff => targetStaff.id === staff.id);

              return (
                <li key={staff.id}>
                  <CheckBox
                    id={`${staff.id}`}
                    label={
                      <div className="staff-label-wrapper">
                        <Avatar size={24} imgUrl={staff.imgUrl} />
                        <div className="staff-label">
                          <Typography size={17} weight={500}>
                            {staff.name}
                          </Typography>
                          {currentStaffId === staff.id && (
                            <Typography textColor="gray3" weight={600}>
                              나
                            </Typography>
                          )}
                        </div>
                      </div>
                    }
                    gap={16}
                    checked={!!checked}
                    onChange={e => {
                      const staffValue = {
                        id: staff.id,
                        name: staff.name,
                        imgUrl: staff.imgUrl,
                      };
                      if (e.target.checked) {
                        changeTempSelectedOption([...tempSelectedOption, staffValue]);
                      } else {
                        changeTempSelectedOption([...tempSelectedOption.filter(targetStaff => targetStaff.id !== staff.id)]);
                      }
                    }}
                  />
                </li>
              );
            })}
        </StyledUl>
      </Container>
    </StyleSingleButtonDrawer>
  );
};

export default ScheduleStaffDrawer;

const StyleSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0;
  padding-right: 0;
`;

const Container = styled.div<{ isOnlySomeChecked: boolean }>`
  padding: 0 20px;

  hr {
    margin-top: 12px;
  }

  .header-checkbox {
    padding: 8px 0px;
    .input-span-style {
      border-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'gray5']};
      background-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'white']};
    }
  }
`;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding: 12px 20px;

  li {
    padding: 8px 0;
  }

  .staff-label-wrapper {
    ${theme.flex('', 'center', '', 8)}

    .staff-label {
      ${theme.flex('', 'center', '', 4)}
    }
  }
`;
