import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';

type Props = {
  start_date: string;
  end_date: string;
  /**
   * 빈 배열일 경우 staff 전체 필터 적용
   */
  staff_ids: number[];
  enabled: boolean;
};

const useGetAllScheduleEtcSchedule = ({ enabled, ...params }: Props) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<EtcScheduleResponse, EtcScheduleParams>({
    url: '/api/schedule/etcSchedule',
    params: {
      ...params,
    },
  });

  return useQuery({
    queryKey: ['schedule/etcSchedule', { ...params, enabled }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllScheduleEtcSchedule;

export type EtcScheduleResponse = EtcScheduleType[];

type EtcScheduleType = {
  id: number;
  etc_schedule_id: number;
  studio_id: number;
  title: string;
  color: string;
  start_on: string;
  end_on: string;
  weekday: number;
  is_repeat: boolean;
  is_overlap: boolean;
  is_private: boolean;
  is_all_day: boolean;
  created_at: string;
  updated_at: string;
  staffs: StaffsType[];
};

type StaffsType = {
  id: number;
  studio_id: number;
  name: string;
  mobile: string;
  account_id: number;
  account_type: 'staffAccount' | 'userAccount' | null;
  created_at: string;
  deleted_at: string | null;
  pivot: PivotType;
  registered_at: string;
  updated_at: string;
  user_grade_id: number | null;
  vaccination_yn: 'Y' | 'N';
};

type PivotType = {
  etc_schedule_time_id: number;
  staff_id: number;
};

export type EtcScheduleParams = Omit<Props, 'enabled'>;
