import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 프라이빗 이후 모든 수업 수정 (코스 수정) */
const BookingPrivateUpdateAll = () => {
  const currentLectureId = useParamsId();

  return (
    <ApiBoundary>
      <FormMain currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingPrivateUpdateAll;
