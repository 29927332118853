import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 1:1 문의 댓글 수정 */
const usePatchBoardQna = (questionId: number, commentId: number) => {
  const queryClient = useQueryClient();
  const { mutationFn } = useMutationFnWithCommonParams<BoardQnaParams>({
    method: `patch`,
    url: `/api/board/qna/${commentId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`board/qna`, questionId] });
    },
  });
};

export default usePatchBoardQna;

type BoardQnaParams = {
  contents: string;
};
