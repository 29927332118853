import _ from 'lodash';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

export const getSchedulesParam = (schedules: ScheduleFormType[]) => {
  return schedules.flatMap(({ isClosed, type, day_of_week, start_time, end_time }, __, list) => {
    // 휴무일 관련 데이터는 아예 전송하지 않아야함
    if (isClosed) return [];
    if (type === 'restTime') {
      const filteredList = list.filter(item => !!item.isClosed);
      // 남아있는 데이터 중, 해당 요일의 workTime이 없다면 없는 요일의 restTime 삭제
      const isCheckApplyDay = _.findIndex(filteredList, { day_of_week }) > -1;
      if (isCheckApplyDay) return [];
    }
    return {
      type,
      date: null,
      day_of_week,
      start_time,
      end_time,
    };
  });
};
