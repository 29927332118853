import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { Fragment } from 'react';
export interface ITooltipProps {
  /** 툴팁 보여줄 대상 (툴팁 타겟) */
  children: React.ReactNode;
  /** 툴팁 텍스트 */
  message: string[];
  /** 툴팁 노출 여부 */
  isOpen: boolean;
  /** 툴팁 max-width */
  maxWidth?: number;
  /** 툴팁 위치 (화살표 위치) */
  position: 'left' | 'center' | 'right';
  /** 툴팁 닫기 버튼 노출 여부 */
  showCloseButton?: boolean;
  /** 툴팁 타겟과 툴팁의 사이의 거리  */
  bottom?: number;
  /** 툴팁 닫기 로직 */
  onClose?: () => void;
  className?: string;
}

const Tooltip = ({ children, message, onClose, ...props }: ITooltipProps) => {
  return (
    <Container className="tooltip" {...props}>
      <div className="tooltip-target">{children}</div>
      <div className="tooltip-contents-container">
        <div className="tooltip-contents-wrapper">
          <span className="tooltip-arrow-icon-wrapper">
            <Icon name="tooltipArrowTopFill" size={12} fillColor={theme.color.primary} />
          </span>
          <span className="tooltip-typography-wrapper">
            <Typography textColor="white" size={13} weight={500}>
              {message.map((item, index) => (
                <Fragment key={index}>
                  {item}
                  <br />
                </Fragment>
              ))}
            </Typography>
          </span>
          {props.showCloseButton && (
            <span className="tooltip-close-button-wrapper">
              <IconButton onClick={onClose}>
                <Icon name="closeBold" size={16} fillColor={theme.color.white} />
              </IconButton>
            </span>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Tooltip;

const Container = styled.div<Omit<ITooltipProps, 'children' | 'message' | 'onClose'>>(
  /** 공통 스타일 */
  ({ isOpen }) => css`
    position: relative;

    .tooltip-contents-container {
      display: ${isOpen ? 'block' : 'none'};
      padding-top: 3px;
      position: absolute;
      z-index: ${Z_INDEX.tooltip};
      box-shadow: 1px 3px 8px rgba(145, 145, 145, 0.32);

      .tooltip-contents-wrapper {
        ${theme.flex('', '', 'space-between', 16)};
        width: max-content;
        padding: 8px 12px;
        background-color: ${theme.color.primary};
        border-radius: 8px;
      }

      .tooltip-arrow-icon-wrapper {
        position: absolute;
        top: 0px;

        svg {
          display: block;
        }
      }

      .tooltip-typography-wrapper {
        text-align: left;
      }

      .tooltip-close-button-wrapper {
        svg {
          opacity: 0.8;
        }
      }
    }
  `,

  /** 툴팁 위치 */
  ({ position, bottom }) => {
    switch (position) {
      case 'left':
        return css`
          .tooltip-contents-container {
            transform: translate(-23px, ${bottom || 3}px);

            .tooltip-arrow-icon-wrapper {
              left: 24px;
            }
          }
        `;
      case 'right':
        return css`
          .tooltip-contents-container {
            transform: translate(-23px, ${bottom || 3}px);

            .tooltip-arrow-icon-wrapper {
              right: 24px;
            }
          }
        `;
      case 'center':
        return css`
          .tooltip-contents-container {
            left: 50%;
            transform: translate(-50%, ${bottom || 3}px);

            .tooltip-arrow-icon-wrapper {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        `;
    }
  },

  /** 툴팁 닫기 버튼 있을 때 */
  ({ showCloseButton }) =>
    showCloseButton &&
    css`
      .tooltip-contents-container .tooltip-contents-wrapper {
        padding: 8px 8px 8px 12px;
      }
    `,

  /** maxWidth 있을 때 */
  ({ maxWidth }) =>
    maxWidth &&
    css`
      .tooltip-contents-container .tooltip-contents-wrapper {
        max-width: ${maxWidth}px;
      }
    `,
);
