import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Counter from 'components/Counter';
import Radio from 'components/Radio';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TicketPaymentFormType } from '../types';
import { PaymentRow } from './StyledComponents';

const Installment = () => {
  const { control } = useFormContext<TicketPaymentFormType>();

  const installment = useWatch({ control, name: 'installment' });
  const isCheckedCard = useWatch({ control, name: 'isCheckedCard' });

  return (
    <>
      <PaymentRadioRow>
        <Controller
          name="installment"
          control={control}
          render={({ field: { value, ...field } }) => (
            <Radio
              id="일시불"
              label="일시불"
              checked={value === 'single'}
              disabled={!isCheckedCard}
              value={'single'}
              {...field}
            />
          )}
        />
      </PaymentRadioRow>
      <PaymentRadioRow className="split">
        <div className="left">
          <Controller
            name="installment"
            control={control}
            render={({ field: { value, ...field } }) => (
              <Radio id="할부" label="할부" disabled={!isCheckedCard} checked={value === 'split'} value={'split'} {...field} />
            )}
          />
        </div>
        <div className="right">
          <Controller
            name="payment.installment_period"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Counter
                id="installment_period"
                min={2}
                max={99}
                suffix="개월"
                disabled={!isCheckedCard || installment === 'single'}
                {...field}
              />
            )}
          />
        </div>
      </PaymentRadioRow>
    </>
  );
};

export default Installment;

const PaymentRadioRow = styled(PaymentRow)`
  height: 56px;
  padding-left: 54px;

  label {
    height: 100%;
  }

  &.split {
    padding-right: 20px;
    ${theme.flex('', 'center', 'space-between')};

    .right {
      width: min-content;

      input {
        min-width: 22px;
      }
      .typography {
        min-width: 26px;
      }
    }
  }
`;
