import { useSuspenseQuery } from '@tanstack/react-query';
import { AttachmentType } from 'api/modules/attachment';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { BoardNoticeType } from './useGetBoardNotice';

/** 시설 공지사항 상세 */
const useGetBoardNoticeDetail = (id: number) => {
  const { queryFn } = useQueryFnWithCommonParams<BoardNoticeDetailResponse>({
    url: `/api/board/notice/${id}`,
  });

  return useSuspenseQuery({
    queryKey: ['board/notice', id],
    queryFn,
    select: data => data.data,
  });
};

export default useGetBoardNoticeDetail;

export type BoardNoticeDetailResponse = BoardNoticeType & {
  attachments: AttachmentType[];
};
