import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import usePatchStaffEventTime from 'hooks/service/mutations/usePatchStaffEventTime';
import useErrorDialog from 'hooks/useErrorDialog';
import useEventScheduleFormSet from 'hooks/useEventScheduleFormSet';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import EventScheduleUpdateForm from 'pages/MoreDetails/Staff/Form/EventScheduleUpdateForm/components/EventScheduleUpdateForm';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { eventScheduleDrawerAtom, eventScheduleSelectDateAtom } from 'recoil/staff';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import filters from 'utils/filters';

import { DrawerButtonPropsType } from '../types';
import EventRestTime from './EventRestTime';
import EventScheduleDialog from './EventScheduleDialog';
import EventScheduleDropdown from './EventScheduleDropdown';

type Props = {
  eventWorkTime: ScheduleFormType;
  eventRestTimes: ScheduleFormType[];
  /**
   * 전체 특정일 근무시간/휴식시간 목록 데이터.
   * ? id로 수정/삭제하는 방식이 아니라 목록을 통으로 보내야해서 필요
   */
  allEventWorkTimes: ScheduleFormType[];
  allEventRestTimes: ScheduleFormType[];
};

const EventScheduleCard = ({ eventWorkTime, eventRestTimes, allEventWorkTimes, allEventRestTimes }: Props) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useRecoilState(eventScheduleDrawerAtom);

  const setSelectDateTexts = useSetRecoilState(eventScheduleSelectDateAtom);

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const isClosedDay = eventWorkTime.start_time === CLOSED_DAY_TIME.start && eventWorkTime.end_time === CLOSED_DAY_TIME.end;
  const workTimePeriod = filters.timePeriodForOnlyTime(eventWorkTime.start_time, eventWorkTime.end_time);

  const targetStaffId = useParamsId();
  const { mutate: patchStaffEventTimeMutate } = usePatchStaffEventTime({ targetStaffId });

  const { methods, eventTimeDataset } = useEventScheduleFormSet({ targetStaffId });

  const dropdownAction = (type: 'update' | 'remove') => {
    if (type === 'update') {
      setSelectDateTexts([dayjs(eventWorkTime.date).toDate()]);
      setIsOpenDrawer({ show: true, id: eventWorkTime.id });
    } else {
      setIsOpenDeleteDialog(true);
    }
  };

  const deleteSubmit = () => {
    const filteredWorkTimes = allEventWorkTimes.filter(time => eventWorkTime.id !== time.id);
    const filteredRestTimes = allEventRestTimes.filter(time => {
      const isDeleteData = eventRestTimes.findIndex(restTime => restTime.id === time.id) > -1;
      return !isDeleteData;
    });

    const params = {
      schedules: [...filteredWorkTimes, ...filteredRestTimes],
    };

    patchStaffEventTimeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: '특정일 근무시간이 삭제되었습니다.' });
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  const drawerButtonProps: DrawerButtonPropsType = useMemo(() => {
    return {
      leftButton: {
        text: '취소',
        variant: 'outlined',
        size: 'large56',
        onClick: () => setIsOpenDrawer({ show: false, id: 0 }),
        fullWidth: true,
      },
      rightButton: {
        text: '수정 완료',
        type: 'submit',
        variant: 'contained',
        color: 'point',
        size: 'large56',
        onClick: () => null,
        fullWidth: true,
        form: 'event-work-time-form',
        disabled: !methods.formState.isDirty,
      },
    };
  }, [setIsOpenDrawer, methods.formState.isDirty]);

  return (
    <Container isOpenDropDown={isOpenDropDown}>
      <div className="card-header">
        <Typography size={15} weight={600}>
          {dayjs(eventWorkTime.date).format('YYYY.MM.DD (ddd)')}
        </Typography>

        <EventScheduleDropdown isOpen={isOpenDropDown} setIsOpen={setIsOpenDropDown} onClick={dropdownAction}>
          <IconButton onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
            <Icon name="more" fillColor={theme.color.gray2} />
          </IconButton>
        </EventScheduleDropdown>
      </div>

      <div className="card-content">
        <div className="schedule-work-time">
          {isClosedDay ? (
            <Typography size={15} textColor="secondary3" opacity={0.8}>
              휴무일
            </Typography>
          ) : (
            <>
              <Typography size={15} textColor="gray2">
                근무시간
              </Typography>
              <Typography size={15} textColor="gray2">
                {workTimePeriod}
              </Typography>
            </>
          )}
        </div>

        {!isClosedDay && <EventRestTime restTimes={eventRestTimes} />}

        <EventScheduleDialog
          isOpen={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          positiveClick={deleteSubmit}
        />

        <StyledDoubleButtonDrawer
          isOpen={isOpenDrawer.show && isOpenDrawer.id === eventWorkTime.id}
          onClose={() => setIsOpenDrawer({ show: false, id: 0 })}
          {...drawerButtonProps}
          hideHandle>
          <FormProvider {...methods}>
            <EventScheduleUpdateForm eventTimeDataset={eventTimeDataset} />
          </FormProvider>
        </StyledDoubleButtonDrawer>
      </div>
    </Container>
  );
};

export default EventScheduleCard;

const Container = styled.li<{ isOpenDropDown: boolean }>`
  padding: 18px 20px;

  .card-header {
    ${theme.flex('row', 'center', 'space-between')};
    margin-bottom: 6px;
  }

  .card-content {
    .schedule-work-time,
    .schedule-rest-time {
      ${theme.flex('row', 'flex-start', 'flex-start', 16)};
    }

    .schedule-work-time {
      margin-bottom: 2px;
    }

    .schedule-rest-time {
      .times {
        ${theme.flex('column')};
        gap: 2px;
      }
    }
  }

  .modify-dropdown {
    ${({ isOpenDropDown }) => !isOpenDropDown && 'z-index: 0'};
  }
`;

/** DoubleButtonDrawer 커스텀 스타일 */
const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  padding: 24px 0 calc(64px + 76px);
`;
