import usePatchStaffProfile from 'hooks/service/mutations/usePatchStaffProfile';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';
import getColor from 'utils/getColor';

import StaffInfoForm from '../../components/StaffInfoForm';
import { RESPONSE_SUCCESS } from '../../constants';
import { InfoUpdateFormType } from '../../types';

const StaffUpdateForm = () => {
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();
  const targetStaffId = useParamsId();
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, dirtyFields, isDirty },
  } = useFormContext<InfoUpdateFormType>();

  const { mutate: patchStaffProfileMutate, isPending } = usePatchStaffProfile({ targetStaffId });

  const submit: SubmitHandler<InfoUpdateFormType> = inputValues => {
    const { mobile, role_id, profile } = inputValues;

    // TODO: 객체 순환 코드로 변경하면 좋을듯
    const params = {
      mobile: dirtyFields.mobile ? mobile : undefined,
      role_id: dirtyFields.role_id ? Number(role_id) : undefined,
      profile: {
        name: dirtyFields.profile?.name ? profile.name : undefined,
        gender: dirtyFields.profile?.gender ? profile.gender : undefined,
        birthday: dirtyFields.profile?.birthday ? filters.dateDash(profile.birthday) : undefined,
        registered_at: dirtyFields.profile?.registered_at ? filters.dateDashTimeSecond(profile.registered_at) : undefined,
        get_noti_all: dirtyFields.profile?.get_noti_all ? (profile.get_noti_all ? 1 : 0) : undefined,
        representative_color: dirtyFields.profile?.representative_color ? getColor(profile.representative_color) : undefined,
      },
    };

    patchStaffProfileMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: RESPONSE_SUCCESS.staffInfoUpdate });
        navigate(-1);
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <>
      {/* TODO: 로딩 처리 */}
      {isPending && <div>loading..</div>}

      <StaffInfoForm
        onSubmit={handleSubmit(submit)}
        footerButton={{ text: '수정 완료', disabled: !isValid || isSubmitting || !isDirty }}
      />
    </>
  );
};

export default StaffUpdateForm;
