import { useSetRecoilState } from 'recoil';
import { errorDialogAtom } from 'recoil/dialog';
import { CustomError } from 'types/errorTypes';

export type CustomErrorType = CustomError | string;

const useErrorDialog = () => {
  const setIsErrorDialogOpen = useSetRecoilState(errorDialogAtom);

  return {
    open: (error?: CustomErrorType) => {
      setIsErrorDialogOpen({ error, show: true });
    },
    close: () => {
      setIsErrorDialogOpen({ show: false });
    },
  };
};

export default useErrorDialog;
