import styled from '@emotion/styled';
import Icon from 'components/Icon';
import { PointSelectedFilterType, SelectedPointStaff } from 'pages/MemberDetail/types';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pointStaffsFilterOptionsAtom } from 'recoil/MemberDetail';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterProps } from 'sharedComponents/Filters/types';
import convertTargetsText from 'utils/convertTargetsText';

import StaffsSelectDrawer from './StaffSelectDrawer';

const StaffSelectFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const staffOptions = useRecoilValue(pointStaffsFilterOptionsAtom);
  const previousOptions = useMemo(() => (selectedOption as PointSelectedFilterType['selectedStaffs']) || [], [selectedOption]);

  const selectedStaffs = useMemo(() => {
    if (!previousOptions.length) {
      return staffOptions.map(item => {
        return {
          id: item.value as number,
          name: item.label,
          imgUrl: item.staffImgUrl,
          isMe: item.isMe,
        };
      });
    } else {
      return previousOptions;
    }
  }, [staffOptions, previousOptions]);

  const save = (selectedStaffs: Array<SelectedPointStaff>) => {
    const isAllSelected = staffOptions.length === selectedStaffs.length;
    changeOptions(
      {
        value: isAllSelected ? undefined : selectedStaffs,
        label: '',
      },
      'selectedStaffs',
    );
    setIsOpen(false);
  };

  return (
    <>
      <Container>
        <FilterTitle title={currentFilter.title} />
        <StaffSelectButton icon={<Icon name="arrowBottom" color="gray2" />} textColor="gray1" onClick={() => setIsOpen(true)}>
          {convertTargetsText(previousOptions, '전체')}
        </StaffSelectButton>
      </Container>

      <StaffsSelectDrawer
        selectedStaffs={selectedStaffs}
        staffOptions={staffOptions}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSave={save}
      />
    </>
  );
};

export default StaffSelectFilter;

const Container = styled.div`
  padding: 20px 20px 24px;
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;
