import styled from '@emotion/styled';
import Button from 'components/Button';
import SubTitle from 'components/SubTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffListPageAtom, staffTotalAtom } from 'recoil/staff';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ListContentHeader from 'sharedComponents/ListContentHeader';
import MainLayout, { IMainLayoutProps } from 'sharedComponents/MainLayout';

import { STAFF_PROFILE_FORM_TEXT } from '../../Form/constants';
import { DATE_SORT_FILTERS } from '../../List/constants';
import EventScheduleCardList from './components/EventScheduleCardList';

const MoreDetailsStaffEventScheduleUpdateDetail = () => {
  const navigate = useNavigate();

  const staffTotal = useRecoilValue(staffTotalAtom);
  const [pageData, setPageData] = useRecoilState(staffListPageAtom);

  const methods = useForm<{ sort: 'asc' | 'desc' }>({
    defaultValues: {
      sort: 'desc',
    },
  });

  const headerProps: IMainLayoutProps['header'] = {
    rightButton: (
      <Button fontSize={15} textColor="gray2" fontWeight={600} onClick={() => navigate('update-form')}>
        추가
      </Button>
    ),
    noUnderline: true,
  };

  const changeSort = (e: { target: { value: string } }) => {
    setPageData({ ...pageData, sort: e.target.value as 'asc' | 'desc' });
  };

  return (
    <MainLayout header={headerProps}>
      {/* TODO: 로딩 처리 */}
      <Container>
        <SubTitle
          title={STAFF_PROFILE_FORM_TEXT.subTitle.eventSchedule}
          message={{ position: 'bottom', text: STAFF_PROFILE_FORM_TEXT.desc.eventSchedule }}
        />
        <ApiBoundary fallback={<div>loading..</div>}>
          <FormProvider {...methods}>
            <ListContentHeader
              type="event"
              sortData={DATE_SORT_FILTERS}
              total={staffTotal}
              onChange={changeSort}
              sort={pageData.sort}
            />
            <EventScheduleCardList />
          </FormProvider>
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default MoreDetailsStaffEventScheduleUpdateDetail;

const Container = styled.div`
  padding-bottom: 40px;

  .sub-title {
    margin: 8px 0 40px;
    padding: 0 20px;
  }
`;
