import { theme } from 'assets/styles';
import { ScheduleCounselResponse } from 'hooks/service/queries/useGetAllScheduleCounsel';
import { EtcScheduleResponse } from 'hooks/service/queries/useGetAllScheduleEtcSchedule';
import { ScheduleLectureResponse } from 'hooks/service/queries/useGetAllScheduleLecture';
import getColor, { Color } from 'utils/getColor';

import { EVENT_CARD_TYPE } from '../constants';

/** EventCalendar data 속성에 start, end 프로퍼티는 꼭 있어야 작동함(mobiscroll) */
export const lectureDataset = (events: ScheduleLectureResponse) => {
  return events.map(({ type, id, title, start_on, end_on, current_trainee_count, max_trainee, staff, booking_closed_at }) => {
    const matchColor = (getColor(staff.profile?.representative_color) || 'red0') as Color;
    return {
      type,
      id,
      start: start_on ? new Date(start_on) : undefined,
      end: end_on ? new Date(end_on) : undefined,
      title,
      currentTraineeCount: current_trainee_count,
      maxTrainee: max_trainee,
      color: theme.color[matchColor],
      staffName: staff.name,
      isBookingClosed: !!booking_closed_at, // 예약 마감 여부. (마감시 배경 채우기 설정 용도)
    };
  });
};

export const counselDataset = (events: ScheduleCounselResponse) => {
  return events.map(({ id, start_on, end_on, name, staff }) => {
    const matchColor = (getColor(staff.profile?.representative_color) || 'red0') as Color;
    return {
      type: EVENT_CARD_TYPE.counsel,
      id,
      start: start_on ? new Date(start_on) : undefined,
      end: end_on ? new Date(end_on) : undefined,
      title: name,
      color: theme.color[matchColor],
      staffName: staff.name,
    };
  });
};

export const etcScheduleDataset = (events: EtcScheduleResponse) => {
  return events.map(({ id, title, start_on, end_on, color, is_private, is_all_day, staffs }) => {
    const matchColor = (getColor(color) || 'red0') as Color;
    return {
      type: is_all_day ? EVENT_CARD_TYPE.allDayEtcSchedule : EVENT_CARD_TYPE.etcSchedule,
      id,
      start: start_on ? new Date(start_on) : undefined,
      end: end_on ? new Date(end_on) : undefined,
      title,
      color: theme.color[matchColor],
      staffs: staffs.map(staff => staff.name),
      isPrivate: is_private,
      allDay: is_all_day,
    };
  });
};
