import { PRODUCT_TYPE_TEXT } from 'constants/text';
import { TICKET_TRANSFER_TEXT } from 'pages/TicketDetail/constants';
import { TicketTransferFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentMemberAtom } from 'recoil/MemberDetail';
import MainLayout from 'sharedComponents/MainLayout';

import FinalConfirm from './FinalConfirm';
import PaymentForm from './PaymentForm';
import SelectMember from './SelectMember';
import TicketDetailForm from './TicketDetailForm';

type Props = UserTicketProps & {
  step: number;
  onClickNextPage: () => void;
  currentMemberId: number;
};

const TicketTransfer = ({ userTicket, currentMemberId, step, onClickNextPage }: Props) => {
  const methods = useForm<TicketTransferFormType>({
    defaultValues: {
      search: '',
      tempSearchKeyword: '',
      selectedMemberId: null,
      selectedMember: null,
      currentMemberId,

      period: {
        availability_start_at: userTicket.availability_start_at,
        expire_at: userTicket.expire_at,
      },

      count: {
        max_coupon: userTicket.max_coupon,
        remaining_coupon: userTicket.remaining_coupon,
        remaining_cancel: userTicket.remaining_cancel,
      },

      installment: 'single',
      isCheckedCard: true,
      isCheckedCash: false,
      isCheckedWireTransfer: false,
      isCheckedPoint: false,
      isAutoWriteUnpaid: userTicket.policy.is_auto_write_unpaid,
      remainingPoint: 0,

      payment: {
        installment_period: 2,
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        unpaid_amount: 0,
        point_amount: 0,
        settlement_at: '',
      },
    },
  });

  const productTypeText = PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type];
  const title = TICKET_TRANSFER_TEXT.title[step - 1];
  const subTitle = TICKET_TRANSFER_TEXT.subTitle[step - 1];

  const commonProps = {
    productTypeText,
    title,
    subTitle,
    onClickNextPage,
  };

  const headerTitle = step === 4 ? '최종 확인' : step === 2 ? `${productTypeText} ${title}` : (title as string);

  const currentMember = useRecoilValue(currentMemberAtom);
  const navigate = useNavigate();
  /**
   * 양도 완료 시, 양수자의 상세페이지로 이동하는데
   * 브라우저 뒤로가기 클릭해서, 양도 4단계 페이지로 되돌아오면
   * 양도자의 회원 상세 페이지로 리다이렉션 (시설웹과 동일)
   */
  useEffect(() => {
    if (!currentMember || !currentMemberId) return;
    if (currentMember.id !== currentMemberId) {
      navigate(-5);
    }
  }, [currentMember, currentMemberId, navigate]);

  useEffect(() => {
    if (step > 1 && !methods.getValues('selectedMember')) {
      navigate(-step);
    }
  }, [methods, navigate, step]);

  return (
    <MainLayout
      progress={{ step, max: 4 }}
      header={{ title: headerTitle, titleScrollThreshold: 40 }}
      resetScrollTrigger={String(step)}>
      <FormProvider {...methods}>
        {step === 1 && <SelectMember {...commonProps} />}
        {step === 2 && <TicketDetailForm {...commonProps} userTicket={userTicket} />}
        {step === 3 && <PaymentForm {...commonProps} userTicket={userTicket} />}
        {step === 4 && <FinalConfirm {...commonProps} userTicket={userTicket} />}
      </FormProvider>
    </MainLayout>
  );
};

export default TicketTransfer;
