import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingParams } from './usePostBooking';
import { BookingFailResponse } from './usePostBookingPrivate';

/** 그룹 수업에 정원이 다 찬 경우, 대기예약 */
const usePostBookingGroupWaiting = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingParams>({
    method: `post`,
    url: `/api/booking/group/waiting`,
  });

  return useMutation<AxiosResponse, AxiosError<Array<BookingFailResponse> | CustomError>, BookingParams | undefined, unknown>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail'],
      });
    },
  });
};

export default usePostBookingGroupWaiting;
