import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { AMOUNT_FIELDS, TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import { PaymentRow } from './StyledComponents';

const Discount = () => {
  const { control, setValue } = useFormContext<TicketPaymentFormType>();
  const amount = useWatch({ control, name: 'payment.amount' });

  const [tempAmount] = useState(amount);

  /**
   * 할인율 입력 시,
   * - 카드 금액과 총 결제금액에 자동 반영
   * - 그 외 입력된 금액은 0원으로 변경
   */
  const changeAmounts = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const discount = Number(e.target.value);
      setValue('payment.discount', discount, { shouldDirty: true });

      const discountedAmount = Math.ceil(tempAmount * (1 - discount / 100));
      setValue('payment.amount', discountedAmount, { shouldDirty: true });
      setValue('payment.card_amount', discountedAmount, { shouldDirty: true });

      const otherFields = AMOUNT_FIELDS.filter(item => item !== 'payment.card_amount');
      otherFields.map(otherKeyValue => setValue(otherKeyValue, 0, { shouldDirty: true }));
    },
    [setValue, tempAmount],
  );

  return (
    <StyledPaymentRow>
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'payment.discount' }}
        inputMode="numeric"
        placeholder={TICKET_PAYMENT_FORM_TEXT.discount.placeholder}
        textAlign="right"
        left={{ prefix: <Icon name="discount" fillColor={theme.color.gray2} />, text: TICKET_PAYMENT_FORM_TEXT.discount.label }}
        suffix={
          <Typography span size={15} weight={500}>
            %
          </Typography>
        }
        onChange={changeAmounts}
      />
    </StyledPaymentRow>
  );
};

export default Discount;

const StyledPaymentRow = styled(PaymentRow)`
  border-bottom: none;

  .underlined-text-field {
    border-bottom: none;
  }
`;
