import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import AvatarGroup from 'components/Avatar/AvatarGroup';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import convertTargetsText from 'utils/convertTargetsText';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  bgColor: keyof typeof color;
  contrastColor: keyof typeof color;
  currentEtcSchedule: EtcScheduleDetailResponse;
};

/** 기타일정 상세 페이지 상단 컬러 영역  */
const ColoredDetailInfo = ({ currentEtcSchedule, bgColor, contrastColor }: Props) => {
  const { is_private, title, start_on, end_on, staffs, is_all_day } = currentEtcSchedule;
  const date = is_all_day ? filters.periodWithWeekday(start_on, end_on) : filters.dateWithWeekday(start_on);
  const time = is_all_day ? null : filters.timePeriod(start_on, end_on);

  return (
    <Container className="lecture-detail-colored-wrapper" bgColor={bgColor}>
      <TitleWrapper>
        {is_private && <Icon name="lockRegular" color={contrastColor} />}
        <Typography size={21} weight={700} textColor={contrastColor}>
          {title}
        </Typography>
      </TitleWrapper>
      <Typography weight={500} textColor={contrastColor} opacity={0.8}>
        {date} {time}
      </Typography>

      <StaffsWrapper>
        <AvatarGroup AvatarSize={16} oneAvatarSize={16}>
          {staffs.map(staff => (
            <Avatar size={16} key={staff.id} imgUrl={getImageUrl(staff.image)} />
          ))}
        </AvatarGroup>
        <Typography textColor={contrastColor} weight={500}>
          {convertTargetsText(staffs)} 강사
        </Typography>
      </StaffsWrapper>
    </Container>
  );
};

export default ColoredDetailInfo;

const Container = styled.div<{ bgColor: keyof typeof color }>(
  ({ bgColor }) => css`
    background-color: ${theme.color[bgColor]};
    padding: 8px 20px 16px;
  `,
);

const TitleWrapper = styled.div`
  ${theme.flex('row', 'center', 'flex-start', 4)};
  padding-bottom: 2px;
`;

const StaffsWrapper = styled.div`
  margin-top: 32px;
  ${theme.flex('row', 'center', 'flex-start', 4)};
`;
