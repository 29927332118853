import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import AllMemberFilter from 'pages/Member/components/AllMemberFilter';
import AllMemberDrawerFilterOptions from 'pages/Member/components/AllMemberFilter/AllMemberDrawerFilterOptions';
import AllMemberWholeFilterOptions from 'pages/Member/components/AllMemberFilter/AllMemberWholeFilterOptions';
import Sorting from 'pages/Member/components/Sorting';
import { DEFAULT_MEMBER_FILTER_OPTIONS, MEMBER_FILTER_DEFAULT_TAB_LABEL } from 'pages/Member/constants';
import { messageTargetMemberSelectedFilter } from 'recoil/message';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import Filter from 'sharedComponents/Filters';

import Counts from './Counts';

type Props = {
  messageType: 'mobile' | 'push';
};

const StickyHeader = ({ messageType }: Props) => {
  const filterAtom = messageTargetMemberSelectedFilter;

  return (
    <StickyContainer>
      <ApiBoundary
        fallback={
          <Filter
            filterAtom={filterAtom}
            filters={DEFAULT_MEMBER_FILTER_OPTIONS}
            defaultTabLabels={MEMBER_FILTER_DEFAULT_TAB_LABEL}
            renderDrawerOptions={AllMemberDrawerFilterOptions}
            renderWholeFilterPopupOptions={AllMemberWholeFilterOptions}
          />
        }>
        <AllMemberFilter filterAtom={filterAtom} hasMobile isAppLinked={messageType === 'push'} />
      </ApiBoundary>
      <Row>
        <Counts />
      </Row>
      <RightAlign>
        <Sorting filterAtom={filterAtom} />
      </RightAlign>
    </StickyContainer>
  );
};

export default StickyHeader;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.stickyTab};
  background-color: ${theme.color.white};
  padding: 1px;
  border-bottom: 1px solid ${theme.color.gray6};
`;

export const Row = styled.div`
  padding: 14px 20px;
  ${theme.flex('', 'center', 'start')}
  border-top: 1px solid ${theme.color.gray7};
  border-bottom: 1px solid ${theme.color.gray6};
`;

export const RightAlign = styled.div`
  ${theme.flex('', 'center', 'end')}
  padding: 14px 20px;
`;
