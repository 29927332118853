import { FilterType } from 'sharedComponents/Filters/types';

import { MobileSelectedFilterType } from '../../Mobile/List/type';

export const APP_PUSH_LIST_TEXT = {
  empty: '앱 푸시 내역이 없습니다.',
};

export const APP_PUSH_FILTER: FilterType = {
  key: 'period',
  type: 'period',
  title: '기간선택',
  options: [
    { label: '오늘', value: 'day' },
    { label: '이번주', value: 'isoWeek' },
    { label: '이번달', value: undefined }, // 기본값 이번달
    { label: '직접선택', value: 'custom' },
  ],
  tabConverter: value => {
    const periodValue = value as MobileSelectedFilterType['period'];
    if (!periodValue || !periodValue.type) return '이번달';
    if (periodValue.type === 'day') return '오늘';
    if (periodValue.type === 'isoWeek') return '이번주';
    return '직접선택';
  },
};

// mobile status와 통일시키기 위한 adapter
export const MESSAGE_PUSH_STATUS_ADAPTER = {
  scheduled: 'request',
  sended: 'sent',
  canceled: 'cancel',
};
