import { RecoilState } from 'recoil';
import { ParamsType } from 'sharedComponents/Filters/types';

import { DEFAULT_CHECKIN_FILTER_OPTIONS, DEFAULT_CHECKIN_FILTER_TABS } from '../../constant';
import ScheduleCommonFilter from '../ScheduleCommonFilter';
import CheckinDrawerFilterOptions from './CheckinDrawerFilterOptions';
import CheckinWholeFilterOptions from './CheckinWholeFilterOptions';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  assigned?: boolean;
  isShowMoreDetailSchedule: boolean;
};

const CheckinFilter = <T extends ParamsType>({ filterAtom, isShowMoreDetailSchedule }: Props<T>) => {
  return (
    <ScheduleCommonFilter
      filterAtom={filterAtom}
      filters={DEFAULT_CHECKIN_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_CHECKIN_FILTER_TABS}
      isShowMoreDetailSchedule={isShowMoreDetailSchedule}
      renderDrawerOptions={CheckinDrawerFilterOptions}
      renderWholeFilterPopupOptions={CheckinWholeFilterOptions}
    />
  );
};

export default CheckinFilter;
