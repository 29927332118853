import Divider from 'components/Divider';
import Form from 'components/Form';
import usePatchStaffWorkTime, { StaffWorkTimeParams } from 'hooks/service/mutations/usePatchStaffWorkTime';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import _ from 'lodash';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PrivateStartInterval from 'sharedComponents/ProfileField/components/PrivateStartInterval';
import WorkTime from 'sharedComponents/ProfileField/components/WorkTime';
import { CustomError } from 'types/errorTypes';

import { RESPONSE_SUCCESS } from '../../constants';
import { ScheduleUpdateFormType } from '../../types';

const ScheduleUpdateForm = () => {
  const navigate = useNavigate();
  const targetStaffId = useParamsId();
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = useFormContext<ScheduleUpdateFormType>();

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: patchStaffWorkTimeMutate } = usePatchStaffWorkTime({ targetStaffId });

  const submit: SubmitHandler<ScheduleUpdateFormType> = inputValues => {
    const { profile, schedules } = inputValues;

    const combineSchedules = [...schedules.restTimes, ...schedules.workTimes];

    const schedulesParams = combineSchedules
      .map(({ id, isClosed, type, day_of_week, ...rest }, index, list) => {
        // 휴무일 관련 데이터는 아예 전송하지 않아야함
        if (isClosed) return [];
        if (type === 'restTime') {
          const filteredList = list.filter(item => !!item.isClosed);
          // 남아있는 데이터 중, 해당 요일의 workTime이 없다면 없는 요일의 restTime 삭제
          const isCheckApplyDay = _.findIndex(filteredList, { day_of_week }) > -1;
          if (isCheckApplyDay) return [];
        }
        return {
          type,
          day_of_week,
          ...rest,
        };
      })
      .flat();

    const params: StaffWorkTimeParams = {
      profile: {
        ...profile,
        private_start_interval_on_off: profile.private_start_interval_on_off ? 1 : 0,
      },
      schedules: schedulesParams,
    };

    patchStaffWorkTimeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: RESPONSE_SUCCESS.schedule });
        navigate(`/more-details/staff/${targetStaffId}?tab=schedule`);
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(submit)} footerButton={{ text: '수정 완료', disabled: !isValid || isSubmitting || !isDirty }}>
      <PrivateStartInterval />
      <Divider thin />

      <WorkTime />
      <Divider thin />
    </Form>
  );
};

export default ScheduleUpdateForm;
