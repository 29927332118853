import Sorting from 'pages/Member/components/Sorting';
import React from 'react';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import Counts from '../../Member/components/Counts';
import { RightAlign, Row, StickyContainer } from '../../Member/components/StickyHeader';
import MessageCounselFilter from './MessageCounselFilter';

const StickyHeader = () => {
  return (
    <StickyContainer>
      <ApiBoundary>
        <MessageCounselFilter />
      </ApiBoundary>
      <Row>
        <Counts />
      </Row>
      <RightAlign>
        <Sorting filterAtom={messageTargetCounselSelectedFilter} />
      </RightAlign>
    </StickyContainer>
  );
};

export default StickyHeader;
