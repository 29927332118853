import styled from '@emotion/styled';
import Form from 'components/Form';
import Typography from 'components/Typography';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import CheckAutoCalculation from './CheckAutoCalculation';
import CheckBookingBeforeHolding from './CheckBookingBeforeHolding';
import HoldingPeriodPicker from './HoldingPeriodPicker';
import SubmitButton from './SubmitButton';

type Props = UserTicketProps & {
  onSubmit: (values: TicketHoldingFormType) => void;
};

const TicketHoldingForm = ({ canUpdateUserTicket, userTicket, onSubmit }: Props) => {
  const { control, handleSubmit, setValue } = useFormContext<TicketHoldingFormType>();
  const bookingCount = useWatch({ control, name: 'bookingCount' });

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!bookingCount) {
      handleSubmit(onSubmit)();
    } else {
      setValue('hasBooking', true);
    }
  };

  return (
    <>
      <FormContainer onSubmit={submit}>
        <Typography span size={15} weight={600}>
          정지기간
        </Typography>
        <HoldingPeriodPicker userTicket={userTicket} />
        <CheckAutoCalculation />
        <SubmitButton canUpdateUserTicket={canUpdateUserTicket} holding={userTicket.holdings} />
      </FormContainer>

      <ApiBoundary>
        <CheckBookingBeforeHolding onSubmit={onSubmit} />
      </ApiBoundary>
    </>
  );
};

export default TicketHoldingForm;

const FormContainer = styled(Form)`
  padding: 24px 20px;
  position: relative;

  .button-group {
    margin-top: 24px;
  }
`;
