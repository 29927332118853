import { upperCase } from 'lodash';
import { PUSH_FILTERS, PUSH_ICONS } from 'pages/Notification/constants';
import { PushFilterType, PushItemType } from 'pages/Notification/types';

import { PUSH_CATEGORY_TYPES } from '../../constants';

/** 메세지 타입별로 아이콘 결정 */
export const determinePushIcon = (pushType: PushItemType['msg_type']): keyof typeof PUSH_ICONS => {
  const currentType = upperCase(pushType);

  const findKeyword = (list: string[]) => {
    return list.some(item => currentType.includes(upperCase(item)));
  };

  if (findKeyword(PUSH_CATEGORY_TYPES['booking'])) {
    return 'booking';
  }
  if (findKeyword(PUSH_CATEGORY_TYPES['ticket'])) {
    return 'ticket';
  }
  if (findKeyword(PUSH_CATEGORY_TYPES['notice'])) {
    return 'notice';
  }
  if (findKeyword(PUSH_CATEGORY_TYPES['qna'])) {
    return 'qna';
  }
  return 'etc';
};

/**
 * string => PushFilterType으로 변환
 * api 호출시에는 "all" 대신 undefined를 넣기 위해 isExculsiveAll 추가
 */
export const getPushFilter = (filter: string, isExculsiveAll = true): PushFilterType | undefined => {
  if (isExculsiveAll && filter === 'all') return undefined;
  if (filter && Object.keys(PUSH_FILTERS).includes(filter)) {
    return filter as PushFilterType;
  }
  return isExculsiveAll ? undefined : 'all';
};

/**
 * PUSH_FILTERS value 값으로 label 텍스트 변환
 */
export const getPushFilterLabel = (value?: PushFilterType): string => {
  if (!value) return PUSH_FILTERS['all'].label;
  return PUSH_FILTERS[value].label;
};
