import useQueryString from 'hooks/useQueryString';
import { AllPageModeType, BookingCommonFormType } from 'pages/Booking/types';
import syncRepeatSetting from 'pages/Booking/utils/syncRepeatSetting';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import CustomRepeatSetting from './CustomRepeatSetting';
import RepeatSetting from './RepeatSetting';

type Props = {
  pageMode: AllPageModeType;
};

const Repeat = ({ pageMode }: Props) => {
  const { getValues, setValue } = useFormContext<BookingCommonFormType>();
  const { getSearchParams } = useQueryString();
  const isCustomSetting = getSearchParams('setting');
  const [initCustomArray] = useState(getValues('repeatCustomWeekday'));

  useEffect(() => {
    /**
     * 반복설정 페이지에서,
     * 맞춤설정 페이지에서 체크한 요일과 반복설정의 값과 맞으면 반복설정 값을 변경하고,
     * 임시로 체크한 요일들 다시 실제 체크된 요일로 세팅
     */
    if (!isCustomSetting) {
      const repeatCustomWeekday = getValues('repeatCustomWeekday');
      const value = syncRepeatSetting(repeatCustomWeekday);
      setValue('repeat', value);
      setValue('tempRepeatCustomWeekday', repeatCustomWeekday, { shouldDirty: true });
    }
  }, [getValues, isCustomSetting, setValue]);

  return (
    <>{isCustomSetting ? <CustomRepeatSetting /> : <RepeatSetting pageMode={pageMode} initCustomArray={initCustomArray} />}</>
  );
};

export default Repeat;
