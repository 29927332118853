import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import DatePicker from 'designedComponents/Pickers/DatePicker';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';
import getExpiredAtByAvailablePeriod from 'utils/getExpiredAtByAvailablePeriod';
import getTicketInfoByType from 'utils/getTicketInfoByType';

import { TicketPeriodFormType } from './types';

type ClickTarget = 'start' | 'expire';
type Props = {
  ticketType: TicketsResponse['type'];
};

const TicketPeriodForm = ({ ticketType }: Props) => {
  const {
    control,
    setValue,
    formState: { dirtyFields },
  } = useFormContext<TicketPeriodFormType>();
  const startAt = useWatch({ control, name: 'period.availability_start_at' });
  const expireAt = useWatch({ control, name: 'period.expire_at' });
  const originAvailablePeriod = useWatch({ control, name: 'originAvailablePeriod' });

  const [isOpen, setIsOpen] = useState(false);
  const [currentOpenedPicker, setCurrentOpenedPicker] = useState<ClickTarget | null>(null);
  const [date, setDate] = useState<Date | null>(null);

  const { showPeriod } = getTicketInfoByType(ticketType);

  const clickDate = useCallback(
    (target: ClickTarget, targetDate: string) => {
      //현재 열려있는 target 버튼을 다시 클릭했을 때 (아코디언 toggle과 같음)
      if (currentOpenedPicker === target) {
        setCurrentOpenedPicker(null);
        setIsOpen(false);
      } else {
        // 현재 설정중인 target 과 다른 target을 선택했을 때
        setIsOpen(true);
        setCurrentOpenedPicker(target);
        setDate(dayjs(targetDate).toDate());
      }
    },
    [currentOpenedPicker],
  );

  const changeDate = useCallback(
    (e: { value: Date }) => {
      setDate(e.value);

      const date = filters.dateDash(e.value);
      if (currentOpenedPicker === 'start') {
        setValue('period.availability_start_at', date, { shouldDirty: true });
      } else if (currentOpenedPicker === 'expire') {
        setValue('period.expire_at', date, { shouldDirty: true });
      }
    },
    [currentOpenedPicker, setValue],
  );

  /**
   * 수강권 시작일이 변경되면 종료일도 이용 가능기간에 따라 자동 변경
   * (종료일은 따로 수정 가능)
   */
  useEffect(() => {
    if (dirtyFields.period?.availability_start_at) {
      const changeExpiredAt = getExpiredAtByAvailablePeriod(originAvailablePeriod, startAt);
      setValue('period.expire_at', changeExpiredAt, { shouldDirty: true });
    }
  }, [startAt, originAvailablePeriod, setValue, dirtyFields.period?.availability_start_at]);

  const header = useMemo(() => {
    return (
      <AccordionHeader>
        <Button
          size="large56"
          onClick={() => clickDate('start', startAt)}
          textColor={currentOpenedPicker === 'start' ? 'primary' : 'gray1'}
          fontWeight={currentOpenedPicker === 'start' ? 600 : 500}>
          {filters.dateWithWeekday(startAt)}
        </Button>
        <Typography size={15} weight={500}>
          ~
        </Typography>
        <Button
          size="large56"
          fullWidth
          onClick={() => clickDate('expire', expireAt)}
          textColor={currentOpenedPicker === 'expire' ? 'primary' : 'gray1'}
          fontWeight={currentOpenedPicker === 'expire' ? 600 : 500}>
          {filters.dateWithWeekday(expireAt)}
        </Button>
      </AccordionHeader>
    );
  }, [clickDate, currentOpenedPicker, expireAt, startAt]);

  return (
    <>
      {showPeriod && (
        <StyledAccordion iconName="calendar" isOpen={isOpen} onChange={() => null} header={header}>
          <DatePicker value={date} min={currentOpenedPicker === 'expire' ? startAt : undefined} onChange={changeDate} />
        </StyledAccordion>
      )}
    </>
  );
};

export default TicketPeriodForm;

const StyledAccordion = styled(FormFieldAccordion)`
  .header-wrapper .header-box {
    height: 100%;
    flex: 1;
  }
`;

const AccordionHeader = styled.div`
  ${theme.flex('', 'center', '', 4)};
  flex: 1;
  overflow: hidden;

  > * {
    flex: 0 0 auto;
  }

  .text-button:last-of-type {
    justify-content: flex-start;
  }
`;
