import { ETC_SCHEDULE_COLOR, MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import { DropdownType } from 'pages/Booking/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import ColoredDetailInfo from './ColoredDetailInfo';
import EtcDeleteListDrawer from './EtcDeleteListDrawer';
import EtcDetailDropDown from './EtcDetailDropDown';
import EtcUpdateListDrawer from './EtcUpdateLIstDrawer';

type Props = {
  currentEtcScheduleId: number;
  data: EtcScheduleDetailResponse;
  canUpdateEtcSchedule: boolean;
  canDeleteEtcSchedule: boolean;
};

const DetailMain = ({ currentEtcScheduleId, data, canUpdateEtcSchedule, canDeleteEtcSchedule }: Props) => {
  const bgColor = ETC_SCHEDULE_COLOR[data.color] || MATCH_REPRESENTATIVE_COLOR[data.color];
  const contrastColor = getContrastTextColor(bgColor) as Color;

  const navigate = useNavigate();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<'update' | 'delete' | null>(null);

  const clickDropDownItem = (value: DropdownType['value']) => {
    switch (value) {
      case 'update':
        setIsOpenDrawer('update');
        break;
      case 'copy':
        navigate(`/schedule/etc/form/copy/${currentEtcScheduleId}`, { state: { data } });
        break;
      case 'delete':
        setIsOpenDrawer('delete');
        break;
    }
    setIsOpenDropDown(false);
  };

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" color={contrastColor} />
          </IconButton>
        ),
        rightButton:
          !canUpdateEtcSchedule && !canDeleteEtcSchedule ? undefined : (
            <EtcDetailDropDown
              contrastColor={contrastColor}
              isOpen={isOpenDropDown}
              onToggle={setIsOpenDropDown}
              onClick={clickDropDownItem}
              canUpdateEtcSchedule={canUpdateEtcSchedule}
              canDeleteEtcSchedule={canDeleteEtcSchedule}
            />
          ),
        bgColor,
      }}>
      <ColoredDetailInfo currentEtcSchedule={data} bgColor={bgColor} contrastColor={contrastColor} />

      <EtcUpdateListDrawer currentEtcSchedule={data} isOpen={isOpenDrawer === 'update'} onClose={() => setIsOpenDrawer(null)} />

      <EtcDeleteListDrawer currentEtcSchedule={data} isOpen={isOpenDrawer === 'delete'} onClose={() => setIsOpenDrawer(null)} />
    </MainLayout>
  );
};

export default DetailMain;
