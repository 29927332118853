import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { messageTargetMemberSelectedFilter } from 'recoil/message';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';

import { MessageTargetFormType, MessageType } from '../../types';
import CompleteButton from './components/CompleteButton';
import MemberList from './components/MemberList';
import StickyHeader from './components/StickyHeader';
import TargetMemberSkeletonList from './components/TargetMemberSkeletonList';
import { convertMessageFilterToMemberFilter } from './utils';

type Props = {
  goToForm: (updater?: (state: MessageType) => MessageType) => void;
};

const TargetMember = ({ goToForm }: Props) => {
  const state = useLocation().state as MessageType | undefined;
  const methods = useForm<MessageTargetFormType>({
    defaultValues: {
      selected: state?.users || [],
      isAllSelected: false,
    },
  });

  const setSelectedFilter = useSetRecoilState(messageTargetMemberSelectedFilter);
  const { setSearchKeyword } = useSearchKeywords('message');

  useEffect(() => {
    if (!state?.filter) return;

    // 발송당시 검색필터 및 검색어 적용
    const { keyword, ...filter } = state.filter;
    setSelectedFilter(convertMessageFilterToMemberFilter(filter));
    if (keyword) {
      setSearchKeyword(keyword);
    }
  }, [state, setSelectedFilter, setSearchKeyword]);

  return (
    <MainLayout
      header={{
        title: '회원 목록',
        noUnderline: true,
        leftButton: (
          <IconButton onClick={() => goToForm()}>
            <Icon name="headerBack" />
          </IconButton>
        ),
        rightButton: <HeaderSearchButton identifier="message" />,
        bgColor: 'white',
      }}
      backgroundColor="gray6">
      <FormProvider {...methods}>
        <StickyHeader messageType={state?.type ?? 'mobile'} />
        <ApiBoundary fallback={<TargetMemberSkeletonList />}>
          <MemberList messageType={state?.type ?? 'mobile'} />
        </ApiBoundary>
        <CompleteButton goToForm={goToForm} messageType={state?.type ?? 'mobile'} />
      </FormProvider>
    </MainLayout>
  );
};

export default TargetMember;
