import styled from '@emotion/styled';
import Form from 'components/Form';
import usePatchLecturePrivate from 'hooks/service/mutations/usePatchLecturePrivate';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import BookingAvailableField from 'pages/Booking/components/AvailableTime/BookingAvailableField';
import CheckInAvailableField from 'pages/Booking/components/AvailableTime/CheckInAvailableField';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import formatBookingPrivateUpdateParams from 'pages/Booking/utils/formatBookingPrivateUpdateParams';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingPrivateUpdateFormType } from '../../types';
import RoomSettingDialog from './RoomSettingDialog';
import SubmitButton from './SubmitButton';

type Props = {
  currentLecture: LectureDetailResponse;
};
const PrivateUpdateForm = ({ currentLecture }: Props) => {
  const { handleSubmit } = useFormContext<BookingPrivateUpdateFormType>();

  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: updatePrivateMutate } = usePatchLecturePrivate(currentLecture.id);

  const submit = (values: BookingPrivateUpdateFormType) => {
    const params = formatBookingPrivateUpdateParams(values);
    updatePrivateMutate(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <>
      <FormContainer onSubmit={handleSubmit(submit)}>
        <ClassTitle />
        <SelectStaff lectureStaff={currentLecture.staff} />
        <BookingRangeDate isRange={false} isUseRooms={currentLecture.policy.is_use_rooms} />
        <BookingRangeTime pageMode="update" isUseRooms={currentLecture.policy.is_use_rooms} />
        <Room />
        <BookingAvailableField />
        {!!currentLecture.policy.is_enter && <CheckInAvailableField />}
        <SubmitButton />
      </FormContainer>

      <RoomSettingDialog />
    </>
  );
};

const FormContainer = styled(Form)`
  min-height: 100%;
  padding-bottom: 116px;
`;

export default PrivateUpdateForm;
