import { PushIconPropsType, PushIconType } from './types';

export const NOTIFICATION_TEXT = {
  title: '알림',
  allRead: '모두 읽음으로 표시',
};

export const NOTIFICATION_TABS = {
  push: { label: '알림', value: 'push' },
  checkIn: { label: '체크인', value: 'checkIn' },
};

export const PUSH_FILTERS = {
  all: { id: 'all', label: '전체 알림', value: 'all' },
  booking: { id: 'booking', label: '예약', value: 'booking' },
  lecture: { id: 'lecture', label: '수업', value: 'lecture' },
  counsel: { id: 'counsel', label: '상담', value: 'counsel' },
  ect_schedule: { id: 'etc', label: '기타 일정', value: 'ect_schedule' },
  ticket: { id: 'ticket', label: '수강권/상품', value: 'ticket' },
  course: { id: 'course', label: '클래스', value: 'course' },
  notice: { id: 'notice', label: '공지사항', value: 'notice' },
  qna: { id: 'qna', label: '1:1문의', value: 'qna' },
};

export const PUSH_TEXT = {
  noData: '알림 내역이 없습니다.',
  detailTitle: '알림 상세보기',
};

/**
 * message_type에 따른 push category
 *
 * 회원앱 분류 참고
 * https://docs.google.com/spreadsheets/d/1H7jONRczuvy91CFnz99SXQSc8o9YKwpgpruORuh-3qk/edit#gid=0
 * https://github.com/studiomate/member-api/blob/master/app/Services/UserNotice/UserNoticeService.php#L76
 * https://github.com/studiomate/manager-api/blob/qa/app/Models/StaffNotice.php
 *
 */
export const PUSH_CATEGORY_TYPES: Record<PushIconType, string[]> = {
  // 예약, 수업, 클래스, 상담
  booking: ['booking', 'private', 'group', 'change.room', 'change.staff', 'lecture', 'course', 'counsel', 'cancel'],
  // 수강권, 상품
  ticket: ['ticket.', 'item', 'refund', 'disable', 'bonus'],
  // 공지사항
  notice: ['studio_message', 'notice'],
  // 1:1문의
  qna: ['qna'],
  // 기타일정
  etc: [],
};

export const PUSH_ICONS: Record<PushIconType, PushIconPropsType> = {
  booking: { name: 'alertCalendar', color: 'primary' },
  ticket: { name: 'alertTicket', color: 'secondary1' },
  notice: { name: 'alertNotice', color: 'gray3' },
  qna: { name: 'alertConsulting', color: 'secondary3' },
  etc: { name: 'alertList', color: 'secondary2' },
};

export const CHECK_IN_TEXT = {
  today: '오늘의 체크인',
  todayNumberSuffix: '명',
  memberSuffix: ' 회원',
  noData: '체크인 내역이 없습니다.',
};
