import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Divider from 'components/Divider';
import Form from 'components/Form';
import usePatchMessageMobile from 'hooks/service/mutations/usePatchMessageMobile';
import usePostMessageMobile from 'hooks/service/mutations/usePostMessageMobile';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import SendMessageLoading from 'pages/MemberDetail/components/AppLinkMessage/SendMessageLoading';
import MessageFooter from 'pages/MoreDetails/Message/components/MessageFooter';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { messageTargetCounselSelectedFilter, messageTargetMemberSelectedFilter } from 'recoil/message';
import SendReservation from 'sharedComponents/Message/SendReservation';
import filters from 'utils/filters';
import { smsByteCheck } from 'utils/smsByteCheck';
import { object, string } from 'yup';

import { MOBILE_DEFAULT_VALUES, MOBILE_MAX_LENGTH } from '../constants';
import { MobileFormType } from '../types';
import { convertSuccessMessage, convertToMobilePostParams } from '../utils';
import MobileConfirmButton from './MobileConfirmButton';
import MobilePreview from './MobilePreview';
import MobileSender from './MobileSender';
import SelectedTargetList from './SelectedTargetList';
import Targets from './Targets';
import TitleAndMessage from './TitleAndMessage';

type Props = {
  defaultValues?: MobileFormType;
};

const yupSchema = object().shape({
  title: string().test(
    'title',
    `${filters.numberComma(MOBILE_MAX_LENGTH.title)}바이트 이내로 입력해 주세요.`,
    value => smsByteCheck(value || '') <= MOBILE_MAX_LENGTH.title,
  ),
  message: string().test(
    'message',
    `${filters.numberComma(MOBILE_MAX_LENGTH.message)}바이트 이내로 입력해 주세요.`,
    value => smsByteCheck(value || '') <= MOBILE_MAX_LENGTH.message,
  ),
});

const MobileSendForm = ({ defaultValues }: Props) => {
  const methods = useForm<MobileFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: { ...MOBILE_DEFAULT_VALUES, ...defaultValues } || MOBILE_DEFAULT_VALUES,
  });

  const { mutate: sendMobileMessage } = usePostMessageMobile();
  const { mutate: updateMobileMessage } = usePatchMessageMobile(defaultValues?.originalMessage?.id);
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const navigate = useNavigate();

  const resetMemberFilter = useResetRecoilState(messageTargetMemberSelectedFilter);
  const resetCounselFilter = useResetRecoilState(messageTargetCounselSelectedFilter);

  const saveMessage = () => {
    const params = convertToMobilePostParams(methods.getValues());

    const mode = methods.getValues('mode');
    const mutateFunction = mode === 'create' ? sendMobileMessage : updateMobileMessage;

    setPopup(<SendMessageLoading />);
    mutateFunction(params, {
      onSuccess: () => {
        const toastPosition = mode === 'update' ? 40 : 92;
        setToast({ message: convertSuccessMessage(methods.getValues()), type: 'success', bottom: toastPosition });
        if (mode === 'update' && defaultValues?.originalMessage) {
          navigate(-1);
        }

        methods.reset(MOBILE_DEFAULT_VALUES);
        resetMemberFilter();
        resetCounselFilter();
      },
      onSettled: () => {
        setPopup(null);
      },
      onError: error => errorDialog.open(error),
    });
  };

  return (
    <FormProvider {...methods}>
      <Form>
        <MobileSender />
        <Container>
          <Contents>
            <Targets />
            <SelectedTargetList />
            <TitleAndMessage maxLength={MOBILE_MAX_LENGTH.message} />
          </Contents>
          <Divider />
          <SendReservation />
        </Container>
        <MessageFooter>
          <MobilePreview />
          <MobileConfirmButton onConfirm={saveMessage} />
        </MessageFooter>
      </Form>
    </FormProvider>
  );
};

export default MobileSendForm;

export const Container = styled.div``;

export const Contents = styled.div`
  padding: 0 20px 56px;
`;
