import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Icon from 'components/Icon';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { CounselCreateUpdateFormProps, CounselingFormType } from '../type';
import Channel from './Channel';
import CounselRangetime from './CounselRangetime';
import CounselSelectDate from './CounselSelectDate';
import Funnel from './Funnel';
import IsRegistered from './IsRegistered';
import Staffs from './Staffs';

const CounselCreateUpdateForm = ({ typeText, onSubmit }: CounselCreateUpdateFormProps) => {
  const { data: staff } = useGetStaffMe();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, isValid },
  } = useFormContext<CounselingFormType>();
  const isName = useWatch({ control, name: 'name' });
  const isPhone = useWatch({ control, name: 'phone' });

  const clearTextField = useCallback(
    (name: keyof CounselingFormType) => setValue(name, '', { shouldValidate: true }),
    [setValue],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} footerButton={{ text: `${typeText} 완료`, disabled: !isValid || isSubmitting }}>
      <CounselSelectDate />
      <CounselRangetime />
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'name' }}
        placeholder="고객명"
        left={{ prefix: <Icon name="privateClass" fillColor={theme.color.gray2} /> }}
        onClear={() => clearTextField('name')}
        textColor={isName ? 'gray1' : 'gray3'}
        suffixMarginRight={20}
      />
      <UnderlinedTextFieldController
        controllerProps={{ control, name: 'phone' }}
        placeholder="휴대폰 번호"
        inputMode="numeric"
        left={{ prefix: <Icon name="phone" fillColor={theme.color.gray2} /> }}
        onClear={() => clearTextField('phone')}
        textColor={isPhone ? 'gray1' : 'gray3'}
        suffixMarginRight={20}
      />
      <IsRegistered />
      <Channel />
      <Funnel />
      <ApiBoundary>
        <Staffs staff={staff} />
      </ApiBoundary>
      <CounselContents>
        <UnderlinedTextareaController
          controllerProps={{ control, name: 'contents' }}
          placeholder="상담내용"
          left={<Icon name="explain" color="gray2" />}
        />
      </CounselContents>
    </Form>
  );
};

export default CounselCreateUpdateForm;

const CounselContents = styled.div`
  .underline-textarea {
    border-bottom: none;
  }
`;
