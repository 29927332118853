import useQueryString from 'hooks/useQueryString';
import useToast from 'hooks/useToast';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MessageType } from '../types';
import TargetCounsel from './Counsel';
import TargetMember from './Member';

const MoreDetailsMessageTarget = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: MessageType };
  const { getSearchParams } = useQueryString();

  const type = getSearchParams('type') === 'member' ? 'member' : 'counsel';

  useEffect(() => {
    // 작성중인 메시지가 없으면 접근 제한
    if (!state || !state.mode || !state.type) {
      navigate('/more-details/message', { replace: true });
    }
  }, [navigate, state]);

  const { setToast } = useToast();
  const goToForm = useCallback(
    (updater?: (previousState: MessageType) => MessageType) => {
      const message = state.type === 'push' ? 'push' : 'mobile';
      navigate(`/more-details/message/${message}/form`, {
        state: updater?.(state) || state,
        replace: true,
      });
      if (updater) {
        setToast({ message: '회원이 추가되었습니다. ', type: 'success', bottom: 16 + (state.isReserve ? 120 : 76) });
      }
    },
    [navigate, setToast, state],
  );

  if (type === 'member') {
    return <TargetMember goToForm={goToForm} />;
  }
  return <TargetCounsel goToForm={goToForm} />;
};

export default MoreDetailsMessageTarget;
