import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { useEffect } from 'react';

import DialogPortal from './DialogPortal';

export interface IDialogProps {
  /** contents */
  children: React.ReactNode;
  /** 텍스트 위치 */
  textAlign?: 'left' | 'right' | 'center';
  /** 상단 타이틀 텍스트 */
  title?: string;
  /** 에러 케이스 */
  isError?: boolean;
  /** 보조 설명 케이스 */
  isInfo?: boolean;
  /** 닫기 버튼 노출 유무 */
  showCloseButton?: boolean;
  /** 버튼 2개 중 왼쪽 부정 버튼의 텍스트 및 클릭 로직 (클릭 로직 없으면 onClose 실행) */
  negativeAction?: { text: string; disabled?: ButtonProps['disabled']; onClick?: () => void; style?: ButtonProps['variant'] };
  /** 버튼 1개일 때 혹은 2개 중 오른쪽 긍정 버튼의 텍스트 및 클릭 로직 */
  positiveAction?: { text: string; disabled?: ButtonProps['disabled']; onClick: () => void };
  /** z-index 값 */
  zIndex?: number;
  /** 다이얼로그 닫기 로직 */
  onClose: () => void;
}

const Dialog = ({ children, textAlign = 'center', zIndex, ...props }: IDialogProps) => {
  const { title, positiveAction, negativeAction, showCloseButton, isError, onClose } = props;
  /** 왼쪽 버튼에 다이얼로그 닫기 로직 외 다른 클릭 이벤트가 있을 때 */
  const isLeftButtonPositive = !!negativeAction?.onClick;

  useEffect(() => {
    const body = document.body;
    if (!body.className) body.classList.add('hidden');
    return () => body.classList.remove('hidden');
  }, []);

  return (
    <DialogPortal>
      <Container className="dialog" zIndex={zIndex}>
        <Overlay onClick={onClose} />
        <DialogWrapper isInfo={props.isInfo}>
          <DialogContentsWrapper textAlign={textAlign} {...props}>
            {isError && (
              <div className="dialog-error-icon-wrapper">
                <Icon name="errorFill" size={48} fillColor={theme.color.gray4} />
              </div>
            )}

            {(title || showCloseButton) && (
              <div className="dialog-header">
                {title && (
                  <div className="dialog-title">
                    <Typography size={17} weight={700} textColor="gray1">
                      {title}
                    </Typography>
                  </div>
                )}
                {showCloseButton && (
                  <IconButton onClick={onClose}>
                    <Icon name="headerClose" color="gray3" />
                  </IconButton>
                )}
              </div>
            )}
            <div className="dialog-content">{children}</div>

            {positiveAction && (
              <div className="dialog-button-wrapper">
                <ButtonGroup leftButtonWidth={128}>
                  {negativeAction && (
                    <Button
                      fullWidth
                      size="medium48"
                      variant={negativeAction.style ? negativeAction.style : isLeftButtonPositive ? 'contained' : 'outlined'}
                      color={negativeAction.style === 'outlined' || isLeftButtonPositive ? 'gray' : undefined}
                      disabled={negativeAction.disabled}
                      onClick={negativeAction.onClick || onClose}>
                      {negativeAction.text}
                    </Button>
                  )}
                  {positiveAction.text && (
                    <Button
                      fullWidth
                      size="medium48"
                      variant="contained"
                      color="point"
                      onClick={positiveAction.onClick}
                      disabled={positiveAction.disabled}>
                      {positiveAction.text}
                    </Button>
                  )}
                </ButtonGroup>
              </div>
            )}
          </DialogContentsWrapper>
        </DialogWrapper>
      </Container>
    </DialogPortal>
  );
};

export default Dialog;

const Container = styled.div<Pick<IDialogProps, 'zIndex'>>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex || Z_INDEX.dialog};
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

const DialogWrapper = styled.div<Pick<IDialogProps, 'isInfo'>>`
  background-color: ${theme.color.white};
  border-radius: 20px;
  padding: ${({ isInfo }) => (isInfo ? '24px 20px' : '32px 20px 24px')};
  width: 304px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
`;

const DialogContentsWrapper = styled.div<Pick<IDialogProps, 'textAlign' | 'title' | 'isError' | 'isInfo'>>`
  line-height: 1;
  text-align: ${({ textAlign, isInfo }) => (isInfo ? 'left' : textAlign)};

  .dialog-error-icon-wrapper {
    ${theme.flex('', 'center', 'center')};
    margin-bottom: 12px;
  }

  .dialog-header {
    position: relative;
    line-height: 24px;

    button {
      position: absolute;
      top: ${({ isInfo }) => (isInfo ? '-8px' : '-16px')};
      right: -4px;
    }
  }

  .dialog-content {
    margin-top: ${({ title, isError, isInfo }) => (!title ? '0px' : isInfo ? '12px' : isError ? '8px' : '16px')};
    line-height: 22px;
  }

  .dialog-button-wrapper {
    margin-top: 32px;
  }
`;
