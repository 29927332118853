import filters from './filters';

type Name = {
  name: string;
};

/**
 * @returns placeholder or 홍길동 or 홍길동 외 n명 표시 용도
 */
const convertTargetsText = <T extends Name>(targets?: Array<T>, placeholder = '') => {
  if (!targets || !targets.length) {
    return placeholder;
  }
  return targets.length === 1 ? targets[0].name : `${targets[0].name} 외 ${filters.numberComma(targets.length - 1)}명`;
};

export default convertTargetsText;
