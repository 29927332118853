import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Form from 'components/Form';
import Typography from 'components/Typography';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { AllPageModeType } from 'pages/Booking/types';
import { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import { BookingGroupCommonFormType } from '../../types';
import BookingRequiredSwitch from './BookingRequiredSwitch';
import Description from './Description';
import DivisionField from './Division/DivisionField';
import MaxTrainee from './MaxTrainee';
import MinTrainee from './MinTrainee';
import OperationAccordion from './Operation/OperationAccordion';
import SubmitButton from './SubmitButton';
import WaitingTrainee from './WaitingTrainee';

type Props = ComponentProps<typeof Form> & {
  pageMode: AllPageModeType;
  currentLecture?: LectureDetailResponse;
};

/**
 * 그룹 수업 폼은 등록, 수정, 복사 폼 디자인이 날짜, 시간 선택 외 모두 같음
 * children 에 날짜, 시간 선택 폼
 */
const CommonGroupForm = ({ children, pageMode, currentLecture, ...props }: Props) => {
  const { getValues, setValue } = useFormContext<BookingGroupCommonFormType>();

  return (
    <Form {...props}>
      <BookingRequiredSwitch />
      <SelectStaff lectureStaff={currentLecture ? currentLecture.staff : undefined} />
      {children}
      {pageMode !== 'update' && <RepeatField onClick={() => setValue('isOpenOperationAccordion', false)} />}
      <Room />
      <Divider />
      <StyledTypography size={15} weight={600}>
        상세정보
      </StyledTypography>
      <ClassTitle />
      <Description />
      <MaxTrainee />
      <MinTrainee />
      {getValues('showWaitingField') && <WaitingTrainee />}
      <DivisionField />
      <Divider />
      <OperationAccordion pageMode={pageMode} />
      <SubmitButton pageMode={pageMode} />
    </Form>
  );
};

export default CommonGroupForm;

const StyledTypography = styled(Typography)`
  padding: 20px 20px 8px;
`;
