import Icon from 'components/Icon';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import { useFormContext } from 'react-hook-form';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';

const Address = () => {
  const { control, setValue } = useFormContext<MemberFormType>();

  return (
    <UnderlinedTextareaController
      controllerProps={{ control, name: 'address' }}
      placeholder={MEMBER_FORM_TEXT.address.placeholder}
      left={<Icon name="location" color="gray2" />}
      onClear={() => setValue('address', '', { shouldDirty: true })}
    />
  );
};

export default Address;
