import { useMutation } from '@tanstack/react-query';
import { ChannelType, FunnelType } from 'api/modules/counseling2';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostCounseling = () => {
  const { mutationFn } = useMutationFnWithCommonParams<PostCounselingParamsType, CounselingResponseType>({
    method: 'post',
    url: 'api/counseling',
  });

  return useMutation({
    mutationFn,
  });
};
export default usePostCounseling;

export type CounselStaffAvatarType = {
  id: number;
  owner_type: string;
  owner_id: number;
  is_representative: number;
  image: {
    path: string;
    name: string;
    extension: string;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type CounselStaffDataType = {
  id: number;
  name: string;
  account_type: string;
  account_id: number;
  mobile: string;
  vaccination_yn: string;
  created_at: string;
  updated_at: string;
  registered_at: string;
  deleted_at: string | null;
  staff_avatar: CounselStaffAvatarType;
};

export type PostCounselingParamsType = {
  studio_id: number;
  staff_id: number;
  name: string;
  phone: string;
  channel: ChannelType;
  funnel: FunnelType;
  contents: string;
  start_on: string;
  end_on: string;
  is_registered: boolean;
  target_staff_id: number;
};

export type CounselingResponseType = {
  id: number;
  studio_id: number;
  staff_id: number;
  staff?: CounselStaffDataType;
  user_id: number;
  name: string;
  phone: string;
  channel: ChannelType;
  funnel: FunnelType;
  contents: string;
  start_on: string;
  end_on: string;
  created_at?: string;
  updated_at?: string;
  target_staff_id?: number;
};
