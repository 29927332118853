import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { CALENDAR_VIEW_TYPES } from 'pages/Schedule/ScheduleMain/constants';
import { CalendarViewType } from 'pages/Schedule/ScheduleMain/types';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilState } from 'recoil';
import { calendarViewTypeAtom } from 'recoil/schedule';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const ViewTypeSelectDropdown = ({ isOpen, setIsOpen }: Props) => {
  const [calendarViewType, setCalendarViewType] = useRecoilState(calendarViewTypeAtom);

  const dropdownContent = (
    <DropdownContainer>
      {CALENDAR_VIEW_TYPES.map(({ value, title }, index) => {
        const isSelected = value === calendarViewType;
        return (
          <li key={value}>
            {index !== 0 && <Divider thin />}
            <Button
              fullWidth
              size="medium40"
              fontWeight={isSelected ? 500 : 400}
              textColor={isSelected ? 'gray1' : 'gray2'}
              padding={{ left: isSelected ? 12 : 32 }} // 체크 아이콘까지 계산한 값
              gap={4}
              onClick={() => setCalendarViewType(value as CalendarViewType)}
              leftIcon={isSelected && <Icon name="selectCheckBold" size={16} fillColor={theme.color.gray2} />}>
              {title}
            </Button>
          </li>
        );
      })}
    </DropdownContainer>
  );

  return (
    <DropDown isOpen={isOpen} content={dropdownContent} position="right" bottomSpacing={12} onClose={() => setIsOpen(false)}>
      <IconButton onClick={() => setIsOpen(!isOpen)}>
        <Icon name="headerCalendar" />
      </IconButton>
    </DropDown>
  );
};

export default ViewTypeSelectDropdown;

const DropdownContainer = styled.ul`
  ${theme.flex('column', 'flex-start')};
  width: 148px;

  li {
    width: 100%;

    button {
      ${theme.flex('row', 'center', 'flex-start', 4)};
    }
  }
`;
