import styled from '@emotion/styled';
import Phone from 'assets/images/phone_full.svg';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import { ReactNode, useState } from 'react';

import { MESSAGE_PREVIEW_TEXT } from '../constants';

type Props = {
  /** 타이틀과 휴대폰UI 사이에 요약정보를 추가할 경우 */
  summary?: ReactNode;
  /** 휴대폰 UI로 들어가는 컴포넌트 */
  children: ReactNode;
  className?: string;
};

const MessagePreview = ({ summary, children, className }: Props) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <>
      <StyledButton variant="contained" color="gray" size="large56" onClick={() => setIsDrawerOpened(true)}>
        {MESSAGE_PREVIEW_TEXT.title}
      </StyledButton>
      <Drawer
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        header={<TitleWithSubText title={MESSAGE_PREVIEW_TEXT.title} subTitle={MESSAGE_PREVIEW_TEXT.description} />}>
        {summary}
        <MobilePreview>
          <ImageBackground src={Phone} />
          <Container className={className}>{children}</Container>
        </MobilePreview>
      </Drawer>
    </>
  );
};

export default MessagePreview;

const StyledButton = styled(Button)`
  min-width: 115px;
`;

const MobilePreview = styled.div`
  position: relative;
  margin-top: 24px;
`;

const ImageBackground = styled.img`
  width: 100%;
  height: auto;
`;

const Container = styled.div`
  position: absolute;
  top: 15vw;
  left: 8vw;
  width: calc(100% - 16vw);
`;
