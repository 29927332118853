import Typography from 'components/Typography';
import DateTimePicker from 'designedComponents/Pickers/DateTimePicker';
import { BOOKING_AVAILABLE_TIME_SETTING_TEXT, BOOKING_RULE_TYPE_DATA } from 'pages/Booking/constants';
import { SettingByRuleTypeProps } from 'pages/Booking/types';

import { RuleTypeContainer, RuleTypeTypography, StyledRuleTypeTimeTypography, UpdateDateTypography } from '../StyledComponents';
import RuleTypeAccordion from './RuleTypeAccordion';

/**
 * 프라이빗/그룹 1개 수정 시,
 * 수업일 기준 - 마감시간까지 (A)
 * 수업시간 기준 - 마감시간까지 (R)
 */
const EndAtUpdate = ({
  iconName,
  isOpen,
  onToggle,
  textType,
  lectureDate,
  endAt,
  ruleType,
  changeDate,
}: SettingByRuleTypeProps) => {
  return (
    <RuleTypeContainer className={`rule-type ${ruleType} update ${textType}`}>
      <RuleTypeTypography>{BOOKING_RULE_TYPE_DATA.filter(({ id }) => id === ruleType)[0].label}</RuleTypeTypography>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={isOpen}
        isLast={textType === 'cancel'}
        noBottomBorder={textType === 'booking'}
        onChange={() => onToggle(!isOpen)}
        header={
          <StyledRuleTypeTimeTypography>
            <UpdateDateTypography isOpen={isOpen} endAt={endAt} />
            <Typography span size={15}>
              {BOOKING_AVAILABLE_TIME_SETTING_TEXT[textType]}
            </Typography>
          </StyledRuleTypeTimeTypography>
        }>
        <DateTimePicker max={lectureDate} defaultValue={endAt} value={endAt} onChange={changeDate} />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default EndAtUpdate;
