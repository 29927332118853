import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MATCH_REPRESENTATIVE_COLOR, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import ColorChip from 'sharedComponents/ColorChip';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { StaffTypes } from '../types';

const StaffCard = ({ id, name, mobile, avatar, color, role, account_id }: StaffTypes) => {
  const navigate = useNavigate();

  const currentStaffId = useRecoilValue(staffIdAtom);

  const currentColorChip = useMemo(() => theme.color[MATCH_REPRESENTATIVE_COLOR[color]], [color]);
  const mobileFormat = useMemo(() => filters.mobile(mobile), [mobile]);

  return (
    <Container isStaffMe={id === currentStaffId} onClick={() => navigate(id.toString())}>
      <div className="card-container">
        <Avatar size={48} imgUrl={getImageUrl(avatar || null)} />

        <Typography size={17} weight={600} lineHeight={24} ellipsis={1}>
          {name}
        </Typography>

        <div className="mobile-wrapper">
          <Typography textColor="gray2">{mobileFormat || '-'}</Typography>
          {!account_id && <Icon name="connectOff" size={16} fillColor={theme.color.gray3} />}
        </div>

        <div className="display-name-wrapper">
          <ColorChip size={7} fillColor={currentColorChip} strokeColor={!currentColorChip ? theme.color.gray4 : undefined} />
          <Typography size={13} weight={500} lineHeight={16} textColor="gray3" ellipsis={1}>
            {role}
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default StaffCard;

const Container = styled.div<{ isStaffMe: boolean }>(
  ({ isStaffMe }) =>
    isStaffMe &&
    css`
      position: relative;

      .card-container {
        &::before {
          content: '나';
          position: absolute;
          right: 16px;
          ${theme.flex()};
          width: 23px;
          height: 22px;
          border-radius: 2px;
          background-color: ${theme.color.gray7};
          color: ${theme.color.gray2};
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 12px;
        }
      }
    `,

  css`
    padding: 16px;
    width: 48%;
    height: 156px;
    border-radius: 16px;
    background-color: ${theme.color.white};
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

    .avatar {
      margin-bottom: 10px;
    }

    .display-name-wrapper {
      ${theme.flex('row', 'center', '', 6)};
      margin: 6px 0 -2px;
    }

    .mobile-wrapper {
      ${theme.flex('row', 'flex-start', '')};
    }
  `,
);
