import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import { APP_LINK_TEXT } from 'pages/MemberDetail/constants';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import SelectMessageReservationDateDrawer from './SelectMessageReservationDateDrawer';
import { MessageReservationType } from './types';

/** 메세지 보낼 때 예약 토글 및 시간 설정 UI  */
const SendReservation = () => {
  const { control } = useFormContext<MessageReservationType>();
  const reservationDate = useWatch({ control, name: 'reservationDate' });
  const isReserve = useWatch({ control, name: 'isReserve' });

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container isReserve={isReserve}>
      <Controller
        name="isReserve"
        control={control}
        render={({ field: { value, ...field } }) => (
          <Switch id="isReserve" label={APP_LINK_TEXT.reservation.switchLabel} {...field} checked={value} />
        )}
      />

      {isReserve && (
        <SelectDateButton
          fullWidth
          size="medium48"
          fontSize={15}
          variant="outlined"
          outlineColor="gray5"
          padding={{ left: 16, right: 12 }}
          rightIcon={<Icon name="arrowBottom" fillColor={theme.color.gray2} />}
          textColor={!reservationDate ? 'gray4' : 'gray1'}
          borderRadius={10}
          onClick={() => setIsOpen(true)}>
          {!reservationDate ? APP_LINK_TEXT.reservation.dateButtonLabel : filters.dateTimeWithWeekday(reservationDate)}
        </SelectDateButton>
      )}

      <SelectMessageReservationDateDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Container>
  );
};

export default SendReservation;

const Container = styled.div<{ isReserve: boolean }>`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: ${({ isReserve }) => (isReserve ? 154 : 116)}px;

  .switch {
    padding: 16px 0;
  }
`;

const SelectDateButton = styled(Button)`
  justify-content: space-between;
`;
