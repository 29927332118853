export const PRIVATE_START_INTERVAL = {
  title: '예약시간 단위 설정',
  subTitle: {
    am: '오전 (00:00 ~ 12:00)',
    pm: '오후 (13:00 ~ 24:00)',
  },
  radio: [
    { label: '정시', value: 60 },
    { label: '30분', value: 30 },
  ],
  tooltip: ['회원앱에서 프라이빗 수업을', '예약할 때 설정한 시간 단위로', '표시합니다.'],
};
