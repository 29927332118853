import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 회원 수정 폼 */
const MemberUpdate = () => {
  const currentMemberId = useParamsId();

  return (
    <ApiBoundary>
      <FormMain currentMemberId={currentMemberId} />
    </ApiBoundary>
  );
};

export default MemberUpdate;
