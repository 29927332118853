import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

import { BookingSelectMemberFormType } from '../types';

const LeaveDialog = () => {
  const {
    control,
    formState: { isSubmitted },
  } = useFormContext<BookingSelectMemberFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });

  return (
    <DialogBeforeLeave isBlocked={!isSubmitted && !!selectedUserTickets.length}>
      <Typography size={15}>
        변경사항이 저장되지 않았습니다. <br />
        페이지를 나가시겠습니까?
      </Typography>
    </DialogBeforeLeave>
  );
};

export default LeaveDialog;
