import { PointStaffResponse } from 'hooks/service/queries/useGetPointHistoryStaffList';
import { cloneDeep } from 'lodash';
import { POINT_FILTER_DEFAULT_TAB_LABEL, POINT_FILTERS } from 'pages/MemberDetail/constants';
import { CurrentMemberIdProps, PointStaffType } from 'pages/MemberDetail/types';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { pointFiltersAtom, pointStaffsFilterOptionsAtom } from 'recoil/MemberDetail';
import Filter from 'sharedComponents/Filters';

import DrawerFilterOptions from './DrawerFilterOptions';
import WholeFilterOptions from './WholeFilterOptions';

type Props = CurrentMemberIdProps & {
  staffs: Array<PointStaffResponse>;
};

const PointFilter = ({ currentMemberId, staffs }: Props) => {
  const setFilterOptions = useSetRecoilState(pointStaffsFilterOptionsAtom);
  const currentStaffId = useRecoilValue(staffIdAtom);

  /**
   * 포인트 내역을 수정한 적 있는 강사들 filter option 에 추가
   * (애초에 필터링된 강사들만 반환됨)
   */
  useEffect(() => {
    setFilterOptions(prevState => {
      const prevStaffOptions = cloneDeep(prevState);

      if (!staffs.length) {
        return [];
      }

      const staffFilterOptionValue = staffs.map(({ id, name, avatar }) => {
        return {
          value: id,
          label: name,
          staffImgUrl: avatar,
          isMe: id === currentStaffId,
        };
      });
      const staffsResponse: PointStaffType[] = [...staffFilterOptionValue];

      prevStaffOptions.forEach(option => {
        const isSame = staffsResponse.find(item => item.value === option.value);
        if (isSame) return;
        staffsResponse.push(option);
      });

      return staffsResponse;
    });
  }, [currentMemberId, currentStaffId, setFilterOptions, staffs]);

  return (
    <Filter
      filterAtom={pointFiltersAtom}
      filters={POINT_FILTERS}
      defaultTabLabels={POINT_FILTER_DEFAULT_TAB_LABEL}
      renderDrawerOptions={DrawerFilterOptions}
      renderWholeFilterPopupOptions={WholeFilterOptions}
    />
  );
};

export default PointFilter;
