import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';
import { get } from 'lodash';
import { useState } from 'react';
import DateFilter, { IDateFilterProps } from 'sharedComponents/DateFilter';
import filters from 'utils/filters';

import { DEFAULT_PERIOD, DEFAULT_PERIOD_FILTER } from '../constants';
import { FilterOptionsType, FilterOptionValuesType, PeriodFilterProps, PeriodFilterType } from '../types';
import PeriodFilterContents from './PeriodFilterContents';

type Props = Omit<IDateFilterProps, 'onClick'> &
  Partial<PeriodFilterProps> & {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    saveChangedFilter: (values: PeriodFilterType) => void;
    selectedFilter: PeriodFilterType;
  };

const PeriodFilterSet = ({
  period,
  text,
  onOpen,
  isOpen,
  onClose,
  saveChangedFilter,
  selectedFilter,
  currentFilter,
  ...props
}: Props) => {
  const { selectedOption, onClick, ...rest } = props;
  const [filterChanged, setFilterChanged] = useState(selectedFilter);
  const currentPeriod = selectedFilter?.period ?? DEFAULT_PERIOD;

  const changeFilter = (option: FilterOptionsType, key: string) => {
    setFilterChanged(prev => ({ ...prev, [key]: option.value }));
  };

  return (
    <>
      <DateFilter
        period={period ?? filters.period(currentPeriod.start, currentPeriod.end)}
        text={text ?? DEFAULT_PERIOD_FILTER.tabConverter?.(currentPeriod as unknown as FilterOptionValuesType)}
        onClick={onOpen}
      />

      <StyledDrawer isOpen={isOpen} onClose={onClose} direction="top">
        <PeriodFilterContents
          currentFilter={currentFilter ?? DEFAULT_PERIOD_FILTER}
          selectedOption={props.selectedOption ?? get(filterChanged, 'period')}
          onClick={onClick ?? changeFilter}
          {...rest}
        />
        <ButtonWrapper>
          <Button
            variant="contained"
            color="point"
            size="medium48"
            fullWidth
            onClick={() => {
              saveChangedFilter({ ...filterChanged });
              onClose();
            }}>
            필터 적용
          </Button>
        </ButtonWrapper>
      </StyledDrawer>
    </>
  );
};

export default PeriodFilterSet;

const StyledDrawer = styled(Drawer)`
  padding: 20px 0 0;
`;

const ButtonWrapper = styled.div`
  padding: 14px 20px;
  background-color: ${theme.color.gray7};
`;
