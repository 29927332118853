import { useIsMutating } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteEtcSchedule from 'hooks/service/mutations/useDeleteEtcSchedule';
import useDeleteEtcScheduleAll from 'hooks/service/mutations/useDeleteEtcScheduleAll';
import useDeleteEtcScheduleBulk from 'hooks/service/mutations/useDeleteEtcScheduleBulk';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import MoreButtonListDrawer from 'pages/Booking/Detail/components/MoreButtonListDrawer';
import { ETC_DETAIL_DELETE_DRAWER_LIST, ETC_DETAIL_DELETE_TEXT } from 'pages/EtcSchedule/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

type Props = {
  currentEtcSchedule: EtcScheduleDetailResponse;
  isOpen: boolean;
  onClose: () => void;
};

const EtcDeleteListDrawer = ({ currentEtcSchedule, isOpen, onClose }: Props) => {
  const [selectedOption, setSelectedOption] = useState<'delete' | 'deleteAfterDate' | 'deleteAll' | null>(null);

  const navigate = useNavigate();
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const isDeleting = useIsMutating({ mutationKey: ['etc/schedule', 'delete'] });
  const { mutate: deleteEtcSingle } = useDeleteEtcSchedule(currentEtcSchedule.id);
  const { mutate: deleteEtcBulk } = useDeleteEtcScheduleBulk(currentEtcSchedule.etc_schedule_id);
  const { mutate: deleteEtcAll } = useDeleteEtcScheduleAll(currentEtcSchedule.etc_schedule_id);

  const mutateCallback = () => {
    const message = ETC_DETAIL_DELETE_TEXT.successMessage;

    return {
      onSuccess: () => {
        navigate('/schedule');
        setToast({ type: 'success', message, bottom: 76 });
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    };
  };

  const deleteEtcSchedule = () => {
    switch (selectedOption) {
      case 'delete':
        deleteEtcSingle(undefined, mutateCallback());
        break;
      case 'deleteAfterDate':
        deleteEtcBulk({ start: currentEtcSchedule.start_on }, mutateCallback());
        break;
      case 'deleteAll':
        deleteEtcAll(undefined, mutateCallback());
    }
    setSelectedOption(null);
  };

  const clickOption = (option: string) => {
    setSelectedOption(option as 'delete' | 'deleteAfterDate' | 'deleteAll');
    onClose();
  };

  useEffect(() => {
    if (isDeleting) {
      setPopup(<ProcessingPopupContents title={ETC_DETAIL_DELETE_TEXT.processingTitle} />);
    } else {
      setPopup(null);
    }
  }, [isDeleting, setPopup]);

  return (
    <>
      <MoreButtonListDrawer
        buttonList={
          currentEtcSchedule.is_all_day
            ? ETC_DETAIL_DELETE_DRAWER_LIST.filter(item => item.value === 'deleteAll')
            : ETC_DETAIL_DELETE_DRAWER_LIST
        }
        onClick={clickOption}
        isOpen={isOpen}
        onClose={onClose}
      />

      {!!selectedOption && (
        <Dialog
          onClose={() => setSelectedOption(null)}
          negativeAction={{
            text: ETC_DETAIL_DELETE_TEXT.dialog.negativeText,
            onClick: () => setSelectedOption(null),
            style: 'outlined',
          }}
          positiveAction={{
            text: ETC_DETAIL_DELETE_TEXT.dialog.positiveText,
            onClick: deleteEtcSchedule,
          }}>
          <DialogDescription tag="pre">{ETC_DETAIL_DELETE_TEXT.dialog.description}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default EtcDeleteListDrawer;
