import styled from '@emotion/styled';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { IHeaderProps } from 'components/Header';
import Icon from 'components/Icon';
import Textarea from 'components/Textarea';
import usePatchMemo from 'hooks/service/mutations/usePatchMemo';
import usePostAttachment from 'hooks/service/mutations/usePostAttachment';
import usePostMemo from 'hooks/service/mutations/usePostMemo';
import useErrorDialog, { CustomErrorType } from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';
import PhotoArea from 'sharedComponents/MemoForm/PhotoArea';
import { MemoFormType } from 'sharedComponents/MemoForm/types';

import { MEMO_TEXT } from './constants';

type Props = {
  formValues: MemoFormType;
};

const MemoForm = ({ formValues }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setToast } = useToast();
  const { setPopup } = usePopup();

  const methods = useForm<MemoFormType>({
    defaultValues: formValues,
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;

  const errorDialog = useErrorDialog();
  const { mutateAsync: createMemoMutate } = usePostMemo();
  const { mutateAsync: updateMemoMutate } = usePatchMemo(Number(getValues('memo_id')));
  const { getAttachmentIds } = usePostAttachment('memo');

  const saveMemo = useCallback(
    async (values: MemoFormType) => {
      try {
        const { files, imgUrls, attachments, memo_id, ...rest } = values;
        const newFiles = (files && (await getAttachmentIds(files))) || [];
        const existingImageIds = attachments?.map(({ id }) => id) || [];

        const attachment_id = [...existingImageIds, ...newFiles];

        const params = { ...rest, attachment_id };
        const message = MEMO_TEXT.successMessage[memo_id ? 'update' : 'create'];

        if (memo_id) {
          await updateMemoMutate(params);
        } else {
          await createMemoMutate(params);
        }
        setToast({ type: 'success', message });
      } catch (error) {
        errorDialog.open(error as CustomErrorType);
      } finally {
        setPopup(null);
      }
    },
    [createMemoMutate, errorDialog, getAttachmentIds, setPopup, setToast, updateMemoMutate],
  );

  const closeMemoForm = useCallback(() => {
    if (isDirty) {
      setIsOpen(true);
    } else {
      setPopup(null);
    }
  }, [isDirty, setPopup]);

  const headerOption: IHeaderProps = useMemo(() => {
    return {
      leftButton: (
        <IconButton onClick={closeMemoForm}>
          <Icon name="headerClose" />
        </IconButton>
      ),
      title: MEMO_TEXT.title,
      rightButton: isDirty ? (
        <Button
          textColor="primary"
          fontSize={15}
          fontWeight={600}
          disabled={isSubmitting}
          onClick={() => handleSubmit(saveMemo)()}>
          저장
        </Button>
      ) : undefined,
    };
  }, [closeMemoForm, isDirty, isSubmitting, handleSubmit, saveMemo]);

  return (
    <MainLayout header={headerOption}>
      <FormProvider {...methods}>
        <Container>
          <Controller
            name="memo"
            control={control}
            render={({ field }) => <Textarea placeholder={MEMO_TEXT.placeholder} height={254} {...field} />}
          />
          <PhotoArea />
        </Container>
      </FormProvider>

      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          negativeAction={{ text: MEMO_TEXT.formDialog.negativeText }}
          positiveAction={{ text: MEMO_TEXT.formDialog.positiveText, onClick: () => setPopup(null) }}>
          <DialogDescription>
            변경사항이 저장되지 않았습니다.
            <br /> 페이지를 나가시겠습니까?
          </DialogDescription>
        </Dialog>
      )}
    </MainLayout>
  );
};

export default MemoForm;

const Container = styled.div`
  padding: 16px 20px;
  height: 100%;
`;
