import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { Image } from './useGetLectureDetail';
import { HoldingResponse } from './useInfinityHolding';

/** 회원별 보유 수강권/상품 리스트 */
const useInfinityUserTicketProduct = (params: UserTicketParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<UserTicketResponse, UserTicketParams>({
    url: `/api/userTicket/product`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityUserTicketProduct;

export type UserTicketParams = {
  user_id: number;
  product_type: 'all' | 'userTicket' | 'item';
  status: 'refund' | 'not_usable' | 'expired' | 'transfer' | 'sell' | 'active' | 'in_active';
};

export type UserTicketResponse = {
  id: number;
  studio_id: number;
  user_id: number;
  active: boolean;
  ticket_id: number;
  availability_start_at: string;
  expire_at: string;
  max_coupon: number;
  remaining_coupon: number;
  usable_coupon: number;
  max_cancel: number;
  remaining_cancel: number;
  booking_limit_per_week: number;
  booking_limit_per_month: number;
  inactive_reason: string | null;
  is_shared: boolean;
  is_show_cancel_count: boolean;
  memoCount: number;
  staffs: Staff[];
  status: 'purchase' | 'refund' | 'upgrade' | 'downgrade' | 'full_payment' | 'installment_payment' | 'pending';
  user_ticket_status: 'using' | 'to_use' | 'holding' | 'not_usable' | 'expired' | 'transfer' | 'refund';
  staff_updated_at: string;
  payment_updated_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  payments: {
    /** 회원이 현재 보유한 포인트 */
    current_point: number;
    /** card_amount + cash_amount +  wiretransfer_amount - 환불금액  */
    total_amount: number;
    /** 부모 수강권의 가격 */
    original_price: number;
    /** 판매가 = total_amount + unpaid_amount  */
    sale_price: number;
    reward_point_total: number;
    point_amount_total: number;
    card_amount_total: number;
    cash_amount_total: number;
    wiretransfer_amount_total: number;
    unpaid_amount_total: number;
    initial_payment: Payment;
    last_payment: Payment;
  };
  holdings: HoldingResponse | null;
  change_daily_booking_count: number;

  ticket: TicketCommon;
  policy: { is_auto_write_unpaid: boolean };
};

export type TicketCommon = {
  title: string;
  /**G: 그룹 수강권
   * P: 프라이빗 수강권
   * I: 상품  */
  available_class_type: 'G' | 'P' | 'I';
  /** 횟수제, 기간제, 대여형(판매, 혼합) 등 */
  type: 'T' | 'P' | 'S' | 'RM' | 'RP' | 'RT';
  /** 주간/월간 이용횟수 자동 차감  */
  use_weekly_auto_coupon_balance: boolean;
  /** 티켓 색상 */
  colors: string[];
  /** 최대 수강 인원 */
  max_trainee: number;
  /** 당일 변경 가능 횟수 */
  daily_booking_change_limit: number;
};

export type Staff = {
  id: number;
  name: string;
  image: Image | null;
};

export type Payment = {
  id: number;
  studio_id: number;
  user_id: number;
  transfer_user_id: null;
  order_id: number;
  order_no: null;
  trno: null;
  type: string;
  goods_type: string;
  goods_id: number;
  parent_type: string;
  parent_id: number;
  status: string;
  amount: number;
  cash_amount: number;
  wiretransfer_amount: number;
  transfer_amount: number;
  card_amount: number;
  unpaid_amount: number;
  reward_point: number;
  point_amount: number;
  commission_amount: number;
  before_user_ticket_amount: number;
  method: string;
  installment_period: number;
  model_preview: null;
  settlement_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};
