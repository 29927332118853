import { useIsMutating } from '@tanstack/react-query';
import Divider from 'components/Divider';
import { TicketUpgradeFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import {
  Card,
  Cash,
  Installment,
  PaymentFormTitle,
  PaymentSubmitButton,
  Point,
  SettlementDate,
  TicketFormContainer,
  Unpaid,
  WireTransfer,
} from 'sharedComponents/TicketForm';

import Difference from './Difference';
import UpgradeTicketSalePrice from './UpgradeTicketSalePrice';

type Props = {
  priceDiff: number;
  upgradeSalePrice: number;
  onSubmit: (values: TicketUpgradeFormType) => void;
};

const PaymentForm = ({ priceDiff, upgradeSalePrice, onSubmit }: Props) => {
  const { handleSubmit } = useFormContext<TicketUpgradeFormType>();
  const isMutating = useIsMutating() > 0;

  return (
    <TicketFormContainer onSubmit={handleSubmit(onSubmit)}>
      <PaymentFormTitle size={14}>업그레이드 결제정보</PaymentFormTitle>
      <UpgradeTicketSalePrice upgradeSalePrice={upgradeSalePrice} />
      <Difference priceDiff={priceDiff} />

      <Divider />
      <PaymentFormTitle size={14}>결제수단</PaymentFormTitle>
      <Card />
      <Installment />
      <Cash />
      <WireTransfer />
      <Point />

      <Divider />
      <Unpaid />
      <SettlementDate />

      <PaymentSubmitButton disabled={isMutating}>수강권 업그레이드</PaymentSubmitButton>
    </TicketFormContainer>
  );
};

export default PaymentForm;
