import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';
import { HTMLAttributes, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';

export type Props = HTMLAttributes<HTMLFormElement> & {
  children: ReactNode;
  footerButton?: Omit<ButtonProps, 'children'> & { text: string };
};

const Form = ({ children, onSubmit, footerButton, ...props }: Props) => {
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  return (
    <Container onSubmit={onSubmit} noValidate footerButton={footerButton} isKeyboardShow={isKeyboardShow} {...props}>
      {children}

      {!!footerButton && (
        <BottomWrapper isKeyboardShow={isKeyboardShow}>
          <Button type="submit" color="point" fontSize={15} fullWidth size="large56" variant="contained" {...footerButton}>
            {footerButton.text}
          </Button>
        </BottomWrapper>
      )}
    </Container>
  );
};

export default Form;

const Container = styled.form<Pick<Props, 'footerButton'> & { isKeyboardShow: boolean }>`
  ${({ footerButton }) => footerButton && 'padding-bottom: 82px;'}

  footer {
    position: ${({ isKeyboardShow }) => (isKeyboardShow ? 'static' : 'fixed')};
  }
`;

export const BottomWrapper = styled.footer<{ isKeyboardShow?: boolean }>`
  padding: ${({ isKeyboardShow }) => (isKeyboardShow ? '24px 0 0' : '10px 20px')};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};
`;
