import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Drawer from 'components/Drawer';
import Icon from 'components/Icon';
import CheckRadio from 'components/Radio/CheckRadio';
import Typography from 'components/Typography';
import { StudioResponse } from 'hooks/service/queries/useGetStudio';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { Fragment } from 'react/jsx-runtime';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import StudioDeletePopup from 'sharedComponents/Studio/StudioDeletePopup';

import { STUDIO_SELECT_DRAWER } from './constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  studios: StudioResponse['studios'];
};

const StudioSelectDrawer = ({ isOpen, onClose, studios = [] }: Props) => {
  const [currentStudioId, setCurrentStudioId] = useRecoilState(studioIdAtom);
  const setCurrentStaffId = useSetRecoilState(staffIdAtom);

  const { setPopup } = usePopup();
  const { setToast } = useToast();

  const currentStudio = studios.filter(studio => studio.id === currentStudioId)[0];

  const changeStudio = (studioId: number, staffId: number) => {
    setCurrentStudioId(studioId);
    setCurrentStaffId(staffId);
    setToast({ type: 'success', message: STUDIO_SELECT_DRAWER.success, bottom: 76 });
    onClose();
  };

  const openStudioDeletePopup = () => {
    setPopup(<StudioDeletePopup />);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} direction="top" backgroundColor={null}>
      <DrawerContent>
        <div className="title-wrapper">
          <Button
            fontSize={15}
            fontWeight={600}
            textColor="white"
            rightIcon={<Icon name={isOpen ? 'arrowTopFill' : 'arrowBottomFill'} size={16} fillColor={theme.color.white} />}
            onClick={onClose}>
            {currentStudio.name}
          </Button>

          <div className="setting-button">
            <Button fontSize={15} textColor="white" onClick={openStudioDeletePopup} textOpacity={0.8}>
              편집
            </Button>
          </div>
        </div>
        <ul className="studio-list">
          {studios.length &&
            studios.map(({ id, name, staff_id }, index) => {
              const isSelectedStudio = id === currentStudioId;
              return (
                <Fragment key={id}>
                  {index !== 0 && <Divider thinColor="white" thin />}
                  <li className="studio">
                    <CheckRadio
                      id={id.toString()}
                      label={
                        <Typography
                          size={17}
                          weight={isSelectedStudio ? 700 : 500}
                          textColor="white"
                          opacity={isSelectedStudio ? 1 : 0.48}>
                          {name}
                        </Typography>
                      }
                      value={id}
                      onChange={() => changeStudio(id, staff_id)}
                      checked={isSelectedStudio}
                      checkColor="white"
                    />
                  </li>
                </Fragment>
              );
            })}
        </ul>
      </DrawerContent>
    </Drawer>
  );
};

export default StudioSelectDrawer;

const DrawerContent = styled.div`
  background: ${theme.color.mainTopBackground};
  border-radius: 0px 0px 20px 20px;

  .title-wrapper {
    ${theme.flex()};
    padding: 17px;
  }

  .setting-button {
    position: absolute;
    right: 20px;
  }

  .studio-list {
    padding: 8px 0 16px;

    .studio {
      padding: 12px 20px;
    }

    hr {
      opacity: 0.08 !important;
    }
  }
`;
